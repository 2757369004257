import { toInteger } from 'lodash'
import { ICurveCoords, IIPointCoordsWithId, IPointCoords, IShape } from 'Types'
import {
  ESizeDirections,
  maxRadius,
  minRadius,
  roundingStep,
} from '../constants'

export class MathUtils {
  static isCircle = (shape: IShape): boolean => {
    return shape && toInteger(shape.radius) > 0
  }

  static roundCoord = (value: number, minValue: number = 0): number => {
    const rounded = Math.round(value / roundingStep) * roundingStep
    return rounded >= minValue ? rounded : minValue
  }

  // Calculations ----------------------------------------------------------------------------------
  static calculateResize = (
    shape: IShape,
    movementX: number,
    movementY: number,
    controlDirection: ESizeDirections
  ) => {
    let { x, y, radius } = shape
    if (controlDirection[0] === 'n') {
      movementY = movementY * -1
    }
    if (controlDirection[1] === 'w') {
      movementX = movementX * -1
    }

    radius = Math.min(
      Math.round(
        Math.max(
          shape.radius + movementX,
          shape.radius + movementY,
          minRadius
        ) / roundingStep
      ) * roundingStep,
      maxRadius
    )

    return {
      x,
      y,
      radius,
    }
  }

  static calculateMoving = (
    shape: IShape,
    movementX: number,
    movementY: number
  ): IPointCoords => {
    return {
      x: shape.x + movementX,
      y: shape.y + movementY,
    }
  }

  static calculateCurve = (
    startShape: IShape,
    endShape: IShape
  ): ICurveCoords => {
    // Vector length in coordinates
    let v = { x: endShape.x - startShape.x, y: endShape.y - startShape.y }
    // Scalar vector length
    const lineLength = Math.sqrt(v.x ** 2 + v.y ** 2) || 1

    const shortCurve = lineLength - startShape.radius - endShape.radius < 15

    if (lineLength === 0) {
      throw new Error('Length has to be positive')
    }
    // Normalization
    v = { x: v.x / lineLength, y: v.y / lineLength }
    // Scalar product of a vector on the radius of a circle
    const scalarMultiplyStart = {
      x: v.x * (startShape.radius + (shortCurve ? 0 : 5)),
      y: v.y * (startShape.radius + (shortCurve ? 0 : 5)),
    }
    const scalarMultiplyEnd = {
      x: v.x * (endShape.radius + 3 + (shortCurve ? 1 : 5)),
      y: v.y * (endShape.radius + 3 + (shortCurve ? 1 : 5)),
    }

    // Coordinates of the intersection of the vector with the circle
    const start: IIPointCoordsWithId = {
      id: startShape.id,
      x: startShape.x + scalarMultiplyStart.x,
      y: startShape.y + scalarMultiplyStart.y,
    }
    const end: IIPointCoordsWithId = {
      id: endShape.id,
      x: endShape.x - scalarMultiplyEnd.x,
      y: endShape.y - scalarMultiplyEnd.y,
    }

    return { start, end }
  }
}
