import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import * as I from 'Types'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import {
  EAPIResponseStatus,
  ENotificationVariant,
  EShapeStates,
  EShapeType,
  ETrackEngineMode,
} from '../../constants'
import NodesService from '../../services/nodes/nodes.service'
import TracksService from '../../services/tracks/tracks.service'
import { DiagramUtils, DOMUtils, MathUtils } from '../../utils'
import { setPermanentLoading } from '../loader/loaderSlice'
import {
  closeSnackBar,
  enqueueSnackbar,
} from '../notification/notificationSlice'
import {
  tracksEngineSlice,
  shapeSet,
  shapesSet,
  setEditTitle,
  shapeContentSet,
  createDataReset,
  shapesContentReset,
  shapesReset,
  editTitleReset,
  activeShapeIDsSet,
  shapesInvalid,
} from './tracksEngineSlice'
import local from './../../localization'

export const originalData: {
  shapes: Record<string, I.IShape | I.ICurve>
  content: Record<string, I.IDiagramContent>
} = {
  shapes: {},
  content: {},
}

export const diagramRestore = createAsyncThunk<
  void,
  I.IDiagramRestorePayload,
  { state: I.RootState }
>(
  'tracks/editor/diagramRestore',
  async (payload: I.IDiagramRestorePayload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(tracksEngineSlice.actions.setIsLoading(true))
      let endpoint = () => {
        if (payload.mode === ETrackEngineMode.EDIT) {
          return NodesService.getManagementNodes(Number(payload.trackId))
        } else if (payload.mode === ETrackEngineMode.VIEW) {
          return NodesService.getNodes(Number(payload.trackId))
        }
        // else if (payload.mode === ETrackEngineMode.PREVIEW) {
        //   return NodesService.getPreviewNodes(Number(payload.trackId))
        // } // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра
        else if (payload.mode === ETrackEngineMode.STAT_ITEMS_PROGRESS_MAP) {
          return NodesService.getStatItemsNodes(Number(payload.trackId))
        } else {
          return NodesService.getStatUsersNodes(
            payload.userIds!,
            Number(payload.trackId)
          )
        }
      }

      let nodes = await stackRequestWrapper(endpoint())

      if (nodes && nodes.status === EAPIResponseStatus.SUCCESS && nodes.data) {
        let diagram = DiagramUtils.restoreDiagram(nodes.data.nodes)
        if (diagram) {
          let { shapes, content } = diagram
          originalData.shapes = { ...shapes }
          originalData.content = { ...content }
          dispatch(shapesSet(shapes))
          dispatch(tracksEngineSlice.actions.shapesContentsSet(content))
          dispatch(
            tracksEngineSlice.actions.shapesSetTotal(nodes.data.nodes.length)
          )
          dispatch(tracksEngineSlice.actions.createDataReset())
          dispatch(tracksEngineSlice.actions.editTitleReset())
          dispatch(
            tracksEngineSlice.actions.setPageState(EShapeStates.CALMNESS)
          )
          dispatch(
            tracksEngineSlice.actions.setTrackRevision(nodes.data.revision)
          )
        } else {
          throw new Error(
            local.notification.track.engine.ERROR.diagramNodesError
          )
        }
      } else {
        throw new Error(local.notification.track.engine.ERROR.requestError)
      }

      dispatch(tracksEngineSlice.actions.resetScaleToStored())
      dispatch(tracksEngineSlice.actions.updateDrafts())
      dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    } finally {
      dispatch(tracksEngineSlice.actions.setIsLoading(false))
      dispatch(setPermanentLoading(false))
    }
  }
)

export const diagramStore = createAsyncThunk<
  void,
  { trackId: string; revision: number },
  { state: I.RootState }
>(
  'tracks/editor/diagramStore',
  async ({ trackId, revision }, { getState, dispatch, rejectWithValue }) => {
    try {
      let state = getState().tracks.engine
      let shapes = _.values(state.shapes).filter(
        s => s.type === EShapeType.CIRCLE
      ) as Array<I.IShape>
      let curves = _.values(state.shapes).filter(
        s => s.type === EShapeType.CURVE
      ) as Array<I.ICurve>

      const modulesAndLinks = shapes.map(s => ({
        id: s.id,
        parents: curves.filter(c => c.endID === s.id).map(c => c.startID),
        childrens: curves.filter(c => c.startID === s.id).map(c => c.endID),
      }))

      //#region Validation
      /**Checking for looping connections */
      const cycleArray: string[][] = []

      const cycleDetector = (ids: string[], childrens: string[]) => {
        const childModules = modulesAndLinks.filter(m =>
          childrens.includes(m.id)
        )

        childModules.forEach(cm => {
          if (ids.includes(cm.id)) {
            cycleArray.push(ids)
          } else {
            const arr = [...ids, cm.id]
            if (cm.childrens.length) {
              cycleDetector(arr, cm.childrens)
            }
          }
        })
      }

      modulesAndLinks
        .filter(m => m.childrens.length)
        .forEach(p => {
          cycleDetector([p.id], p.childrens)
        })

      /**Checking a single root */
      const roots = modulesAndLinks
        .filter(s => !s.parents.length && s.childrens.length)
        .map(r => r.id)

      if (cycleArray.length || roots.length > 1) {
        const ids: string[] = []

        if (cycleArray.length) {
          const newArr = _.uniq(_.flatten(cycleArray))
          ids.push(
            ...curves
              .filter(
                c => newArr.includes(c.startID) && newArr.includes(c.endID)
              )
              .map(c => c.id)
          )

          dispatch(
            enqueueSnackbar({
              message: local.notification.track.engine.ERROR.recursion,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: ENotificationVariant.ERROR,
              },
            })
          )
        }

        if (roots.length > 1) {
          ids.push(...roots)
          dispatch(
            enqueueSnackbar({
              message: local.notification.track.engine.ERROR.oneRoot,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: ENotificationVariant.ERROR,
              },
            })
          )
        }

        dispatch(shapesInvalid(ids))
        return
      }
      //#endregion

      let nodes: Array<I.ISaveNodeModel> = shapes.map(shape => {
        return {
          id: shape.id,
          title: state.content[shape.id]?.title!!,
          formattedTitle:
            state.content[shape.id]?.formattedTitle ||
            state.content[shape.id]?.title!!,
          coordinateX: shape.x,
          coordinateY: shape.y,
          radius: shape.radius,
          parentIds: curves
            .filter(curve => curve.endID === shape.id)
            .map(curve => curve.startID),
          childIds: curves
            .filter(curve => curve.startID === shape.id)
            .map(curve => curve.endID),
          revision: shape.revision,
          mandatory: shape.mandatory,
        }
      })

      let request: I.ISaveNodesRequest = {
        trackId,
        revision,
        nodes,
      }

      await stackRequestWrapper(NodesService.saveNodes(request))

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.saveNodes,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      dispatch(
        diagramRestore({
          mode: ETrackEngineMode.EDIT,
          trackId,
        })
      )

      dispatch(diagramReset())
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const shapesMove = createAsyncThunk<
  void,
  Array<I.IShapeMovePayload>,
  { state: I.RootState }
>(
  'tracks/editor/move',
  async (payload: Array<I.IShapeMovePayload>, { getState, dispatch }) => {
    const { shapes, scale, activeShapeIDs } = getState().tracks.engine

    const newShapes = { ...Object(shapes) }

    payload.forEach(p => {
      _.assign(newShapes, {
        [p.id]: { ...newShapes[p.id], x: p.movementX, y: p.movementY },
      })
    })

    dispatch(shapesSet(newShapes))

    if (newShapes[activeShapeIDs[0]].type !== EShapeType.CURVE) {
      dispatch(rebuildTouchedCurves(Object.keys(newShapes)))
    }

    dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())

    const x = Math.max.apply(
      null,
      payload.map(p => p.movementX)
    )
    const y = Math.max.apply(
      null,
      payload.map(p => p.movementY)
    )
    const radius = (newShapes[activeShapeIDs[0]] as I.IShape).radius

    dispatch(
      tracksEngineSlice.actions.scrollPage({
        x: (x + radius) * scale,
        y: (y + radius) * scale,
      })
    )
  }
)

export const copyNode = createAsyncThunk(
  'tracks/editor/copyNode',
  async (
    { nodeIds, trackId }: I.ICopyNodeRequest,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await stackRequestWrapper(NodesService.copyNode({ nodeIds, trackId }))

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.copied,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const shapesRemove = createAsyncThunk<
  void,
  Array<string>,
  { state: I.RootState }
>('tracks/editor/remove', (ids: Array<string>, { getState, dispatch }) => {
  const shapes = Object.values(getState().tracks.engine.shapes)
    .filter(
      shape =>
        ids.includes(shape.id) ||
        ids.includes((shape as I.ICurve).startID) ||
        ids.includes((shape as I.ICurve).endID)
    )
    .map(shape => shape.id)

  dispatch(tracksEngineSlice.actions.activeShapeIDsSet([]))
  dispatch(tracksEngineSlice.actions.shapesRemove(shapes))
  dispatch(tracksEngineSlice.actions.contentRemove(shapes))
  dispatch(tracksEngineSlice.actions.updateDrafts())
  dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())
})

export const rebuildTouchedCurves = createAsyncThunk<
  void,
  Array<string>,
  { state: I.RootState }
>(
  'tracks/editor/rebuildCurves',
  (ids: Array<string>, { getState, dispatch }) => {
    const { shapes } = getState().tracks.engine
    const touchedCurves = Object.values(shapes)
      .filter(shape => shape.type === EShapeType.CURVE)
      .map(shape => shape as I.ICurve)
      .filter(shape => ids.includes(shape.startID) || ids.includes(shape.endID))

    const newCurves = touchedCurves.reduce((acc, curve) => {
      const startShape = shapes[curve.startID] as I.IShape
      const endShape = shapes[curve.endID] as I.IShape
      if (!startShape || !endShape) {
        dispatch(shapesRemove([curve.id]))
        return acc
      }
      const { start, end } = MathUtils.calculateCurve(startShape, endShape)
      const resCurve = DiagramUtils.updateCurve(curve, start, end)
      acc = { ...acc, [curve.id]: resCurve }
      return acc
    }, {})

    dispatch(shapesSet(newCurves))
    dispatch(tracksEngineSlice.actions.updateDrafts())
  }
)

export const dndComplete = createAsyncThunk<
  void,
  Record<string, I.IShape>,
  { state: I.RootState }
>(
  'tracks/editor/dndComplete',
  (positions: Record<string, I.IShape>, { dispatch }) => {
    const newPositions = Object.values(positions).reduce((acc, p) => {
      const resPosition = {
        ...p,
        x: MathUtils.roundCoord(p.x),
        y: MathUtils.roundCoord(p.y),
      }
      _.assign(acc, { [p.id]: resPosition })
      return acc
    }, {})

    dispatch(shapesSet(newPositions))
    dispatch(rebuildTouchedCurves(Object.keys(newPositions)))
  }
)

export const createComplete = createAsyncThunk<
  void,
  I.IPointCoords,
  { state: I.RootState }
>(
  'tracks/editor/createComplete',
  (payload: I.IPointCoords, { getState, dispatch }) => {
    const { create: createData, scale, content } = getState().tracks.engine
    const { shapeType } = createData
    const { x, y } = payload

    const resX = MathUtils.roundCoord(x) / scale
    const resY = MathUtils.roundCoord(y) / scale

    // Box or Circle
    if (shapeType === EShapeType.BOX || shapeType === EShapeType.CIRCLE) {
      const shape = DiagramUtils.createCircle(resX, resY)
      shape.editTitle = true
      shape.draft = true
      const shapeContent = DiagramUtils.createShapeContent(shape.id, content)
      dispatch(
        setEditTitle({
          top: 0,
          left: 0,
          title: '',
          formattedTitle: '',
          show: false,
        })
      )
      dispatch(shapeContentSet({ id: shape.id, shapeContent }))
      dispatch(shapeSet({ id: shape.id, shape }))
      dispatch(activeShapeIDsSet([shape.id]))
      dispatch(closeSnackBar())
      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.INFO.addNodeTitle,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.INFO,
          },
        })
      )
    }
    dispatch(tracksEngineSlice.actions.updateDrafts())
    dispatch(createDataReset())
  }
)

export const createCurveComplete = createAsyncThunk<
  void,
  I.ICreateCurveCompletePayload,
  { state: I.RootState }
>(
  'tracks/editor/createCurveComplete',
  (payload: I.ICreateCurveCompletePayload, { getState, dispatch }) => {
    const { shapes } = getState().tracks.engine
    const { startShapeID, endShapeID } = payload

    const startShape = shapes[startShapeID] as I.IShape
    const endShape = shapes[endShapeID] as I.IShape

    const { start, end } = MathUtils.calculateCurve(startShape, endShape)

    const curve = DiagramUtils.createCurve(start, end)

    dispatch(shapeSet({ id: curve.id, shape: curve }))
    dispatch(activeShapeIDsSet([endShapeID]))
    dispatch(createDataReset())
    dispatch(tracksEngineSlice.actions.updateDrafts())
  }
)

export const diagramDownload = createAsyncThunk<
  void,
  string,
  { state: I.RootState }
>(
  'tracks/editor/diagramDownload',
  async (trackId: string, { dispatch, rejectWithValue }) => {
    try {
      await stackRequestWrapper(TracksService.exportTrack(trackId))

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.export,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const diagramReset = createAsyncThunk<
  void,
  void,
  { state: I.RootState }
>('tracks/editor/diagramReset', (_, { dispatch }) => {
  dispatch(activeShapeIDsSet([]))
  dispatch(shapesContentReset())
  dispatch(shapesReset())
  dispatch(tracksEngineSlice.actions.shapesSetTotal())
  dispatch(createDataReset())
  dispatch(editTitleReset())
})

export const uploadFileSelect = createAsyncThunk<
  void,
  void,
  { state: I.RootState }
>('tracks/editor/uploadFileSelect', _ => {
  DOMUtils.clickUploadInput()
})

export const incrementScale = createAsyncThunk<
  void,
  void,
  { state: I.RootState }
>('tracks/editor/incrementScale', (_, { dispatch }) => {
  dispatch(tracksEngineSlice.actions.incrementScale())
  dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())
})

export const decrementScale = createAsyncThunk<
  void,
  void,
  { state: I.RootState }
>('tracks/editor/decrementScale', (_, { dispatch }) => {
  dispatch(tracksEngineSlice.actions.decrementScale())
  dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())
})

export const resetScale = createAsyncThunk<void, void, { state: I.RootState }>(
  'tracks/editor/resetScale',
  (_, { dispatch }) => {
    dispatch(tracksEngineSlice.actions.resetScale())
    dispatch(tracksEngineSlice.actions.calculatePageWidthAndHeight())
  }
)
