import local from './../localization'

export * from './tracks-editor/dnd'
export * from './tracks-editor/editor'
export * from './tracks-editor/layout'
export * from './tracks-editor/machines'
export * from './tracks-editor/common'

/**Permissions */
export enum Authorities {
  HOME_PAGE = 'HOME_PAGE',
  PROFILE_PAGE = 'PROFILE_PAGE',
  /** Available tracks page (User)*/
  AVAILABLE_TRACKS_PAGE = 'AVAILABLE_TRACKS_PAGE',
  /** Cymbals page of available tracks (User)*/
  AVAILABLE_NODES_PAGE = 'AVAILABLE_NODES_PAGE',
  /** Module familiarization page (User)*/
  TOPIC_PAGE = 'TOPIC_PAGE',
  /** Passing task page (also for passing result) (User)*/
  PASS_TASK_PAGE = 'PASS_TASK_PAGE',
  /** Viewing page of the submitted solution (on checking or rejected) (User)*/
  VIEW_PASS_TASK_PAGE = 'VIEW_PASS_TASK_PAGE',
  /** Statistics page (Admin or User-manager and now mentor)*/
  STAT_PAGE = 'STAT_PAGE',
  /** Track management page (User-manager or Admin)*/
  MANAGEMENT_TRACKS_PAGE = 'MANAGEMENT_TRACKS_PAGE',
  /** Editing a card (User-manager or Admin) */
  EDIT_TRACKS_CARD = 'EDIT_TRACKS_CARD',
  /** Viewing and editing your tracks (User-Manager)*/
  MANAGEMENT_TRACKS_ONLY_ALLOWED = 'MANAGEMENT_TRACKS_ONLY_ALLOWED',
  /** Viewing and editing all tracks (Admin)*/
  MANAGEMENT_TRACKS_ALL = 'MANAGEMENT_TRACKS_ALL',
  /** Page of creating and management of modules (User-manager or Admin)*/
  MANAGEMENT_NODES_PAGE = 'MANAGEMENT_NODES_PAGE',
  /** Permission to add a module (User-manager or Admin) */
  ADD_NODE = 'ADD_NODE',
  /** Permission to edit a module (User-Manager or Admin) */
  EDIT_NODE = 'EDIT_NODE',
  /** Permission to delete the module (User-Manager or Admin) */
  DELETE_NODE = 'DELETE_NODE',
  /** Page for editing the description and task for a module (User-Manager or Admin)*/
  EDIT_TASK_PAGE = 'EDIT_TASK_PAGE',
  /** Clear pass (User-Manager or Admin)*/
  RESET_TASK_PASS = 'RESET_TASK_PASS',
  /** Task validation table page (User-Manager, User-Mentor or Admin)*/
  CHECK_TASKS_PAGE = 'CHECK_TASKS_PAGE',
  /** List of all sent decisions (Admin) */
  TASKS_CHECK_SHOW_ALL = 'TASKS_CHECK_SHOW_ALL',
  /** List of sent solutions by track (User-Manager and User-Mentor)*/
  TASKS_CHECK_SHOW_ONLY_ALLOWED = 'TASKS_CHECK_SHOW_ONLY_ALLOWED',
  /** Viewing page of the solution (User-Manager, User-Mentor or Admin)*/
  CHECK_TASK_PAGE = 'CHECK_TASK_PAGE',
  /** Permission to comment (Managing user, User-Manager or Admin)*/
  CHECK_TASK_COMMENT = 'CHECK_TASK_COMMENT',
  /** Permission to make decisions (Managing user, User-Mentor or Admin)*/
  CHECK_TASK_VERIFICATION = 'CHECK_TASK_VERIFICATION',
  /** Users page (Admin) */
  USERS_PAGE = 'USERS_PAGE',
  /** Adding a user (Admin)*/
  ADD_USER = 'ADD_USER',
  /** Editing of user (Admin) */
  EDIT_USER = 'EDIT_USER',
  /** Reset password for user (Admin) */
  RESET_PASSWORD = 'RESET_PASSWORD',
  /** Deleting of user (Admin) */
  DELETE_USER = 'DELETE_USER',
  /** Post page (Admin) */
  POSITIONS_PAGE = 'POSITIONS_PAGE',
  /** Adding posts (Admin)*/
  ADD_POSITION = 'ADD_POSITION',
  /** Post editing (Admin)*/
  EDIT_POSITION = 'EDIT_POSITION',
  /** Deleting of a post (Admin)*/
  DELETE_POSITION = 'DELETE_POSITION',
  /** Tracks page (Admin)*/
  ITEMS_PAGE = 'ITEMS_PAGE',
  /** Adding tracks (Admin)*/
  ADD_ITEM = 'ADD_ITEM',
  /** Editing tracks (Admin)*/
  EDIT_ITEM = 'EDIT_ITEM',
  /** Deleting tracks (Admin)*/
  DELETE_ITEM = 'DELETE_ITEM',
  /** View user profile page */
  USERS_PROFILES = 'USERS_PROFILES',
}

/**Pages */
export enum EPages {
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  RESTORE = '/restore',
  NEW_PASSWORD = '/new_password',
  PROFILE = '/profile',
  USERS_PROFILE = '/profiles/:userId',
  AVAIBLE_TRACKS = '/tracks/available',
  AVAIBLE_TRACKS_NODES = '/tracks/available/:trackId',
  AVAIBLE_TRACKS_PASSING = '/tracks/available/:trackId/:taskId',
  AVAIBLE_TRACKS_PASSING_START = '/tracks/available/:trackId/:taskId/start',
  AVAIBLE_TRACKS_PASSING_RESULT = '/tracks/available/:trackId/:taskId/result',
  AVAIBLE_TRACKS_VIEW_PASSING_TASK = '/tracks/available/:trackId/:taskId/view',
  STATS_BY_USERS = '/stats/users',
  STATS_BY_USER_FOR_ITEM = '/stats/users/:userId/:trackId',
  STATS_BY_ITEMS = '/stats/items',
  TRACKS_MANAGEMENT = '/tracks/management',
  TRACKS_MANAGEMENT_DETAILS = '/tracks/management/:trackId',
  TRACKS_MANAGEMENT_DETAILS_EDIT_TASK_DETAILS = '/tracks/management/:trackId/:taskId',
  CHECK_TASKS = '/check_tasks',
  CHECK_TASK_DETAILS = '/check_tasks/:taskId/:userId',
  REFERENCE = '/reference',
  PROFILE_SETTINGS = '/profile/settings',
  USERS = '/users',
  POSITIONS = '/positions',
  NOT_FOUND = '/404',
  MAINTENANCE = '/maintenance',
  TEST = '/test',
}

/**Response Statuses */
export enum EAPIResponseStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
}

/**Status of the action */
export enum EStateStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

/**Popup message type */
export enum ENotificationVariant {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

/**Type of form (edit/add) */
export enum ETypeForm {
  EDIT = 'editType',
  ADD = 'addType',
}

export enum ERoleUser {
  ADMIN = 'Administrator',
  USER = 'User',
}

export const DEFAULT_USER_ROLE = {
  key: 2,
  value: 'User',
}

export enum EQuestionType {
  TEST = 'TEST',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  VIDEO_INTERVIEW = 'VIDEO_INTERVIEW',
}

export enum EAnswerType {
  MULTIPLE_TEST = 'MULTIPLE_TEST',
  SINGLE_TEST = 'SINGLE_TEST',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
}

/**Type of filtration */
export enum ESearchFilter {
  USERS = 'users',
  POSITIONS = 'positions',
  ITEMS = 'items',
  TRACKS = 'tracks',
  NODES = 'nodes',
  ROLES = 'roles',
}

/**Tabs on the statistics pages */
export enum EStatTab {
  TABLE = 'table',
  MAP = 'map',
  STAT = 'stat',
}

export interface IKeyValuePair {
  key: any
  value: string
}

/**Passing status of the task*/
export enum EPassResult {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  TIMEOUT = 'TIMEOUT',
  ERROR = 'ERROR',
}

export enum ENodeStatus {
  UNAVAILABLE = 'UNAVAILABLE',
  REJECTED = 'REJECTED',
  CHECKING = 'CHECKING',
  COMPLETED = 'COMPLETED',
  AVAILABLE = 'AVAILABLE',
}

/**Recent event statuses on the profile page */
export enum EActionType {
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  SENT = 'SENT',
  DROPPED_NODE_STATUS = 'DROPPED_NODE_STATUS',
}

export enum EInterval {
  MICROSECONDS = 'microseconds',
  MILLISECONDS = 'milliseconds',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  DECADE = 'decade',
  CENTURY = 'century',
  MILLENNIUM = 'millennium',
}

export enum ETaskViewMode {
  CHECKING = 'CHECKING',
  VIEWING = 'VIEWING',
}

export enum EResetMode {
  RESET = 'reset',
  NOTIFICATION = 'notification',
}

export enum EVideoAnswerType {
  ATTACH_OR_RECORD = 'ATTACH_OR_RECORD',
  RECORD = 'RECORD',
}

/** Universal type for Object with key-value fields */
export type TMap = Record<string | number, string>

export const cyrillicRegExp = /[а-яё]/i
export const smallLettersRegExp = /[a-z]+/g
export const bigLettersRegExp = /[A-Z]+/g
export const digitsRegExp = /\d/g
export const specialSymbolsRegExp = /[^a-zA-Zа-яА-Я0-9\s]+/g
export const spaceLetterRegExp = /\s/g

// eslint-disable-next-line no-useless-escape
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"а-яА-Я]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum ELocalStorageKeys {
  TRACK_SCALE = 'EIS_TRACK_SCALE',
}

export enum ETaskStatus {
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CHECKING = 'CHECKING',
  NOT_COMPLETED = 'NOT_COMPLETED',
  ALL = '',
}

export const optionSelectNodeStatusList: Array<IKeyValuePair> = [
  { key: ETaskStatus.COMPLETED, value: local.ENodeStatus.COMPLETED },
  { key: ETaskStatus.REJECTED, value: local.ENodeStatus.REJECTED },
  { key: ETaskStatus.CHECKING, value: local.ENodeStatus.CHECKING },
  { key: ETaskStatus.NOT_COMPLETED, value: local.ENodeStatus.NOT_COMPLETED },
  { key: ETaskStatus.ALL, value: local.ENodeStatus.ALL },
]

export const optionStatItemsTab = [
  { label: local.EStatTabs.TABLE, value: EStatTab.TABLE, disabled: false },
  { label: local.EStatTabs.MAP, value: EStatTab.MAP, disabled: true },
  { label: local.EStatTabs.STAT, value: EStatTab.STAT, disabled: false },
]

export const optionSelectList: Array<IKeyValuePair> = [
  { key: true, value: local.ESelectList.YES },
  { key: false, value: local.ESelectList.NO },
  { key: undefined, value: local.ESelectList.ALL },
]

export const optionSelectOnlineStatusList: Array<IKeyValuePair> = [
  { key: 'ONLINE', value: local.ESelectOnlineStatusList.ONLINE },
  { key: 'OFFLINE', value: local.ESelectOnlineStatusList.OFFLINE },
  { key: 'NO_STATUS', value: local.ESelectOnlineStatusList.NO_STATUS },
  { key: undefined, value: local.ESelectOnlineStatusList.ALL },
]

export const EValidationMessage = local.EValidationMessage
export const EStatusUser = local.EStatusUser
export const EStatusItem = local.EStatusItem
export const EStatusPosition = local.EStatusPosition

export enum EEditorButtonSize {
  'tiny' = 'tiny',
  'xsmall' = 'xsmall',
  'small' = 'small',
  'middle' = 'middle',
  'large' = 'large',
}

export interface IReferenceState {
  featuresView: boolean
  problemView: boolean
  passingView: boolean
  fastNoteView: boolean
}

export enum EReferenceState {
  FEATURES = 'featuresView',
  PROBLEM = 'problemView',
  FAST_NOTE = 'fastNoteView',
  PASSING = 'passingView',
}

export const answerKeysLimit = 50
export const testItemsLimit = 10

export enum ESourceType {
  TOPIC = 'topic',
  ATTEMPT = 'attempt',
}

export enum ETabState {
  CARD = 'card',
  TABLE = 'table',
}

export const rootArea = { id: 1, title: local.tracksManagement.rootAreaTitle }

export enum EPeriods {
  CW = 'currentWeek',
  LW = 'lastWeek',
  CM = 'currentMonth',
  LM = 'lastMonth',
  CY = 'currentYear',
  AT = 'allTime',
}
