export const createDefaultStore = (
  storage: () => Storage,
  initData: Record<string, string | null> = {}
): {
  getItem: (key: string) => string | null
  setItem: (key: string, value: string) => void
  removeItem: (key: string) => void
} => {
  const data: Record<string, string | null> = initData

  const getItem = (key: string): string | null =>
    data[key] || storage().getItem(key)

  const setItem = (key: string, value: string): void => {
    data[key] = value
    storage().setItem(key, value)
  }

  const removeItem = (key: string): void => {
    delete data[key]
    storage().removeItem(key)
  }

  return { getItem, setItem, removeItem }
}
