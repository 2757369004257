export const registration = {
  agreementPersonDataTitle: 'Согласие на обработку персональных данных',
  agreementUserTitle: 'Пользовательского соглашения',
  sublicenseTitle: 'Сублицензионного договора',
  form: {
    fullName: 'ФИО',
    username: 'Логин (e-mail)',
    phoneNumber: 'Номер телефона',
    phoneNumberSearchPlaceholder: 'Поиск',
    password: 'Пароль',
    passwordConfirm: 'Подтверждение пароля',
    agreement: 'Нажимая кнопку "Зарегистрироваться", вы принимаете условия ',
    and: ' и ',
  },
  title: 'Регистрация',
  registrationResult: {
    result: 'Регистрация завершена',
    info:
      'Для подтверждения учетной записи, перейдите по ссылке, отправленной на указанную почту',
    ps: 'Данную вкладку можно закрыть',
  },
  registrationButton: 'Зарегистрироваться',
  instructionButton: 'Инструкция для регистрации',
  backToSignInLink: 'Вернуться на страницу входа',
}
