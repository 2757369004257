export const recoveryPassword = {
  form: {
    help:
      "Please enter your email address below, and we'll send you a link to set a new password",
    username: 'Login (email)',
  },
  title: 'Password recovery',
  titleRecovery: 'Enter a new password',
  recoveryResult: {
    info: "We've sent you an email with a link to reset your password",
    ps: 'You can close this tab',
  },
  recoveryButton: 'Send',
  backToSignInLink: 'Back to Login Page',
  validation: {
    emailRequired: 'Required',
    emailValid: 'Login must be a valid email address',
  },
}
