import React, { FC } from 'react'
import { Tooltip } from 'antd'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Styles from './EISInfo.module.scss'

interface IEISInfo {
  text: string
  /** Width & height in px (default 20px) */
  size?: number
}

const EISInfo: FC<IEISInfo> = ({ text, size = 20 }) => {
  return (
    <Tooltip destroyTooltipOnHide title={text}>
      <InfoOutlinedIcon
        style={{ width: `${size}px`, height: 'auto' }}
        className={Styles.info}
      />
    </Tooltip>
  )
}

export default EISInfo
