export const items = {
  columns: {
    title: 'İsim',
    description: 'Tanım',
    embeddedIn: 'Klasör içine gömülü',
    managers: 'Yöneticileri takip et',
    mentors: 'Eğitmenler',
    users: 'Kullanıcılar',
    isActive: {
      title: 'Aktif',
      true: 'Evet',
      false: 'Hayır',
      isBlocked: 'Etkin olmayan parça',
    },
    createdTime: 'Oluşturma tarihi',
    updatedTime: 'Güncelleme tarihi',
    action: {
      title: 'Eylem',
      editButton: 'Değiştir',
      deleteButton: 'Sil',
    },
  },
  emptyData: {
    noData: 'Eklenmiş rota yok',
    noFilterItems: 'Seçilen filtrelerle eşleşen veri yok',
  },
  filter: {
    item: 'Bir rota seçin',
    withMentor: 'Bir eğitmen ile',
    isActive: 'Aktif',
  },
  addItemButton: 'Rota ekle',
  deleteItemModal: {
    title: 'Rota',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Aktif',
      disable: 'Pasif',
    },
    title: {
      add: 'Rota ekle',
      edit: 'Rota düzenle',
      delete: 'Parçayı sil',
      export: 'Eğitim rotasını dışa aktar',
    },
    cancelText: 'Kaldır',
    okText: 'Kaydet',
    back: 'Geri',
    addGroup: 'Grup Ekle',
    divideGroup: 'Gruplara göre bölün',
    groupsTitle:
      'Görevleri kontrol etmek için Mentor(lar) - Kullanıcı(lar) grupları oluşturun',
    formItem: {
      title: {
        label: 'Rota ismi',
        placeholder: 'Rota adını girin',
      },
      description: {
        label: 'Rota tanımı',
        placeholder: 'Açıklama girin',
      },
      status: {
        label: 'Rota durumu',
        placeholder: 'Aktif / Pasif',
        isBlocked: 'Etkin olmayan parça',
      },
      managers: {
        filterInputLabel: 'Yönetici',
        label: 'Rota yöneticisi',
        placeholder: 'Rota yöneticilerini seç',
      },
      mentors: {
        filterInputLabel: 'Eğitmenle',
        label: 'Eğitmenler',
        placeholder: 'Eğitmenleri seç',
      },
      users: {
        filterInputLabel: 'Kullanıcı',
        label: 'Kullanıcılar',
        placeholder: 'Kullanıcıları seç',
      },
      folder: {
        label: 'Klasör',
        placeholder: 'Bir klasör seçin',
      },
      isDefault: {
        label: 'Varsayılan parça',
        placeholder:
          'Bu ayar etkinse, bu parça tüm yeni kullanıcılara otomatik olarak atanacaktır',
      },
      isShadowMode: {
        label: '"Gölge Harita" Modu',
        placeholder:
          'Mod etkinleştirildiğinde, kullanıcı aktif olmayan modüller (gri renkli) hariç tüm modülleri görecektir.',
      },
    },
    disableItemModal: {
      title: 'Rota kilitleme',
      content:
        'Bu rotayı pasif hale getirmek istediğinizden emin misiniz? Bu rotaya atanan tüm kullanıcılar (konumlarına göre atananlar dahil) üzerindeki görevlerin tamamlanma ilerlemesi kaybolacaktır. Ayrıca bu rotanın, atandığı tüm konumlardan da bağlantısı kesilecektir. Rotaya atanan tüm eğitmenler ve rota yöneticilerinin de bağlantısı kesilecektir.',
      okText: 'Evet',
      cancelText: 'Hayır',
    },
  },
}
