import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ENotificationVariant } from '../../constants'
import * as I from 'Types'

const initialState: I.NotificationState = {
  notifications: [],
}

const isAsyncAction = (action: AnyAction): action is I.RejectedAction => {
  const { type } = action
  const matches = /(.*)\/(rejected)/.exec(type)

  return !!matches
}

const notifierSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<I.Notification>) => {
      if (
        !state.notifications.some(n => n.message === action.payload.message)
      ) {
        state.notifications.push(action.payload)
      }
    },
    closeSnackBar: (state, action: PayloadAction<number | undefined>) => {
      const dismissAll = !action.payload

      state.notifications = state.notifications.map(notification =>
        dismissAll || notification.options.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    },
    removeSnackbar: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        notification => notification.options.key !== action.payload
      )
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAsyncAction,
      (state, action: PayloadAction<string>) => {
        if (
          action.payload &&
          !state.notifications.some(n => n.message === action.payload)
        ) {
          state.notifications.push({
            message: action.payload,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: ENotificationVariant.ERROR,
            },
          })
        }
      }
    )
  },
})

export const {
  enqueueSnackbar,
  closeSnackBar,
  removeSnackbar,
} = notifierSlice.actions

export const getAllNotifications = (state: I.RootState) =>
  state.notifications.notifications

export const { reducer: notifierReducer } = notifierSlice
