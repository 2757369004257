import { useCallback, useEffect, useState } from 'react'
import { IAPIResult } from '../../IBaseService'
import { AuthResponse } from '../api/IAuthService'
import * as I from './IAuthProvider'
import { Authorities, EAPIResponseStatus } from '../../../constants'

import AuthService, { TokenService } from '../api/auth.service'

import { createDefaultStore } from './authStore'
import { createTokenProvider } from './tokenProvider'
import { createAuthorityProvider } from './authoritiesProvider'
import { processError } from './processError'

const _storage = (): Storage =>
  localStorage.getItem('ps') === '1' ? sessionStorage : localStorage

export const createAuthProvider = ({
  localStorageTokenKey = 'EIS_AUTH_KEY',
  localStorageAuthoritiesKey = 'EIS_AUTHORITIES_KEY',
  storage = createDefaultStore(_storage, {
    [localStorageTokenKey]: _storage().getItem(localStorageTokenKey),
    [localStorageAuthoritiesKey]: _storage().getItem(
      localStorageAuthoritiesKey
    ),
  }),
  customFetch,
}: I.AuthProviderConfig): [
  typeof useAuth,
  typeof authFetch,
  typeof isInRole,
  typeof tp.refreshToken,
  typeof authService,
  typeof logout
] => {
  const tokenService = new TokenService()
  const ap = createAuthorityProvider({
    localStorageKey: localStorageAuthoritiesKey,
    onUpdateAuthorities: tokenService.getAuthorities,
    processError,
    storage,
  })

  const logout = (): void => {
    tp.setToken(null)
    ap.setAuthorities(null)
  }

  const tp = createTokenProvider({
    localStorageKey: localStorageTokenKey,
    onUpdateToken: tokenService.refreshToken,
    onUpdateAuthority: ap.refreshAuthorities,
    logout,
    processError,
    storage,
  })

  const login = async (newTokens: AuthResponse): Promise<IAPIResult<void>> => {
    await ap.refreshAuthorities(newTokens?.accessToken)

    tp.setToken(newTokens)

    return new Promise(resolve =>
      resolve({ status: EAPIResponseStatus.SUCCESS })
    )
  }

  const authFetch = async (
    input: RequestInfo,
    init?: RequestInit
  ): Promise<Response> => {
    const token = await tp.getToken()

    const _init = init || {}

    if (token) {
      _init.headers = {
        ..._init.headers,
        Authorization: `Bearer ${token}`,
      }
    }

    if (customFetch) {
      return customFetch(input, init)
    }

    return fetch(input, init)
  }

  const authService = new AuthService(authFetch, login, logout)

  const useAuth = (): [boolean] => {
    const [isLogged, setIsLogged] = useState(tp.isLoggedIn())

    const listener = useCallback(
      (newIsLogged: boolean) => {
        setIsLogged(newIsLogged)
      },
      [setIsLogged]
    )

    useEffect(() => {
      tp.subscribe(listener)
      return (): void => {
        tp.unsubscribe(listener)
      }
    }, [listener])
    // TODO: Removed mock
    return [isLogged] as [typeof isLogged]
  }

  const isInRole = (authority: Authorities): boolean => {
    const authorities = ap.getAuthorities() || []
    // TODO: Removed mock
    return (
      authorities.length > 0 &&
      authorities.some(a => a.toLowerCase() === authority.toLowerCase())
    )
  }

  return [
    useAuth,
    authFetch,
    isInRole,
    tp.refreshToken,
    authService,
    logout,
  ] as [
    typeof useAuth,
    typeof authFetch,
    typeof isInRole,
    typeof tp.refreshToken,
    typeof authService,
    typeof logout
  ]
}
