import React, { useEffect } from 'react'
import Styles from './Error404.module.scss'
import local from './../../localization'

export const Error404: React.FC = (): React.ReactElement => {
  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.className = Styles.error
    }

    return () => {
      if (body) {
        body.className = ''
      }
    }
  }, [])

  return (
    <div className={Styles.content}>
      <a href='/'>{local.main.returnToMain}</a>
    </div>
  )
}
