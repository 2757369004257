export const trackEngine = {
  deleteModal: {
    title: 'Delete',
    okText: 'Yes',
    cancelText: 'No',
    deleteNode:
      'Are you sure you want to completely remove the module(-s), description(-s), and all user completions and progress?',
    deleteCurve: 'Are you sure you want to remove the relation?',
  },
  editModal: {
    saveButton: 'Save',
  },
  notification: {
    saveNodes: 'Changes saved successfully',
    export: 'Export completed',
    moveInNode:
      'To start doing the module task use the button on control panel',
  },
  toolbar: {
    title: 'Control panel',
    importButton: 'Import map',
    addNodeButton: 'Add module',
    addCurveButton: 'Create a relation',
    editNodeTitleButton: 'Edit the title',
    removeButton: 'Delete element',
    saveButton: 'Save Changes',
    moveInNodeButton: 'Start doing the task (Double click)',
    notSave: 'First save the map',
    copyNode: 'Copy the module(-s)',
    searchTrack: 'Copy to ...',
    scale: {
      resetScaleButton: 'Reset scaling',
      zoomInButton: 'Zoom in',
      zoomOutButton: 'Zoom out',
    },
    export: {
      tooltip: 'Export the Map',
      validation: 'There are unsaved changes, save the map first',
      button: 'Export',
    },
    // preview: 'Preview',
    // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра
  },
  draft: 'DRAFT',
  contains: {
    hasTopic: 'Description',
    hasTest: 'Test question',
    hasQuestion: 'Detailed question',
    hasVideo: 'Video question',
  },
  emptyData: 'No data',
}
