import React, { FC, ReactElement } from 'react'
import { EISModal } from '../EISComponents'
import styles from './PDFViewer.module.scss'
import { Worker, Viewer, LocalizationMap } from '@react-pdf-viewer/core'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import en_US from '@react-pdf-viewer/locales/lib/en_US.json'
import { ToolbarSlot } from '@react-pdf-viewer/toolbar'

import {
  defaultLayoutPlugin,
  ToolbarProps,
} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import '@react-pdf-viewer/core/lib/styles/index.css'

interface IPDFViewer {
  src: string
  title: string
  setVisible: (f: boolean) => void
}

const language = localStorage.getItem('language')

const PDFViewer: FC<IPDFViewer> = ({ src, title, setVisible }) => {
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          NumberOfPages,
          Print,
          Zoom,
          ZoomIn,
          ZoomOut,
          SwitchTheme,
        } = slots
        return (
          <div className={styles.customToolbar}>
            <div className={styles.__item}>
              <CurrentPageInput /> / <NumberOfPages />
            </div>
            <div className={`${styles.__item} ${styles.__mlAuto}`}>
              <ZoomOut />
            </div>
            <div className={styles.__item}>
              <Zoom />
            </div>
            <div className={styles.__item}>
              <ZoomIn />
            </div>
            <div className={`${styles.__item} ${styles.__mlAuto}`}>
              <EnterFullScreen />
            </div>
            <div className={styles.__item}>
              <Download />
            </div>
            <div className={styles.__item}>
              <Print />
            </div>
            <div className={styles.__item}>
              <SwitchTheme />
            </div>
          </div>
        )
      }}
    </Toolbar>
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
    renderToolbar,
  })
  return (
    <EISModal
      open={true}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      title={title}
      className={styles.PDFViewerModal}
      width={1000}
      style={{ top: 20 }}
      footer={[<></>]}
    >
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
        <div className={styles.PDFViewer}>
          <Viewer
            localization={
              language === 'ru'
                ? ((ru_RU as unknown) as LocalizationMap)
                : ((en_US as unknown) as LocalizationMap)
            }
            plugins={[defaultLayoutPluginInstance]}
            fileUrl={src}
          />
        </div>
      </Worker>
    </EISModal>
  )
}
export default React.memo(PDFViewer)
