import React from 'react'
import ViewTable from '../../../../../components/ViewTable/ViewTable'
import moment from 'moment'
import { useSelector } from 'react-redux'
import * as I from 'Types'
import {
  profileStatTasks,
  selectStatTracks,
  selectTasksStatus,
  changePage,
  changeSorted,
} from '../../../profileSlice'
import { ENodeStatus, EPages } from '../../../../../constants'
import { useHistory } from 'react-router'
import { useAppDispatch } from '../../../../../app/store'
import { IProfileStatProps } from 'Types'
import {
  ColumnsType,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import {
  ETables,
  TArrayColumnType,
} from '../../../../../components/ViewTable/IViewTable'
import style from './ProfileTasks.module.scss'
import local from './../../../../../localization'
import { Tooltip } from 'antd'

const ProfileTasksPL: React.FC<IProfileStatProps> = ({
  userId,
}): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const tasksData = useSelector(selectTasksStatus)
  const {
    tasks,
    tasksPage,
    tasksSortedInfo,
    tasksTotal,
    rangeDate,
    track,
  } = useSelector(selectStatTracks)

  const handleTask = (node: I.INodeAction) => () => {
    if (node)
      history.push(
        EPages.AVAIBLE_TRACKS_PASSING.replace(
          ':trackId',
          node.trackId.toString()
        ).replace(':taskId', node.id.toString())
      )
  }

  const mainColumns = ['id', 'status']

  const columns: ColumnsType<TArrayColumnType> = [
    {
      title: local.profile.stat.tasks.columnTasksTable.name,
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder:
        tasksSortedInfo?.columnKey === 'id'
          ? tasksSortedInfo?.order
          : undefined,
      width: 300,
      ellipsis: true,
      className: style.taskTitleWrapper,
      render: (id: number) => {
        const task = tasks.find(
          t => Number(`${t.node.trackId}${t.node.id}`) === id
        )
        return task &&
          (task.status === ENodeStatus.CHECKING ||
            task.status === ENodeStatus.COMPLETED ||
            task.status === ENodeStatus.REJECTED) &&
          !userId ? (
          <Tooltip
            placement='bottomLeft'
            title={tasksData.find(t => t.id === id)?.title}
            mouseLeaveDelay={0}
          >
            <span onClick={handleTask(task.node)} className={style.taskTitle}>
              {tasksData.find(t => t.id === id)?.title}
            </span>
          </Tooltip>
        ) : (
          <span>{tasksData.find(t => t.id === id)?.title}</span>
        )
      },
    },
    {
      title: local.profile.stat.tasks.columnTasksTable.status,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: true,
      sortOrder:
        tasksSortedInfo?.columnKey === 'status'
          ? tasksSortedInfo?.order
          : undefined,
      width: 130,
      render: (status: ENodeStatus) => {
        switch (status) {
          case ENodeStatus.REJECTED:
            return (
              <b className={style.rejected}>
                {local.profile.stat.tasks.columnTasksTable.statusRejected}
              </b>
            )
          case ENodeStatus.COMPLETED:
            return (
              <b className={style.completed}>
                {local.profile.stat.tasks.columnTasksTable.statusCompleted}
              </b>
            )
          case ENodeStatus.CHECKING:
            return (
              <b className={style.checking}>
                {local.profile.stat.tasks.columnTasksTable.statusChecking}
              </b>
            )
          case ENodeStatus.UNAVAILABLE:
          case ENodeStatus.AVAILABLE:
          default:
            return (
              <b className={style.default}>
                {local.profile.stat.tasks.columnTasksTable.statusUnavailable}
              </b>
            )
        }
      },
    },
    {
      title: local.profile.stat.tasks.columnTasksTable.timeCompleted,
      dataIndex: 'timeCompleted',
      key: 'timeCompleted',
      sorter: true,
      sortOrder:
        tasksSortedInfo?.columnKey === 'timeCompleted'
          ? tasksSortedInfo?.order
          : undefined,
      width: 130,
      align: 'center',
      render: (miliseconds: number) =>
        miliseconds ? moment(miliseconds).format('DD.MM.YYYY | HH:mm') : '——',
    },
    {
      title: local.profile.stat.tasks.columnTasksTable.timeVerified,
      dataIndex: 'timeVerified',
      key: 'timeVerified',
      align: 'center',
      sorter: true,
      sortOrder:
        tasksSortedInfo?.columnKey === 'timeVerified'
          ? tasksSortedInfo?.order
          : undefined,
      width: 130,
      render: (miliseconds: number) =>
        miliseconds ? moment(miliseconds).format('DD.MM.YYYY | HH:mm') : '——',
    },
  ]

  const onChange = (
    pagination: TablePaginationConfig | undefined,
    _filters: Record<string, (boolean | React.Key)[] | null> | undefined,
    sorter:
      | SorterResult<TArrayColumnType>
      | SorterResult<TArrayColumnType>[]
      | undefined,
    _extra: TableCurrentDataSource<TArrayColumnType> | undefined
  ) => {
    dispatch(changePage(pagination?.current! - 1 || 0))
    dispatch(changeSorted(sorter as SorterResult<TArrayColumnType>))
  }

  React.useEffect(() => {
    dispatch(
      profileStatTasks({
        userId,
        trackId: track?.key,
        page: tasksPage,
        size: 10,
        sort: tasksSortedInfo?.order
          ? `${
              tasksSortedInfo.columnKey === 'id'
                ? 'node'
                : tasksSortedInfo.columnKey
            }(${tasksSortedInfo.order === 'ascend' ? 'asc' : 'desc'})`
          : undefined,
        rangeDate,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksSortedInfo, tasksPage, userId, rangeDate, track])

  return (
    <div className={style.tableContainer}>
      <ViewTable
        data={tasksData.map(task => ({ ...task, key: task.id }))}
        columns={columns}
        mainColumns={mainColumns}
        type={ETables.PROFILE_TASKS}
        title={local.profile.stat.tasks.tableTitle as any}
        navigate={undefined}
        pagination={{
          current: tasksPage + 1,
          position: ['bottomRight'],
          simple: true,
          total: tasksTotal,
        }}
        height={225}
        onChange={onChange}
      />
    </div>
  )
}

export default ProfileTasksPL
