import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import _ from 'lodash'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import SearchService from '../../../services/search/search.service'

const initialState: I.IGlobalSearch = {
  hasMore: false,
  onSearchMode: false,
  isLoad: false,
  page: 0,
  searchItems: [],
  textFind: undefined,
  inNodeTitle: true,
  inNodeTopic: true,
  inQuestionsText: true,
  inTrackTitle: true,
  inUnavailableNodes: false,
  inManagerNodes: false,
}

export const search = createAsyncThunk(
  'search',
  async (data: I.ISearchRequest, { dispatch, rejectWithValue }) => {
    dispatch(updateLoader(true))
    dispatch(updateFetch(false))
    try {
      const result = await stackRequestWrapper(SearchService.search(data))
      return result
    } catch (e) {
      dispatch(updateLoader(false))
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const searchMore = createAsyncThunk(
  'searchMore',
  async (data: I.ISearchRequest, { dispatch, rejectWithValue }) => {
    dispatch(updateLoader(true))
    dispatch(updateFetch(false))
    try {
      const result = await stackRequestWrapper(SearchService.search(data))
      return result
    } catch (e) {
      dispatch(updateLoader(false))
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<I.ISearchRequest>) => {
      _.assign(state, action.payload)
    },
    updateLoader: (state, action: PayloadAction<boolean>) => {
      state.isLoad = action.payload
    },
    updateSearchMode: (state, action: PayloadAction<boolean>) => {
      state.onSearchMode = action.payload
    },
    updateFetch: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload
    },
    updateText: (state, action: PayloadAction<string>) => {
      state.textFind = action.payload
    },
    updateResult: state => {
      state.textFind = undefined
      state.searchItems = []
      state.hasMore = false
      state.page = 0
      state.isLoad = false
    },
    updateManagerSwitch: (state, action: PayloadAction<boolean>) => {
      state.inManagerNodes = action.payload
    },
    clearState: state => _.assign(state, initialState),
  },
  extraReducers: builder => {
    builder.addCase(search.rejected, state => {
      state.isLoad = false
    })
    builder.addCase(searchMore.rejected, state => {
      state.isLoad = false
    })
    builder.addCase(search.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.isLoad = false
        state.searchItems = action.payload.data.content
        state.page = action.payload.data.pageNumber
        state.hasMore =
          action.payload.data.totalPages !== action.payload.data.pageNumber
      }
    })
    builder.addCase(searchMore.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.isLoad = false
        state.searchItems = [
          ...state.searchItems,
          ...action.payload.data.content,
        ]
        state.page = action.payload.data.pageNumber
        state.hasMore =
          action.payload.data.totalPages !== action.payload.data.pageNumber
      }
    })
  },
})

export const { reducer: searchReducer } = searchSlice

export const {
  clearState,
  updateSearch,
  updateLoader,
  updateSearchMode,
  updateFetch,
  updateResult,
  updateText,
  updateManagerSwitch,
} = searchSlice.actions

export const selectSearch = (state: I.RootState) => state.search
