export const statItems = {
  filters: {
    label: 'Трек',
    searchItems: 'Для всех треков',
    mapTooltip: 'Поле станет активным при выборе одного трека',
  },
  statTab: {
    areaConfig: {
      yAxis: 'Выполнено, %',
    },
    progressTitle: 'Общий прогресс',
    diagramTitle: 'Динамика прогресса',
    byUsersTitle: 'В разрезе пользователей',
    byUsers: {
      haveItems: 'Имеют доступ',
      completed: 'Прошли полностью',
      inProgress: 'В стадии прохождения',
      withoutItems: {
        title: 'не назначено ни одного трека',
        value: 'На :Count',
      },
    },
    byNodesTitle: 'В разрезе модулей',
    byNodes: {
      total: 'Всего модулей',
      withTopics: 'Модулей с описанием',
      withTasks: 'Модулей с заданием',
      withoutTasks: 'Модулей без заданий',
    },
    byTasksTitle: 'В разрезе заданий',
    byTasks: {
      onChecking: 'Модулей не проверено',
      averageChecking: 'Среднее время проверки',
      days: 'д',
      hours: 'ч',
      minutes: 'м',
    },
  },
  tableTab: {
    tableTitle: 'Статистика по модулям',
    columns: {
      title: 'Название модуля',
      questionsCount: 'Кол-во вопросов',
      completedPercentage: '% успешных выполнений',
      averageAttempts: 'Среднее количество попыток',
      secondsEstimated: 'Расчетное время выполнения',
      averageSecondsActual: 'Среднее время выполнения',
      progress: 'Средний прогресс выполнения',
      hours: 'ч',
      minutes: 'мин',
    },
    exportFileName: 'Статистика по модулям',
  },
}
