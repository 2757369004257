export const statUsers = {
  filters: {
    filterUsersInputLabel: 'Пользователь',
    filterItemsInputLabel: 'Трек',
    searchUsers: 'Для всех пользователей',
    searchItems: 'Для всех треков',
    mapTooltip: 'Поле станет активным при выборе одного трека',
  },
  statTab: {
    status: {
      available: 'ДОСТУПНЫЕ',
      checking: 'НА ПРОВЕРКЕ',
      completed: 'ВЫПОЛНЕННЫЕ',
      rejected: 'ОТКЛОНЕННЫЕ',
      unavailable: 'НЕДОСТУПНЫЕ',
    },
    areaConfig: {
      yAxis: 'Выполнено, %',
    },
    progressTitle: 'Общий прогресс',
    progressLegend: {
      done: 'Выполненные',
      undone: 'Невыполненные',
    },
    progressTooltip:
      'Круговая диаграмма разделена на два сектора - выполненные модули и невыполненные. Размер сектора, соответствующего выполненным модулям, соответствует проценту выполненных работ. Размер сектора, соответствующего невыполненным модулям, соответствует проценту незавершенных работ. Кольцевая диаграмма помогает быстро оценить прогресс выполнения проекта или программы, а также идентифицировать области, в которых необходимо улучшить прогресс',
    byUsersTitle: 'В разрезе пользователей',
    byUsers: {
      total: 'Всего модулей',
      averageCompleted: 'Модулей выполнено',
      averageLeft: 'Осталось выполнить',
      everyoneCompleted: 'Выполнено всеми',
      noneCompleted: 'Не выполнено всеми',
      checkingCount: 'Ожидает проверки',
      blockedProgress: 'Заблокировано прохождение',
      average: '(сред.)',
      all: '(всего)',
      tooltip: {
        total:
          'Общее количество модулей, содержащихся в треках, назначенных на пользователя (-ей)',
        averageCompleted:
          'Среднее количество выполненных модулей пользователем (-ми)',
        averageLeft:
          'Среднее количество модулей, к выполнению которых пользователь (-ли) еще не приступал (-ли)',
        everyoneCompleted:
          'Общее число модулей, которые успешно прошли все пользователи, у которых есть данные модули',
        noneCompleted:
          'Общее количество модулей, задания по которым не пройдены ни одним из пользователей, на которых назначены треки с этими модулями',
        checkingCount:
          'Общее количество модулей, задания по которым ожидают проверки ментора',
        blockedProgress:
          'Заблокировано дальнейшее прохождение модулей',
      },
    },
    areaTitle: 'Динамика прогресса',
    areaTooltip:
      '«Динамика прогресса» - график, отражающий динамику прогресса прохождения заданий в треках. График показывает взаимосвязь временного промежутка с даты создания трека до текущей даты (по горизонтали) и процент выполнения заданий (по вертикали). Также доступен выбор отдельного временного промежутка из выпадающего списка.',
    pieTitle: 'Соотношение по статусам',
    pieTooltip:
      '«Соотношение по статусам» - это круговая диаграмма, отображающая процентное соотношение по всем модулям, находящимся в различных статусах.',
  },
  tableTab: {
    tableTitle: 'Статистика по пользователям',
    columns: {
      fullName: 'Пользователь',
      email: 'Email',
      position: 'Должность',
      lastAuthTime: 'Дата последнего входа',
      track: 'Трек',
      progress: { title: 'Прогресс', noTask: 'Нет заданий' },
      action: {
        title: 'Действие',
        actionButton: 'Перейти к списку',
      },
    },
    emptyData: {
      noData: 'Статистика по прохождению отсутствует',
      noFilterStat: 'Нет данных, соответствующих выбранным фильтрам',
    },
    exportFileName: 'Статистика по пользователям',
  },
  statUserByTask: {
    filters: {
      label: 'Модуль',
      searchTask: 'Выберите модуль',
      selectStatus: 'Статус',
    },
    reset: {
      resetButton: 'Обнулить прохождение',
      cancelButton: 'Отмена',
      confirmButton: 'Подтвердить обнуление',
    },
    tabs: {
      stat: 'Статистика',
      table: 'Таблица',
      map: 'Карта прогресса',
    },
    columns: {
      node: 'Название модуля',
      attemptsCount: {
        title: 'Количество попыток',
        manyAttempts: 'попыток',
        singleAttempt: 'попытка',
        attempts: 'попытки',
      },
      mentor: 'Последний проверил(а)',
      timeCompleted: 'Последнее выполнение',
      status: {
        title: 'Статус',
        completed: 'Выполнено',
        rejected: 'Отклонено',
        checking: 'На проверке',
        default: 'Не пройдено',
      },
      action: {
        title: 'Действие',
        actionButton: 'Перейти к проверке',
      },
    },
    emptyData: {
      noData: 'Статистика по прогрессу отсутствует',
      noFilterStats: 'Нет данных, соответствующих выбранным фильтрам',
    },
  },
}
