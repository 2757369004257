import React, { Dispatch, FC, SetStateAction } from 'react'
import { TTheme, IThemeProps, themes } from '.'

interface IThemeContextProps {
  theme: TTheme
  themeProps: IThemeProps
  setCurrentTheme?: Dispatch<SetStateAction<TTheme>>
}

const defaultTheme = window.REACT_APP_DEFAULT_THEME
export const currentTheme = (localStorage.getItem('theme') ||
  defaultTheme) as TTheme
const whithThemeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER)

const ThemeContext = React.createContext<IThemeContextProps>({
  theme: currentTheme,
  themeProps: themes[currentTheme],
})

export const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = React.useState<TTheme>(currentTheme)
  const localTheme = localStorage.getItem('theme')

  if ((!whithThemeSwitcher && localTheme !== defaultTheme) || !localTheme) {
    localStorage.setItem('theme', defaultTheme)
  }

  const setFavicon = () => {
    const url = `https://storage.yandexcloud.net/eis-static/theme/${currentTheme}/favicons`

    const listLinks = document.querySelectorAll(
      'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]'
    )
    listLinks.forEach(element => {
      if (!element.getAttribute('href')?.includes(currentTheme)) {
        const size = element.getAttribute('sizes')
        if (size) {
          element.setAttribute('href', `${url}/favicon-${size}.png`)
        } else {
          element.setAttribute('href', `${url}/favicon.ico`)
        }
      }
    })

    const meta = document.querySelector('meta[name="msapplication-TileImage"]')
    if (meta?.getAttribute('content')?.includes('{{logo}}')) {
      const manifest = document.querySelector('link[rel="manifest"]')
      if (manifest) manifest.remove()
    }
    if (!meta?.getAttribute('content')?.includes(currentTheme)) {
      meta?.setAttribute('content', `${url}/favicon-144x144.png`)
    }
  }
  setFavicon()

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        themeProps: themes[theme],
        setCurrentTheme: setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => React.useContext(ThemeContext)
