import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SorterResult } from 'antd/lib/table/interface'
import _ from 'lodash'
import * as I from 'Types'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import { TArrayColumnType } from '../../components/ViewTable/IViewTable'
import { ENotificationVariant } from '../../constants'
import PositionsService from '../../services/positions/positions.service'
import { enqueueSnackbar } from '../notification/notificationSlice'
import local from './../../localization'

const initialState: I.IPositionsState = {
  positions: [],
  value: undefined,
  isActive: undefined,
  page: 1,
  pageSize: 20,
  totalElements: 0,
  sortedInfo: undefined,
}

export const getPositions = createAsyncThunk(
  'positions',
  async (data: I.IGetPositionsRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        PositionsService.getPositions(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const editPosition = createAsyncThunk(
  'positions/edit',
  async (
    { id, title, isActive, items, users }: I.IEditPositionRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        PositionsService.postPosition(id, title, isActive, items, users)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.positions.SUCCESS.edited.replace(
            ':Title',
            title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const addPosition = createAsyncThunk(
  'positions/add',
  async (
    { title, isActive, items, users }: I.IAddPositionRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        PositionsService.putPosition(title, isActive, items, users)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.positions.SUCCESS.added.replace(
            ':Title',
            title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const deletePosition = createAsyncThunk(
  'positions/delete',
  async (
    { positionId }: I.IDeletePositionRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        PositionsService.deletePosition(positionId)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.positions.SUCCESS.deleted,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    clearPositionsState: (state: I.IPositionsState) => {
      _.assign(state, {
        positions: initialState.positions,
        totalElements: initialState.totalElements,
      })
    },
    setPage: (state: I.IPositionsState, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setSize: (state: I.IPositionsState, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setValue: (
      state: I.IPositionsState,
      action: PayloadAction<string | undefined>
    ) => {
      state.value = action.payload
    },
    setIsActive: (
      state: I.IPositionsState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isActive = action.payload
    },
    setSortedInfo: (
      state: I.IPositionsState,
      action: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      state.sortedInfo = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getPositions.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.positions = action.payload.data.content
        state.totalElements = action.payload.data.totalElements
      }
    })
    builder.addCase(editPosition.fulfilled, (state, action) => {
      if (action.payload?.data)
        state.positions = state.positions.map(p => {
          if (p.id === action.payload?.data?.id) {
            return action.payload?.data
          } else {
            return p
          }
        })
    })
  },
})

export const { reducer: positionsReducer } = positionsSlice

export const selectPositions = (state: I.RootState) => state.positions

export const {
  clearPositionsState,
  setPage,
  setSize,
  setValue,
  setIsActive,
  setSortedInfo,
} = positionsSlice.actions
