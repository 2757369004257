export const items = {
  columns: {
    title: 'Название',
    description: 'Описание',
    embeddedIn: 'Вложен в папку',
    managers: 'Управляющие',
    mentors: 'Менторы',
    users: 'Пользователи',
    isActive: {
      title: 'Активный',
      true: 'Да',
      false: 'Нет',
      isBlocked: 'Неактивный трек',
    },
    createdTime: 'Дата создания',
    updatedTime: 'Дата изменения',
    action: {
      title: 'Действие',
      editButton: 'Изменить',
      deleteButton: 'Удалить',
    },
  },
  emptyData: {
    noData: 'Нет добавленных треков',
    noFilterItems: 'Нет данных, соответствующих выбранным фильтрам',
  },
  filter: {
    item: 'Выберите трек...',
    withMentor: 'С ментором',
    isActive: 'Активные',
  },
  addItemButton: 'Добавить трек',
  deleteItemModal: {
    title: 'трек',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Активный',
      disable: 'Неактивный',
    },
    title: {
      add: 'Выполнить добавление Трека',
      edit: 'Выполнить редактирование Трека',
      delete: 'Выполнить удаление Трека',
      export: 'Выполнить экспорт Трека',
    },
    cancelText: 'Отменить',
    okText: 'Сохранить',
    back: 'Назад',
    addGroup: 'Добавить группу',
    divideGroup: 'Разделить на группы',
    groupsTitle:
      'Сформируйте группы Ментор(-ы) - Пользователь(-ли) для проверки заданий',
    formItem: {
      title: {
        label: 'Название трека',
        placeholder: 'Введите название трека',
      },
      description: {
        label: 'Описание трека',
        placeholder: 'Введите описание трека',
      },
      status: {
        label: 'Статус трека',
        placeholder: 'Активный / Неактивный',
        isBlocked: 'Неактивный трек',
      },
      managers: {
        filterInputLabel: 'Управляющий',
        label: 'Управляющие',
        placeholder: 'Выберите управляющих',
      },
      mentors: {
        filterInputLabel: 'Ментор',
        label: 'Менторы',
        placeholder: 'Выберите менторов',
      },
      users: {
        filterInputLabel: 'Пользователь',
        label: 'Пользователи',
        placeholder: 'Выберите пользователей',
      },
      folder: {
        label: 'Папка',
        placeholder: 'Выберите папку',
      },
      isDefault: {
        label: 'Трек по умолчанию',
        placeholder:
          'Если данная настройка активна, всем новым пользователям системы данный трек будет назначен автоматически',
      },
      isShadowMode: {
        label: 'Режим "Теневая карта"',
        placeholder:
          'При активации режима пользователю будут отображаться все модули, кроме неактивных (серого цвета).',
      },
    },
    disableItemModal: {
      title: 'Блокировка трека',
      content:
        'Вы действительно хотите сделать трек неактивным? У всех пользователей, которые были назначены на данный трек (в том числе по должности), будет обнулен прогресс прохождения заданий по нему. Также данный трек будет откреплен от всех должностей, на которые назначен. Все менторы и управляющие назначенные на трек также будут откреплены от него.',
      okText: 'Да',
      cancelText: 'Нет',
    },
  },
}
