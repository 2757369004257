import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as I from 'Types'
import { EPassResult } from '../../constants'
import TaskViewService from '../../services/tasks/task-view/taskView.service'
import _ from 'lodash'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import { IAttemptSolution } from '../../components/TaskViewerAndReviewer/ITaskViewerAndReviewer'
import local from './../../localization'

const initialState: IAttemptSolution = {
  id: 0,
  minutesEstimated: 0,
  questions: [],
  secondsActual: 0,
  status: EPassResult.ACCEPTED,
  task: '',
  timeCompleted: 0,
  timeVerified: 0,
  track: '',
  userName: '',
  limitTime: false,
  mentorName: undefined,
  mentorAvatarUrls: undefined,
  userAvatarUrls: undefined,
}

export const getSolution = createAsyncThunk(
  'taskView/solution',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskViewService.getSolution(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const taskViewSlice = createSlice({
  name: 'taskView',
  initialState,
  reducers: {
    clearTaskViewState: state => {
      _.assign(state, initialState)
    },
  },
  extraReducers: builder => {
    builder.addCase(getSolution.fulfilled, (state, action) => {
      if (action.payload?.data) _.assign(state, action.payload.data)
      else
        console.error(
          local.taskCheckAndView.notification.error.solutionNotFound
        )
    })
  },
})

export const selectSolution = (state: I.RootState) => state.tasks.view

export const { reducer: taskViewReducer } = taskViewSlice

export const { clearTaskViewState } = taskViewSlice.actions
