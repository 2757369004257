import { Observable } from 'rxjs'
import { IAPIResult } from '../../IBaseService'
import { BaseApiService } from '../../base.service'
import * as I from 'Types'
import { TMap } from '../../../constants'
import { EAPIEndpoint } from '../../constants'

const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export default class TaskEditService {
  static getNodeTopic(
    nodeId: number
  ): Observable<IAPIResult<I.INodeTextResponse>> {
    const baseApiService = new BaseApiService<I.INodeTextResponse>()
    return baseApiService.getRequest(
      EAPIEndpoint.NODE_TOPIC.replace(':nodeId', nodeId.toString())
    )
  }

  static getNodeTask(nodeId: number): Observable<IAPIResult<I.INodeTask>> {
    const baseApiService = new BaseApiService<I.INodeTask>()
    return baseApiService.getRequest(
      EAPIEndpoint.MANAGEMENT_NODE_TASK.replace(':nodeId', nodeId.toString())
    )
  }

  static getNodeTaskInfo(
    nodeId: number
  ): Observable<IAPIResult<I.INodeTaskInfo>> {
    const baseApiService = new BaseApiService<I.INodeTaskInfo>()
    return baseApiService.getRequest(
      EAPIEndpoint.MANAGEMENT_NODE_INFO.replace(':nodeId', nodeId.toString())
    )
  }

  static autosaveTopic(nodeId: number, text: string) {
    const baseApiService = new BaseApiService<void>()
    baseApiService.postBackgroundRequest(EAPIEndpoint.NODE_TOPIC_CHANGE, head, {
      nodeId,
      text,
    })
  }

  static postNodeTopic(
    nodeId: number,
    text: string,
    confirmButtonText: string | null
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.NODE_TOPIC_CHANGE, head, {
      nodeId,
      text,
      confirmButtonText,
    })
  }

  static postNodeTask(task: I.INodeTask): Observable<IAPIResult<I.INodeTask>> {
    const baseApiService = new BaseApiService<I.INodeTask>()
    return baseApiService.postRequest(
      EAPIEndpoint.MANAGEMENT_NODE_TASK_CHANGE,
      head,
      {
        ...task,
      }
    )
  }

  static postNodeTitle(
    nodeId: number,
    title: string
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.NODE_TITLE_CHANGE, head, {
      id: nodeId,
      nodeTitle: title,
    })
  }

  static getPassedUsers(
    nodeId: number
  ): Observable<IAPIResult<Array<I.IPassedUsersResponse>>> {
    const baseApiService = new BaseApiService<Array<I.IPassedUsersResponse>>()
    return baseApiService.getRequest(
      EAPIEndpoint.NODE_PASSED_USERS.replace(':nodeId', nodeId.toString())
    )
  }

  static getRejectedUsers(
    nodeId: number
  ): Observable<IAPIResult<Array<I.IRejectedUsersResponse>>> {
    const baseApiService = new BaseApiService<Array<I.IRejectedUsersResponse>>()
    return baseApiService.getRequest(
      EAPIEndpoint.NODE_REJECTED_USERS.replace(':nodeId', nodeId.toString())
    )
  }

  static postZeroPassed(
    body: I.IZeroPassRequest
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(
      EAPIEndpoint.NODE_RESET_PASSED_USERS.replace(
        ':nodeId',
        body.id.toString()
      ),
      head,
      {
        userIds: body.userIds,
      }
    )
  }

  static postTopicChanged(
    body: I.ITopicChangedRequest
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(
      EAPIEndpoint.NODE_TOPIC_CHANGED_NOTIFY.replace(
        ':nodeId',
        body.id.toString()
      ),
      head,
      {
        userIds: body.userIds,
        message: body.message,
      }
    )
  }

  static getJobResults(uiids: string[]): Promise<Response> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postBackgroundRequest(
      EAPIEndpoint.JOB_RESULTS,
      head,
      {
        jobSet: uiids,
      }
    )
  }
}
