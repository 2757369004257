export const statItems = {
  filters: {
    label: 'Track',
    searchItems: 'All tracks',
    mapTooltip: 'The field will become active when one track is selected',
  },
  statTab: {
    areaConfig: {
      yAxis: 'Done, %',
    },
    progressTitle: 'Overall progress',
    diagramTitle: 'Progress dynamic',
    byUsersTitle: 'By users',
    byUsers: {
      haveItems: 'Have access',
      completed: 'Completed all',
      inProgress: 'In progress',
      withoutItems: {
        title: 'No tracks have been assigned',
        value: ':Count',
      },
    },
    byNodesTitle: 'By modules',
    byNodes: {
      total: 'Total number of modules',
      withTopics: 'Modules with description',
      withTasks: 'Modules with a task',
      withoutTasks: 'Modules without tasks',
    },
    byTasksTitle: 'By tasks',
    byTasks: {
      onChecking: 'Modules under review',
      averageChecking: 'Average checking time',
      days: 'd',
      hours: 'h',
      minutes: 'm',
    },
  },
  tableTab: {
    tableTitle: 'Modules statistics',
    columns: {
      title: 'Module title',
      questionsCount: 'Number of questions',
      completedPercentage: '% completed',
      averageAttempts: 'Average number of attempts',
      secondsEstimated: 'Estimated time to completion',
      averageSecondsActual: 'Average time to completion',
      progress: 'Average progress',
      hours: 'h',
      minutes: 'm',
    },
    exportFileName: 'Modules statistics',
  },
}
