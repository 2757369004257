export const settings = {
  tabs: {
    genereal: 'Личная информация',
    profileSettings: 'Настройки профиля',
    systemSettings: 'Системные настройки',
  },
  expiration: {
    title: 'Активность профиля',
    date: 'Профиль активен до :date',
    help: 'Для продления активности профиля необходимо внести оплату',
  },
  profileImg: 'Аватар профиля',
  changePassword: {
    label: 'Изменить пароль',
    currentPassword: 'Введите текущий пароль:',
    newPassword: 'Введите новый пароль:',
    confirmedPassword: 'Подтвердите новый пароль:',
    currentPasswordPlaceholder: 'Введите текущий пароль',
    newPasswordPlaceholder: 'Введите новый пароль',
    confirmedPasswordPlaceholder: 'Подтвердите новый пароль',
    button: 'Изменить пароль',
  },
  changeEmail: {
    label: 'Изменить Email',
    newEmail: 'Введите новый Email:',
    confirmedEmail: 'Подтвердите новый Email:',
    newEmailPlaceholder: 'Введите новый Email',
    confirmedEmailPlaceholder: 'Подтвердите новый Email',
    button: 'Изменить Email',
  },
  changeEmailModal: {
    title: 'Изменить Email',
    content:
      'Вы действительно хотите сменить свой email на :Email? После изменения, Вам будет необходимо заново авторизоваться в системе с новым email.',
    okText: 'Подтвердить',
    cancelText: 'Отменить',
  },
  changePhoneNumber: {
    label: 'Изменить номер телефона',
    newPhoneNumber: 'Введите новый номер телефона:',
    newPhoneNumberPlaceholder: 'Введите новый номер телефона',
    newPhoneNumberSearchPlaceholder: 'Поиск',
    button: 'Изменить номер телефона',
  },
  changePhoneNumberModal: {
    title: 'Изменить номер телефона',
    content: 'Вы действительно хотите сменить свой номер телефона на :Phone?',
    okText: 'Подтвердить',
    cancelText: 'Отменить',
  },
  notifier: {
    label: 'Уведомления',
    prompt: 'Отправлять уведомления на почту',
  },
  selfRegistration: {
    label: 'Регистрация',
    prompt: 'Самостоятельная регистрация пользователей',
  },
  saveSettingsButton: 'Сохранить',
}
