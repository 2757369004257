import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { TMap } from '../../constants'
import { EAPIEndpoint } from '../constants'
const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
export default class TracksService {
  static getAvailableTracks(
    data: Record<string | number, any>
  ): Observable<IAPIResult<I.IAvailableTracksResponse>> {
    const baseApiService = new BaseApiService<I.IAvailableTracksResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.AVAILABLE_TRACKS}${getParams(data)}`
    )
  }

  static getTracks(data: Record<any, any>): Observable<IAPIResult<I.ITrack[]>> {
    const baseApiService = new BaseApiService<I.ITrack[]>()
    return baseApiService.getRequest(`${EAPIEndpoint.TRACKS}${getParams(data)}`)
  }

  static putTrack(
    data: I.IAddManagementTrackRequest
  ): Observable<IAPIResult<I.ITrack>> {
    const baseApiService = new BaseApiService<I.ITrack>()
    const body = {
      ...data,
      mentors: data.mentors ? data.mentors : [],
      managers: data.managers ? data.managers : [],
      users: data.users ? data.users : [],
    }

    return baseApiService.putRequest(EAPIEndpoint.ITEMS, head, body)
  }

  static postTrack(
    data: I.IEditManagementTrackRequest
  ): Observable<IAPIResult<I.ITrack>> {
    const baseApiService = new BaseApiService<I.ITrack>()
    const body = {
      ...data,
      mentors: data.mentors ? data.mentors : [],
      managers: data.managers ? data.managers : [],
      users: data.users ? data.users : [],
    }

    return baseApiService.postRequest(EAPIEndpoint.ITEMS, head, body)
  }

  static deleteTrack(trackId: string): Observable<IAPIResult<I.ITrack>> {
    const baseApiService = new BaseApiService<I.ITrack>()
    return baseApiService.deleteRequest(
      EAPIEndpoint.DELETE_ITEMS.replace(':itemId', trackId)
    )
  }

  static exportTrack(trackId: string): Observable<IAPIResult<Blob>> {
    const baseApiService = new BaseApiService<Blob>()
    const params = getParams({ trackId })
    return baseApiService.getExportFileRequest(
      `${EAPIEndpoint.TRACK_EXPORT}${params}`
      // `${EAPIEndpoint.TRACK_EXPORT}/${trackId}` // EIS-2663
    )
  }

  static importTrack(
    body: I.IImportManagementTrackRequest
  ): Observable<IAPIResult<I.ITrack>> {
    const baseApiService = new BaseApiService<I.ITrack>()
    return baseApiService.postFileRequest(EAPIEndpoint.TRACK_IMPORT, body)
  }

  static getTrackTitle(trackId: string): Observable<IAPIResult<string>> {
    const baseApiService = new BaseApiService<string>()
    return baseApiService.getRequest(
      EAPIEndpoint.TRACK_TITLE.replace(':trackId', trackId)
    )
  }

  static getAreas(): Observable<IAPIResult<Array<I.IArea>>> {
    const baseApiService = new BaseApiService<Array<I.IArea>>()
    return baseApiService.getRequest(EAPIEndpoint.AREA)
  }

  static editArea(body: I.IEditAreaRequest): Observable<IAPIResult<I.IArea>> {
    const baseApiService = new BaseApiService<I.IArea>()
    return baseApiService.postRequest(EAPIEndpoint.AREA, head, body)
  }

  static addArea(body: I.IAddAreaRequest): Observable<IAPIResult<I.IArea>> {
    const baseApiService = new BaseApiService<I.IArea>()
    return baseApiService.putRequest(EAPIEndpoint.AREA, head, body)
  }

  static deleteArea(areaId: string): Observable<IAPIResult<I.IArea>> {
    const baseApiService = new BaseApiService<I.IArea>()
    return baseApiService.deleteRequest(
      EAPIEndpoint.AREA_DELETE.replace(':areaId', areaId)
    )
  }
}
