import { useState, useEffect } from 'react'

//request for permission to access camera
const GetUserMedia = (requestedMedia: any) => {
  const [mediaStream, setMediaStream] = useState<any>(null)
  const [currentMedia, setCurrentMedia] = useState<any>(null)

  useEffect(() => {
    async function enableStream() {
      try {
        setCurrentMedia(requestedMedia)
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia)
        setMediaStream(stream)
      } catch (err) {
        alert('error in accesing camera')
      }
    }

    if (
      !mediaStream ||
      currentMedia.video.facingMode !== requestedMedia.video.facingMode
    ) {
      enableStream()
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track: any) => {
          track.stop()
        })
      }
    }
  }, [currentMedia, mediaStream, requestedMedia])

  return mediaStream
}

export default GetUserMedia
