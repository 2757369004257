import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as I from 'Types'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import ProfileSettingsService from '../../../services/settings/settings.service'
import { processError } from '../../../app/processError'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import { ENotificationVariant } from '../../../constants'
import { logout } from '../../../services/auth'
import local from './../../../localization'

const initialState: I.IProfileSettings = {
  enableNotificationsAll: true,
  userSelfRegistration: true,
}

export const getSelfRegistrationStatus = createAsyncThunk(
  'profile/settings/self_registration',
  async (_p, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.getSelfRegistrationStatus()
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeSelfRegistrationStatus = createAsyncThunk(
  'profile/settings/change/self_registration',
  async (body: I.IProfileSettings, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.changeSelfRegistrationStatus(body)
      )
      getSelfRegistrationStatus()
      dispatch(
        enqueueSnackbar({
          message: local.notification.settings.SUCCESS.saved,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getProfileSettings = createAsyncThunk(
  'profile/settings/all',
  async (_p, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.getSettings()
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeProfileSettings = createAsyncThunk(
  'profile/settings/change',
  async (body: I.IProfileSettings, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.changeProfileSettings(body)
      )
      getProfileSettings()
      dispatch(
        enqueueSnackbar({
          message: local.notification.settings.SUCCESS.saved,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeProfilePassword = createAsyncThunk(
  'profile/settings/password',
  async (
    body: I.IChangeProfileSettingsPasswordRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.changeProfilePassword(body)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.settings.SUCCESS.passwordChanged,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      logout()
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeProfileEmail = createAsyncThunk(
  'profile/settings/email',
  async (
    body: I.IChangeProfileSettingsEmailRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.changeProfileEmail(body)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.settings.SUCCESS.emailChanged,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      logout()
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeProfilePhoneNumber = createAsyncThunk(
  'profile/settings/phone',
  async (
    body: I.IChangeProfileSettingsPhoneNumberRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        ProfileSettingsService.changeProfilePhoneNumber(body)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.settings.SUCCESS.phoneNumberChanged,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const settingsSlice = createSlice({
  name: 'profile/settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProfileSettings.fulfilled, (state, action) => {
      state.enableNotificationsAll = action.payload.data?.enableNotificationsAll
    })
    builder.addCase(getSelfRegistrationStatus.fulfilled, (state, action) => {
      state.userSelfRegistration = action.payload.data?.userSelfRegistration
    })
  },
})

export const { reducer: settingsReducer } = settingsSlice

export const selectSettings = (state: I.RootState) => state.settings
