export const users = {
  columns: {
    fullName: 'Kullanıcı',
    position: 'Konum',
    items: 'Rotalar',
    phoneNumber: 'Telefon numarası',
    email: 'E-posta',
    progress: 'İlerleme',
    createdTime: 'Kayıt Tarihi',
    lastAuthTime: 'Son oturum açma',
    activePeriod: 'Son etkinlik :Period günden fazla bir süre önce',
    action: {
      title: 'Eylem',
      editButton: 'Düzenle',
      deleteButton: 'Sil',
    },
  },
  deleteUserModal: {
    title: 'Kullanıcıyı silin',
    content:
      'Gerçekten bir kullanıcıyı silmek istiyor musunuz? Görevinin tüm ilerleyişi sistemden silinecek.',
    okText: 'Evet',
    cancelText: 'Hayır',
  },
  emptyData: {
    noData: 'Hiçbir kullanıcı eklenmedi',
    noFilterUsers: 'Seçilen filtrelerle eşleşen veri yok',
  },
  filter: {
    user: 'Kullanıcı adını girin',
    role: {
      title: 'Görev',
      user: 'Kullanıcı',
      mentor: 'Mentor',
      manager: 'Müdür',
      admin: 'Yönetici',
      placeholder: 'Tümü',
    },
    isActive: 'Aktif',
    status: 'Durum',
  },
  addButton: 'Kullanıcı ekle',
  notification: {
    notConfirmed: 'Kullanıcı hesabı doğrulamadı',
    isBlocked: 'Kullanıcı engellendi',
    isAdmin: 'Kullanıcı bir yöneticidir',
    isManager: 'Kullanıcı bir parça yöneticisidir',
    isMentor: 'Kullanıcı bir akıl hocasıdır',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Aktif',
      disable: 'Bloke edilmiş',
    },
    title: {
      add: 'Kullanıcı ekle',
      edit: 'Kullanıcı düzenle',
    },
    cancelButton: 'Kaldır',
    saveButton: 'Kaydet',
    formItem: {
      fullName: {
        label: 'Ad-Soyad',
        placeholder: 'Ad-Soyad giriniz',
        required: 'Gerekli',
        length: 'En az 3 karakter kullanın',
      },
      email: {
        label: 'E-posta',
        placeholder: 'E-postayı girin',
        required: 'Gerekli',
        valid: 'Geçersiz e-posta!',
      },
      phoneNumber: {
        label: 'Telefon numarası',
        placeholder: 'Telefon numarasını girin',
        searchPlaceholder: 'Arama',
        required: 'Gerekli',
        valid: 'Geçersiz telefon numarası!',
      },
      status: {
        label: 'Durum',
        placeholder: 'Aktif/Pasif',
        required: 'Gerekli',
      },
      role: {
        filterInputLabel: 'Görev',
        label: 'Görev',
        placeholder: 'Görev seçin',
        required: 'Gerekli',
      },
      position: {
        filterInputLabel: 'Konum',
        label: 'Konum',
        placeholder: 'Konum seçin',
      },
      items: {
        filterInputLabel: 'Parça',
        label: 'Rotalar',
        placeholder: 'Rota seçin',
      },
    },
    deletePositionModal: {
      title: 'Konum silin',
      content:
        'Konumu kullanıcıdan ayırmak istediğinizden emin misiniz? Kullanıcı konumlarına göre atanan tüm ilerlemeler ve rotalar kaydedilecektir.',
      okText: 'Evet',
      cancelText: 'Hayır',
    },
    deleteItemModal: {
      title: 'Bir rota sil',
      content:
        'Bir parçayı gerçekten bir kullanıcıdan ayırmak istiyor musunuz? Bu parçadaki görevleri tamamlamadaki tüm ilerlemeleri sistemden silinecektir.',

      okText: 'Evet',
      cancelText: 'Hayır',
    },
    disableUserModal: {
      title: 'Kullanıcı engelleme',
      content:
        'Engellenen bir kullanıcı oturum açamaz, ancak izlerin ilerlemesi kaydedilir. Kullanıcı artık kendisine atanan parkurlarda "Müdür", "Mentor" veya "Yönetici" olarak hareket etmeyecektir Kullanıcının tekrar aktif hale getirilmesi gerekiyorsa, tüm parkurlar için mentor, müdür veya yönetici olarak yeniden atanması gerekecektir.',
      okText: 'Evet',
      cancelText: 'Hayır',
    },
    changeEmailModal: {
      title: 'Kullanıcının e-posta adresini değiştirin',
      okText: 'Onayla',
      description:
        'Kullanıcının e-posta adresini değiştirdikten sonra, kullanıcı eski e-posta adresiyle giriş yapamayacak.\n Değişiklikten sonra, eski ve yeni e-posta adreslerine e-posta değişikliği ile ilgili bildirimler gönderilecektir.',
      label: 'E-posta',
      placeholder: 'Yeni e-posta',
      required: 'Gerekli',
      valid: 'Geçersiz e-posta!',
    },
  },
  exportFileName: 'Kullanıcılar',
}
