export const taskPassing = {
  viewing: {
    confirmButton: 'Eğitimi onaylayın',
    disabledConfirmButtonTooltip:
      'Yönetici veya sistem yöneticisinin uygun kararı vermesi halinde video mülakat geçişinin tekrarlanması mümkündür',
    proceedWithTheTask: 'Göreve devam edin',
    viewingButton: 'Gönderilen cevabı görüntüleyinr',
    previewModal: {
      okText: 'Başla',
      cancelText: 'Geri',
      description: {
        accept: 'İşlemi onaylayın',
        helper: 'Eğitimi onaylamak için görevi tamamlamanız gerekir.',
        withLimitTime: 'Şu süre içinde tamamlayın :MinutesEstimated dk.',
        withLimitTimeHelper:
          'Süre dolduktan sonra görev tamamlama askıya alınır ve kontrol için otomatik olarak gönderilir.',
        unlimitTime: `Görevi tamamlamak için hesaplanan süre: :MinutesEstimated dk.`,
      },
    },
    interviewModal: {
      okText: 'Başlangıç',
      cancelText: 'İptal',
      accept: 'Eylemi onaylayın',
      description:
        'Online video görüşmesini geçmek için web kamerasını açın ve çalıştığını kontrol edin. Lütfen dikkat edin, mülakatı tekrar yapma girişimi olmayacaktır. Hazır mısınız başlamak için?',
      error: 'Hata! Kamera ve/veya mikrofona erişilemedi',
    },
  },
  passingRezult: {
    returnButton: 'Rotaya Geri Dön',
    completed: {
      title: 'Tebrikler! Parkuru tamamladınız.',
    },
    confirmed: {
      title: 'Modülü başarıyla tamamladınız',
    },
    rejected: {
      title: 'Test başarısız oldu',
      description: 'Modüldeki bilgileri tekrar incelemenizi öneririz',
    },
    timeout: {
      title: 'Süre doldu ve görev kontrole gönderildi',
      description: 'Görevin yakında kontrol edilmesini bekliyoruz',
    },
    accepted: {
      title: 'Görev kontrol için gönderildi',
      description: 'Görevin yakında kontrol edilmesini bekliyoruz',
    },
    error: {
      title: 'Görev gönderilemedi',
      description: 'Görevi yeniden tamamlayın',
    },
  },
  passing: {
    leftTime: 'Kalan süre',
    sendTaskButton: 'Kontrol için gönder',
    exitPromptMessage:
      'Sayfadan ayrılırsanız, modül reddedilecektir. Sayfadan ayrılmak istediğinizden emin misiniz?',
    question: {
      titles: {
        test: 'Test sorusu',
        text: 'Detaylı soru',
        video: 'Videolu soru',
        default: 'Bilinmeyen soru türü',
        task: 'Görev №',
        question: 'Soru',
        answer: 'Cevap',
      },
    },
    answer: {
      video: 'Videolu yanıt',
      text: {
        placeholder: 'Cevabı girin',
        loadFileButton: 'Dosya yükle',
        maxCountAndSizeFiles: ':Count/:MaxCount dosyalar, :Size/:MaxSize MB',
      },
    },
  },
}
