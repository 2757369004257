import React from 'react'
import { Modal } from 'antd'
import { useTheme } from '../../../theme/Theme'
import { ExclamationCircleFilled } from '@ant-design/icons'
import style from './EISConfirm.module.scss'
import { ModalFuncProps } from 'antd/lib/modal'

interface TAntConfirm extends ModalFuncProps {
  title: string
  okText: string
  cancelText: string
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  icon?: React.ReactNode
  content?: React.ReactNode
  maskClosable?: boolean
  closable?: boolean

  iconStyle?: string
  titleStyle?: string
  contentStyle?: string
}

export const EISConfirm: React.FC<TAntConfirm> = props => {
  const { themeProps } = useTheme()
  return (
    <Modal
      {...props}
      title={undefined}
      style={{ ...props.style, ...(themeProps as React.CSSProperties) }}
      className={`${style.modalConfirm} ${props.className}`}
    >
      <div className={props.iconStyle || style.icon}>
        {props.icon || <ExclamationCircleFilled translate={undefined} />}
      </div>
      <p className={props.titleStyle || style.title}>{props.title}</p>
      <p className={props.contentStyle || style.content}>{props.content}</p>
    </Modal>
  )
}
