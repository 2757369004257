import FingerprintJS, { Options, V18Component } from 'fingerprintjs2'

export { LocalStorageUtils } from './LocalStorageUtils'
export { CommonUtils } from './CommonUtils'
export { DiagramUtils } from './DiagramUtils'
export { DOMUtils } from './DOMUtils'
export { MathUtils } from './MathUtils'

export const uuid = (): string => {
  let d = new Date().getTime() //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const opt: Options = {
  excludes: {
    userAgent: true,
    adBlock: true,
    canvas: true,
    availableScreenResolution: true,
    audio: true,
    fonts: true,
    indexedDb: true,
    localStorage: true,
    webgl: true,
    doNotTrack: true,
    fontsFlash: true,
    pixelRatio: true,
    openDatabase: true,
    screenResolution: true,
    sessionStorage: true,
    plugins: true,
    hasLiedResolution: true,
    enumerateDevices: true,
    deviceMemory: true,
    cpuClass: true,
    hasLiedBrowser: true,
    hasLiedLanguages: true,
    hasLiedOs: true,
    language: true,
    colorDepth: true,
    touchSupport: true,
    webdriver: true, //Don't delete
  },
}

export const generateFingerprint = async (): Promise<string> => {
  return new Promise<string>((res, _rej) => {
    FingerprintJS.getV18(opt, (id: string, _p: V18Component[]) => {
      res(id)
    })
  })
}

/** Mobile screen width in px */
const vWidth = 480
/** Mobile screen height in px */
const vHeight = 915
/** Reads the screen resolution of the device */
export const isMobile = () => {
  return window.screen.width <= vWidth && window.screen.height <= vHeight
    ? true
    : false
}

const mimeType = [
  { type: 'webm|mkv', hex: '1A45DFA3', offset: 0 },
  { type: 'mp4', hex: '6674797069736F6D', offset: 4 },
  { type: 'mov', hex: '66747970', offset: 4 },
]

/**Read mime type from byte code */
export const readMimeType = (file: Uint8Array) => {
  mimeType.forEach(mime => {
    let header = ''
    const arr = file.subarray(mime.offset, mime.offset + mime.hex.length / 2)

    // let element = ''
    // const el2 = file.subarray(mime.offset, mime.offset + 8)
    // for (let i = 0; i < el2.length; i++) {
    //   const num = el2[i].toString(16).toUpperCase()
    //   element += num.length > 1 ? num : `0${num}`
    // }
    // console.log(element)

    for (var i = 0; i < arr.length; i++) {
      const num = arr[i].toString(16).toUpperCase()
      header += num.length > 1 ? num : `0${num}`
      if (header === mime.hex) {
        console.log(mime.type)
        break
      }
    }
  })
}
