import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Badge, Menu as AntMenu } from 'antd'
import spriteIcons from '../../../assets/svg/sprite-icons.svg'
import _ from 'lodash'
import { Authorities, EPages } from '../../../constants'
import Styles from './Menu.module.scss'
import { isInRole } from '../../../services/auth'
import * as I from './IMenu'
import { useSelector } from 'react-redux'
import { selectAcceptedCount } from '../../../features/task-check/taskCheckSlice'
import local from './../../../localization'
import { useTheme } from '../../../theme/Theme'

export const Menu: React.FC<I.OwnPros> = ({
  collapsed = false,
  onClose,
}): React.ReactElement => {
  const accepted = useSelector(selectAcceptedCount)
  const location = useLocation()
  const pages = Object.values(EPages)
  const { theme } = useTheme()

  const [selectedKey, setSelectedKey] = useState<string>(
    _.findLast(pages, p => location.pathname.search(p) === 0) || '/'
  )

  useEffect(() => {
    setSelectedKey(
      _.findLast(pages, p => location.pathname.search(p) === 0) || '/'
    )
  }, [location.pathname, pages])

  const menuLogoSprite = (id: string) => {
    return (
      <svg>
        <use xlinkHref={`${spriteIcons}${id}`}></use>
      </svg>
    )
  }

  const onClick = () => {
    onClose && onClose()
  }

  return (
    <AntMenu
      className={Styles.menu}
      mode='inline'
      defaultSelectedKeys={[EPages.PROFILE]}
      selectedKeys={[selectedKey]}
    >
      {isInRole(Authorities.PROFILE_PAGE) && (
        <AntMenu.Item
          key={EPages.PROFILE}
          icon={menuLogoSprite('#profile-icon')}
        >
          <Link onClick={onClick} to={EPages.PROFILE}>
            {local.main.breadcrumb.profile}
          </Link>
        </AntMenu.Item>
      )}
      {isInRole(Authorities.AVAILABLE_TRACKS_PAGE) && (
        <AntMenu.Item
          key={EPages.AVAIBLE_TRACKS}
          icon={menuLogoSprite('#available-tracks-icon')}
        >
          <Link onClick={onClick} to={EPages.AVAIBLE_TRACKS}>
            {local.main.breadcrumb.avaibleTracks}
          </Link>
        </AntMenu.Item>
      )}
      {isInRole(Authorities.STAT_PAGE) && (
        <AntMenu.SubMenu
          key='/stats'
          icon={menuLogoSprite('#statistic-icon')}
          title={local.main.breadcrumb.stat}
          popupClassName={`ant-submenu-${theme}`}
        >
          <AntMenu.Item
            key={EPages.STATS_BY_USERS}
            icon={menuLogoSprite('#statistic-users-icon')}
          >
            <Link onClick={onClick} to={EPages.STATS_BY_USERS}>
              {local.main.breadcrumb.statsByUsers}
            </Link>
          </AntMenu.Item>
          <AntMenu.Item
            key={EPages.STATS_BY_ITEMS}
            icon={menuLogoSprite('#statistic-tracks-icon')}
          >
            <Link onClick={onClick} to={EPages.STATS_BY_ITEMS}>
              {local.main.breadcrumb.statsByItems}
            </Link>
          </AntMenu.Item>
        </AntMenu.SubMenu>
      )}
      {isInRole(Authorities.MANAGEMENT_TRACKS_PAGE) && (
        <AntMenu.Item
          key={EPages.TRACKS_MANAGEMENT}
          icon={menuLogoSprite('#tracks-management-icon')}
        >
          <Link onClick={onClick} to={EPages.TRACKS_MANAGEMENT}>
            {local.main.breadcrumb.tracksManagement}
          </Link>
        </AntMenu.Item>
      )}
      {isInRole(Authorities.CHECK_TASKS_PAGE) && (
        <AntMenu.Item
          key={EPages.CHECK_TASKS}
          icon={
            collapsed ? (
              <span>
                {menuLogoSprite('#assignment-check-icon')}
                <span />
                <Badge count={accepted} overflowCount={9} offset={[-5, -30]} />
              </span>
            ) : (
              menuLogoSprite('#assignment-check-icon')
            )
          }
        >
          <Link onClick={onClick} to={EPages.CHECK_TASKS}>
            {local.main.breadcrumb.checkTasks}
            {!collapsed && (
              <Badge count={accepted} overflowCount={9} offset={[0, -20]} />
            )}
          </Link>
        </AntMenu.Item>
      )}
      {isInRole(Authorities.USERS_PAGE) && (
        <AntMenu.Item key={EPages.USERS} icon={menuLogoSprite('#users-icon')}>
          <Link onClick={onClick} to={EPages.USERS}>
            {local.main.breadcrumb.users}
          </Link>
        </AntMenu.Item>
      )}
      {isInRole(Authorities.POSITIONS_PAGE) && (
        <AntMenu.Item
          key={EPages.POSITIONS}
          icon={menuLogoSprite('#positions-icon')}
        >
          <Link onClick={onClick} to={EPages.POSITIONS}>
            {local.main.breadcrumb.positions}
          </Link>
        </AntMenu.Item>
      )}
    </AntMenu>
  )
}
