export const taskPassing = {
  viewing: {
    confirmButton: 'Confirm training',
    disabledConfirmButtonTooltip:
      'A repeat of video interview passing is possible if the manager or system administrator make the appropriate decision',
    proceedWithTheTask: 'Proceed with the task',
    viewingButton: 'View submitted answer',
    previewModal: {
      okText: 'Start',
      cancelText: 'Back',
      description: {
        accept: 'Confirm action',
        helper: 'To confirm training, you must complete the task.',
        withLimitTime: 'Complete within :MinutesEstimated minute(s).',
        withLimitTimeHelper:
          'After time is up, the task completion will be suspended and automatically submitted for checking.',

        unlimitTime: `Evaluated time to complete the task: :MinutesEstimated minute(s).`,
      },
    },
    interviewModal: {
      okText: 'Start',
      cancelText: 'Cancel',
      accept: 'Confirm action',
      description:
        "To pass an online video interview, turn on your webcam and make sure it's working. Please be aware that there will be no further attempt to conduct an interview. Are you ready to start?",
      error: 'Error! Could not access camera and/or microphone',
    },
  },
  passingRezult: {
    returnButton: 'Back to Track',
    completed: {
      title: 'Congratulations! You have completed the Track.',
    },
    confirmed: {
      title: 'You have successfully completed the Module',
    },
    rejected: {
      title: 'Test failed',
      description: 'We recommend you study the information in the module again',
    },
    timeout: {
      title: 'Time is up and the task is submitted for checking',
      description: 'Expect the task to be checked soon',
    },
    accepted: {
      title: 'Task submitted for checking',
      description: 'Expect the task to be checked soon',
    },
    error: {
      title: 'Task submission failed',
      description: 'Re-complete the task',
    },
  },
  passing: {
    leftTime: 'Time left',
    sendTaskButton: 'Submit for checking',
    exitPromptMessage:
      'If you leave the page, the module will be rejected. Are you sure you want to leave the page?',
    question: {
      titles: {
        test: 'Tests question',
        text: 'Detailed question',
        video: 'Video question',
        default: 'Unknown question type',
        task: 'Task № ',
        question: 'Question',
        answer: 'Answer',
      },
    },
    answer: {
      video: 'Video answer',
      text: {
        placeholder: 'Enter the answer',
        loadFileButton: 'Upload file',
        maxCountAndSizeFiles: ':Count/:MaxCount files, :Size/:MaxSize MB',
      },
    },
  },
}
