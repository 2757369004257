export const taskPassing = {
  viewing: {
    confirmButton: 'Подтвердить ознакомление',
    disabledConfirmButtonTooltip:
      'Повторное прохождение видео собеседования возможно в случае, если управляющий или администратор системы примут соответствующее решение',
    proceedWithTheTask: 'Перейти к выполнению заданий',
    viewingButton: 'Просмотреть отправленный ответ',
    previewModal: {
      okText: 'Начать',
      cancelText: 'Вернуться',
      description: {
        accept: 'Подтвердите действие',
        helper: 'Для подтверждения ознакомления необходимо выполнить задание.',
        withLimitTime: `Выполнить в течение :MinutesEstimated мин.`,
        withLimitTimeHelper: `После истечения времени, выполнение задания будет приостановлено и автоматически отправлено на проверку.`,
        unlimitTime: `Задание желательно выполнить в течение :MinutesEstimated мин.`,
      },
    },
    interviewModal: {
      okText: 'Начать',
      cancelText: 'Отмена',
      accept: 'Подтвердите действие',
      description:
        'Для прохождения видео собеседования в онлайн режиме включите веб-камеру и убедитесь, что она работает. Обратите внимание, у вас есть только одна попытка. Вы готовы начать прохождение?',
      error: 'Ошибка! Не удалось получить доступа к камере и/или микрофону',
    },
  },
  passingRezult: {
    returnButton: 'Вернуться к Треку',
    completed: {
      title: 'Поздравляем! Вы завершили прохождение Трека.',
    },
    confirmed: {
      title: 'Модуль успешно пройден',
    },
    rejected: {
      title: 'Вы не сдали тест',
      description:
        'Рекомендуем снова изучить описание и подготовиться еще лучше',
    },
    timeout: {
      title: 'Время вышло и задание отправлено на проверку',
      description: 'Ожидайте проверки задания в ближайшее время',
    },
    accepted: {
      title: 'Задание отправлено на проверку',
      description: 'Ожидайте проверки задания в ближайшее время',
    },
    error: {
      title: 'Сбой отправки задания',
      description: 'Пройдите задание заново',
    },
  },
  passing: {
    leftTime: 'Осталось времени',
    sendTaskButton: 'Отправить на проверку',
    exitPromptMessage:
      'Если вы покинете страницу, модуль будет отклонен. Вы уверены, что хотите покинуть страницу?',
    question: {
      titles: {
        test: 'Тестовый вопрос',
        text: 'Развёрнутый вопрос',
        video: 'Видео-вопрос',
        default: 'Неизвестный тип вопроса:',
        task: 'Задание № ',
        question: 'Вопрос',
        answer: 'Ответ',
      },
    },
    answer: {
      video: 'Видео ответ',
      text: {
        placeholder: 'Введите ответ',
        loadFileButton: 'Загрузить файл',
        maxCountAndSizeFiles: ':Count/:MaxCount файлов, :Size/:MaxSize МБ',
      },
    },
  },
}
