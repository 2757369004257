export const main = {
  returnToMain: 'Go to Home Page',
  maintenanceMode: {
    title: 'Our website is temporarily unavailable due to system maintenance.',
    subtitle: 'We sincerely apologize for any inconvenience caused!',
  },
  breadcrumb: {
    home: 'Home Page',
    profile: 'Dashboard',
    avaibleTracks: 'My Transit Maps',
    tracksManagement: 'Maps Management',
    checkTasks: 'Review Tasks',
    users: 'Users',
    positions: 'Roles',
    items: 'Maps',
    reference: 'Help',
    statsByUsers: 'By Users',
    statsByUserForItem: 'User :UserName progress on map «:TrackName»',
    statsByItems: 'By Maps',
    profileSettings: 'Settings',
    usersProfile: ':UserName profile page',
    notFound: 'Page not found',
    stat: 'Statistics',
  },
  auth: {
    signIn: 'Sign in',
    signUp: 'Registration',
    restore: 'Password recovery',
    newPassword: 'Change password',
  },
  headMenu: {
    profileSettings: 'Settings',
    reference: 'Help',
    exit: 'Logout',
    language: 'Language',
    theme: 'Theme',
    bonus: 'Partner bonuses',
    bonusNotify: 'Become a WinWin partner and earn passive income!',
  },
  search: {
    placeholder: 'Enter search request',
    button: 'Search',
    searchIn: 'Search in:',
    inUnavailableNodes: 'Unavailable nodes',
    inTrackTitle: 'Maps name',
    inNodeTitle: 'Nodes name',
    inNodeTopic: 'Nodes content',
    inQuestionsText: 'Text of questions',
    trackTitle: 'Maps name:',
    nodeTitle: 'Node name:',
    nodeTopic: 'Node content:',
    questionsText: 'Question text',
    noMatch: 'No matches',
    inManagerNodes: 'Maps Management',
    inAvailableNodes: 'My Transit Maps',
    visibleOffTooltip: 'Step in the status - "Unavailable"',
    editTooltip: 'Switching to map (step) editing',
  },
  menu: {
    collapse: 'Minimize',
    expand: 'Expand',
  },
  PDFViewer: {
    page: 'Page',
    of: 'of',
    loading: 'Loading PDF ...',
    failedLoad: 'Failed to load PDF file',
  },

  task: 'Task',
  postSolution: 'Sent answer',
  momentLanguage: 'en', //All Language you can see on https://momentjs.com/
  editorLanguage: 'en', //ar, bs, cs, da, de, el, en_ca, en_gb, es, et, fa, fi, fr, he, hr, hu, id, it, ja, ko, ku, me, nb, nl, pl, pt_br, pt_pt, ro, ru, sk, sl, sr, sv, th, tr, uk, vi, zh_cn, zh_tw
  acceptLanguage: 'en-US',
  editorPlaceholder: {
    default: 'Write something',
    editModule: 'Type a description',
  },
  validation: {
    requiredField: 'Required',
    fullNameLength: 'Use at least 3 characters',
    emailValid: 'Login must be a valid email address',
    phoneNumberValid: 'Phone number must be valid',
    passwordValidTitle: 'The password must contain:',
    passwordLength: 'Use 8 or more characters',
    passwordWithoutWhitespace: 'No whitespace are allowed',
    latinLettersOnly: 'Only lowercase and uppercase Latin letters are allowed',
    upperLowerCaseLetters:
      'Password must contain Latin lowercase or uppercase letters',
    includeSpecialSymbols: 'Must contain special characters (!@#$%^&*, etc.)',
    includeDigits: 'Must contain 1 or more numbers',
    includeDigitsOrSymbols:
      'Must contain at least 1 number (0-9) or a special character (!@#$%^&* etc.)',
    passwordMustBeDifferent: 'New password must be different',
    passwordConfirmValid: 'Passwords do not match',
    emailConfirmValid: 'Email does not match',
  },
  passwordStrength: {
    mainTitle: 'Password is',
    weakPassword: 'Insecure',
    middlePassword: 'Average',
    hardPassword: 'Strong',
  },
  periods: {
    currentWeek: 'this week',
    lastWeek: 'last week',
    currentMonth: 'this month',
    lastMonth: 'last month',
    currentYear: 'this year',
    allTime: 'in all time',
  },
}
