export const profile = {
  info: {
    defaultFullName: 'Guest',
    position: 'Role',
    items: 'Maps',
    availableItems: 'Available Maps',
    progress: 'Overall Progress',
    tasksCompleted: 'Steps Completed',
  },
  lastAction: {
    tasksCompleted: 'Step successfully completed',
    tasksConfirmed: 'Step completion confirmed',
    tasksFailed: 'Unsuccessful attempt to complete the step',
    tasksRejected: 'Step completion rejected',
    tasksSent: 'The step has been submitted for review',
    tasksDroppedNodeStatus: 'Step progress was reset',
    unknownStatus: 'Unknown step status',
    lastActions: 'Recent actions',
  },
  stat: {
    title: 'Transit Maps Progress',
    selectItem: 'Select Map',
    itemLabel: 'Map',
    tasks: {
      tasksProgress: 'Progress',
      progressTooltip:
        'The pie chart is divided into two sectors - completed steps and incomplete steps. The size of the sector representing completed steps corresponds to the percentage of completed work. The size of the sector representing incomplete steps corresponds to the percentage of unfinished work. The pie chart displays progress over the entire time period.',
      completedProgress: 'Completed',
      failedProgress: 'Not completed',
      tasksTotal: 'Total steps',
      tasksCompleted: 'Completed',
      tasksLeft: 'Remaining Steps',
      tasksChecked: 'Under Review',
      columnTasksTable: {
        name: 'Title',
        status: 'Status',
        statusRejected: 'FOR REWORK',
        statusCompleted: 'COMPLETED',
        statusChecking: 'UNDER REVIEW',
        statusUnavailable: 'NOT COMPLETED',
        timeCompleted: 'Completed',
        timeVerified: 'Reviewed',
      },
      tableTitle: 'Steps By Status',
    },
  },
}
