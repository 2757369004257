import React, { FC, useCallback, useEffect, useState } from 'react'
import Styles from './EISPasswordProgress.module.scss'
import local from './../../../localization'

interface IPasswordProgress {
  passwordStrength: number
}

const EISPasswordProgress: FC<IPasswordProgress> = ({ passwordStrength }) => {
  const [forcePasswordStyle, setForcePasswordStyle] = useState<string>('')

  const setForceLevelStyle = useCallback((forceLevel: number) => {
    if (!forceLevel || forceLevel === 0) return

    switch (forceLevel) {
      case 1:
      case 2:
        setForcePasswordStyle(Styles.weak)
        break
      case 3:
      case 4:
        setForcePasswordStyle(Styles.medium)
        break
      case 5:
        setForcePasswordStyle(Styles.high)
        break

      default:
        setForcePasswordStyle('')
        break
    }
  }, [])

  useEffect(() => {
    !!passwordStrength && setForceLevelStyle(passwordStrength)
  }, [passwordStrength, setForceLevelStyle])

  return (
    <div className={Styles.progress_wrapper}>
      <p>{local.main.passwordStrength.mainTitle}</p>
      <div
        className={`${Styles.progress} ${passwordStrength &&
          forcePasswordStyle}`}
      >
        <span id='weak'></span>
        <span id='medium'></span>
        <span id='high'></span>
      </div>
    </div>
  )
}

export default EISPasswordProgress
