import { Observable, of } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { TMap } from '../../constants'
import { catchError, map } from 'rxjs/operators'
import { EAPIEndpoint } from '../constants'

const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
export default class PositionsService {
  static getPositions(
    data: Record<string | number, any>
  ): Observable<IAPIResult<I.IPositionsResponse>> {
    const baseApiService = new BaseApiService<I.IPositionsResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.POSITIONS}${getParams(data)}`
    )
  }

  static postPosition(
    id: number,
    title: string,
    isActive: boolean,
    items?: Array<number>,
    users?: Array<number>,
  ): Observable<IAPIResult<I.IPosition>> {
    const baseApiService = new BaseApiService<I.IPosition>()
    const body = {
      id,
      title,
      isActive,
      items: items ? items : [],
      users: users ? users : [],
    }
    return baseApiService.postRequest(EAPIEndpoint.POSITIONS, head, body)
  }

  static putPosition(
    title: string,
    isActive: boolean,
    items?: Array<number>,
    users?: Array<number>,
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    const body = {
      title: title,
      isActive: isActive,
      items: items ? items : [],
      users: users ? users : [],
    }
    return baseApiService.putRequest(EAPIEndpoint.POSITIONS, head, body)
  }

  static deletePosition(positionId: number): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.deleteRequest(
      EAPIEndpoint.DELETE_POSITION.replace(':positionId', positionId.toString())
    )
  }

  static getDefaultItems(positionId: number): Observable<IAPIResult<TMap>> {
    const baseApiService = new BaseApiService<TMap>()
    return baseApiService
      .getRequest(
        EAPIEndpoint.DEFAULT_ITEMS.replace(':positionId', positionId.toString())
      )
      .pipe(
        map(r => r),
        catchError(err => {
          console.error(err)
          return of() as Observable<IAPIResult<TMap>>
        })
      )
  }
}
