export const confirmedPassword = {
  form: {
    password: 'New password',
    passwordConfirm: 'Password confirmation',
  },
  confirmedButton: 'Confirm',
  backToSignInLink: 'Back to Login Page',
  notification: {
    passwordChanged: 'Password has been changed',
  },
}
