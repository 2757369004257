export enum EValidationMessage {
  REQUIRED_FIELD = 'Zorunlu alan',
  OPTION_NOT_SELECTED = 'Seçenek seçili değil',
  OPTIONS_EMPTY = 'Eksik seçenek listesi',
  UNKNOW_OPTION = 'Seçenek listede yok',
  ONLY_POSITIVE = 'Sadece pozitif tamsayı',
  INCORRECT_PHONE = 'Hatalı telefon numarası',
  ONLY_BOOLEAN = 'Yalnızca Boole',
  INCORRECT_DATE = 'Hatalı tarih',
  ONLY_DECIMAL_OR_INTEGER = 'Sadece ondalık veya tam sayı',
  UNKNOW_COLUMN = 'Bilinmeyen sütun',
  NONE = '',
}
export enum EStatusUser {
  ACTIVE = 'Aktif',
  DISABLE = 'Bloke edilmiş',
}
export enum EStatusItem {
  ACTIVE = 'Aktif',
  DISABLE = 'Pasif',
}
export enum EStatusPosition {
  ACTIVE = 'Aktif',
  DISABLE = 'Pasif',
}
export enum ENodeStatus {
  COMPLETED = 'Tamamlandı',
  REJECTED = 'Reddedildi',
  CHECKING = 'İnceleniyor',
  NOT_COMPLETED = 'Tamamlanmadı',
  ALL = 'Tümü',
}
export enum EStatTabs {
  STAT = 'İstatistik',
  TABLE = 'E-tablo',
  MAP = 'İlerleme haritası',
}
export enum ESelectList {
  YES = 'Evet',
  NO = 'Hayır',
  ALL = 'Tümü',
}
export enum ESelectOnlineStatusList {
  ONLINE = 'Çevrimiçi',
  OFFLINE = 'Çevrimdışı',
  NO_STATUS = 'Durum yok',
  ALL = 'Tümü',
}