import { Observable } from 'rxjs'
import { IAPIResult } from '../../IBaseService'
import { BaseApiService } from '../../base.service'
import * as I from 'Types'
import { TMap } from '../../../constants'
import { EAPIEndpoint } from '../../constants'

const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export default class TaskPassingService {
  static getNodeTask(nodeId: number): Observable<IAPIResult<I.INodePassTask>> {
    const baseApiService = new BaseApiService<I.INodePassTask>()
    return baseApiService.getRequest(
      EAPIEndpoint.AVAILABLE_NODE_TASK.replace(':nodeId', nodeId.toString())
    )
  }

  static getNodeTaskQuestions(
    nodeId: number
  ): Observable<IAPIResult<Array<I.IPassQuestion>>> {
    const baseApiService = new BaseApiService<Array<I.IPassQuestion>>()
    return baseApiService.getRequest(
      EAPIEndpoint.AVAILABLE_NODE_TASK_QUESTIONS.replace(
        ':nodeId',
        nodeId.toString()
      )
    )
  }

  static postTaskSolution(
    task: I.INodePassDraft
  ): Observable<IAPIResult<I.INodePassResult>> {
    const baseApiService = new BaseApiService<I.INodePassResult>()
    return baseApiService.postRequest(
      EAPIEndpoint.SOLUTION_ATTEMPTS,
      head,
      task
    )
  }

  static setTaskPassingStartTime(taskId: string): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(
      EAPIEndpoint.TASK_PASSING_START_TIME,
      head,
      {
        nodeId: taskId,
      }
    )
  }

  static getTaskPassingDraft(
    taskId: number
  ): Observable<IAPIResult<I.INodePassDraft>> {
    const baseApiService = new BaseApiService<I.INodePassDraft>()
    return baseApiService.getRequest(
      EAPIEndpoint.TASK_PASSING_DRAFT_GET.replace(':taskId', taskId.toString())
    )
  }

  static setTaskPassingDraft(
    draft: I.INodePassDraft
  ): Observable<IAPIResult<I.IId>> {
    const baseApiService = new BaseApiService<I.IId>()
    return baseApiService.postRequest(
      EAPIEndpoint.TASK_PASSING_DRAFT_SET,
      head,
      draft
    )
  }

  static putSolutionAttemptReject(
    taskId: number
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.putRequest(
      EAPIEndpoint.SOLUTION_ATTEMPT_REJECT,
      head,
      {
        nodeId: taskId,
      }
    )
  }
}
