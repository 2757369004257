import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENotificationVariant, EStateStatus } from '../../../constants'
import { AuthService } from '../../../services/auth'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import local from './../../../localization'

const initialState: I.RecoveryPasswordState = {
  status: EStateStatus.IDLE,
  error: null,
}

export const recoveryPassword = createAsyncThunk(
  'auth/recoveryPassword',
  async (username: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        AuthService.resetPassword(username)
      )
      dispatch(
        enqueueSnackbar({
          message: local.notification.auth.SUCCESS.recoveryPassword,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

const recoveryPasswordSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(recoveryPassword.pending, state => {
      state.status = EStateStatus.PENDING
    })
    builder.addCase(recoveryPassword.fulfilled, state => {
      state.status = EStateStatus.FULFILLED
    })
    builder.addCase(recoveryPassword.rejected, state => {
      state.status = EStateStatus.REJECTED
    })
  },
})

export const { reducer: recoveryPasswordReducer } = recoveryPasswordSlice

export const selectStatusRestore = (state: I.RootState) =>
  state.auth.recoveryPasswordReducer.status
