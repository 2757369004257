import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import * as I from 'Types'
import {
  ELocalStorageKeys,
  EShapeStates,
  EShapeType,
  ETrackEngineMode,
  PAGE,
  maxScale,
  minScale,
  scaleStep,
} from '../../constants'
import { originalData } from './tracksActionsSlice'
import { isMobile } from '../../utils'

const initialState: I.ITrackEngine = {
  loading: false,
  activeShapeIDs: [],

  page: {
    width: PAGE.width,
    height: PAGE.height,
    scale: PAGE.scale,
  },

  resizes: [],

  create: {
    shapeType: null,
  },
  content: {},
  shapes: {},
  editTitle: {
    show: false,
    title: '',
    formattedTitle: '',
    left: 0,
    top: 0,
  },
  scale: 1,
  dragToolbar: false,
  state: EShapeStates.CALMNESS,
  trackRevision: 0,
  selectionArea: undefined,
  lastCoords: {
    trackId: undefined,
    x: 0,
    y: 0,
  },
  staleCoords: {
    x: 0,
    y: 0,
  },
  moveCoords: {
    x: 0,
    y: 0,
  },
  alignments: {
    coordsHorizontal: [],
    coordsVertical: [],
    coordsAligment: [],
  },
  viewMode: ETrackEngineMode.EDIT,
}

export const tracksEngineSlice = createSlice({
  name: 'tracks/editor',
  initialState,
  reducers: {
    activeShapeIDsSet: (
      state: I.ITrackEngine,
      action: PayloadAction<Array<string>>
    ) => {
      state.activeShapeIDs = [...action.payload]
    },
    shapeSet: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<I.IShapeSetPayload>
    ) => {
      const { id, shape } = payload
      const initShape = state.shapes[id]
      if (initShape) {
        _.assign(initShape, shape)
      } else {
        _.set(state.shapes, id, shape)
      }
    },
    shapesSet: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Record<string, I.IShape | I.ICurve>>
    ) => {
      _.assign(state.shapes, payload)
    },
    shapesSetTotal: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.total = payload
    },
    shapesReset: (state: I.ITrackEngine) => {
      _.keys(state.shapes).forEach(k => {
        _.unset(state.shapes, k)
      })
    },
    shapesRemove: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Array<string>>
    ) => {
      payload.forEach(id => delete state.shapes[id])
    },
    shapeContentSet: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<I.IShapeContentSetPayload>
    ) => {
      const { id, shapeContent } = payload
      const initContent = state.content[id]

      if (initContent) {
        _.assign(initContent, shapeContent)
      } else {
        _.set(state.content, id, shapeContent)
      }
    },
    shapesInvalid: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<string[]>
    ) => {
      const keys = Object.keys(state.shapes)
      for (let i = 0; i < keys.length; i++) {
        state.shapes[keys[i]].isNotValid = false
      }
      payload.forEach(id => {
        state.shapes[id].isNotValid = true
      })
    },
    shapesContentsSet: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Record<string, I.IDiagramContent>>
    ) => {
      _.assign(state.content, payload)
    },
    shapesContentReset: (state: I.ITrackEngine) => {
      _.keys(state.content).forEach(k => {
        _.unset(state.content, k)
      })
    },
    contentRemove: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Array<string>>
    ) => {
      payload.forEach(id => delete state.shapes[id])
    },
    createDataSet: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<I.ITrackEngineCreateConfig>
    ) => {
      _.assign(state.create, payload)
    },
    createDataReset: (state: I.ITrackEngine) => {
      _.assign(state.create, initialState.create)
    },
    setPageWidthAndHeight: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Partial<I.ITrackEnginePageConfig>>
    ) => {
      _.assign(state.page, payload)
    },
    updateDrafts: (state: I.ITrackEngine) => {
      const curves = _.values(state.shapes).filter(
        shape => shape.type === EShapeType.CURVE
      ) as Array<I.ICurve>
      const circles = (_.values(state.shapes).filter(
        shape => shape.type === EShapeType.CIRCLE
      ) as Array<I.IShape>).reduce(
        (circlesDict: Record<string, I.IShape>, shape: I.IShape) => {
          circlesDict[shape.id] = _.assign({}, shape, {
            draft:
              curves.findIndex(curve => curve.startID === shape.id) === -1 &&
              curves.findIndex(curve => curve.endID === shape.id) === -1,
          })
          return circlesDict
        },
        {}
      )
      _.assign(state.shapes, circles)
    },
    calculatePageWidthAndHeight: (state: I.ITrackEngine) => {
      const scrollableArea = document.querySelector('#area') as HTMLDivElement
      const offsetY = scrollableArea
        ? scrollableArea.getBoundingClientRect().top
        : 130 - document.body.getBoundingClientRect().top

      const shapes = _.values(state.shapes).filter(
        s => s.type === EShapeType.CIRCLE
      ) as Array<I.IShape>

      const maxX =
        shapes.length > 0
          ? Math.max(
              ...shapes.map(c => (c.x + c.radius + 10) * state.scale),
              scrollableArea
                ? scrollableArea.clientWidth
                : document.body.clientWidth - 10
            )
          : scrollableArea
          ? scrollableArea.clientWidth
          : document.body.clientWidth - 10

      const maxY =
        shapes.length > 0
          ? Math.max(
              ...shapes.map(c => (c.y + c.radius + 10) * state.scale),
              document?.scrollingElement?.clientHeight!! - offsetY - 60
            )
          : document?.scrollingElement?.clientHeight!! - offsetY - 60

      state.page.width = maxX
      state.page.height = maxY
    },
    scrollPage: (
      _state: I.ITrackEngine,
      { payload }: PayloadAction<I.IPointCoords>
    ) => {
      const scrollableArea = document.querySelector('#area') as HTMLDivElement
      const offsetY =
        scrollableArea.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top

      // scrollLeft
      if (scrollableArea && document?.scrollingElement) {
        if (
          payload.x >=
          scrollableArea.clientWidth + scrollableArea.scrollLeft
        ) {
          scrollableArea.scrollLeft = payload.x - scrollableArea.clientWidth
        }

        if (
          payload.y + offsetY >=
          document.scrollingElement.clientHeight +
            document.scrollingElement.scrollTop
        ) {
          document.scrollingElement.scrollTop =
            payload.y + offsetY - document.scrollingElement.clientHeight
        }
      }
    },
    setEditTitle: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<I.IEditTitle>
    ) => {
      _.assign(state.editTitle, payload)
    },
    editTitleReset: (state: I.ITrackEngine) => {
      _.assign(state.editTitle, initialState.editTitle)
    },
    incrementScale: (state: I.ITrackEngine) => {
      if (state.scale < maxScale - scaleStep / 2) {
        state.scale += scaleStep
        localStorage.setItem(
          ELocalStorageKeys.TRACK_SCALE,
          state.scale.toFixed(2)
        )
      }
    },
    decrementScale: (state: I.ITrackEngine) => {
      if (state.scale > minScale + scaleStep / 2) {
        state.scale -= scaleStep
        localStorage.setItem(
          ELocalStorageKeys.TRACK_SCALE,
          state.scale.toFixed(2)
        )
      }
    },
    resetScale: (state: I.ITrackEngine) => {
      state.scale = 1
      localStorage.setItem(
        ELocalStorageKeys.TRACK_SCALE,
        state.scale.toFixed(2)
      )
    },
    resetScaleToStored: (state: I.ITrackEngine) => {
      state.scale =
        Number(localStorage.getItem(ELocalStorageKeys.TRACK_SCALE)) || (isMobile() ? 0.3 : 1)
    },
    setDragToolbar: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<boolean>
    ) => {
      state.dragToolbar = payload
    },
    setPageState: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<EShapeStates>
    ) => {
      state.state = payload
    },
    setResizeState: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<Array<I.ITrackEngineResizeConfig>>
    ) => {
      state.resizes = payload
    },
    clearEngineState: (state: I.ITrackEngine) => {
      document.body.style.cursor = ''
      _.assign(state, {
        ...initialState,
        lastCoords: {
          x: state.lastCoords.x,
          y: state.lastCoords.y,
          trackId: state.lastCoords.trackId,
        },
      })
    },
    setTrackRevision: (
      state: I.ITrackEngine,
      revision: PayloadAction<number>
    ) => {
      state.trackRevision = revision.payload
    },
    setIsLoading: (state: I.ITrackEngine, revision: PayloadAction<boolean>) => {
      state.loading = revision.payload
    },
    setSelectionArea: (
      state: I.ITrackEngine,
      { payload }: PayloadAction<I.ISelectedArea>
    ) => {
      if (payload) {
        const { x, y, isAppendix } = payload

        if (state.selectionArea) {
          if (isAppendix) {
            state.selectionArea.endX += x
            state.selectionArea.endY += y
          } else {
            state.selectionArea.endX = x
            state.selectionArea.endY = y
          }
        } else {
          state.selectionArea = {
            startX: x,
            startY: y,
            endX: x,
            endY: y,
          }
        }
      }
    },
    removeSelectionArea: (state: I.ITrackEngine) => {
      state.selectionArea = undefined
    },
    setLastCoords: (
      state: I.ITrackEngine,
      action: PayloadAction<I.ILastCoords>
    ) => {
      _.assign(state.lastCoords, action.payload)
    },
    setStaleCoords: (
      state: I.ITrackEngine,
      action: PayloadAction<I.IPointCoords>
    ) => {
      _.assign(state.staleCoords, action.payload)
    },
    resetStaleCoords: (state: I.ITrackEngine) => {
      _.assign(state.staleCoords, initialState.staleCoords)
    },
    setMoveCoords: (
      state: I.ITrackEngine,
      action: PayloadAction<I.IPointCoords>
    ) => {
      _.assign(state.moveCoords, action.payload)
    },
    resetMoveCoords: (state: I.ITrackEngine) => {
      _.assign(state.moveCoords, initialState.moveCoords)
    },
    setAligments: (
      state: I.ITrackEngine,
      action: PayloadAction<{
        coordsHorizontal: number[]
        coordsVertical: number[]
      }>
    ) => {
      const { coordsHorizontal, coordsVertical } = action.payload
      state.alignments.coordsHorizontal = coordsHorizontal
      state.alignments.coordsVertical = coordsVertical
    },
    setCoordsAligments: (
      state: I.ITrackEngine,
      action: PayloadAction<I.IPointCoords[]>
    ) => {
      state.alignments.coordsAligment = action.payload
    },
    clearAligments: state => {
      state.alignments.coordsAligment = []
      state.alignments.coordsHorizontal = []
      state.alignments.coordsVertical = []
    },

    setViewMode: (
      state: I.ITrackEngine,
      action: PayloadAction<ETrackEngineMode>
    ) => {
      state.viewMode = action.payload
    },
  },
})

export const selectTrackRevision = (state: I.RootState) => {
  return state.tracks.engine.trackRevision
}
export const selectPageConfig = (state: I.RootState) => {
  return state.tracks.engine.page
}
export const selectCreateData = (state: I.RootState) =>
  state.tracks.engine.create
export const selectActiveShapeIds = (state: I.RootState) =>
  state.tracks.engine.activeShapeIDs
export const selectActiveShapes = (state: I.RootState) =>
  Object.entries(state.tracks.engine.shapes).reduce(
    (a, [k, v]) =>
      state.tracks.engine.activeShapeIDs.includes(v.id) ? { ...a, [k]: v } : a,
    {}
  )

export const selectCircles = (state: I.RootState): Array<I.IShape> =>
  _.sortBy(
    _.values(state.tracks.engine.shapes).filter(
      shape => shape.type === EShapeType.CIRCLE
    ) as Array<I.IShape>,
    'zIndex'
  )
export const selectCurves = (state: I.RootState): Array<I.ICurve> =>
  _.sortBy(
    _.values(state.tracks.engine.shapes).filter(
      shape => shape.type === EShapeType.CURVE
    ) as Array<I.ICurve>,
    'zIndex'
  )

export const selectShape = <T extends I.IShape | I.ICurve>(id: string) => (
  state: I.RootState
): T => state.tracks.engine.shapes[id] as T

export const selectContent = (state: I.RootState) => state.tracks.engine.content

export const selectIsSelecteds = (id: string) => (
  state: I.RootState
): boolean => state.tracks.engine.activeShapeIDs.includes(id)

export const selectEditTitle = (state: I.RootState) =>
  state.tracks.engine.editTitle

export const shouldBlockNavigation = (state: I.RootState) => {
  const od = JSON.stringify(originalData)
  const cd = JSON.stringify({
    shapes: state.tracks.engine.shapes,
    content: state.tracks.engine.content,
  })

  return od !== cd
}

export const selectTotalShapesCount = (state: I.RootState) =>
  state.tracks.engine.total

export const selectIfSomeShapeEditTileNeeded = (state: I.RootState) =>
  selectCircles(state).find(s => s.editTitle)

export const selectSvgScale = (state: I.RootState) => state.tracks.engine.scale
export const getIsDragToolbar = (state: I.RootState) =>
  state.tracks.engine.dragToolbar
export const selectPageState = (state: I.RootState) => state.tracks.engine.state

export const selectResizeState = (state: I.RootState) =>
  state.tracks.engine.resizes

export const selectIsLoading = (state: I.RootState) =>
  state.tracks.engine.loading

export const selectSelectedArea = (state: I.RootState) =>
  state.tracks.engine.selectionArea

export const selectLastCoords = (state: I.RootState) =>
  state.tracks.engine.lastCoords

export const selectStaleCoords = (state: I.RootState) =>
  state.tracks.engine.staleCoords

export const selectMoveCoords = (state: I.RootState) =>
  state.tracks.engine.moveCoords

export const selectAligments = (state: I.RootState) =>
  state.tracks.engine.alignments

export const selectViewMode = (state: I.RootState) =>
  state.tracks.engine.viewMode
export const { reducer: tracksEngineReducer } = tracksEngineSlice

export const {
  activeShapeIDsSet,
  createDataSet,
  createDataReset,
  shapeSet,
  shapesSet,
  shapesReset,
  shapeContentSet,
  shapesContentReset,
  setPageWidthAndHeight,
  setEditTitle,
  editTitleReset,
  setDragToolbar,
  setPageState,
  clearEngineState,
  setResizeState,
  setTrackRevision,
  setIsLoading,
  setLastCoords,
  setStaleCoords,
  resetStaleCoords,
  setMoveCoords,
  resetMoveCoords,
  shapesInvalid,
  setAligments,
  setCoordsAligments,
  clearAligments,
  setViewMode,
} = tracksEngineSlice.actions
