export enum EValidationMessage {
  REQUIRED_FIELD = 'Required field',
  OPTION_NOT_SELECTED = 'Option not selected',
  OPTIONS_EMPTY = 'Missing option list',
  UNKNOW_OPTION = 'Option is not in list',
  ONLY_POSITIVE = 'Positive integer only',
  INCORRECT_PHONE = 'Incorrect phone number',
  ONLY_BOOLEAN = 'Boolean only',
  INCORRECT_DATE = 'Incorrect date',
  ONLY_DECIMAL_OR_INTEGER = 'Decimal or integer only',
  UNKNOW_COLUMN = 'Unknow column',
  NONE = '',
}
export enum EStatusUser {
  ACTIVE = 'Active',
  DISABLE = 'Blocked',
}
export enum EStatusItem {
  ACTIVE = 'Active',
  DISABLE = 'Inactive',
}
export enum EStatusPosition {
  ACTIVE = 'Active',
  DISABLE = 'Inactive',
}
export enum ENodeStatus {
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  CHECKING = 'Under review',
  NOT_COMPLETED = 'Not completed',
  ALL = 'All',
}
export enum EStatTabs {
  STAT = 'Statistic',
  TABLE = 'Spreadsheet',
  MAP = 'Progress map',
}
export enum ESelectList {
  YES = 'Yes',
  NO = 'No',
  ALL = 'All',
}
export enum ESelectOnlineStatusList {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  NO_STATUS = 'No status',
  ALL = 'All',
}