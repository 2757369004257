import { cloneDeep, keys } from 'lodash'
import dompurify from 'dompurify'

const config = {
  ADD_TAGS: ['iframe'], //or ALLOWED_TAGS
  ADD_ATTR: [
    'data-file-size',
    'data-origin',
    'allow',
    'allowfullscreen',
    'frameborder',
    'scrolling',
    'data-proportion',
    'data-percentage',
    'data-size',
    'style',
    'data-align',
    'data-index',
    'data-file-name',
    'data-*',
  ], //or //or ALLOWED_ATR
}

dompurify.addHook('uponSanitizeElement', (node: any, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || ''
    if (!src.startsWith('https://www.youtube.com/embed/')) {
      return node.parentNode.removeChild(node)
    }
  }
})

dompurify.addHook('afterSanitizeAttributes', function(node) {
  // build an anchor to map URLs to
  const regex = RegExp(
    '^(' + ['http', 'https', 'ftp', 'sftp'].join('|') + '):',
    'gim'
  )
  var anchor = document.createElement('a') as HTMLAnchorElement
  // check all href attributes for validity
  if (node.hasAttribute('href')) {
    anchor.href = node.getAttribute('href')!!
    if (anchor.protocol && !anchor.protocol.match(regex)) {
      node.removeAttribute('href')
    }
  }
  // check all action attributes for validity
  if (node.hasAttribute('action')) {
    anchor.href = node.getAttribute('action')!!
    if (anchor.protocol && !anchor.protocol.match(regex)) {
      node.removeAttribute('action')
    }
  }
  // check all xlink:href attributes for validity
  if (node.hasAttribute('xlink:href')) {
    anchor.href = node.getAttribute('xlink:href')!!
    if (anchor.protocol && !anchor.protocol.match(regex)) {
      node.removeAttribute('xlink:href')
    }
  }
})

export class CommonUtils {
  static safeMerge = (
    target: Record<string, any> = {},
    source: Record<string, any> = {},
    createKeys: boolean = false
  ) => {
    const targetKeys = keys(target)
    const sourceKeys = keys(source)
    const result = cloneDeep(target)

    sourceKeys.forEach(key => {
      if (!targetKeys.includes(key) && !createKeys) {
        return
      }

      result[key] = source[key]
    })

    return result
  }

  static dompurify = {
    sanitize: (text: string | Node) => dompurify.sanitize(text, config),
  }
}
