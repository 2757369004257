import { useState } from 'react'

interface FormField {
  valid: boolean
  value: string
  message: string
}

export const useFormField = (): [typeof state, typeof updateState] => {
  const [state, setState] = useState<FormField>({
    valid: false,
    value: '',
    message: '',
  })

  const updateState = (field: FormField) => setState(field)

  return [state, updateState]
}
