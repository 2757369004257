export const selfie = {
  profilePicture: 'Profile picture',
  actionBtn: {
    uploadImage: 'Upload image',
    cancel: 'Cancel',
  },
  imageCaptuer: {
    turnOnWebcam: 'Turn on the webcam',
  },
  camera: {
    tooltip: {
      cameraSwitch: 'Switch the camera',
      takePicture: 'Take a picture',
      takeNewPicture: 'Take a new picture',
      saveImg: 'Save image',
    },
  },
}
