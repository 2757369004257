export const taskPreview = {
  backToEdit: 'Вернуться к редактированию',
  question: {
    titles: {
      task: 'Задание № ',
    },
  },
  answer: {
    video: 'Видео ответ',
    text: {
      placeholder: 'Введите ответ',
      loadFileButton: 'Загрузить файл',
    },
  },
}
