import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import _ from 'lodash'
import moment from 'moment'
import StatService from '../../../services/stat/stat.service'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import {
  IStatItemsState,
  IStatItemsTableRequest,
  IStatItemsProgressRequest,
  IStatItemsDiagramRequest,
  IStatItemsTableExportRequest,
} from './IStatItems'
import {
  ENotificationVariant,
  EPeriods,
  EStatTab,
  IKeyValuePair,
} from '../../../constants'
import { SorterResult } from 'antd/lib/table/interface'
import { TArrayColumnType } from '../../../components/ViewTable/IViewTable'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import local from '../../../localization'

const initialState: IStatItemsState = {
  tabMenu: EStatTab.STAT,
  diagramTab: {
    progressPercent: {
      progress: 0,
      timestamp: '',
    },
    dynamicProgress: {
      data: [],
      period: EPeriods.CM,
      start: moment()
        .startOf('month')
        .toDate()
        .getTime(),
      end: moment.now(),
    },
    byUsers: {
      haveItems: 0,
      completed: 0,
      inProgress: 0,
      withoutItems: 0,
    },
    byNodes: {
      total: 0,
      withTopics: 0,
      withTasks: 0,
      withoutTasks: 0,
    },
    byTasks: {
      onCheckingCount: 0,
      averageCheckingTime: 0,
    },
  },
  tableTab: {
    data: [],
    page: 1,
    pageSize: 20,
    totalElements: 0,
    sortedInfo: undefined,
  },
  tracksFilter: undefined,
}

export const getStatItemsProgress = createAsyncThunk(
  'stat-items/progress',
  async (data: IStatItemsProgressRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsProgress(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatItemsByUsers = createAsyncThunk(
  'stat-items/by-users',
  async (data: IStatItemsProgressRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsByUsers(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatItemsByTasks = createAsyncThunk(
  'stat-items/by-tasks',
  async (data: IStatItemsProgressRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsByTasks(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatItemsByNodes = createAsyncThunk(
  'stat-items/by-nodes',
  async (data: IStatItemsProgressRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsByNodes(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatItemsDiagram = createAsyncThunk(
  'stat-items/diagram',
  async (data: IStatItemsDiagramRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsDiagram(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatItemsTable = createAsyncThunk(
  'stat-items/table',
  async (data: IStatItemsTableRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatItemsTable(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postStatItemsTableExport = createAsyncThunk(
  'stat-items/table/export',
  async (data: IStatItemsTableExportRequest, { rejectWithValue, dispatch }) => {
    try {
      const fileName = data.fileName
      delete data.fileName
      await stackRequestWrapper(
        StatService.postStatItemsTableExport(data, fileName!)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.export,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const statItemsSlice = createSlice({
  name: 'stat-items',
  initialState,
  reducers: {
    clearStatItemsDiagramState: (stat: IStatItemsState) => {
      _.assign(stat.diagramTab, initialState.diagramTab)
    },
    clearStatItemsTableState: (stat: IStatItemsState) => {
      _.assign(stat.tableTab, {
        totalElements: initialState.tableTab.totalElements,
        data: initialState.tableTab.data,
      })
    },
    setTracksFilter: (
      stat: IStatItemsState,
      prop: PayloadAction<IKeyValuePair[] | undefined>
    ) => {
      stat.tracksFilter = prop.payload
    },
    setTabMenu: (stat: IStatItemsState, prop: PayloadAction<EStatTab>) => {
      stat.tabMenu = prop.payload
    },
    setPage: (stat: IStatItemsState, prop: PayloadAction<number>) => {
      stat.tableTab.page = prop.payload
    },
    setSize: (stat: IStatItemsState, prop: PayloadAction<number>) => {
      stat.tableTab.pageSize = prop.payload
    },
    setSortedInfo: (
      stat: IStatItemsState,
      prop: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      stat.tableTab.sortedInfo = prop.payload
    },
    setItemsDynamicPeriod: (
      stat: IStatItemsState,
      prop: PayloadAction<{ period: EPeriods; start: number; end: number }>
    ) => {
      const { period, start, end } = prop.payload
      stat.diagramTab.dynamicProgress.period = period
      stat.diagramTab.dynamicProgress.start = start
      stat.diagramTab.dynamicProgress.end = end
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatItemsProgress.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.diagramTab.progressPercent = action.payload.data
      }
    })
    builder.addCase(getStatItemsByUsers.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.diagramTab.byUsers = action.payload.data
      }
    })
    builder.addCase(getStatItemsByTasks.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.diagramTab.byTasks = action.payload.data
      }
    })
    builder.addCase(getStatItemsByNodes.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.diagramTab.byNodes = action.payload.data
      }
    })
    builder.addCase(getStatItemsDiagram.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.diagramTab.dynamicProgress.data =
          action.payload.data.dynamicProgress
      }
    })
    builder.addCase(getStatItemsTable.fulfilled, (state, action) => {
      if (action.payload.data) {
        _.assign(state.tableTab, {
          data: action.payload.data.content,
          totalElements: action.payload.data.totalElements,
        })
      }
    })
  },
})

export const { reducer: statItemsReducer } = statItemsSlice

export const selectStatItemsState = (state: I.RootState) => ({
  tabMenu: state.stat.statItems.tabMenu,
  tracksFilter: state.stat.statItems.tracksFilter,
})

export const selectDiagramState = (state: I.RootState) =>
  state.stat.statItems.diagramTab

export const selectTableState = (state: I.RootState) =>
  state.stat.statItems.tableTab

export const selectStatItemsColumn = (state: I.RootState) =>
  state.stat.statItems.tableTab.data.map((stat, i) => ({
    sequence:
      i +
      1 +
      state.stat.statItems.tableTab.pageSize *
        (state.stat.statItems.tableTab.page - 1),
    key: Number(stat.node.id),
    title: stat.node.title,
    ...stat,
  }))

export const {
  clearStatItemsDiagramState,
  clearStatItemsTableState,
  setTracksFilter,
  setTabMenu,
  setPage,
  setSize,
  setSortedInfo,
  setItemsDynamicPeriod,
} = statItemsSlice.actions
