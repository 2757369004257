import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import { processError } from '../../../../app/processError'
import _ from 'lodash'
import StatService from '../../../../services/stat/stat.service'
import { stackRequestWrapper } from '../../../../app/requestWrapper'
import {
  ETaskStatus,
  ENotificationVariant,
  EStatTab,
  IKeyValuePair,
} from '../../../../constants'
import { enqueueSnackbar } from '../../../notification/notificationSlice'
import local from './../../../../localization'
import { SorterResult } from 'antd/lib/table/interface'
import { TArrayColumnType } from '../../../../components/ViewTable/IViewTable'

const initialState: I.IStatUserItemState = {
  navigate: {
    user: undefined,
    track: undefined,
    tab: EStatTab.STAT,
  },
  page: 1,
  pageSize: 20,
  totalElements: 0,
  statUserItemData: [],
  status: ETaskStatus.ALL,
  sortedInfo: undefined,
  searchTask: undefined,
}

export const getStatUserItem = createAsyncThunk(
  'stat-users/user/item',
  async (data: I.IStatUserItemRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatUserItemTasks(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postResetItems = createAsyncThunk(
  'stat-users/zero',
  async (data: I.IStatUserItemResetRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(StatService.postResetItems(data))

      dispatch(
        enqueueSnackbar({
          message: local.notification.verification.SUCCESS.postZeroPassed,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const statUserItemSlice = createSlice({
  name: 'stat-users/user',
  initialState,
  reducers: {
    clearStatUserItemData: (state: I.IStatUserItemState) => {
      _.assign(state, {
        statUserItemData: initialState.statUserItemData,
        totalElements: initialState.totalElements,
      })
    },
    clearStatUserItemState: (state: I.IStatUserItemState) => {
      _.assign(state, initialState)
    },
    setNavigate: (
      state: I.IStatUserItemState,
      action: PayloadAction<I.IStatUsersNavigate>
    ) => {
      _.assign(state.navigate, action.payload)
    },
    clearNavigate: (state: I.IStatUserItemState) => {
      _.assign(state.navigate, initialState.navigate)
    },
    setPage: (state: I.IStatUserItemState, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setSize: (state: I.IStatUserItemState, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setStatus: (
      state: I.IStatUserItemState,
      action: PayloadAction<ETaskStatus>
    ) => {
      state.status = action.payload
    },
    setSortedInfo: (
      state: I.IStatUserItemState,
      action: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      state.sortedInfo = action.payload
    },
    setSearchTask: (
      state: I.IStatUserItemState,
      action: PayloadAction<IKeyValuePair | undefined>
    ) => {
      state.searchTask = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatUserItem.fulfilled, (state, action) => {
      if (action.payload?.data) {
        _.assign(state, {
          totalElements: action.payload.data.totalElements || 0,
          statUserItemData: action.payload.data.content || [],
        })
      }
    })
  },
})

export const { reducer: statUserItemReducer } = statUserItemSlice

export const selectStatUserItems = (state: I.RootState) =>
  state.stat.statUserItem

export const selectStatUserItemNavigate = (state: I.RootState) =>
  state.stat.statUserItem.navigate

export const {
  clearStatUserItemData,
  setNavigate,
  clearNavigate,
  clearStatUserItemState,
  setPage,
  setSize,
  setSortedInfo,
  setStatus,
  setSearchTask,
} = statUserItemSlice.actions

export const selectStatUserItemColumn = (
  state: I.RootState
): Array<I.IStatUserItemColumn> =>
  state.stat.statUserItem.statUserItemData.map((stat, i) => ({
    sequence:
      i +
      1 +
      state.stat.statUserItem.pageSize * (state.stat.statUserItem.page - 1),
    key: stat.nodeId,
    node: stat.node,
    attemptsCount: stat.attemptsCount,
    mentor: stat.mentor,
    timeCompleted: stat.timeCompleted,
    status: stat.status,
  }))
