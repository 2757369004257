export const tasksChecking = {
  filter: {
    optionsSelect: {
      yes: 'Evet',
      no: 'Hayır',
      all: 'Tümü',
    },
    users: 'Kullanıcı(lar) seçin',
    item: 'Parça(lar)ı seçin',
    task: 'Görev(ler)i seçin',
    tests: 'Testler',
    checked: 'İncelendi',
    showAll: 'Tümünü göster',
    showAllInfo: `
    Ayar etkin değilse, tabloda yalnızca mentor olduğunuz parkurlar için tamamlanmış görevler görüntülenecektir.
    Ayar etkinse, tabloda diğer mentorların da dahil olduğu görevler görüntülenecektir`,
  },
  emptyData: {
    noData: 'Kontrol edilecek görev yok',
    noFilterData: 'Seçilen filtrelerle eşleşen veri yok',
  },
  columns: {
    user: 'Kullanıcı',
    track: 'Rota',
    node: 'Görev',
    timeCompleted: 'Tamamlama tarihi',
    timeVerified: 'İnceleme tarihi',
    status: {
      title: 'Sonuç',
      confirmed: 'Kabul edildi',
      rejected: 'Reddedildi',
      other: 'Kontrol edilmedi',
    },
    actions: {
      title: 'Eylem',
      actionButton: 'İncele',
      actionButtonView: 'Cevabı görüntüle',
    },
  },
}
