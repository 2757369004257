export const taskCheckAndView = {
  header: {
    item: 'Rota',
    attempt: 'Deneme',
  },
  body: {
    testAnswers: 'Çoktan seçmeli testler (otomatik olarak kontrol edilir)',
    verifiedButton: 'Görevi kabul et',
    systemCheckButton: 'Görevi kabul et',
    rejectedButton: 'Görevi reddet',
    rejectModal: {
      title: 'Yorum yok',
      content:
        'Yorumunuzu eklemeden görevi reddetmek istediğinizden emin misiniz??',
      cancelText: 'Kaldır',
      okText: 'Onayla',
    },
    tooltip: 'Yukarıdaki sonucu seçin',
    submitButton: 'Onayla',
    returnButton: 'Görevleri kontrol etmeye geri dönün',
    question: {
      questionTitle: {
        test: 'Çoktan seçmeli soru',
        text: 'Detaylı soru',
        video: 'Videolu soru',
        default: 'Bilinmeyen soru türü:',
        task: 'Görev № ',
      },
      videoQuestion: 'Videolu soru',
      testQuestion: {
        text1: 'Doğru seçenekler işaretlendi ',
        right: 'yeşil',
        text2: ', yanlış ',
        wrong: 'kırmızı',
      },
      breakpointsTitle: 'Doğru cevap anahtarları',
    },
    answer: {
      title: 'Kullanıcının yanıtı:',
      commentCheckTitle: 'Cevap hakkında yorum yapın:',
      commentCheckTitleinfo:
        'Bu yorum, görev kontrolünün sonucuyla birlikte kullanıcıya e-posta ile gönderilecektir.',
      commentViewTitle: 'Eğitmenin yorumu:',
      videoAnswer: 'Videolu cevap:',
      notPassed: 'Cevap kabul edilmedi',
    },
  },
  checked: {
    title: 'Kontrol eden',
    timeChecked: 'Kontrol edildi',
    estimateChecked: 'Kontrol zamanı',
    automatically: 'Otomatik olarak',
    hour: 'sa',
    minute: 'dk',
    second: 'sn',
    rezult: {
      title: 'Sonuç',
      comfirmed: 'Kabul edildi',
      rejected: 'Reddedildi',
    },
    mentor: 'Kontrol eden',
    empty: 'Kontrol edilmedi',
  },
  fulfilled: {
    title: 'Tarafından tamamlandı',
    timeFulfilled: 'Tamamlandı',
    estimateFulfilled: {
      title: 'Tahmini süre',
      notSet: 'Ayarlanmadı',
      minute: 'dk',
    },
    leadTime: {
      title: 'Gerçek zaman',
      limit: 'SINIR',
      hour: 'sa',
      minute: 'dk',
      second: 'sn',
    },
    user: 'Tamamlayan',
  },
  notification: {
    editComment: 'Yorum değiştirildi',
    verification: {
      confirmed: 'Görev tamamlama kabul edildi',
      sentForReview: 'Görev kontrol edilmek üzere gönderildi ',
      rejected: 'Görev tamamlama reddedildi',
    },
    error: {
      solutionNotFound: 'Bu girişim için bir çözüm bulunamadı',
    },
  },
}
