import { IThemeProps } from '..'
import page_bg_img from '../../assets/svg/layout_bg.svg'
import auth_bg_img from '../../assets/svg/auth-img.svg'
import card_img from '../../assets/png/card_default_img.jpg'
// import card_img from '../../assets/svg/itc_img.svg'
// import logo from '../../assets/svg/itc.svg'
// import logo_mini from '../../assets/svg/itc_mini.svg'

export const itc: IThemeProps = {
  '--font-family': `'Roboto', sans-serif`,
  '--mini-logo': '', //`url('${logo_mini}')`,
  '--main-logo': '', //`url('${logo}')`,
  '--background-image': `url('${page_bg_img}')`,
  '--auth-background-image': auth_bg_img,
  '--default-card-image': card_img,
  '--main-theme-color': '#1890FF',
  '--light-main-theme-color': '#E1EEFE',
  '--main-text-color': '#000000',
  '--reverse-text-color': '#FFFFFF',
  '--secondary-text-color': '#A5A5A5',
  '--icon-color': 'rgba(0, 0, 0, 0.85)',
  '--divider-color': '#D9D9D9',
  '--main-background-color': '#FFFFFF',
  '--secondary-background-color': '#F4F4F4',
  '--first-complementary-color': '#D9D9D9',
  '--second-complementary-color': '#1890FF',
  '--third-complementary-color': '#EFBC09',
  '--fourth-complementary-color': '#52C41A',
  '--fifth-complementary-color': '#FF4D4F',
  '--sixth-complementary-color': '#A63CFF',
  '--seventh-complementary-color': '#AF753C',
  '--eighth-complementary-color': '#FD47CF',
  '--default-complementary-color': '#969FA2',
  '--gradient-colors': ['#FFB596', '#FFD15C', '#E3FF93', '#65C280'],
}
