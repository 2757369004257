export const video = {
  record: {
    title: 'Записать',
    press: 'НАЖМИТЕ НА КНОПКУ ДЛЯ НАЧАЛА',
    rec: 'ЗАПИСИ',
    when_ready: 'ВИДЕО',
    another_video: 'Перезаписать',
    upload: 'Загрузить видео',
    blockedUpload: 'В этом вопросе доступна только онлайн-запись видео-ответа',
    uploadValidFormats: 'Допустимый формат загружаемых видео - mov, mp4',
    record_video: 'Записать',
    cameraOn: 'Включить камеру',
    loadButton: 'Загрузить видео',
    cancelButton: 'Отмена',
    sendButton: 'Отправить видео',
    loading: 'Загрузка...',
    errorView: 'Не удалось записать видео, попробуйте повторить',
    unsupportedView: 'Этот браузер не поддерживает запись видео',
  },
  videoUrl: {
    urlButton: 'Добавить ссылку',
    urlTitle: 'Ссылка на видео',
    urlPlaceholder: 'Укажите ссылку на видео',
    okBtn: 'Добавить',
    cancelBtn: 'Отмена',
  },
  view: {
    title: 'Просмотреть',
    resetVideoButton: 'Создать новую запись',
    resetVideoModal: {
      title: 'Удалить текущую запись?',
      content: 'Чтобы создать новую запись, требуется удалить текущую',
      okText: 'Подтвердить',
      cancelText: 'Отмена',
    },
    videoUndefined: 'Видео недоступно!',
  },
}
