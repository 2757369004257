export const login = {
  form: {
    login: 'Login (email)',
    password: 'Password',
  },
  title: 'Welcome!',
  loginButton: 'Log in',
  checkbox: 'Allow multiple tabs',
  restoreLink: 'Forgot your password?',
  signupLink: 'Create account',
}
