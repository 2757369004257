import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import {
  IStatItemsByNodesResponse,
  IStatItemsByTasksResponse,
  IStatItemsByUsersResponse,
  IStatItemsDiagramRequest,
  IStatItemsDiagramResponse,
  IStatItemsProgressRequest,
  IStatItemsTableRequest,
  IStatItemsTableResponse,
  IStatUserItemResetResponse,
  IStatItemsTableExportRequest,
} from '../../features/stats/stat-items/IStatItems'
import { EAPIEndpoint } from '../constants'

export default class StatService {
  static getStatUsersProgress(
    data: I.IStatUsersRequest
  ): Observable<IAPIResult<I.IProgressPoint>> {
    const baseApiService = new BaseApiService<I.IProgressPoint>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_PROGRESS}${getParams({ ...data })}`
    )
  }

  static getStatUsersByTasks(
    data: I.IStatUsersRequest
  ): Observable<IAPIResult<I.IStatUsersByTasksResponse>> {
    const baseApiService = new BaseApiService<I.IStatUsersByTasksResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_TASKS}${getParams({ ...data })}`
    )
  }

  static getStatUsersDynamic(
    data: I.IStatUsersDynamicRequest
  ): Observable<IAPIResult<I.IStatUsersDynamicResponse>> {
    const baseApiService = new BaseApiService<I.IStatUsersDynamicResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_DYNAMIC}${getParams({ ...data })}`
    )
  }

  static getStatUsersStatusProgress(
    data: I.IStatUsersRequest
  ): Observable<IAPIResult<I.IStatusProgress>> {
    const baseApiService = new BaseApiService<I.IStatusProgress>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_STATUSES}${getParams({ ...data })}`
    )
  }

  static getStatUsersTable(
    data: Record<string | number, string | number | number[] | undefined>
  ): Observable<IAPIResult<I.IStatUsersTableResponse>> {
    const baseApiService = new BaseApiService<I.IStatUsersTableResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_TABLE}${getParams(data)}`
    )
  }

  static postStatUsersTableExport(
    data: I.IStatUsersTableExportRequest,
    fileName: string
  ): Observable<IAPIResult<Blob>> {
    const baseApiService = new BaseApiService<Blob>()
    return baseApiService.postExportFileRequest(
      EAPIEndpoint.STAT_USERS_TABLE_EXPORT,
      data,
      fileName
    )
  }

  static getStatUserItemTasks(
    data: I.IStatUserItemRequest
  ): Observable<IAPIResult<I.IStatUserItemResponse>> {
    const baseApiService = new BaseApiService<I.IStatUserItemResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_TABLE}/${data.userId}/${
        data.trackId
      }${getParams({
        ...data,
      })}`
    )
  }

  static getStatItemsProgress(
    data: IStatItemsProgressRequest
  ): Observable<IAPIResult<I.IProgressPoint>> {
    const baseApiService = new BaseApiService<I.IProgressPoint>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_PROGRESS}${getParams({ ...data })}`
    )
  }

  static getStatItemsDiagram(
    data: IStatItemsDiagramRequest
  ): Observable<IAPIResult<IStatItemsDiagramResponse>> {
    const baseApiService = new BaseApiService<IStatItemsDiagramResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_DYNAMIC}${getParams({ ...data })}`
    )
  }

  static getStatItemsByUsers(
    data: IStatItemsProgressRequest
  ): Observable<IAPIResult<IStatItemsByUsersResponse>> {
    const baseApiService = new BaseApiService<IStatItemsByUsersResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_BY_USERS}${getParams({ ...data })}`
    )
  }

  static getStatItemsByTasks(
    data: IStatItemsProgressRequest
  ): Observable<IAPIResult<IStatItemsByTasksResponse>> {
    const baseApiService = new BaseApiService<IStatItemsByTasksResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_BY_TASKS}${getParams({ ...data })}`
    )
  }

  static getStatItemsByNodes(
    data: IStatItemsProgressRequest
  ): Observable<IAPIResult<IStatItemsByNodesResponse>> {
    const baseApiService = new BaseApiService<IStatItemsByNodesResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_BY_NODES}${getParams({ ...data })}`
    )
  }

  static getStatItemsTable(
    data: IStatItemsTableRequest
  ): Observable<IAPIResult<IStatItemsTableResponse>> {
    const baseApiService = new BaseApiService<IStatItemsTableResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_TABLE}${getParams({ ...data })}`
    )
  }

  static postStatItemsTableExport(
    data: IStatItemsTableExportRequest,
    fileName: string
  ): Observable<IAPIResult<Blob>> {
    const baseApiService = new BaseApiService<Blob>()
    return baseApiService.postExportFileRequest(
      EAPIEndpoint.STAT_ITEMS_TABLE_EXPORT,
      data,
      fileName
    )
  }

  static postResetItems(
    data: I.IStatUserItemResetRequest
  ): Observable<IAPIResult<IStatUserItemResetResponse>> {
    const baseApiService = new BaseApiService<IStatUserItemResetResponse>()
    return baseApiService.postRequest(
      EAPIEndpoint.STAT_NODES_RESET_PASSED_USER,
      undefined,
      data
    )
  }
}
