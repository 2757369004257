export const tracksAvailable = {
  filter: {
    itemSearch: 'Rota bul',
    positionStatus: {
      all: 'Tümü',
      completed: 'Tamamlandı',
      unfulfilled: 'Tamamlanmadı',
    },
  },
  emptyData: {
    noAvailable: 'Kullanılabilir rota yok',
    noData: 'Seçilen filtrelerle eşleşen veri yok',
  },
  total: 'TOPLAM',
  inProgressTooltip: 'Parça :percentage% tamamlandı',
  progressDoneTooltip: 'Parça başarıyla geçildi',
}
