import { createAsyncThunk } from '@reduxjs/toolkit'
import { EAPIResponseStatus } from '../constants'
import { logout } from '../services/auth'
import { IAPIResult } from '../services/IBaseService'
import local from './../localization'

interface IPayload {
  e: Error | IAPIResult<void> | any
  /** Allow redirects to the login page in case of login errors. This flag is used to separate the login page from other pages */
  allowRedirect?: boolean
}

export const processError = createAsyncThunk(
  'error/processing',
  async (
    { e, allowRedirect = true }: IPayload,
    { rejectWithValue, dispatch }
  ) => {
    /* If network error */
    console.error(e)

    if (e.constructor.name === 'TypeError') {
      return rejectWithValue(local.notification.general.ERROR.connectionError)
    } else {
      let err

      if (e instanceof Response) {
        err = await e.json()
      } else {
        err = e
      }

      if (err && err.status !== EAPIResponseStatus.SUCCESS) {
        let errorMessage =
          err.message || local.notification.general.ERROR.unknownError

        if (err.code) {
          switch (err.code) {
            case 401: {
              if (allowRedirect) {
                errorMessage = '' //local.notification.general.ERROR.sessionExpired
                dispatch(logout)
              } else {
                errorMessage =
                  err.message === local.notification.general.ERROR.message
                    ? local.notification.general.ERROR.userLocked
                    : local.notification.general.ERROR.wrongLoginPassword
              }

              break
            }
            case 551: {
              if (allowRedirect) {
                errorMessage = ''
              }

              break
            }
            case 403: {
              errorMessage = local.notification.general.ERROR.notAuthorities
              break
            }
            case 400: {
              if (!allowRedirect) {
                errorMessage = local.notification.general.ERROR.userUndefined
              } else {
                errorMessage = ''
              }

              break
            }
          }
        }

        return rejectWithValue(errorMessage)
      }
      return err
    }
  }
)
