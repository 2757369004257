export const tracksAvailable = {
  filter: {
    itemSearch: 'Поиск трека',
    positionStatus: {
      all: 'Все',
      completed: 'Выполненные',
      unfulfilled: 'Невыполненные',
    },
  },
  emptyData: {
    noAvailable: 'Нет треков, которые вам доступны для прохождения',
    noData: 'Нет данных, соответствующих выбранным фильтрам',
  },
  total: 'ВСЕГО',
  inProgressTooltip: 'Трек выполнен на :percentage%',
  progressDoneTooltip: 'Трек успешно пройден',
}
