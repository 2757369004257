export const taskPreview = {
  backToEdit: 'Düzenlemeye geri dön',
  question: {
    titles: {
      task: 'Görev №',
    },
  },
  answer: {
    video: 'Videolu yanıt',
    text: {
      placeholder: 'Cevabı girin',
      loadFileButton: 'Dosya yükle',
    },
  },
}
