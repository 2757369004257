import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SorterResult } from 'antd/lib/table/interface'
import _ from 'lodash'
import * as I from 'Types'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import { TArrayColumnType } from '../../components/ViewTable/IViewTable'
import { IKeyValuePair } from '../../constants'
import TasksService from '../../services/tasks/tasks.service'

const initialState: I.ITasksState = {
  tasks: [],
  track: undefined,
  node: undefined,
  users: [],
  testsOnly: false,
  isVerified: false,
  showAll: false,
  page: 1,
  pageSize: 20,
  totalElements: 0,
  sortedInfo: undefined,
  rangeDate: undefined,
}

export const getTasks = createAsyncThunk(
  'tasks',
  async (data: I.IGetTasksRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(TasksService.getTasks(data))

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearTasksState: (state: I.ITasksState) => {
      _.assign(state, {
        tasks: initialState.tasks,
        totalElements: initialState.totalElements,
      })
    },
    setIsVerified: (
      state: I.ITasksState,
      prop: PayloadAction<boolean | undefined>
    ) => {
      state.isVerified = prop.payload
    },
    setTestsOnly: (
      state: I.ITasksState,
      prop: PayloadAction<boolean | undefined>
    ) => {
      state.testsOnly = prop.payload
    },
    setShowAll: (
      state: I.ITasksState,
      prop: PayloadAction<boolean | undefined>
    ) => {
      state.showAll = prop.payload
    },
    setUsersFilter: (
      state: I.ITasksState,
      prop: PayloadAction<IKeyValuePair[]>
    ) => {
      if (Array.isArray(prop.payload)) {
        state.users = prop.payload
      }
    },
    setTrackFilter: (
      state: I.ITasksState,
      prop: PayloadAction<IKeyValuePair | undefined>
    ) => {
      state.track = prop.payload
    },
    setTaskFilter: (
      state: I.ITasksState,
      prop: PayloadAction<IKeyValuePair | undefined>
    ) => {
      state.node = prop.payload
    },
    setPage: (state: I.ITasksState, prop: PayloadAction<number>) => {
      state.page = prop.payload
    },
    setSize: (state: I.ITasksState, prop: PayloadAction<number>) => {
      state.pageSize = prop.payload
    },
    setSortedInfo: (
      state: I.ITasksState,
      prop: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      state.sortedInfo = prop.payload
    },
    setRangeDate: (
      state: I.ITasksState,
      prop: PayloadAction<string | undefined>
    ) => {
      state.rangeDate = prop.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      if (action.payload?.data) {
        _.assign(state, {
          tasks: action.payload.data.content,
          totalElements: action.payload.data.totalElements,
        })
      }
    })
  },
})

export const selectTasks = (state: I.RootState) => state.tasks.list

export const { reducer: tasksReducer } = tasksSlice

export const {
  clearTasksState,
  setIsVerified,
  setTestsOnly,
  setUsersFilter,
  setTrackFilter,
  setTaskFilter,
  setShowAll,
  setPage,
  setSize,
  setSortedInfo,
  setRangeDate,
} = tasksSlice.actions
