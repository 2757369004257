import local from './../../localization'
import { Area } from 'react-easy-crop/types'
import { enqueueSnackbar } from '../../features/notification/notificationSlice'
import { ENotificationVariant } from '../../constants'
import { store } from '../../app/store'

const format =
  window.REACT_APP_IMAGE_FORMAT || process.env.REACT_APP_IMAGE_FORMAT

let validFormat = ''
let acceptFormat = ''

format?.split(',').forEach((f, i) => {
  const pair = f.split(':') as [string, string]
  validFormat += `${i === 0 ? '' : ' '}${pair[1]}`
  acceptFormat += `${i === 0 ? '' : ','}${pair[0]}`
})

export const validationImage = (file: File, maxSize: string) => {
  const inFormat = !!file.type && acceptFormat.includes(file.type.toLowerCase())
  const inSize = file.size / 1024 / 1024 < Number(maxSize)

  if (!inFormat && !inSize) {
    store.dispatch(
      enqueueSnackbar({
        message: local.notification.validation.ERROR.maxSizeImg.replace(
          ':MaxSize',
          `${Number(maxSize)}`
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: ENotificationVariant.ERROR,
        },
      })
    )
    store.dispatch(
      enqueueSnackbar({
        message: local.notification.validation.ERROR.format.replace(
          ':ValidFormat',
          `${validFormat}`
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: ENotificationVariant.ERROR,
        },
      })
    )
  } else if (!inSize) {
    store.dispatch(
      enqueueSnackbar({
        message: local.notification.validation.ERROR.maxSizeImg.replace(
          ':MaxSize',
          `${Number(maxSize)}`
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: ENotificationVariant.ERROR,
        },
      })
    )
  } else if (!inFormat) {
    store.dispatch(
      enqueueSnackbar({
        message: local.notification.validation.ERROR.format.replace(
          ':ValidFormat',
          `${validFormat}`
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: ENotificationVariant.ERROR,
        },
      })
    )
  }

  return inSize && inFormat
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = (await createImage(imageSrc)) as HTMLImageElement
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  // set canvas size to match the bounding box
  canvas.width = image.width
  canvas.height = image.height

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2)
  ctx.translate(-image.width / 2, -image.height / 2)
  // draw image
  ctx.drawImage(image, 0, 0)
  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As a blob
  return new Promise((resolve, _reject) => {
    canvas.toBlob(file => {
      resolve(file)
    }, 'image/jpeg')
  })
}

export const readUrlFile = (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}
