export const statUsers = {
  filters: {
    filterUsersInputLabel: 'User',
    filterItemsInputLabel: 'Track',
    searchUsers: 'For all users',
    searchItems: 'For all tracks',
    mapTooltip: 'The field will become active when one track is selected',
  },
  statTab: {
    status: {
      available: 'AVAILABLE',
      checking: 'UNDER REVIEW',
      completed: 'COMPLETED',
      rejected: 'REJECTED',
      unavailable: 'UNAVAILABLE',
    },
    areaConfig: {
      yAxis: 'Completed, %',
    },
    progressTitle: 'Overall progress',
    progressLegend: {
      done: 'Completed',
      undone: 'Not completed',
    },
    progressTooltip:
      'The circular diagram is divided into two sectors: completed modules and not competed modules. The size of the sector corresponding to completed modules represents the percentage of completed work by User(-s). The size of the sector corresponding to incomplete modules represents the percentage of undone work. The circular diagram helps to quickly assess the progress of the User(-s) or and identify areas that need improvement.',
    byUsersTitle: 'By users',
    byUsers: {
      total: 'Total modules',
      averageCompleted: 'Modules completed',
      averageLeft: 'Modules remaining to complete',
      everyoneCompleted: 'Completed by all',
      noneCompleted: 'Not completed by all',
      checkingCount: 'Waiting for checking',
      blockedProgress: 'Further modules are blocked',
      average: '(average)',
      all: '(total)',
      tooltip: {
        total:
          'The total number of modules contained in the tracks assigned to the user(s)',
        averageCompleted:
          'The average number of completed modules by the user(s)',
        averageLeft:
          'The average number of modules that the user(s) has not completed yet',
        everyoneCompleted:
          'The total number of modules that have been successfully completed by all users who have access to those modules',
        noneCompleted:
          'The total number of modules where none of the users assigned to tracks with these modules have not passed the related tasks',
        checkingCount:
          'The total number of modules with tasks under mentor review',
        blockedProgress:
          'Further steps are blocked',
      },
    },
    areaTitle: 'Progress Dynamics',
    areaTooltip:
      '"Progress dynamics" is a graph reflecting the progress dynamics of completing tasks in tracks. The graph shows the correlation between the time interval from the date of creating the track to the current date (horizontally) and the percentage of completed tasks (vertically). Additionally, a selectable time interval is available from the dropdown list.',
    pieTitle: 'Status ratio',
    pieTooltip:
      '"Status ratio" is a pie chart that displays the percentage breakdown of all modules in different statuses.',
  },
  tableTab: {
    tableTitle: 'Users statistics',
    columns: {
      fullName: 'User',
      email: 'Email',
      position: 'Position',
      lastAuthTime: 'Last login date',
      track: 'Track',
      progress: { title: 'Progress', noTask: 'No tasks' },
      action: {
        title: 'Action',
        actionButton: 'Modules list',
        resetButton: 'Reset progress',
      },
    },
    emptyData: {
      noData: 'No progress statistics found',
      noFilterStat: 'No data matching the selected filters',
    },
    exportFileName: 'Users statistics',
  },
  statUserByTask: {
    filters: {
      label: 'Module',
      searchTask: 'Select module',
      selectStatus: 'Status',
    },
    reset: {
      resetButton: 'Reset progress',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm reset',
    },
    tabs: {
      stat: 'Statistics',
      table: 'Spreadsheet',
      map: 'Progress map',
    },
    columns: {
      node: 'Module title',
      attemptsCount: {
        title: 'Number of attempts',
        manyAttempts: 'attempts',
        singleAttempt: 'attempt',
        attempts: 'attempts',
      },
      mentor: 'Last reviewed',
      timeCompleted: 'Last completion ',
      status: {
        title: 'Status',
        completed: 'Completed',
        rejected: 'Rejected',
        checking: 'Under review',
        default: 'Not completed',
      },
      action: {
        title: 'Action',
        actionButton: 'Check',
      },
    },
    emptyData: {
      noData: 'No progress statistics found',
      noFilterStats: 'No data matching the selected filters',
    },
  },
}
