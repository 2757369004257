import Styles from './EISButton.module.scss'
import { IPassResult } from '../../../features/task-passing-result/components/ITaskPassResult'

type TStatusButton =
  | 'default'
  | 'danger'
  | 'info'
  | 'success'
  | 'ghost'
  | 'ghost_dark'
  | 'white'

/** Choose a color type for button:
 *
 * default - Gray
 *
 * danger - Red
 *
 * info - Yellow
 *
 * success - Green
 *
 * ghost - Green ghost
 *
 * ghost_dark - Dark gray ghost
 *
 * white - White button color (only for variant='contained')
 *  */
export const setColorTypeButton = (status?: TStatusButton) => {
  switch (status) {
    case 'danger':
      return Styles.danger
    case 'info':
      return Styles.info
    case 'success':
      return Styles.success
    case 'ghost':
      return Styles.ghost
    case 'ghost_dark':
      return Styles.ghost_dark
    case 'white':
      return Styles.white
    default:
      return Styles.default
  }
}

export const setResultTrack = (result?: IPassResult) => {
  switch (result?.status) {
    case 'success':
      return Styles.success
    case 'error':
      return Styles.danger
    case 'timeout':
      return Styles.info
    default:
      return Styles.default
  }
}
