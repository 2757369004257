import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import { Affix, Layout, Spin } from 'antd'
import { getLoading } from '../features/loader/loaderSlice'
import { useAuth, authFetch } from '../services/auth'
import { EPages } from '../constants'
import { Sidebar } from './Sidebar/Sidebar'
import { Header } from './Header/Header'
import { Content } from './Content/Content'
import { Router } from './Content/Router/Router'
import { Error404 } from './404/Error404'
import Maintenance from './Maintenance/Maintenance'
import Styles from './Main.module.scss'
import { useTheme } from '../theme/Theme'
import { isMobile } from '../utils'
import { EAPIEndpoint } from '../services/constants'
import local from './../localization'
import { addMetrik } from '../app/metrik'
import { ETables } from '../components/ViewTable/IViewTable'
import { generateFingerprint } from '../utils'

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
)

const url = window.REACT_APP_API_URL
const version = window.REACT_APP_VERSION
const maintenanceTimeout =
  Number(window.REACT_APP_MAINTENANCE_TIMEOUT) * 1000 || 60000

export const Main: React.FC = (): React.ReactElement => {
  const history = useHistory()
  const [isLoggedIn] = useAuth()
  const loading = useSelector(getLoading)
  const { themeProps } = useTheme()

  //#region Reloading for a new version of the application
  const appVersion = localStorage.getItem('version')

  if (!!version && (!appVersion || appVersion !== version)) {
    localStorage.setItem(ETables.USERS, '')
    localStorage.setItem('version', version)
    window.location.reload()
  }
  //#endregion

  //#region google translate
  const translate = document.querySelector('meta[name="google"]')
  const isTranslate = !!Number(window.REACT_APP_GOOGLE_TRANSLATE)
  if (translate && isTranslate) {
    translate.setAttribute('content', 'translate')
  }
  //#endregion

  ;(!!window.REACT_APP_METRIC_KEY || !!window.REACT_APP_GOOGLE_KEY) &&
    addMetrik()

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const subscribe = async () => {
      try {
        const fingerprint = await generateFingerprint()
        const request = await authFetch(
          `${url}${EAPIEndpoint.SYSTEM_MAINTENANCE}?fingerprint=${fingerprint}`,
          {
            method: 'GET',
            cache: 'no-cache',
            signal: controller.signal,
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Accept-Language': local.main.acceptLanguage,
            },
          }
        )

        const response = await request.json()

        if (
          response.status === 'SUCCESS' &&
          document.location.pathname === EPages.MAINTENANCE
        ) {
          if (document.location.search) {
            history.push(document.location.search.replace('?returnUrl=', ''))
          } else {
            history.push(EPages.PROFILE)
          }
        }
      } catch (e) {
        if (
          !signal.aborted &&
          document.location.pathname !== EPages.MAINTENANCE
        ) {
          history.push(
            `${EPages.MAINTENANCE}?returnUrl=${document.location.pathname}`
          )
        }
      }

      await new Promise(resolve => setTimeout(resolve, maintenanceTimeout))
      await subscribe()
    }

    subscribe()

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Switch>
        <Route exact path={EPages.NOT_FOUND} render={() => <Error404 />} />
        <Route exact path={EPages.MAINTENANCE} render={() => <Maintenance />} />
        <Route
          render={() => (
            <div style={{ ...(themeProps as React.CSSProperties) }}>
              {isLoggedIn ? (
                <div className={Styles.container}>
                  <Layout className={Styles.layout}>
                    {!isMobile() && <Sidebar />}
                    <Layout>
                      <Affix className={Styles.headerZIndex} offsetTop={0}>
                        <Header />
                      </Affix>
                      <Content loading={loading} />
                    </Layout>
                  </Layout>
                </div>
              ) : (
                <Spin spinning={loading}>
                  <Router isLoggedIn={isLoggedIn} />
                </Spin>
              )}
            </div>
          )}
        />
      </Switch>
    </>
  )
}
