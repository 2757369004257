import style from './EISColorCard.module.scss'

export enum EColorCard {
    DEFAULT = 'default',
    DEFAULT_DARK = 'default_dark',
    COMPLETED = 'completed',
    COMPLETED_DARK = 'completed_dark',
    AVAILABLE = 'available',
    WAITING_DARK = 'waiting_dark',
    WAITING = 'waiting',
    REJECT_DARK = 'reject_dark',
    REJECT = 'reject',
}

export const getStyle = (status: EColorCard, value?: number) => {
    if (value === 0) {
        return style.disable;
    } 
    switch (status) {
        case EColorCard.COMPLETED:
            return style.completed;
        
        case EColorCard.COMPLETED_DARK:
            return style.completed_dark;

        case EColorCard.AVAILABLE:
            return style.available;

        case EColorCard.WAITING:
            return style.waiting;
        
        case EColorCard.DEFAULT_DARK:
            return style.default_dark; 

        case EColorCard.REJECT_DARK:
            return style.reject_dark;    
        
        case EColorCard.REJECT:
            return style.reject;  

        case EColorCard.WAITING_DARK:
            return style.waiting_dark;  

        default:
            return style.default;
    }
}