import { IThemeProps } from '..'

export const dark: IThemeProps = {
  '--font-family': `'Roboto', sans-serif`,
  '--mini-logo': '',
  '--main-logo': '',
  '--default-card-image': '',
  '--background-image': ``,
  '--auth-background-image': '',
  '--main-theme-color': '#96919B',
  '--light-main-theme-color': '#555555',
  '--main-text-color': '#C3C2C0',
  '--reverse-text-color': '#FFFFFF',
  '--secondary-text-color': '#C3C2C0',
  '--icon-color': '#C3C2C073',
  '--divider-color': '#414343',
  '--main-background-color': '#333036',
  '--secondary-background-color': '#000000',
  '--first-complementary-color': '#414343',
  '--second-complementary-color': '#45A1F7',
  '--third-complementary-color': '#CDAF46',
  '--fourth-complementary-color': '#7EC35C',
  '--fifth-complementary-color': '#D26061',
  '--sixth-complementary-color': '',
  '--seventh-complementary-color': '',
  '--eighth-complementary-color': '',
  '--default-complementary-color': '#C3C2C073',
  '--gradient-colors': ['#FFB596', '#FFD15C', '#E3FF93', '#65C280'],
}

const currentTheme = window.REACT_APP_DEFAULT_THEME

if (currentTheme) {
  import(`../../assets/svg/${currentTheme}.svg`).then(logo => {
    dark['--main-logo'] = `url('${logo.default}')`
  })

  import(`../../assets/svg/${currentTheme}_mini.svg`).then(miniLogo => {
    dark['--mini-logo'] = `url('${miniLogo.default}')`
  })

  import(`../../assets/svg/${currentTheme}_img.svg`).then(cardImg => {
    dark['--default-card-image'] = cardImg.default
  })
}
