import React from 'react'
import { Layout, Skeleton, Spin } from 'antd'
import Styles from './Content.module.scss'
import { useAuth } from '../../services/auth'
import Router from './Router/Router'

export const Content: React.FC<{ loading: boolean }> = ({
  loading,
}): React.ReactElement => {
  const [isLoggedIn] = useAuth()

  return (
    <Layout.Content className={Styles.container}>
      <Skeleton loading={false}>
        <Spin spinning={loading}>
          <div className={Styles.content}>
            <Router isLoggedIn={isLoggedIn} />
          </div>
        </Spin>
      </Skeleton>
    </Layout.Content>
  )
}
