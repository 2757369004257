import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import NodesService from '../../services/nodes/nodes.service'
import TracksService from '../../services/tracks/tracks.service'
import UsersService from '../../services/users/users.service'

const initialState: I.IBreadcrumbState = {}

export const getTaskTitle = createAsyncThunk(
  'breadcrumb/getTaskTitle',
  async (taskId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await stackRequestWrapper(
        NodesService.getNodeTitle(taskId)
      )

      dispatch(breadcrumbSlice.actions.setTaskName(response.data!!))

      return response
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('')
    }
  }
)

export const getTrackTitle = createAsyncThunk(
  'breadcrumb/getTrackTitle',
  async (trackId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await stackRequestWrapper(
        TracksService.getTrackTitle(trackId)
      )

      dispatch(breadcrumbSlice.actions.setTrackName(response.data!!))

      return response
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('')
    }
  }
)

export const getUserName = createAsyncThunk(
  'breadcrumb/getUserName',
  async (userId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await stackRequestWrapper(
        UsersService.getUserName(userId)
      )

      dispatch(breadcrumbSlice.actions.setUserName(response.data?.fullName!))

      return response
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('')
    }
  }
)

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setTaskName: (state, { payload }: PayloadAction<string>) => {
      state.taskName = payload
    },
    setTrackName: (state, { payload }: PayloadAction<string>) => {
      state.trackName = payload
    },
    setUserName: (state, { payload }: PayloadAction<string>) => {
      state.userName = payload
    },
    resetBreadcrumbStore: state => {
      state.taskName = undefined
      state.trackName = undefined
      state.userName = undefined
    },
  },
})

export const selectTaskName = (state: I.RootState) => state.breadcrumb.taskName
export const selectTrackName = (state: I.RootState) =>
  state.breadcrumb.trackName
export const selectUserName = (state: I.RootState) => state.breadcrumb.userName

export const { resetBreadcrumbStore, setTaskName } = breadcrumbSlice.actions

export const { reducer: breadcrumbReducer } = breadcrumbSlice
