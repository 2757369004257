/**Scaling points of the module */
export enum ESizeDirections {
  NW = 'nw',
  NE = 'ne',
  SE = 'se',
  SW = 'sw',
}

/**HTML elements to get links */
export const HTML_IDS = {
  page: 'page',
  downloadLink: 'downloadLink',
  uploadInput: 'uploadInput',
}
