import ru from './ru'
import en from './en'
import tr from './tr'
import enUS from './en-us'

/** Default (for the whole stand) language set in the global variable */
export const defaultLanguage = window.REACT_APP_DEFAULT_LANGUAGE
const withLanguageSwitcher = !!Number(window.REACT_APP_WITH_LANGUAGE_SWITCHER)
const availableLanguages = window.REACT_APP_AVAILABLE_LANGUAGES.split(
  ','
).map(lang => ({ label: lang.toUpperCase(), value: lang }))

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', defaultLanguage)
}
if (
  !withLanguageSwitcher &&
  localStorage.getItem('language') !== defaultLanguage
) {
  localStorage.setItem('language', defaultLanguage)
}

export const changeLanguage = (val: string) => {
  localStorage.setItem('language', val)
  window.location.reload()
}

export const languagesList: {
  label: string
  value: string
}[] = !!window.REACT_APP_AVAILABLE_LANGUAGES
  ? availableLanguages
  : [
      { value: 'en-us', label: 'EN-US' },
      { value: 'en', label: 'EN' },
      { value: 'ru', label: 'RU' },
      { value: 'tr', label: 'TR' },
    ]

const lang = () => {
  switch (localStorage.getItem('language')) {
    case 'en-us':
      return enUS
    case 'en':
      return en
    case 'tr':
      return tr
    default:
      return ru
  }
}

export const getCountry = (): string => {
  const lang = localStorage.getItem('language')
  switch (lang) {
    case 'ru':
      return 'ru'
    case 'en':
      return 'gb'
    case 'en-us':
      return 'us'
    case 'tr':
      return 'tr'
    default:
      return 'ru'
  }
}

export default lang()
