export const statItems = {
  filters: {
    label: 'Map',
    searchItems: 'All maps',
    mapTooltip: 'The field will become active when one map is selected',
  },
  statTab: {
    areaConfig: {
      yAxis: 'Done, %',
    },
    progressTitle: 'Overall Progress',
    diagramTitle: 'Progress Dynamics',
    byUsersTitle: 'By Users',
    byUsers: {
      haveItems: 'Have Access',
      completed: 'Completed All',
      inProgress: 'In Progress',
      withoutItems: {
        title: 'No Maps Have Been Assigned',
        value: ':Count',
      },
    },
    byNodesTitle: 'By Steps',
    byNodes: {
      total: 'Total Number Of Steps',
      withTopics: 'Steps With Description',
      withTasks: 'Steps With A Task',
      withoutTasks: 'Steps Without Tasks',
    },
    byTasksTitle: 'By Tasks',
    byTasks: {
      onChecking: 'Steps Under Review',
      averageChecking: 'Average Review Time',
      days: 'd',
      hours: 'h',
      minutes: 'm',
    },
  },
  tableTab: {
    tableTitle: 'Steps Statistics',
    columns: {
      title: 'Step Title',
      questionsCount: 'Number Of Questions',
      completedPercentage: '% Completed',
      averageAttempts: 'Average Number Of Attempts',
      secondsEstimated: 'Estimated Time To Completion',
      averageSecondsActual: 'Average Time To Completion',
      progress: 'Average Progress',
      hours: 'h',
      minutes: 'm',
    },
    exportFileName: 'Steps Statistics',
  },
}
