import process_en from '../../assets/png/help_process_en.jpg'
import management_en from '../../assets/png/help_management_en.jpg'
import status_en from '../../assets/png/help_status_en.jpg'

export const help = {
  welcome: 'Welcome to :System!',
  purpose:
    'The main idea of :System is to integrate employees into work processes, positions, teach them new things, accumulate and store useful knowledge in the company, while an important feature is that they do it themselves.',
  canDo: {
    title: 'What users can do:',
    features: [
      'create maps',
      'choose between two modes of training: "with" or "without" a mentor',
      'receive feedback on the completed tasks',
      'use statistics to analyze maps, progress, performance of employees',
      'receive information about the best practices and experience of colleagues',
      'if you want to change the direction of your career  - immerse yourself in another position, there is a variety of maps',
      'support and updating of knowledge (if something has changed in the company)',
      'make sure that the employee is 100% ready for the new role and confirmed their knowledge by completing all the tasks of the map',
      'manage user access rights',
    ],
    solution:
      'It is possible to solve a variety of company cases by combining different functions and organizational rules.',
  },
  problem: {
    title: 'How does :System reach the goal?',
    solution:
      'We assign a manager to the map of the company (in fact, the map is the area of knowledge, function, work process, position, etc.) and continue according to the scheme:',
  },
  fastNote: 'Quick briefing on creating maps for the manager',
  passing: 'How to pass the map assigned to me?',
  wishes: {
    title: 'Have you noticed a mistake, or do you have any suggestions? ',
    btn: 'Let us',
    titleEnd: ' know!',
  },
  imgSrc: {
    process: process_en,
    management: management_en,
    status: status_en,
  },
  imgSVG: {
    problem: {
      manager: 'Manager',
      createTrack: 'Create a Map',
      track: 'Map',
      trackWasAssigned: 'The Map was assigned to Employee',
      employee: 'Employee',
      runsTheTrack: 'Runs the Map’s Task',
      mentor: 'Mentor',
      checkCompletedTask: 'Check the Completed Task',
      readyToWork: '100% Ready to Work',
    },
    fastNote: {
      controlPanel: 'Control panel',
      hotkeys: {
        title: 'Hotkeys:',
        altQ: 'Alt + Q :split Add a New Step',
        altW: 'Alt + W :split Adding a New Connection',
        altE: 'Alt + E :split Edit the Title',
        altS: 'Alt + S :split Saving the Changes',
        altC: 'Alt + C :split Copy the Step',
      },
      textList: {
        changeModule: 'Change the Step Title',
        addModule: 'Add a New Step',
        createConnection: 'Create a connection from one Step to another',
        deleteModule: 'Delete the Step',
        saveChanges: 'Save the Changes',
        doubleClickModule:
          'Double-click on the Step or this button will take you to the description of the selected Step',
        copyModule: 'Copy the Step',
        indicatorsWillShow:
          'Indicators will show you if you have added the step description  and what types of checks you have set: test, detailed answers, video answers',
      },
    },
    passing: {
      completeTask: {
        title: 'To Complete Task:',
        read: 'Read and Learn the Step Description',
        confirm: 'Confirm that You Have Completed the Study',
        complete: 'Complete Tasks',
        send: 'Send Them to Be Checked',
      },
      start: 'Start',
      task: 'Task',
      moduleCompleted:
        'The Step is completed. Knowledge is gained, and you can always open the Step in case you need to brush up your memory',
      moduleUnderReview:
        'You have completed the Task 2, but the Mentor has not yet checked it',
      youCantStart:
        'You cannot review this Step because the previous Step is not marked as already checked and approved ',
      moduleAvailable:
        'The Step is available, you have started the Task 3, but did not send it for checking',
      moduleTasksHaveBeenReviewed:
        'The Task 4 was checked by the Mentor: rejected and needs to be rerun',
    },
  },
  feedBackModal: {
    titleModal: 'Feedback',
    subjectMessage: 'Subject of letter',
    textAreaPlaceholder: 'Text of the letter',
    messageType: {
      bug: 'Error',
      question: 'Question',
      proposal: 'Proposal',
      other: 'Other',
    },
    uploadTitle: 'Attach photo or video',
    footNote:
      'If you want to attach a video and/or a photo, add a link to the text',
    actionBtnSend: 'Send',
    actionBtnCancle: 'Cancel',
    thanksMessage: 'Thank you for the feedback',
  },
}
