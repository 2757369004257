import React, {
  useState,
  useCallback,
  useRef,
  FC,
  useEffect,
  useMemo,
} from 'react'
import { Image, Spin, Space, Tooltip } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import * as I from 'Types'
import { useAppDispatch } from '../../app/store'
import FileService from '../../services/file/file.service'
import { processError } from '../../app/processError'
import styles from './ImageHolder.module.scss'
import { stackRequestWrapper } from '../../app/requestWrapper'
import local from './../../localization'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import { EISModal } from '../EISComponents'
import { IconButton, Slider } from '@mui/material'
import { getCroppedImg, readUrlFile, validationImage } from './utils'
import spriteIcons from '../../assets/svg/sprite-icons.svg'
import AddIcon from '@mui/icons-material/Add'
import Selfie from '../Selfie/Selfie'
import { isZeroFileHelper } from '../EISComponents/helper'

const maxSize =
  window.REACT_APP_IMAGE_MAX_SIZE || process.env.REACT_APP_IMAGE_MAX_SIZE

const ImageHolder: FC<I.IImageProps> = ({
  src,
  uploadImage,
  width = 265,
  height = 150,
  defaultImage,
  isAvatar = false,
  isAlianAvatar = false,
  withSelfie = false,
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const initialState: I.IImageHolderLocalState = {
    isLoading: false,
    isModalVisible: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    imageFile: undefined,
    imageSrc: src,
    croppedAreaPixels: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
  }

  const sliderValue = useMemo(() => {
    return {
      min: 1,
      max: 3,
      step: 0.1,
    }
  }, [])

  const [state, setState] = useState<I.IImageHolderLocalState>(initialState)
  const uploadFile = useRef<HTMLInputElement>(null)

  const [isSelfieMode, setIsSelfieMode] = useState(false)
  const [isTooltipChange, setIsTooltipChange] = useState(false)

  const handleOpenModal = useCallback(
    (isSelfie: boolean) => () => {
      if (uploadFile.current && !isSelfie) uploadFile.current.click()
      setIsSelfieMode(isSelfie)
    },
    []
  )

  const onInputClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const element = event.target as HTMLInputElement
      element.value = ''
    },
    []
  )

  const handleClearImg = useCallback(() => {
    uploadImage(isAvatar ? {} : undefined)
  }, [isAvatar, uploadImage])

  const beforeUploadHandler = useCallback(
    (file: RcFile | File) => {
      if (isZeroFileHelper(file)) return false
      const result = validationImage(file, maxSize!)
      if (result) {
        setState({ ...state, isLoading: true })
      }
      return result
    },
    [state]
  )

  const onFileChange = useCallback(
    async (e: any) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0]
        const fileCheck = beforeUploadHandler(file)
        if (fileCheck) {
          const imageDataUrl = (await readUrlFile(file)) as string
          setState({
            ...state,
            imageSrc: imageDataUrl,
            imageFile: file,
            isModalVisible: fileCheck,
          })
        }
      }
    },
    [beforeUploadHandler, state]
  )

  const onSelfieChange = useCallback(
    async (file: File) => {
      const fileCheck = beforeUploadHandler(file)
      if (fileCheck) {
        const imageDataUrl = (await readUrlFile(file)) as string
        setState({
          ...state,
          imageSrc: imageDataUrl,
          imageFile: file,
          isModalVisible: fileCheck,
        })
      }
    },
    [beforeUploadHandler, state]
  )

  const onCropChange = useCallback(
    (crop: Point) => {
      setState({ ...state, crop })
    },
    [state]
  )

  const onZoomChange = useCallback(
    (zoom: number) => {
      if (
        zoom >= sliderValue.max + sliderValue.step ||
        zoom <= sliderValue.min - sliderValue.step
      )
        return
      setState({ ...state, zoom })
    },
    [sliderValue, state]
  )

  const onWheelRequest = useCallback(
    (event: WheelEvent) => {
      event.deltaY >= 0
        ? onZoomChange((state.zoom as number) - sliderValue.step)
        : onZoomChange((state.zoom as number) + sliderValue.step)
      return true
    },
    [onZoomChange, sliderValue, state]
  )

  const pageUpDownKeys = useCallback(
    (key: KeyboardEvent) => {
      if (!key || !state.isModalVisible) return

      if (key.code === 'PageUp') {
        onZoomChange((state.zoom as number) + sliderValue.step)
      } else if (key.code === 'PageDown') {
        onZoomChange((state.zoom as number) - sliderValue.step)
      }
    },
    [onZoomChange, sliderValue, state]
  )

  useEffect(() => {
    document.addEventListener('keydown', pageUpDownKeys)
    return () => {
      document.removeEventListener('keydown', pageUpDownKeys)
    }
  }, [pageUpDownKeys])

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      setState({ ...state, croppedAreaPixels })
    },
    [state]
  )

  const actionForBinary = useCallback(
    (file: Blob) => {
      if (state.imageFile) {
        const img = new File([file], state.imageFile?.name, {
          type: state.imageFile?.type,
        })
        if (isAvatar) {
          uploadImage(img)
          setState({ ...state, isLoading: false, isModalVisible: false })
        } else {
          stackRequestWrapper(FileService.postBinaryFile(img))
            .then(result => {
              uploadImage(result.data)
              setState({ ...state, isLoading: false, isModalVisible: false })
            })
            .catch(e => {
              dispatch(processError({ e }))
              return e
            })
        }
      }
      return ''
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, uploadImage]
  )

  const handleOk = useCallback(async () => {
    try {
      if (state.imageSrc) {
        const croppedImage = (await getCroppedImg(
          state.imageSrc,
          state.croppedAreaPixels
        )) as Blob
        actionForBinary(croppedImage)
      }
    } catch (e) {
      console.error(e)
    }
    setIsSelfieMode(false)
  }, [state, actionForBinary])

  const handleCancel = useCallback(() => {
    setState({ ...state, isModalVisible: false })
  }, [state])

  return (
    <Spin spinning={state.isLoading}>
      <div className={styles.imageHolder}>
        {src || (defaultImage && typeof defaultImage === 'string') ? (
          <Image
            preview={isAlianAvatar}
            width={width}
            height={height}
            src={src || (defaultImage as string)}
            className={isAvatar ? styles.round : ''}
          />
        ) : (
          defaultImage
        )}
        {!isAlianAvatar && (
          <Tooltip
            open={
              isSelfieMode || state.isModalVisible ? false : isTooltipChange
            }
            onOpenChange={e => setIsTooltipChange(e)}
            title={local.notification.validation.ERROR.lessThan.replace(
              ':MaxSize',
              Number(maxSize!).toString()
            )}
          >
            <Space
              className={`${styles.hover} ${isAvatar ? styles.round : ''}`}
              size={'small'}
            >
              {src ? (
                <Tooltip title={local.image.actionBtn.edit}>
                  <svg
                    width={35}
                    height={35}
                    className={styles.img}
                    onClick={handleOpenModal(withSelfie)}
                  >
                    <use xlinkHref={`${spriteIcons}#img-edit`}></use>
                  </svg>
                </Tooltip>
              ) : (
                <Tooltip title={local.image.actionBtn.add}>
                  <PlusOutlined
                    style={{ fontSize: height * 0.4 }}
                    className={styles.img}
                    onClick={handleOpenModal(withSelfie)}
                    translate={undefined}
                  />
                </Tooltip>
              )}
              {isSelfieMode && (
                <Selfie
                  isSelfieMode={isSelfieMode}
                  onSelfieChange={onSelfieChange}
                  handleSelfieMode={setIsSelfieMode}
                  uploadLocalImg={handleOpenModal(false)}
                />
              )}
              {state.isModalVisible && !!state.imageSrc && (
                <EISModal
                  title={local.image.selectArea}
                  open={true}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <>
                    <div className={styles.crop_container}>
                      <Cropper
                        cropShape={isAvatar ? 'round' : 'rect'}
                        image={state.imageSrc}
                        aspect={isAvatar ? 1 : 1.5}
                        crop={state.crop}
                        zoom={state.zoom}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onWheelRequest={onWheelRequest}
                      />
                    </div>
                    <div className={`controls ${styles.controlStyle}`}>
                      <IconButton
                        onClick={() =>
                          onZoomChange(
                            (state.zoom as number) - sliderValue.step
                          )
                        }
                      >
                        <MinusOutlined translate={undefined} />
                      </IconButton>
                      <Tooltip title={state.zoom.toFixed(1)}>
                        <Slider
                          value={state.zoom}
                          min={sliderValue.min}
                          max={sliderValue.max}
                          step={sliderValue.step}
                          aria-labelledby='Zoom'
                          onChange={(_e, zoom) => onZoomChange(zoom as number)}
                          classes={{ root: 'slider' }}
                        />
                      </Tooltip>
                      <IconButton
                        onClick={() =>
                          onZoomChange(
                            (state.zoom as number) + sliderValue.step
                          )
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </>
                </EISModal>
              )}
              {src && (
                <Tooltip title={local.image.actionBtn.delete}>
                  <svg
                    width={35}
                    height={35}
                    className={styles.img}
                    onClick={handleClearImg}
                  >
                    <use xlinkHref={`${spriteIcons}#img-delete`}></use>
                  </svg>
                </Tooltip>
              )}
            </Space>
          </Tooltip>
        )}
        <input
          type='file'
          onChange={onFileChange}
          onClick={onInputClick}
          accept='image/*'
          ref={uploadFile}
          className={styles.uploadFile}
        />
      </div>
    </Spin>
  )
}

export default ImageHolder
