import React, { FC, MutableRefObject, useEffect, useRef } from 'react'
import Styles from '../../CustomSearchSelect.module.scss'

interface ISearchSelectCounter {
  dataLength: number
  inputRef: MutableRefObject<any>
}

export const SearchSelectCounter: FC<ISearchSelectCounter> = ({
  dataLength,
  inputRef,
}) => {
  const inputCounterRef = useRef<any>(null)

  useEffect(() => {
    const inputCounterClientWidth = inputCounterRef?.current?.clientWidth
    if (inputCounterClientWidth) {
      inputRef.current.style.paddingRight = `${inputCounterClientWidth + 11}px`

      inputCounterRef.current.innerHTML = inputCounterRef.current.innerText.replace(
        /(\d)(?=(\d\d\d)+([^\d]|$))/g,
        '$1 '
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <span ref={inputCounterRef} className={Styles.counter}>
      {dataLength}
    </span>
  )
}
