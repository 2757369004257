export const confirmedPassword = {
  form: {
    password: 'Новый пароль',
    passwordConfirm: 'Подтверждение пароля',
  },
  confirmedButton: 'Подтвердить',
  backToSignInLink: 'Вернуться на страницу входа',
  notification: {
    passwordChanged: 'Пароль изменен',
  },
}
