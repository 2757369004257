import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Form, Image } from 'antd'
import { ValidationHelper } from '../../Validation'
import Styles from './Authorization.module.scss'
import * as I from '../../IAuth'
import { confirmed, login, setUsernameValue } from '../authSlice'
import { useAppDispatch } from '../../../../app/store'
import { EPages } from '../../../../constants'
import local from './../../../../localization'
import TextField from '@mui/material/TextField'
import { Button, IconButton, InputAdornment, Stack } from '@mui/material'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Typography from '@mui/material/Typography'
import { ThemeSwitcher } from '../../../../components/ThemeSwitcher/ThemeSwitcher'
import { setColorTypeButton } from '../../../../components/EISComponents/EISButton/EISButton.styles'
import { useTheme } from '../../../../theme/Theme'
import { getSelfRegistrationStatus } from '../../../profile/settings/profileSettingsSlice'
import { store } from '../../../../app/store'

const themeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER) || false // 0 - false, 1 - true

export const Authorization = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const selfRegistrationEnabled = store.getState().settings.userSelfRegistration

  const { themeProps, theme } = useTheme()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  // const [privateSession, setPrivateSession] = React.useState<boolean>(false)
  const [username, setUsername] = React.useState<I.FormField>({
    valid: false,
    message: '',
    value: '',
  })

  const [password, setPassword] = React.useState<I.FormField>({
    valid: false,
    message: '',
    value: '',
  })

  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [encoded, setEncoded] = React.useState<string>()

  React.useEffect(() => {
    if (encoded) {
      dispatch(confirmed(encoded)).then(() => history.push(EPages.SIGN_IN))
    } else {
      if (query.get('encoded')) setEncoded(query.get('encoded') as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encoded])

  React.useEffect(() => {
    dispatch(getSelfRegistrationStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeLoginHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validateEmail(e.target.value)
      setUsername(result)
      result.valid && dispatch(setUsernameValue(result.value))
    },
    [dispatch]
  )

  const onChangePasswordHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validatePassword(e.target.value)
      setPassword(result)
    },
    []
  )

  // const onChangePrivateSessionHandler = React.useCallback((): void => {
  //   localStorage.setItem('ps', privateSession ? '0' : '1')
  //   setPrivateSession(!privateSession)
  // }, [privateSession])

  const formOnKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onLoginHandler()
    }
  }

  const onLoginHandler = React.useCallback((): void => {
    // localStorage.setItem('ps', privateSession ? '1' : '0')

    if (username.valid && password.valid) {
      dispatch(
        login({
          username: username.value,
          password: password.value,
        })
      )
    } else {
      const usernameResult = ValidationHelper.validateEmail(username.value)
      setUsername(usernameResult)

      const passwordResult = ValidationHelper.validatePassword(password.value)
      setPassword(passwordResult)
    }
  }, [dispatch, password, username])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const language = localStorage.getItem('language')

  let instruction
  if ((theme === 'winwin' || theme === 'umentor') && language === 'ru') {
    instruction = `https://storage.yandexcloud.net/eis-static/theme/${theme}/${language}/instruction.pdf`
  }

  return (
    <div onKeyPress={formOnKeyPressed} className={Styles.container}>
      {themeSwitcher && <ThemeSwitcher />}
      <div className={Styles.wrapper}>
        <div
          className={Styles.wrapperLogo}
          style={{
            background: 'var(--main-logo) no-repeat left top / contain',
          }}
        ></div>
        <Form
          name='basic'
          initialValues={{
            // privateSession: privateSession,
            username: username.value,
            password: password.value,
          }}
        >
          <Typography
            className={Styles.MuiTypographySubtitle}
            variant='subtitle1'
            gutterBottom
            component='div'
          >
            {local.login.title}
          </Typography>
          <Form.Item
            name='username'
            className={Styles.formItem}
            help={username.message}
            validateStatus={username.message.length > 0 ? 'error' : 'success'}
          >
            <TextField
              InputProps={{ disableUnderline: true }}
              name={username.value}
              autoComplete={'username'}
              className={Styles.inputForm}
              onChange={onChangeLoginHandler}
              data-testid={'username'}
              label={local.login.form.login}
              variant='filled'
              id='filled-basic'
              inputProps={{ maxLength: 100 }}
            />
          </Form.Item>
          <Form.Item
            name='password'
            className={Styles.formItem}
            rules={[
              {
                required: true,
              },
            ]}
            help={password.message}
            validateStatus={password.message.length > 0 ? 'error' : 'success'}
          >
            <TextField
              InputProps={{ disableUnderline: true }}
              name={password.value}
              autoComplete='password'
              className={Styles.inputForm}
              onChange={onChangePasswordHandler}
              data-testid={'password'}
              label={local.login.form.password}
              variant='filled'
              id='filled-password-input'
              type={showPassword ? 'text' : 'password'}
              inputProps={{ maxLength: 30 }}
            />
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          </Form.Item>
          <Stack
            direction='row'
            justifyContent='flex-end'
            spacing={2}
            className={Styles.btnsWrap}
          >
            <Link
              data-testid={'restore-link'}
              to={'/restore'}
              className={Styles.link}
            >
              {local.login.restoreLink}
            </Link>
            {selfRegistrationEnabled && (
              <Link
                data-testid={'signup-link'}
                to={'/signup'}
                className={Styles.link}
              >
                {local.login.signupLink}
              </Link>
            )}
          </Stack>
          {/* <Checkbox
            id={'rememberMe'}
            className={Styles.rememberMe}
            checked={privateSession}
            onChange={onChangePrivateSessionHandler}
            data-testid={'checkbox'}
          >
            {local.login.checkbox}
          </Checkbox> */}
          <Form.Item>
            <Stack
              direction='column'
              justifyContent='flex-end'
              spacing={2}
              className={Styles.btnsWrap}
            >
              <Button
                variant='contained'
                className={setColorTypeButton('success')}
                onClick={onLoginHandler}
                disabled={!username.value.length || !password.value.length}
              >
                {local.login.loginButton}
              </Button>
              {instruction && (
                <a href={instruction} target='_blank' rel='noopener noreferrer'>
                  <Button
                    variant='outlined'
                    className={`${Styles.instruction}`}
                  >
                    {local.login.instructionButton}
                  </Button>
                </a>
              )}
            </Stack>
          </Form.Item>
        </Form>
      </div>
      <div className={Styles.img_side}>
        <Image src={themeProps['--auth-background-image']} preview={false} />
      </div>
    </div>
  )
}

export default Authorization
