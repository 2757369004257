import { Observable } from 'rxjs'
import { IAPIResult } from '../../IBaseService'
import { AuthoritiesResponse } from '../api/IAuthService'
import { Authorities, EAPIResponseStatus } from '../../../constants'
import { EAPIEndpoint } from '../../constants'
import local from './../../../localization'

interface AuthorityProviderConfig {
  localStorageKey: string
  onUpdateAuthorities: (
    token: string
  ) => Observable<IAPIResult<AuthoritiesResponse>>
  processError: (err: string) => void
  storage: {
    getItem: (key: string) => string | null
    setItem: (key: string, value: string) => void
    removeItem: (key: string) => void
  }
}

export const createAuthorityProvider = ({
  localStorageKey,
  onUpdateAuthorities,
  processError,
  storage,
}: AuthorityProviderConfig): {
  getAuthorities: () => Array<Authorities> | null
  setAuthorities: (authorities: AuthoritiesResponse | null) => void
  refreshAuthorities: (token?: string) => Promise<void>
} => {
  const getAuthorities = (): Array<Authorities> | null => {
    const data = storage.getItem(localStorageKey)

    const authorities = (data && JSON.parse(data)) || null

    return authorities
  }

  const setAuthorities = (authorities: AuthoritiesResponse | null): void => {
    if (authorities) {
      storage.setItem(localStorageKey, JSON.stringify(authorities))
    } else {
      storage.removeItem(localStorageKey)
    }
  }

  const refreshAuthorities = (token?: string): Promise<void> =>
    !token
      ? new Promise(resolve => resolve(setAuthorities(null)))
      : onUpdateAuthorities(token)
          .toPromise()
          .then(result => {
            if (result.status !== EAPIResponseStatus.SUCCESS) {
              let err = ''

              if (result.status === EAPIResponseStatus.FAIL) {
                err =
                  result.data?.toString() ||
                  local.notification.general.ERROR.unknownError
              } else {
                if (result.data) {
                  const { code } = result.data as any
                  if (code && code === 401) {
                    window.location.href = EAPIEndpoint.SIGNIN
                  } else {
                    err =
                      result.message ||
                      local.notification.general.ERROR.unknownError
                  }

                  processError(err)
                } else {
                  processError(local.notification.general.ERROR.unknownError)
                }
              }
              processError(err)
            } else {
              const authorities = result.data

              if (authorities && Array.isArray(authorities)) {
                const diff: Array<Authorities> = []

                authorities.forEach(a => {
                  if (!(a in Authorities)) {
                    diff.push(a)
                  }
                })

                if (diff.length) {
                  throw new Error(
                    `${
                      local.notification.general.ERROR.unknownAthorities
                    }: ${diff.join(', ')}`
                  )
                } else {
                  setAuthorities(authorities)
                }
              } else {
                setAuthorities(null)
                throw new Error(
                  `${local.notification.general.ERROR.wrongResponse}: ${result}`
                )
              }
            }
          })

  return {
    getAuthorities,
    setAuthorities,
    refreshAuthorities,
  }
}
