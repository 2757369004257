export const positions = {
  columns: {
    title: 'Title',
    items: 'Default Maps',
    isActive: {
      title: 'Active',
      true: 'Yes',
      false: 'No',
    },
    createdTime: 'Сreation date',
    action: {
      title: 'Action',
      editButton: 'Edit',
      deleteButton: 'Delete',
    },
  },
  positionDeleteModal: {
    title: 'Delete a role',
    content:
      'Do you really want to delete the post? All users will have this assigned role detached. The maps attached to the role will be saved by the users who have been assigned this role.',
    okText: 'Yes',
    cancelText: 'No',
  },
  emptyData: {
    noData: 'No roles added',
    noFilterPositions: 'No data matching the selected filters',
  },
  filter: {
    position: 'Enter the Role',
    isActive: 'Active',
  },
  addPositionButton: 'Add Role',
  addAndEditModal: {
    optionsStatus: {
      active: 'Active',
      disable: 'Inactive',
    },
    title: {
      add: 'Add Role',
      edit: 'Edit Role',
    },
    cancelText: 'Cancel',
    okText: 'Save',
    formItem: {
      title: {
        label: 'Role title',
        placeholder: 'Enter role title',
      },
      status: {
        label: 'Role status',
        placeholder: 'Active / Inactive',
      },
      items: {
        filterInputLabel: 'Map',
        label: 'Attached maps',
        placeholder: 'Select map',
      },
      users: {
        filterInputLabel: 'User',
        label: 'Users',
        placeholder: 'Select user',
      },
    },
    deleteItemModal: {
      title: 'Delete maps',
      content:
        'Are you sure you want to disconnect the map from the role? The map will remain with the users who have been appointed to this role.',
      okText: 'Yes',
      cancelText: 'No',
    },
    disablePositionModal: {
      title: 'Role locking',
      content:
        'Are you sure you want to make the role inactive? It will be dissociated from all the users who have this role now. The maps assigned to the role will remain with the users who had this role.',
      okText: 'Yes',
      cancelText: 'No',
    },
  },
  notification: {
    isNotActive: 'Inactive Roll',
  },
}
