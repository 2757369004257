export const trackEngine = {
  deleteModal: {
    title: 'Sil',
    okText: 'Evet',
    cancelText: 'Hayır',
    deleteNode:
      'Modül(ler), açıklama(lar) ile tüm kullanıcı tamamlama ve ilerlemelerini tamamen kaldırmak istediğinizden emin misiniz?',
    deleteCurve: 'Bağlantıyı kaldırmak istediğinizden emin misiniz?',
  },
  editModal: {
    saveButton: 'Kaydet',
  },
  notification: {
    saveNodes: 'Değişiklikler başarıyla kaydedildi',
    export: 'Dışa aktarma tamamlandı',
    moveInNode:
      'Modül görevini yapmaya başlamak için kontrol panelindeki butonu kullanın',
  },
  toolbar: {
    title: 'Kontrol paneli',
    importButton: 'Rotayı içe aktar',
    addNodeButton: 'Modül ekle',
    addCurveButton: 'Bağlantı oluştur',
    editNodeTitleButton: 'Başlığı düzenle',
    removeButton: 'Öğeyi sil',
    saveButton: 'Değişiklikleri kaydet',
    moveInNodeButton: 'Göreve başlayın (Çift tıklayın)',
    notSave: 'Önce rotayı kaydedin',
    copyNode: 'Modülü(leri) kopyalayın',
    searchTrack: 'Şuraya kopyala ...',
    scale: {
      resetScaleButton: 'Ölçeklendirmeyi sıfırla',
      zoomInButton: 'Yakınlaştır',
      zoomOutButton: 'Uzaklaştır',
    },
    export: {
      tooltip: 'Eğitim rotasını dışa aktar',
      validation: 'Kaydedilmemiş değişiklikler var, önce rotayı kaydedin',
      button: 'Dışa Aktar',
    },
    // preview: 'Önizleme',
    // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра
  },
  draft: 'TASLAS',
  contains: {
    hasTopic: 'Tanım',
    hasTest: 'Test sorusu',
    hasQuestion: 'Detaylı soru',
    hasVideo: 'Videolu soru',
  },
  emptyData: 'Bilgi yok',
}
