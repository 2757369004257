export const positions = {
  columns: {
    title: 'Название',
    items: 'Треки по умолчанию',
    isActive: {
      title: 'Активная',
      true: 'Да',
      false: 'Нет',
    },
    createdTime: 'Дата создания',
    action: {
      title: 'Действие',
      editButton: 'Изменить',
      deleteButton: 'Удалить',
    },
  },
  positionDeleteModal: {
    title: 'Удаление должности',
    content:
      'Вы действительно хотите удалить должность? Все треки которые были назначены пользователю по его должности будут сохранены за пользователем и прогресс прохождения будет сохранен.',
    okText: 'Да',
    cancelText: 'Нет',
  },
  emptyData: {
    noData: 'Нет добавленных должностей',
    noFilterPositions: 'Нет данных, соответствующих выбранным фильтрам',
  },
  filter: {
    position: 'Введите название должности',
    isActive: 'Активные',
  },
  addPositionButton: 'Добавить должность',
  addAndEditModal: {
    optionsStatus: {
      active: 'Активный',
      disable: 'Неактивный',
    },
    title: {
      add: 'Добавить должность',
      edit: 'Редактировать должность',
    },
    cancelText: 'Отменить',
    okText: 'Сохранить',
    formItem: {
      title: {
        label: 'Название должности',
        placeholder: 'Введите название должности',
      },
      status: {
        label: 'Статус должности',
        placeholder: 'Активный / Неактивный',
      },
      items: {
        filterInputLabel: 'Трек',
        label: 'Привязанные треки',
        placeholder: 'Выберите треки',
      },
      users: {
        filterInputLabel: 'Пользователь',
        label: 'Пользователи',
        placeholder: 'Выберите пользователя',
      },
    },
    deleteItemModal: {
      title: 'Удаление трека',
      content:
        'Вы действительно хотите открепить трек от должности? Трек останется у пользователей, которые были назначены на эту должность.',
      okText: 'Да',
      cancelText: 'Нет',
    },
    disablePositionModal: {
      title: 'Блокировка должности',
      content:
        'Вы действительно хотите сделать должность неактивной? От всех пользователей, у которых сейчас эта должность есть, она будет откреплена. Прикрепленные треки к должности сохраняться за пользователями, которые имели эту должность.',
      okText: 'Да',
      cancelText: 'Нет',
    },
  },
  notification: {
    isNotActive: 'Должность неактивна',
  },
}
