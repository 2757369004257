export const items = {
  columns: {
    title: 'Name',
    description: 'Description',
    embeddedIn: 'Embedded in the folder',
    managers: 'Track managers',
    mentors: 'Mentors',
    users: 'Users',
    isActive: {
      title: 'Active',
      true: 'Yes',
      false: 'No',
      isBlocked: 'Inactive track',
    },
    createdTime: 'Сreation date',
    updatedTime: 'Updated date',
    action: {
      title: 'Action',
      editButton: 'Change',
      deleteButton: 'Delete',
    },
  },
  emptyData: {
    noData: 'No tracks added',
    noFilterItems: 'No data matching the selected filters',
  },
  filter: {
    item: 'Select a track',
    withMentor: 'With a mentor',
    isActive: 'Active',
  },
  addItemButton: 'Add track',
  deleteItemModal: {
    title: 'track',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Active',
      disable: 'Inactive',
    },
    title: {
      add: 'Add the Track',
      edit: 'Edit the Track',
      delete: 'Delete the Track',
      export: 'Export the Track',
    },
    cancelText: 'Cancel',
    okText: 'Save',
    back: 'Back',
    addGroup: 'Add Group',
    divideGroup: 'Divide by groups',
    groupsTitle: 'Form Mentor(s) - User(s) groups to check tasks',
    formItem: {
      title: {
        label: 'Track name',
        placeholder: 'Enter track name',
      },
      description: {
        label: 'Track description',
        placeholder: 'Enter description',
      },
      status: {
        label: 'Track status',
        placeholder: 'Active / Inactive',
        isBlocked: 'Inactive track',
      },
      managers: {
        filterInputLabel: 'Manager',
        label: 'Track managers',
        placeholder: 'Select track managers',
      },
      mentors: {
        filterInputLabel: 'Mentor',
        label: 'Mentors',
        placeholder: 'Select mentors',
      },
      users: {
        filterInputLabel: 'User',
        label: 'Users',
        placeholder: 'Select users',
      },
      folder: {
        label: 'Folder',
        placeholder: 'Select a folder',
      },
      isDefault: {
        label: 'Default track',
        placeholder:
          'If this setting is active, this track will be automatically assigned to all new users',
      },
      isShadowMode: {
        label: '"Shadow Map" Mode',
        placeholder:
          'When the mode is activated, the user will see the entire track of the modules, except for inactive modules (colored gray).',
      },
    },
    disableItemModal: {
      title: 'Track locking',
      content:
        'Are you sure you want to make this track inactive? All users who this track has been assigned to (including the ones assigned according to their position) will lose the progress of completing tasks on it. Also, this track will be disconnected from all positions which it was assigned to. All mentors and track managers assigned to the track will also be disconnected from it.',
      okText: 'Yes',
      cancelText: 'No',
    },
  },
}
