import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENotificationVariant, EStateStatus } from '../../../constants'
import { AuthService } from '../../../services/auth'
import { SignUpRequest } from '../../../services/auth/api/IAuthService'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import local from './../../../localization'

const initialState: I.SignUpState = {
  status: EStateStatus.IDLE,
  error: null,
}

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (
    { fullName, username, phoneNumber, password }: SignUpRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        AuthService.register(fullName, username, phoneNumber, password)
      )
      dispatch(
        enqueueSnackbar({
          message: local.notification.auth.SUCCESS.registration,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

const registrationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearStatus: state => {
      state.status = EStateStatus.IDLE
    },
  },
  extraReducers: builder => {
    builder.addCase(signUp.pending, state => {
      state.status = EStateStatus.PENDING
    })
    builder.addCase(signUp.fulfilled, state => {
      state.status = EStateStatus.FULFILLED
    })
    builder.addCase(signUp.rejected, state => {
      state.status = EStateStatus.REJECTED
    })
  },
})

export const { clearStatus } = registrationSlice.actions

export const { reducer: registrationReducer } = registrationSlice

export const selectStatusRegistration = (state: I.RootState) =>
  state.auth.registrationReducer.status
