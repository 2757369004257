export const statUsers = {
  filters: {
    filterUsersInputLabel: 'User',
    filterItemsInputLabel: 'Map',
    searchUsers: 'For all users',
    searchItems: 'For all maps',
    mapTooltip: 'The field will become active when one map is selected',
  },
  statTab: {
    status: {
      available: 'AVAILABLE',
      checking: 'UNDER REVIEW',
      completed: 'COMPLETED',
      rejected: 'REJECTED',
      unavailable: 'UNAVAILABLE',
    },
    areaConfig: {
      yAxis: 'Completed, %',
    },
    progressTitle: 'Overall Progress',
    progressLegend: {
      done: 'Completed',
      undone: 'Not completed',
    },
    progressTooltip:
      'The circular diagram is divided into two sectors: completed steps and not competed steps. The size of the sector corresponding to completed steps represents the percentage of completed work by User(s). The size of the sector corresponding to incomplete steps represents the percentage of undone work. The circular diagram helps to quickly assess the progress of the User(s) or and identify areas that need improvement.',
    byUsersTitle: 'By Users',
    byUsers: {
      total: 'Total Number Of Steps',
      averageCompleted: 'Steps Completed',
      averageLeft: 'Steps Remaining To Complete',
      everyoneCompleted: 'Completed By All',
      noneCompleted: 'Not completed By All',
      checkingCount: 'Waiting For Review',
      blockedProgress: 'Further modules are blocked',
      average: '(average)',
      all: '(total)',
      tooltip: {
        total:
          'The total number of Steps contained in the Maps assigned to the User(s)',
        averageCompleted:
          'The average number of completed Steps by the User(s)',
        averageLeft:
          'The average number of Steps that the User(s) has not completed yet',
        everyoneCompleted:
          'The total number of Steps that have been successfully completed by all Users who have access to those Steps',
        noneCompleted:
          'The total number of Steps where none of the Users assigned to Maps with these modules have not passed the related tasks',
        checkingCount:
          'The total number of Steps with tasks under Mentor review',
        blockedProgress:
          'Further steps are blocked',
      },
    },
    areaTitle: 'Progress Dynamics',
    areaTooltip:
      '"Progress Dynamics" is a graph reflecting the progress dynamics of completing tasks in maps. The graph shows the correlation between the time interval from the date of creating the map to the current date (horizontally) and the percentage of completed tasks (vertically). Additionally, a selectable time interval is available from the dropdown list.',
    pieTitle: 'Status Ratio',
    pieTooltip:
      '"Status Ratio" is a pie chart that displays the percentage breakdown of all steps in different statuses.',
  },
  tableTab: {
    tableTitle: 'Users statistics',
    columns: {
      fullName: 'User',
      email: 'Email',
      position: 'Role',
      lastAuthTime: 'Last Login Date',
      track: 'Map',
      progress: { title: 'Progress', noTask: 'No tasks' },
      action: {
        title: 'Action',
        actionButton: 'Steps list',
        resetButton: 'Reset Progress',
      },
    },
    emptyData: {
      noData: 'No progress statistics found',
      noFilterStat: 'No data matching the selected filters',
    },
    exportFileName: 'Users statistics',
  },
  statUserByTask: {
    filters: {
      label: 'Step',
      searchTask: 'Select step',
      selectStatus: 'Status',
    },
    reset: {
      resetButton: 'Reset Progress',
      cancelButton: 'Cancel',
      confirmButton: 'Confirm Reset',
    },
    tabs: {
      stat: 'Statistics',
      table: 'Spreadsheet',
      map: 'Progress Map',
    },
    columns: {
      node: 'Step Title',
      attemptsCount: {
        title: 'Number Of Attempts',
        manyAttempts: 'attempts',
        singleAttempt: 'attempt',
        attempts: 'attempts',
      },
      mentor: 'Last Reviewed',
      timeCompleted: 'Last Completion ',
      status: {
        title: 'Status',
        completed: 'Completed',
        rejected: 'Rejected',
        checking: 'Under review',
        default: 'Not completed',
      },
      action: {
        title: 'Action',
        actionButton: 'Check',
      },
    },
    emptyData: {
      noData: 'No progress statistics found',
      noFilterStats: 'No data matching the selected filters',
    },
  },
}
