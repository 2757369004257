export enum EAlign {
  LEFT = 'left',
  CONTENT = 'center',
  RIGHT = 'right',
}

export enum ESize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum ETextSize {
  SM = '10px',
  MD = '12px',
  LG = '14px',
}

export enum EDirection {
  AUTO = 'auto',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export const PAGE = {
  width: 100,
  height: 100,
  scale: 1,
}

export const defaultPage = { ...PAGE }

export const minRadius = 90
export const maxRadius = 130
export const roundingStep = 10
export const pageOffset = 50
