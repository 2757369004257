import React, { FC } from 'react'
import Styles from './EISCheckbox.module.scss'

interface IEISCheckbox {
  title: string
  checked: boolean
  includeCheckedMark: boolean
  callback: Function
  disabled?: boolean
}

const EISCheckbox: FC<IEISCheckbox> = ({
  title,
  checked,
  includeCheckedMark,
  callback,
  disabled = false,
}) => {
  return (
    <label
      className={`${Styles.checkboxContainer} ${disabled && Styles.disabled}`}
    >
      <span>{title}</span>
      <input
        type='checkbox'
        checked={includeCheckedMark ? false : checked}
        onChange={e => {
          callback(e)
        }}
        disabled={disabled}
      />
      <span
        className={`${
          includeCheckedMark ? Styles.includeCheckmark : Styles.checkmark
        }`}
      ></span>
    </label>
  )
}

export default EISCheckbox
