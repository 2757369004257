import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Layout, Image, Tooltip, Affix } from 'antd'
import { Menu } from './Menu/Menu'
import Styles from './Sidebar.module.scss'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import local from './../../localization'
import { getGlobalSearchRef } from '../Header/Search/SearchPL'
import { isMobile } from '../../utils'
import { useTheme } from '../../theme/Theme'

const collapsed = !!Number(localStorage.getItem('menuCollapsed') || '0')
const isEmptyURL = window.REACT_APP_COMPANY_WEBSITE_URL.trim() === ''

let isHover: boolean = false

export const Sidebar: React.FC = (): React.ReactElement => {
  const [menuCollapsed, setMenuCollapsed] = useState(collapsed)
  const { theme } = useTheme()

  const toggleCustomCollapse = useCallback(() => {
    setMenuCollapsed(!menuCollapsed)
    localStorage.setItem('menuCollapsed', menuCollapsed ? '0' : '1')
  }, [menuCollapsed])

  useEffect(() => {
    if (!isMobile()) {
      window.addEventListener('DOMNodeInserted', () => {
        const sidebar = document.querySelector<HTMLElement>(
          `.${Styles.sidebar}`
        )

        sidebar?.addEventListener('mouseover', () => {
          if (isHover) return
          sidebar.style.zIndex = '152'
          getGlobalSearchRef.current.style = `margin-left: 20px`
          isHover = true
        })

        sidebar?.addEventListener('mouseout', () => {
          if (!isHover) return
          sidebar.style.zIndex = '100'
          getGlobalSearchRef.current.style = `margin-left: 0px;`
          isHover = false
        })
      })
    }
  }, [])

  const imgLogoJSX = useMemo(() => {
    return (
      <Image
        className={
          menuCollapsed
            ? Styles.mini_logo
            : theme === 'umentor'
            ? Styles.logo_umentor
            : Styles.logo
        }
        preview={false}
      />
    )
  }, [menuCollapsed, theme])

  const urlLogoJSX = useCallback((children: JSX.Element) => {
    return (
      <a
        href={window.REACT_APP_COMPANY_WEBSITE_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        {children}
      </a>
    )
  }, [])

  return (
    <Layout.Sider
      theme='light'
      className={Styles.sidebar}
      width={260}
      trigger={null}
      collapsible
      collapsed={menuCollapsed}
    >
      <Affix>
        <Tooltip
          placement='bottomLeft'
          title={
            menuCollapsed ? local.main.menu.expand : local.main.menu.collapse
          }
        >
          {React.createElement(menuCollapsed ? RightOutlined : LeftOutlined, {
            className: Styles.menuCollapsed,
            onClick: toggleCustomCollapse,
            translate: undefined,
          })}
        </Tooltip>
      </Affix>
      {!isEmptyURL ? urlLogoJSX(imgLogoJSX) : imgLogoJSX}
      <Menu collapsed={menuCollapsed} />
    </Layout.Sider>
  )
}
