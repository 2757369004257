export const recoveryPassword = {
  form: {
    help:
      'Пожалуйста, введите свой адрес электронной почты ниже, и мы отправим вам ссылку для ввода нового пароля.',
    username: 'Логин (e-mail)',
  },
  title: 'Восстановить пароль',
  titleRecovery: 'Введите новый пароль',
  recoveryResult: {
    info: 'Для завершения восстановления перейдите по ссылке на вашей почте',
    ps: 'Данную вкладку можно закрыть',
  },
  recoveryButton: 'Отправить',
  backToSignInLink: 'Назад',
}
