export const statUsers = {
  filters: {
    filterUsersInputLabel: 'Kullanıcı',
    filterItemsInputLabel: 'Parça',
    searchUsers: 'Tüm kullanıcılar için',
    searchItems: 'Tüm rotalar için',
    mapTooltip: 'Bir parça seçildiğinde alan aktif hale gelecektir',
  },
  statTab: {
    status: {
      available: 'UYGUN',
      checking: 'İNCELENİYOR',
      completed: 'TAMAMLANDI',
      rejected: 'REDDEDİLDİ',
      unavailable: 'KULLANIM DIŞI',
    },
    areaConfig: {
      yAxis: 'Tamamlandı, %',
    },
    progressTitle: 'Genel ilerleme',
    progressLegend: {
      done: 'Tamamlandı',
      undone: 'Tamamlanamadı',
    },
    progressTooltip:
      'Dairesel diyagram iki sektöre ayrılmıştır: tamamlanan modüller ve rekabet edilmeyen modüller. Tamamlanan modüllere karşılık gelen sektörün boyutu, Kullanıcı(lar) tarafından tamamlanan işlerin yüzdesini temsil eder. Tamamlanmamış modüllere karşılık gelen sektörün boyutu, tamamlanmamış iş yüzdesini temsil eder. Dairesel diyagram, Kullanıcı(lar)ın ilerlemesini hızlı bir şekilde değerlendirmeye veya iyileştirilmesi gereken alanları belirlemeye yardımcı olur.',
    byUsersTitle: 'Kullanıcılar tarafından',
    byUsers: {
      total: 'Toplam modüller',
      averageCompleted: 'Tamamlanan modüller',
      averageLeft: 'Tamamlanması gereken modüller',
      everyoneCompleted: 'Herkes tamamladı',
      noneCompleted: 'Hiçkimse tamamlamadı',
      checkingCount: 'Kontrol için bekleniyor',
      blockedProgress: 'Modüllere erişimi engellendi',
      average: '(ortalama)',
      all: '(toplam)',
      tooltip: {
        total: 'Kullanıcı(lar)a atanan parçalarda bulunan toplam modül sayısı',
        averageCompleted:
          'Kullanıcı(lar) tarafından tamamlanan ortalama modül sayısı',
        averageLeft:
          'Kullanıcı(lar)ın henüz tamamlamadığı ortalama modül sayısı',
        everyoneCompleted:
          'Bu modüllere erişimi olan tüm kullanıcılar tarafından başarıyla tamamlanan toplam modül sayısı',
        noneCompleted:
          'Bu modüllerin bulunduğu parkurlara atanan kullanıcıların hiçbirinin ilgili görevleri geçemediği toplam modül sayısı',
        checkingCount:
          'Mentor incelemesi altında görevleri olan toplam modül sayısı',
        blockedProgress:
          'Diğer modüllere erişimi engellendi',
      },
    },
    areaTitle: 'İlerleme dinamiği',
    areaTooltip:
      '"İlerleme Dinamikleri", parkurlardaki görevlerin tamamlanmasına ilişkin ilerleme dinamiklerini yansıtan bir grafiktir. Grafik, parkurun oluşturulduğu tarihten geçerli tarihe kadar geçen zaman aralığı (yatay olarak) ile tamamlanan görevlerin yüzdesi (dikey olarak) arasındaki korelasyonu gösterir. Ayrıca, açılır listeden seçilebilir bir zaman aralığı da mevcuttur.',
    pieTitle: 'Durum oranı',
    pieTooltip:
      '"Durum Oranı", farklı durumlardaki tüm modüllerin yüzde dağılımını gösteren bir pasta grafiktir.',
  },
  tableTab: {
    tableTitle: 'Kullanıcı İstatistikleri',
    columns: {
      fullName: 'Kullanıcı',
      email: 'E-posta',
      position: 'Pozisyon',
      lastAuthTime: 'Son giriş tarihi',
      track: 'Rota',
      progress: { title: 'İlerleme', noTask: 'Görev yok' },
      action: {
        title: 'Eylem',
        actionButton: 'Modül listesi',
        resetButton: 'İlerlemeyi sıfırla',
      },
    },
    emptyData: {
      noData: 'İlerleme istatistiği bulunamadı',
      noFilterStat: 'Seçilen filtrelerle eşleşen veri yok',
    },
    exportFileName: 'Kullanıcı İstatistikleri',
  },
  statUserByTask: {
    filters: {
      label: 'Modül',
      searchTask: 'Modül seçin',
      selectStatus: 'Durum',
    },
    reset: {
      resetButton: 'İlerlemeyi sıfırla',
      cancelButton: 'Kaldır',
      confirmButton: 'Sıfırlama işlemini onaylayın',
    },
    tabs: {
      stat: 'İstatistikler',
      table: 'E-tablo',
      map: 'İlerleme haritası',
    },
    columns: {
      node: 'Modül başlığı',
      attemptsCount: {
        title: 'Deneme sayısı',
        manyAttempts: 'denemeler',
        singleAttempt: 'deneme',
        attempts: 'denemeler',
      },
      mentor: 'Son incelenen',
      timeCompleted: 'Son tamamlanma',
      status: {
        title: 'Durum',
        completed: 'Tamamlandı',
        rejected: 'Reddedildi',
        checking: 'İnceleniyor',
        default: 'Tamamlanmadı',
      },
      action: {
        title: 'Eylem',
        actionButton: 'İncele',
      },
    },
    emptyData: {
      noData: 'İlerleme istatistiği bulunamadı',
      noFilterStats: 'Seçilen filtrelerle eşleşen veri yok',
    },
  },
}
