export const tracksAvailable = {
  filter: {
    itemSearch: 'Find track',
    positionStatus: {
      all: 'All',
      completed: 'Completed',
      unfulfilled: 'Not completed',
    },
  },
  emptyData: {
    noAvailable: 'No tracks available',
    noData: 'No data matching the selected filters',
  },
  total: 'TOTAL',
  inProgressTooltip: 'The track is :percentage% completed',
  progressDoneTooltip: 'The track has been successfully passed',
}
