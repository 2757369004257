export const login = {
  form: {
    login: 'Giriş Yap (e-posta)',
    password: 'Şifre',
  },
  title: 'Hoşgeldiniz',
  loginButton: 'Giriş Yap',
  checkbox: 'Sadece bu sekmede çalışın',
  restoreLink: 'Şifrenizi mi unuttunuz?',
  signupLink: 'Hesap oluşturun',
}
