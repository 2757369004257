export const positions = {
  columns: {
    title: 'Başlık',
    items: 'Varsayılan Rotalar',
    isActive: {
      title: 'Aktif',
      true: 'Evet',
      false: 'Hayır',
    },
    createdTime: 'Oluşturma tarihi',
    action: {
      title: 'Aksiyon',
      editButton: 'Düzenle',
      deleteButton: 'Sil',
    },
  },
  positionDeleteModal: {
    title: 'Konumu sil',
    content:
      'Gönderiyi gerçekten silmek istiyor musunuz? Tüm kullanıcılar bu atanmış konumdan ayrılacaktır. Konuma eklenen parçalar, bu konuma atanan kullanıcılar tarafından kaydedilir.',
    okText: 'Evet',
    cancelText: 'Hayır',
  },
  emptyData: {
    noData: 'Konum eklenmedi',
    noFilterPositions: 'Seçilen filtrelerle eşleşen veri yok',
  },
  filter: {
    position: 'Pozisyon başlığını girin',
    isActive: 'Aktif',
  },
  addPositionButton: 'Konum Ekle',
  addAndEditModal: {
    optionsStatus: {
      active: 'Aktif',
      disable: 'Pasif',
    },
    title: {
      add: 'Konum ekle',
      edit: 'Konumu düzenle',
    },
    cancelText: 'Kaldır',
    okText: 'Kaydet',
    formItem: {
      title: {
        label: 'Konum başlığı',
        placeholder: 'Konum başlığı gir',
      },
      status: {
        label: 'Konum durumu',
        placeholder: 'Aktif / Pasif',
      },
      items: {
        filterInputLabel: 'Parça',
        label: 'Ekli rotalar',
        placeholder: 'Konum seç',
      },
      users: {
        filterInputLabel: 'Kullanıcı',
        label: 'Kullanıcılar',
        placeholder: 'Kullanıcı seç',
      },
    },
    deleteItemModal: {
      title: 'Konumları sil',
      content:
        'Rotayı konumdan ayırmak istediğinize emin misiniz? Rota, bu pozisyona atanan kullanıcılarda kalacaktır.',
      okText: 'Evet',
      cancelText: 'Hayır',
    },
    disablePositionModal: {
      title: 'Konumu kilitle',
      content:
        'Konumu pasif hale getirmek istediğinizden emin misiniz? Onay verdiğinizde bu konuma sahip tüm kullanıcılardan kaldırılacaktır. Konuma atanan rotalar, bu konuma sahip olan kullanıcılarda kalacaktır.',
      okText: 'Evet',
      cancelText: 'Hayır',
    },
  },
  notification: {
    isNotActive: 'Pozisyon aktif değil',
  },
}
