export const selfie = {
  profilePicture: 'Profil fotoğrafı',
  actionBtn: {
    uploadImage: 'Fotoğraf yükle',
    cancel: 'Kaldır',
  },
  imageCaptuer: {
    turnOnWebcam: 'Web kamerasını açın',
  },
  camera: {
    tooltip: {
      cameraSwitch: 'Kamera değiştir',
      takePicture: 'Bir fotoğraf çek',
      takeNewPicture: 'Yeni bir fotoğraf çek',
      saveImg: 'Resmi kaydet',
    },
  },
}
