export const settings = {
  tabs: {
    genereal: 'Genel',
    profileSettings: 'Profil ayarları',
    systemSettings: 'Sistem ayarları',
  },
  expiration: {
    title: 'Profil etkinliği',
    date: 'Profil tarihine kadar aktiftir :date',
    help: 'Profilinizi yenilemek için ödeme yapmalısınız',
  },
  profileImg: 'Avatarı değiştir',
  changePassword: {
    label: 'Şifreyi Değiştir',
    currentPassword: 'Geçerli şifre:',
    newPassword: 'Yeni şifreyi girin:',
    confirmedPassword: 'Şifreyi onaylayın:',
    currentPasswordPlaceholder: 'Güncel şifrenizi giriniz',
    newPasswordPlaceholder: 'Yeni şifreyi girin',
    confirmedPasswordPlaceholder: 'Şifreyi onayla',
    button: 'Şifreyi değiştir',
  },
  changeEmail: {
    label: 'E-posta Değiştir',
    newEmail: 'Yeni bir e-posta girin:',
    confirmedEmail: 'Yeni e-postayı doğrulayın:',
    newEmailPlaceholder: 'Yeni e-posta girin',
    confirmedEmailPlaceholder: 'Yeni e-postayı onaylayın',
    button: 'E-postayı değiştir',
  },
  changeEmailModal: {
    title: 'E-postayı değiştir',
    content:
      'E-posta adresinizi değiştirmek istediğinizden emin misiniz :Email? Değişiklikten sonra sistemde yeni e-posta ile yeniden yetkilendirme yapmanız gerekecektir.',
    okText: 'Onayla',
    cancelText: 'Kaldır',
  },
  changePhoneNumber: {
    label: 'Telefon numarasını değiştir',
    newPhoneNumber: 'Yeni telefon numarasını girin:',
    newPhoneNumberPlaceholder: 'Yeni telefon numarasını girin',
    newPhoneNumberSearchPlaceholder: 'Arama',
    button: 'Telefon numarasını değiştir',
  },
  changePhoneNumberModal: {
    title: 'Telefon numarasını değiştir',
    content:
      'Telefon numaranızı :Phone olarak değiştirmek istediğinizden emin misiniz?',
    okText: 'Onayla',
    cancelText: 'Kaldır',
  },
  notifier: {
    label: 'Bildirimler',
    prompt: 'Bildirimleri e-posta adresine gönderin',
  },
  selfRegistration: {
    label: 'Kayıt',
    prompt: 'Kullanıcı kendi kendine kayıt',
  },
  saveSettingsButton: 'Kaydet',
}
