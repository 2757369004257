import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENotificationVariant, EStateStatus } from '../../../constants'
import { AuthService } from '../../../services/auth'
import { ConfirmedPasswordRequest } from '../../../services/auth/api/IAuthService'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import local from './../../../localization'

const initialState: I.ConfirmedPasswordState = {
  status: EStateStatus.IDLE,
  error: null,
}

export const confirmedEmail = createAsyncThunk(
  'auth/confirmedEmail',
  async (encoded: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(AuthService.confirmed(encoded))
      return result
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('')
    }
  }
)

export const confirmedPassword = createAsyncThunk(
  'auth/confirmedPassword',
  async (body: ConfirmedPasswordRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        AuthService.confirmedPassword(body)
      )
      dispatch(
        enqueueSnackbar({
          message: local.notification.auth.SUCCESS.passwordChanged,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

const confirmedPasswordSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(confirmedPassword.pending, state => {
      state.status = EStateStatus.PENDING
    })
    builder.addCase(confirmedPassword.fulfilled, state => {
      state.status = EStateStatus.FULFILLED
    })
    builder.addCase(confirmedPassword.rejected, state => {
      state.status = EStateStatus.REJECTED
    })
  },
})

export const { reducer: confirmedPasswordReducer } = confirmedPasswordSlice
