import React from 'react'
import { Avatar, Layout, MenuProps, Space, Tooltip, Typography } from 'antd'
import Styles from './Header.module.scss'
import { useAppDispatch } from '../../app/store'
import { useSelector } from 'react-redux'
import { profileHead, selectHead } from './HeaderSlice'
import { useHistory } from 'react-router-dom'
import { signOut } from '../../features/auth/login/authSlice'
import * as I from 'Types'
import { DownOutlined } from '@ant-design/icons'
import { EPages } from '../../constants'
import _ from 'lodash'
import local, { changeLanguage, languagesList } from './../../localization'
import Search from './Search/SearchPL'
import { ThemeSwitcher } from '../../components/ThemeSwitcher/ThemeSwitcher'
import { EISDropdown, EISSelect } from '../../components/EISComponents'
import spriteIcons from '../../assets/svg/sprite-icons.svg'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import { isMobile } from '../../utils'
import { BreadcrumbList } from '../Breadcrumb/Breadcrumb'

const withLanguageSwitcher = !!Number(window.REACT_APP_WITH_LANGUAGE_SWITCHER)
const themeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER) || false // 0 - false, 1 - true

export const Header: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { fullName, avatarUrl } = useSelector(selectHead)
  const history = useHistory()

  const menuData: Array<I.IMenuItem> = React.useMemo(
    () => [
      {
        icon: (
          <svg width={14} height={14}>
            <use xlinkHref={`${spriteIcons}#header-settings-icon`}></use>
          </svg>
        ),
        title: local.main.headMenu.profileSettings,
        url: EPages.PROFILE_SETTINGS,
      },
      {
        icon: (
          <svg width={14} height={14}>
            <use xlinkHref={`${spriteIcons}#header-info-icon`}></use>
          </svg>
        ),
        title: local.main.headMenu.reference,
        url: EPages.REFERENCE,
      },
      {
        icon: (
          <svg width={14} height={14}>
            <use xlinkHref={`${spriteIcons}#header-logout-icon`}></use>
          </svg>
        ),
        title: local.main.headMenu.exit,
        callback: async () => {
          dispatch(signOut())
        },
      },
    ],
    [dispatch]
  )

  const handleClick = React.useCallback(
    (item: I.IMenuItem) => () => {
      if (item.url) history.push(item.url)
      else if (item.callback) item.callback()
    },
    [history]
  )

  const menuItems: MenuProps['items'] = menuData.map((item, index) => ({
    key: `${index + 1}`,
    label: (
      <div onClick={handleClick(item)}>
        {item.icon}
        {item.title}
      </div>
    ),
  }))

  React.useEffect(() => {
    dispatch(profileHead())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout.Header className={Styles.header} id='headerContainer'>
      <div className={Styles.wrapper}>
        <Search />
        <div className={Styles.itemsWrapper}>
          {themeSwitcher && <ThemeSwitcher isHead={true} />}
          {!!withLanguageSwitcher && (
            <EISSelect
              className={Styles.language}
              bordered={false}
              size={'small'}
              options={languagesList}
              dropdownMatchSelectWidth={false}
              defaultValue={
                languagesList.find(
                  l => l.value === localStorage.getItem('language')
                )?.label
              }
              onChange={changeLanguage}
            />
          )}
          <div className={Styles.bref}>
            <EISDropdown menu={{ items: menuItems }} trigger={['click']}>
              <Space size={'small'}>
                <Avatar size={32} src={_.get(avatarUrl, 32)}>
                  {fullName[0]}
                </Avatar>
                <Tooltip
                  placement='bottom'
                  title={fullName}
                  mouseLeaveDelay={0}
                >
                  <Typography.Text className={Styles.fullName}>
                    {fullName}
                  </Typography.Text>
                </Tooltip>
                <DownOutlined
                  className={Styles.downLine}
                  translate={undefined}
                />
              </Space>
            </EISDropdown>
          </div>
          {isMobile() && <BurgerMenu />}
        </div>
      </div>
      <BreadcrumbList />
    </Layout.Header>
  )
}
