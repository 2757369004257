import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import _ from 'lodash'
import { ENotificationVariant } from '../../constants'
import UsersService from '../../services/users/users.service'
import { enqueueSnackbar } from '../notification/notificationSlice'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import local from './../../localization'
import { SorterResult } from 'antd/lib/table/interface'
import { TArrayColumnType } from '../../components/ViewTable/IViewTable'

const initialState: I.IUsersState = {
  users: [],
  value: undefined,
  isActive: undefined,
  onlineStatus: undefined,
  role: undefined,
  isMentor: undefined,
  isManager: undefined,
  page: 1,
  pageSize: 20,
  totalElements: 0,
  position: undefined,
  sortedInfo: undefined,
}

export const getUsers = createAsyncThunk(
  'users',
  async (data: I.IGetUsersRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(UsersService.getUsers(data))

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postUsersTableExport = createAsyncThunk(
  'users/table/export',
  async (data: I.IUserTableExport, { rejectWithValue, dispatch }) => {
    try {
      const fileName = data.fileName
      delete data.fileName
      await stackRequestWrapper(
        UsersService.postUsersTableExport(data, fileName!)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.export,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const editUser = createAsyncThunk(
  'users/edit',
  async (
    {
      id,
      fullName,
      email,
      isActive,
      phoneNumber,
      role,
      position,
      items,
    }: I.IEditUserRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        UsersService.postUser(
          id,
          fullName,
          email,
          isActive,
          phoneNumber,
          role,
          position,
          items
        )
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.users.SUCCESS.edited.replace(
            ':fullName',
            fullName
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const addUser = createAsyncThunk(
  'users/add',
  async (
    {
      fullName,
      email,
      isActive,
      phoneNumber,
      role,
      position,
      items,
    }: I.IAddUserRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        UsersService.putUser(
          fullName,
          email,
          isActive,
          phoneNumber,
          role,
          position,
          items
        )
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.users.SUCCESS.added.replace(
            ':fullName',
            fullName
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const deleteUser = createAsyncThunk(
  'users/delete',
  async ({ userId }: I.IDeleteUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(UsersService.deleteUser(userId))

      dispatch(
        enqueueSnackbar({
          message: local.notification.users.SUCCESS.deleted,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getRoles = createAsyncThunk(
  'users/roles',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(UsersService.getRoles())

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async ({ userId }: I.IResetUserRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        UsersService.resetPassword(userId)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.users.SUCCESS.resetPassword,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeEmail = createAsyncThunk(
  'users/changeEmail',
  async ({ body, callback }: I.IChangeEmailRequest, { dispatch }) => {
    try {
      await stackRequestWrapper(UsersService.changeEmail(body))

      dispatch(
        enqueueSnackbar({
          message: local.notification.users.SUCCESS.changeEmail,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      callback(false)
    } catch (e) {
      dispatch(processError({ e }))
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsersState: (state: I.IUsersState) => {
      _.assign(state, {
        users: initialState.users,
        totalElements: initialState.totalElements,
      })
    },
    setPage: (state: I.IUsersState, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setSize: (state: I.IUsersState, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setIsActive: (
      state: I.IUsersState,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isActive = action.payload
    },
    setOnlineStatus: (
      state: I.IUsersState,
      action: PayloadAction<string | undefined>
    ) => {
      state.onlineStatus = action.payload
    },    
    setPosition: (
      state: I.IUsersState,
      action: PayloadAction<string | undefined>
    ) => {
      state.position = action.payload
    },
    setRole: (
      state: I.IUsersState,
      action: PayloadAction<number | string | undefined>
    ) => {
      if (action.payload === 1) {
        state.role = action.payload
        state.isManager = undefined
        state.isMentor = undefined
      } else if (action.payload === 2) {
        state.role = action.payload
        state.isManager = false
        state.isMentor = false
      } else if (action.payload === 'isManager') {
        state.role = undefined
        state.isManager = true
        state.isMentor = undefined
      } else if (action.payload === 'isMentor') {
        state.role = undefined
        state.isManager = undefined
        state.isMentor = true
      } else {
        state.role = undefined
        state.isManager = undefined
        state.isMentor = undefined
      }
    },
    setValue: (
      state: I.IUsersState,
      action: PayloadAction<string | undefined>
    ) => {
      state.value = action.payload
    },
    setSortedInfo: (
      state: I.IUsersState,
      action: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      state.sortedInfo = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload?.data) {
        _.assign(state, {
          users: action.payload.data.content,
          totalElements: action.payload.data.totalElements,
        })
      }
    })
    builder.addCase(editUser.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.users = state.users.map(u => {
          if (u.id === action.payload?.data?.id) {
            return action.payload?.data
          } else {
            return u
          }
        })
      }
    })
  },
})

export const { reducer: usersReducer } = usersSlice

export const {
  clearUsersState,
  setPage,
  setSize,
  setIsActive,
  setOnlineStatus,
  setPosition,
  setRole,
  setValue,
  setSortedInfo,
} = usersSlice.actions

export const selectUsersState = (state: I.RootState) => state.users

export const selectUsersColumn = (state: I.RootState): Array<I.IUserColumn> =>
  state.users.users.map(u => ({
    key: u.id,
    fullName: u.fullName,
    avatarUrl: u.avatarUrl,
    phoneNumber: u.phoneNumber,
    email: u.email,
    position: u.position,
    items: u.items,
    role: u.role,
    progress: u.progress,
    createdTime: u.createdTime,
    lastAuthTime: u.lastAuthTime,
    isOnline: u.isOnline,
  }))
