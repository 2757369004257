import React, { useCallback, useMemo, useState } from 'react'
import { EISModal, EISSelect, setColorTypeButton } from '../../../EISComponents'
import style from './ExportModal.module.scss'
import local from '../../../../localization'
import EISCheckbox from '../../../EISComponents/EISCheckbox/EISCheckbox'
import { Button } from '@mui/material'
import { useAppDispatch } from '../../../../app/store'
import { IExportModal } from './IExportModal'

const ExportModal: React.FC<IExportModal> = ({
  columns,
  mainColumns,
  filters,
  fileName,
  exportMethod,
  modalState,
  handleChangeModalState,
  excludeFields,
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [selectedExportColumns, setSelectedExportColumns] = useState<string[]>(
    columns.map(c => String(c.key))
  )
  const [format, setFormat] = useState<string>('xls')

  const exporFormatData = [
    { label: '.xls', value: 'xls' },
    { label: '.pdf', value: 'pdf' },
  ]

  const changeViewTableColumns = useCallback(
    (key: string, value: boolean) => {
      if (value) {
        const newColumns = [
          ...selectedExportColumns!,
          columns.find(c => (c.key as string) === key)?.key! as string,
        ]
        setSelectedExportColumns(newColumns)
      } else {
        const newColumns = selectedExportColumns!.filter(c => c !== key)
        setSelectedExportColumns(newColumns)
      }
    },
    [columns, selectedExportColumns]
  )

  const changeExportColumnName = useCallback(
    (columnArray: string[], nameToChange: string, targetName: string) => {
      const changedColumnArray = columnArray.map(el =>
        el === nameToChange ? (el = targetName) : el
      )

      return changedColumnArray
        .filter(column => !excludeFields?.includes(column))
        .join()
    },
    [excludeFields]
  )

  const footer = useMemo(
    () => (
      <div className={style.footer}>
        <Button
          variant='outlined'
          onClick={() =>
            handleChangeModalState({
              modalStatus: false,
              isModalExport: false,
              title: '',
            })
          }
          className={`${setColorTypeButton('default')}`}
        >
          {local.users.addAndEditModal.cancelButton}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            const data = {
              format,
              fileName,
              ...filters,
              fields: changeExportColumnName(
                selectedExportColumns,
                'title',
                'node.title'
              ),
            }

            dispatch(exportMethod!(data))

            handleChangeModalState({
              modalStatus: false,
              isModalExport: false,
              title: '',
            })
          }}
          className={`${setColorTypeButton('success')}`}
          disabled={!format}
        >
          {local.users.addAndEditModal.saveButton}
        </Button>
      </div>
    ),
    [
      format,
      filters,
      fileName,
      selectedExportColumns,
      dispatch,
      exportMethod,
      handleChangeModalState,
      changeExportColumnName,
    ]
  )

  return (
    <EISModal
      open={modalState.modalStatus}
      width={400}
      onCancel={() =>
        handleChangeModalState({
          modalStatus: false,
          isModalExport: false,
          title: '',
        })
      }
      maskClosable
      footer={<></>}
    >
      <div className={style.modalContainer}>
        <p className={style.modalTitle}>{modalState.title}</p>

        <div className={style.dropdownContainer}>
          <span className={style.dropdownLabel}>
            {local.table.exportFormatTitle}
          </span>
          <EISSelect
            bordered
            size={'small'}
            options={exporFormatData}
            dropdownMatchSelectWidth={false}
            defaultValue={format}
            onChange={format => {
              setFormat(format)
            }}
          />
        </div>

        <span className={style.columnsLabel}>
          {local.table.exportColumnsTitle}
        </span>

        <div className={style.checkboxGroup}>
          {columns.map(
            c =>
              !!c.title && (
                <EISCheckbox
                  title={c.title as string}
                  key={c.key}
                  callback={(e: any) => {
                    changeViewTableColumns(c.key as string, e.target.checked)
                  }}
                  checked={selectedExportColumns!.includes(c.key as string)}
                  includeCheckedMark={false}
                  disabled={mainColumns.includes(c.key as string)}
                />
              )
          )}
        </div>

        {footer}
      </div>
    </EISModal>
  )
}

export default ExportModal
