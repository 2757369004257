import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import style from './ProfileInfo.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../app/store'
import {
  profiles,
  selectProfile,
  clearProfileState,
  changeAvatar,
} from '../../profileSlice'
import {
  EColorCard,
  getStyle,
} from './../../../../components/EISComponents/EISColorCard/EISColorCard.styles'
import ImageHolder from '../../../../components/ImageHolder/ImageHolder'
import _ from 'lodash'
import * as I from 'Types'
import local from './../../../../localization'
import { useHistory } from 'react-router-dom'
import { EPages } from '../../../../constants'
import {
  clearAvailavleTrackState,
  getAvailableTracks,
  selectAvaibleTracks,
} from '../../../tracks/available/tracksSlice'

export const defaultAvatar = (
  <svg
    className='profileDefaultImg'
    width='68'
    height='67'
    viewBox='0 0 68 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M34.3159 33.0544C37.8929 32.9676 40.8586 31.7625 43.213 29.4391C45.5674 27.1156 46.7886 24.1889 46.8765 20.659C46.7886 17.1291 45.5674 14.2024 43.213 11.8789C40.8586 9.5555 37.8929 8.35039 34.3159 8.26363C30.7389 8.35039 27.7732 9.5555 25.4187 11.8789C23.0643 14.2024 21.8431 17.1291 21.7552 20.659C21.8431 24.1889 23.0643 27.1156 25.4187 29.4391C27.7732 31.7625 30.7389 32.9676 34.3159 33.0544ZM34.3159 37.1862C29.5624 37.0567 25.6155 35.4425 22.4754 32.3437C19.3352 29.2449 17.6995 25.35 17.5683 20.659C17.6995 15.968 19.3352 12.0731 22.4754 8.97429C25.6155 5.87545 29.5624 4.2613 34.3159 4.13184C39.0694 4.2613 43.0162 5.87545 46.1564 8.97429C49.2965 12.0731 50.9322 15.968 51.0634 20.659C50.9322 25.35 49.2965 29.2449 46.1564 32.3437C43.0162 35.4425 39.0694 37.0567 34.3159 37.1862ZM55.2503 57.8451V51.6474C55.207 49.8831 54.5964 48.4198 53.4185 47.2574C52.2406 46.095 50.7577 45.4924 48.9699 45.4497H19.6618C17.874 45.4924 16.3911 46.095 15.2132 47.2574C14.0353 48.4198 13.4247 49.8831 13.3815 51.6474V57.8451C13.3815 58.4483 13.1854 58.9435 12.7932 59.3305C12.401 59.7175 11.8993 59.911 11.288 59.911C10.6767 59.911 10.175 59.7175 9.78284 59.3305C9.39067 58.9435 9.19458 58.4483 9.19458 57.8451V51.6474C9.2825 48.7207 10.3076 46.2892 12.2698 44.3527C14.2321 42.4163 16.6961 41.4047 19.6618 41.3179H48.9699C51.9357 41.4047 54.3996 42.4163 56.3619 44.3527C58.3241 46.2892 59.3492 48.7207 59.4371 51.6474V57.8451C59.4371 58.4483 59.2411 58.9435 58.8489 59.3305C58.4567 59.7175 57.955 59.911 57.3437 59.911C56.7324 59.911 56.2307 59.7175 55.8385 59.3305C55.4464 58.9435 55.2503 58.4483 55.2503 57.8451Z'
      fill='currentColor'
    />
  </svg>
)

const maxCountTracksShow = 5

const ProfileInfo: React.FC<I.IProfileProps> = ({ userId }): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {
    items,
    fullName,
    position,
    progress,
    tasksCompleted,
    avatarUrl,
  } = useSelector(selectProfile)
  const { isCompleted, title, tracks, page } = useSelector(selectAvaibleTracks)

  const uploadAvatar = (file: File) => {
    dispatch(changeAvatar({ file }))
  }

  const moveToPage = React.useCallback(
    (page: EPages) => () => history.push(page),
    [history]
  )

  const handleClickTrack = (trackId?: number) => {
    !!trackId &&
      history.push(
        EPages.AVAIBLE_TRACKS_NODES.replace(':trackId', `${trackId}`)
      )
  }

  const handleClickTrackCounter = () => {
    history.push(EPages.AVAIBLE_TRACKS)
  }

  useEffect(() => {
    dispatch(profiles({ userId }))
    dispatch(
      getAvailableTracks({
        page: 0,
        size: 200,
        title,
        isCompleted,
      })
    )

    return () => {
      dispatch(clearAvailavleTrackState())
      dispatch(clearProfileState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const tracksKeyValuePairs = Object.entries(items)

  return (
    <>
      <Row className={style.profile} gutter={24}>
        <Col className={'gutter-row ' + style.user} span={14}>
          <ImageHolder
            uploadImage={uploadAvatar}
            src={_.get(avatarUrl, 128)}
            height={100}
            width={100}
            isAvatar={true}
            isAlianAvatar={!!userId}
            defaultImage={defaultAvatar}
            withSelfie={true}
          />
          <div className={style.userInfo}>
            <div className={style.name}>{fullName}</div>
            {!!position.length && (
              <div className={style.position}>
                <b>{`${local.profile.info.position}: `}</b>
                {position}
              </div>
            )}
            <div className={style.items}>
              <b>{`${local.profile.info.items}: `}</b>
              {!userId && !!tracks.length ? (
                tracksKeyValuePairs
                  .slice(
                    0,
                    tracksKeyValuePairs.length > maxCountTracksShow
                      ? maxCountTracksShow
                      : tracksKeyValuePairs.length
                  )
                  .sort((a, b) =>
                    a[1].toLowerCase() > b[1].toLowerCase() ? 1 : -1
                  )
                  .map((item, index) => {
                    return (
                      <span
                        key={item[0]}
                        className={
                          index === 4 ? style.lastItemLink : style.itemLink
                        }
                        onClick={() => handleClickTrack(Number(item[0]))}
                      >
                        {item[1]}
                      </span>
                    )
                  })
              ) : (
                <span>
                  {tracksKeyValuePairs
                    ?.slice(
                      0,
                      tracksKeyValuePairs.length > maxCountTracksShow
                        ? maxCountTracksShow
                        : tracksKeyValuePairs.length
                    )
                    .map(item => item[1])
                    .join(', ')}
                </span>
              )}
              {tracksKeyValuePairs.length > maxCountTracksShow && (
                <span
                  className={style.trackCounter}
                  onClick={handleClickTrackCounter}
                >{` +${tracksKeyValuePairs.length - maxCountTracksShow}`}</span>
              )}
            </div>
          </div>
        </Col>
        <Col className={'gutter-row ' + style.statContainer} span={10}>
          <div
            onClick={moveToPage(EPages.AVAIBLE_TRACKS)}
            className={`${style.statBlock} ${style.link} ${getStyle(
              EColorCard.AVAILABLE
            )}`}
          >
            <p className={style.statValue}>{tracksKeyValuePairs.length}</p>
            <p
              className={style.statName}
            >{`${local.profile.info.availableItems}`}</p>
          </div>
          <div className={`${style.statBlock} ${getStyle(EColorCard.DEFAULT)}`}>
            <p className={style.statValue}>{progress + '%'}</p>
            <p className={style.statName}>{`${local.profile.info.progress}`}</p>
          </div>
          <div
            className={`${style.statBlock} ${getStyle(
              EColorCard.COMPLETED,
              tasksCompleted
            )}`}
          >
            <p className={style.statValue}>{tasksCompleted}</p>
            <p
              className={style.statName}
            >{`${local.profile.info.tasksCompleted}`}</p>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProfileInfo
