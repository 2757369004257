export const items = {
  columns: {
    title: 'Name',
    description: 'Description',
    embeddedIn: 'Embedded in the folder',
    managers: 'Map managers',
    mentors: 'Mentors',
    users: 'Users',
    isActive: {
      title: 'Active',
      true: 'Yes',
      false: 'No',
      isBlocked: 'Inactive map',
    },
    createdTime: 'Сreation date',
    updatedTime: 'Updated date',
    action: {
      title: 'Action',
      editButton: 'Change',
      deleteButton: 'Delete',
    },
  },
  emptyData: {
    noData: 'No maps added',
    noFilterItems: 'No data matching the selected filters',
  },
  filter: {
    item: 'Select a map',
    withMentor: 'With a mentor',
    isActive: 'Active',
  },
  addItemButton: 'Add Map',
  deleteItemModal: {
    title: 'map',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Active',
      disable: 'Inactive',
    },
    title: {
      add: 'Add the Map',
      edit: 'Edit the Map',
      delete: 'Delete the Map',
      export: 'Export the Map',
    },
    cancelText: 'Cancel',
    okText: 'Save',
    back: 'Back',
    addGroup: 'Add Group',
    divideGroup: 'Divide by groups',
    groupsTitle: 'Form Mentor(s) - User(s) groups to check tasks',
    formItem: {
      title: {
        label: 'Map name',
        placeholder: 'Enter map name',
      },
      description: {
        label: 'Map description',
        placeholder: 'Enter description',
      },
      status: {
        label: 'Map status',
        placeholder: 'Active / Inactive',
        isBlocked: 'Inactive map',
      },
      managers: {
        filterInputLabel: 'Manager',
        label: 'Map managers',
        placeholder: 'Select map managers',
      },
      mentors: {
        filterInputLabel: 'Mentor',
        label: 'Mentors',
        placeholder: 'Select mentors',
      },
      users: {
        filterInputLabel: 'User',
        label: 'Users',
        placeholder: 'Select users',
      },
      folder: {
        label: 'Folder',
        placeholder: 'Select a folder',
      },
      isDefault: {
        label: 'Default map',
        placeholder:
          'If this setting is active, this Map will be automatically assigned to all new users',
      },
      isShadowMode: {
        label: '"Shadow Map" Mode',
        placeholder:
          'When the mode is activated, the user will see the entire map without inactive steps (colored gray).',
      },
    },
    disableItemModal: {
      title: 'Map locking',
      content:
        'Are you sure you want to make this map inactive? All users who this map has been assigned to (including the ones assigned according to their role) will lose the progress of completing tasks on it. Also, this map will be disconnected from all roles which it was assigned to. All mentors and map managers assigned to the map will also be disconnected from it.',
      okText: 'Yes',
      cancelText: 'No',
    },
  },
}
