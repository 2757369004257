import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import TracksService from '../../../services/tracks/tracks.service'

const initialState: I.IAvailableTrackState = {
  title: undefined,
  isCompleted: undefined,
  tracks: [],
  page: 0,
  isLast: true,
}

export const getAvailableTracks = createAsyncThunk(
  'tracks/available',
  async (data: I.IGetAvailableTracksRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TracksService.getAvailableTracks(data)
      )

      return { result }
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getNextAvailableTracks = createAsyncThunk(
  'tracks/available/next',
  async (data: I.IGetAvailableTracksRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TracksService.getAvailableTracks(data)
      )

      return { result }
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const availableTrackSlice = createSlice({
  name: 'availableTrack',
  initialState,
  reducers: {
    clearAvailavleTrackState: state => {
      _.assign(state, initialState)
    },
  },
  extraReducers: builder => {
    builder.addCase(getAvailableTracks.fulfilled, (state, action) => {
      if (action.payload?.result.data) {
        _.assign(state, {
          tracks: action.payload.result.data.content,
          isLast: action.payload.result.data.last,
        })
      }
    })
    builder.addCase(getNextAvailableTracks.fulfilled, (state, action) => {
      if (action.payload?.result.data) {
        _.assign(state, {
          page: action.payload.result.data.pageable.pageNumber,
          isLast: action.payload.result.data.last,
          tracks: action.payload.result.data.first
            ? action.payload.result.data.content
            : _.uniqBy(
                [...state.tracks, ...action.payload.result.data.content],
                t => t.id
              ),
        })
      }
    })
    builder.addCase(getNextAvailableTracks.rejected, state => {
      state.isLast = true
    })
  },
})

export const selectAvaibleTracks = (state: I.RootState) => state.tracks.avaible
export const selectTrack = (state: I.RootState, trackId: number) =>
  state.tracks.avaible.tracks.find(t => t.id === trackId)
export const { clearAvailavleTrackState } = availableTrackSlice.actions
export const { reducer: availableTrackReducer } = availableTrackSlice
