import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Image } from 'antd'
import { ValidationHelper } from '../../Validation'
import { useFormField } from '../../../../hooks/useFormField'
import Styles from './RecoveryPassword.module.scss'
import { recoveryPassword, selectStatusRestore } from '../recoveryPasswordSlice'
import { useAppDispatch } from '../../../../app/store'
import { useSelector } from 'react-redux'
import { EStateStatus } from '../../../../constants'
import local from './../../../../localization'
import { ThemeSwitcher } from '../../../../components/ThemeSwitcher/ThemeSwitcher'
import { TextField, Typography, Button } from '@mui/material'
import { setColorTypeButton } from '../../../../components/EISComponents'
import { authValue } from '../../login/authSlice'
import { ReactComponent as BackArrow } from '../../../../assets/svg/arrow_back_icon.svg'
import { useTheme } from '../../../../theme/Theme'

const themeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER) || false // 0 - false, 1 - true

const RecoveryPassword = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { themeProps } = useTheme()
  const { usernameValue } = useSelector(authValue)
  const [username, setUsername] = useFormField()
  const status = useSelector(selectStatusRestore)

  const formOnKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onResetPasswordHandler()
    }
  }

  const onChangeLoginHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validateEmail(e.target.value)
      setUsername(result)
    },
    [setUsername]
  )

  const onResetPasswordHandler = (): void => {
    if (username.valid) {
      dispatch(recoveryPassword(username.value))
    } else {
      const usernameResult = ValidationHelper.validateEmail(username.value)
      setUsername(usernameResult)
    }
  }

  React.useEffect(() => {
    !!usernameValue &&
      setUsername(ValidationHelper.validateEmail(usernameValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernameValue])

  return (
    <div onKeyPress={formOnKeyPressed} className={Styles.container}>
      {themeSwitcher && <ThemeSwitcher />}
      <div className={Styles.wrapper}>
        <div
          className={Styles.wrapperLogo}
          style={{
            background: 'var(--main-logo) no-repeat left top / contain',
          }}
        ></div>
        {status !== EStateStatus.FULFILLED ? (
          <Form name='basic'>
            <Typography
              className={Styles.MuiTypographySubtitle}
              variant='subtitle1'
              gutterBottom
              component='div'
            >
              {local.recoveryPassword.title}
            </Typography>
            <Form.Item
              name='username'
              className={Styles.formItem}
              help={username.message}
              validateStatus={username.message.length > 0 ? 'error' : ''}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                name={username.value}
                autoComplete={'username'}
                className={Styles.inputForm}
                onChange={onChangeLoginHandler}
                data-testid={'username'}
                label={local.recoveryPassword.form.username}
                variant='filled'
                id='filled-basic'
                autoFocus={true}
                inputProps={{ maxLength: 100 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                variant='contained'
                className={setColorTypeButton('success')}
                onClick={onResetPasswordHandler}
                disabled={!username.value.length}
              >
                {local.recoveryPassword.recoveryButton}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className={Styles.resultContainer}>
            <p className={Styles.info}>
              {local.recoveryPassword.recoveryResult.info}
            </p>
            <p className={Styles.ps}>
              {local.recoveryPassword.recoveryResult.ps}
            </p>
          </div>
        )}
        <Link to='/signin' className={Styles.backTo}>
          <BackArrow />
          <span>{local.recoveryPassword.backToSignInLink}</span>
        </Link>
      </div>
      <div className={Styles.img_side}>
        <Image src={themeProps['--auth-background-image']} preview={false} />
      </div>
    </div>
  )
}

export default RecoveryPassword
