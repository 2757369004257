import { ICurve, IDiagramContent, IShape, ITrackEnginePageConfig } from 'Types'
import { EStorageName } from '../constants/tracks-editor/common'

export class LocalStorageUtils {
  // Common -------------------------------------------------------------------
  static storeValue = (key: string, value: object): void => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static restoreValue = <T>(
    key: string,
    defaultValue: T | null = null
  ): T | null => {
    try {
      const rawValue = localStorage.getItem(key)
      if (!rawValue) {
        return defaultValue
      }

      return JSON.parse(rawValue) as T
    } catch (error) {
      return defaultValue
    }
  }

  static clearValue = (key: string): void => {
    localStorage.removeItem(key)
  }

  // Custom -------------------------------------------------------------------
  static storeDiagram = (
    shapes: Record<string, IShape | ICurve>,
    content: Record<string, IDiagramContent>,
    page: ITrackEnginePageConfig
  ): void => {
    LocalStorageUtils.storeValue(EStorageName.SHAPES, shapes)
    LocalStorageUtils.storeValue(EStorageName.CONTENT, content)
    LocalStorageUtils.storeValue(EStorageName.PAGE, page)
  }

  static restoreDiagram = () => {
    const shapes = LocalStorageUtils.restoreValue<
      Record<string, IShape | ICurve>
    >(EStorageName.SHAPES)

    if (!shapes) {
      return null
    }

    const content = LocalStorageUtils.restoreValue<
      Record<string, IDiagramContent>
    >(EStorageName.CONTENT)

    const page = LocalStorageUtils.restoreValue<ITrackEnginePageConfig>(
      EStorageName.PAGE
    )

    return {
      shapes,
      content,
      page,
    }
  }
}
