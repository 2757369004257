export const login = {
  form: {
    login: 'Логин (e-mail)',
    password: 'Пароль',
  },
  title: 'Добро пожаловать',
  loginButton: 'Войти',
  instructionButton: 'Инструкция для входа',
  checkbox: 'Работать только в этой вкладке',
  restoreLink: 'Восстановить пароль',
  signupLink: 'Создать аккаунт',
}
