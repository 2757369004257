import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { EAPIEndpoint } from '../constants'

export default class ProfileService {
  static getProfileInfo(
    data: I.IProfileProps
  ): Observable<IAPIResult<I.IProfileInfo>> {
    const baseApiService = new BaseApiService<I.IProfileInfo>()
    return baseApiService.getRequest(
      data.userId
        ? EAPIEndpoint.ALIEN_INFO.replace(':userId', data.userId.toString())
        : EAPIEndpoint.PROFILE_INFO
    )
  }

  static getProfileLastActions(
    data: I.IGetProfileLastActionsRequest
  ): Observable<IAPIResult<I.IProfileLastActionsResponse>> {
    const baseApiService = new BaseApiService<I.IProfileLastActionsResponse>()
    return baseApiService.getRequest(
      data.userId
        ? `${EAPIEndpoint.ALIEN_LAST_ACTIONS.replace(
            ':userId',
            data.userId.toString()
          )}${getParams({
            page: data.page,
            size: data.size,
          })}`
        : `${EAPIEndpoint.PROFILE_LAST_ACTIONS}${getParams({
            page: data.page,
            size: data.size,
          })}`
    )
  }

  static getProfileStatTracks(
    data: I.IGetProfileStatRequest
  ): Observable<IAPIResult<I.IProfileStatTracksResponse>> {
    const baseApiService = new BaseApiService<I.IProfileStatTracksResponse>()
    return baseApiService.getRequest(
      data.userId
        ? `${EAPIEndpoint.ALIAN_STAT_TRACKS.replace(
            ':userId',
            data.userId.toString()
          )}${getParams({
            trackId: data.trackId,
            rangeDate: data.rangeDate,
          })}`
        : `${EAPIEndpoint.PROFILE_STAT_TRACKS}${getParams({
            trackId: data.trackId,
            rangeDate: data.rangeDate,
          })}`
    )
  }

  static getProfileStatTasks(
    data: I.IGetProfileStatRequest
  ): Observable<IAPIResult<I.IProfileStatTasksResponse>> {
    const baseApiService = new BaseApiService<I.IProfileStatTasksResponse>()
    return baseApiService.getRequest(
      data.userId
        ? `${EAPIEndpoint.ALIAN_STAT_TASKS.replace(
            ':userId',
            data.userId.toString()
          )}${getParams({
            page: data.page,
            size: data.size,
            sort: data.sort,
            trackId: data.trackId,
            rangeDate: data.rangeDate,
          })}`
        : `${EAPIEndpoint.PROFILE_STAT_TASKS}${getParams({
            page: data.page,
            size: data.size,
            sort: data.sort,
            trackId: data.trackId,
            rangeDate: data.rangeDate,
          })}`
    )
  }

  static getProfileHead(): Observable<IAPIResult<I.IProfileHead>> {
    const baseApiService = new BaseApiService<I.IProfileHead>()
    return baseApiService.getRequest(EAPIEndpoint.BREIF_INFO)
  }

  static changeAvatar(
    body: I.IChangeAvatarRequest
  ): Observable<IAPIResult<I.IFileResponse>> {
    const baseApiService = new BaseApiService<I.IFileResponse>()
    return baseApiService.postImportFileRequest(
      EAPIEndpoint.CHANGE_AVATAR,
      body.file,
      undefined,
      {},
      body.username
    )
  }
}
