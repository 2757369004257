export const registration = {
  agreementPersonDataTitle: 'Kişisel veri işleme onayı',
  agreementUserTitle: 'Kullanıcı sözleşmesi',
  sublicenseTitle: 'Alt lisans sözleşmesi',
  form: {
    fullName: 'Ad-Soyad',
    username: 'Giriş Yap (e-posta)',
    phoneNumber: 'Telefon numarası',
    phoneNumberSearchPlaceholder: 'Arama',
    password: 'Şifre',
    passwordConfirm: 'Şifre doğrulama',
    agreement: '"Kaydol" düğmesine tıklayarak şartları kabul etmiş olursunuz ',
    and: ' ve ',
  },
  title: 'Kayıt',
  registrationResult: {
    result: 'Kayıt tamamlandı',
    info: 'Hesabınızı onaylamak için e-postaya gönderilen bağlantıyı tıklayın',
    ps: 'Bu sekmeyi kapatabilirsiniz',
  },
  registrationButton: 'Kaydol',
  backToSignInLink: 'Ana Sayfaya Dön',
}
