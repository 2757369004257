export const video = {
  record: {
    title: 'Kaydet',
    press: 'BAŞLATMAK İÇİN BUTONA TIKLAYIN',
    rec: 'KAYIT',
    when_ready: 'VİDEO',
    another_video: 'Başka bir video kaydet',
    upload: 'Video yükle',
    blockedUpload: 'Bu soruda yalnızca çevrimiçi video yanıtı kaydı mevcuttur',
    uploadValidFormats: 'Yüklenen videonun kabul edilebilir formatı - mov, mp4',
    record_video: 'Bir video kaydedin',
    cameraOn: 'Kameramı AÇ',
    loadButton: 'Bir video yükleyin',
    cancelButton: 'Kaldır',
    sendButton: 'Videoyu gönderin',
    loading: 'Yükleniyor...',
    errorView:
      'Tarayıcınız videonuzu kaydedemedi. Lütfen yeniden başlatın ve tekrar deneyin',
    unsupportedView: 'Bu tarayıcı video kaydını desteklemiyor',
  },
  videoUrl: {
    urlButton: 'Bağlantı ekle',
    urlTitle: 'Videoya bağlantı',
    urlPlaceholder: 'Video için bağlantı sağlayın',
    okBtn: 'Ekle',
    cancelBtn: 'İptal',
  },
  view: {
    title: 'Görüntüle',
    resetVideoButton: 'Yeni bir video oluştur',
    resetVideoModal: {
      title: 'Mevcut videoyu sil?',
      content: 'Yeni bir video oluşturmak için mevcut videoyu silmeniz gerekir',
      okText: 'Onayla',
      cancelText: 'Kaldır',
    },
    videoUndefined: 'Video kullanılamıyor!',
  },
}
