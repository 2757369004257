export const confirmedPassword = {
  form: {
    password: 'Yeni şifre',
    passwordConfirm: 'Şifre doğrulama',
  },
  confirmedButton: 'Onayla',
  backToSignInLink: 'Oturum Açma Sayfasına Geri Dön',
  notification: {
    passwordChanged: 'Şifre değişmış',
  },
}
