import { itc } from './themes/itc'
import { winwin } from './themes/winwin'
import { dark } from './themes/dark'
import { smart } from './themes/smart'
import { umentor } from './themes/umentor'

export type TTheme = 'itc' | 'winwin' | 'dark' | 'smart' | 'umentor'

export interface IThemeProps {
  /**Font */
  '--font-family': string
  /**Logo - mini */
  '--mini-logo': string
  /**Logo*/
  '--main-logo': string
  /**Background of Available Tracks page */
  '--background-image': string
  /**Background on authorization pages */
  '--auth-background-image': string
  /**Default card picture */
  '--default-card-image': string
  /**Main theme color */
  '--main-theme-color': string
  /**Light variant of the theme */
  '--light-main-theme-color': string
  /**Main text color */
  '--main-text-color': string
  /**Opposite to the main text color */
  '--reverse-text-color': string
  /**Secondary text color */
  '--secondary-text-color': string
  /**Icon color */
  '--icon-color': string
  /**Separating line */
  '--divider-color': string
  /**Main background color */
  '--main-background-color': string
  /**Background color of a group of elements */
  '--secondary-background-color': string
  /**Additional color for Status not available or for general statics blocks */
  '--first-complementary-color': string
  /**Additional color for Status is available, for statistical blocks, for expanded tasks icon*/
  '--second-complementary-color': string
  /**Additional color for Status pending check, for statics blocks, for the Test Jobs icon, for the Late Date*/
  '--third-complementary-color': string
  /**Additional color for the Checked/Executed status, for the Statsics block, for the icon Unchecked, for the due date*/
  '--fourth-complementary-color': string
  /**Additional color for Status rejected/blocked, for staticsics blocks, for the Video Question icon, for the overdue date*/
  '--fifth-complementary-color': string
  /**Additional color for statistics blocks*/
  '--sixth-complementary-color': string
  /**Additional color for statistics blocks*/
  '--seventh-complementary-color': string
  /**Additional color for statistics blocks*/
  '--eighth-complementary-color': string
  /**Additional color for statics blocks, or additional background*/
  '--default-complementary-color': string
  /**Colors for the gradient */
  '--gradient-colors': string[]
}

export const themes: Record<TTheme, IThemeProps> = {
  itc: itc,
  winwin: winwin,
  dark: dark,
  smart: smart,
  umentor: umentor,
}

export const themeList: Array<{ label: string; value: string }> = [
  { value: 'itc', label: 'itc' },
  { value: 'winwin', label: 'winwin' },
  { value: 'smart', label: 'smart' },
  { value: 'umentor', label: 'uMentor' },
  // { value: 'dark', label: 'dark' },
]
