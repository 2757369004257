export enum EShapeStates {
  CALMNESS = 'calmness',
  SHAPES_SELECTED = 'shapeSelected',
  RESIZING_CIRCLE = 'resizingCircle',
  CREATING = 'creating',
  CREATING_CURVE = 'creatingCurve',
  TITLE_EDIT = 'titleEdit',
  SELECTING = 'selecting',
}

export enum EShapeEvents {
  ON_MOUSE_DOWN = 'onMouseDown',
  ON_MOUSE_UP = 'onMouseUp',
  ON_MOUSE_MOVE = 'onMouseMove',
  ON_CLICK_CREATE = 'onClickCreate',
  ON_CLICK_EDIT_TITLE = 'onClickEditTitle',
  ON_EDIT_TITLE_COMPLETE = 'onEditTitleComplete',
  ON_PRESS_ESC = 'onPressESC',
}
