import { IThemeProps } from '..'

import logo_mini from '../../assets/svg/smart_mini.svg'
import logo from '../../assets/svg/smart.svg'
import auth_bg_img from '../../assets/svg/smart_bg.svg'
import card_img from '../../assets/svg/smart_img.svg'

export const smart: IThemeProps = {
  '--font-family': `'Raleway', 'Roboto', sans-serif`,
  '--mini-logo': `url('${logo_mini}')`,
  '--main-logo': `url('${logo}')`,
  '--background-image': ``,
  '--auth-background-image': auth_bg_img,
  '--default-card-image': card_img,
  '--main-theme-color': '#145E80',
  '--light-main-theme-color': '#A7CDE0',
  '--main-text-color': '#111340',
  '--reverse-text-color': '#FFFFFF',
  '--secondary-text-color': '#969FA2',
  '--icon-color': '#1C212D',
  '--divider-color': '#CED5D4',
  '--main-background-color': '#FFFFFF',
  '--secondary-background-color': '#F4F4F4',
  '--first-complementary-color': '#E2E2E2',
  '--second-complementary-color': '#535C99',
  '--third-complementary-color': '#DDAE33',
  '--fourth-complementary-color': '#109048',
  '--fifth-complementary-color': '#CA3C17',
  '--sixth-complementary-color': '#8861CB',
  '--seventh-complementary-color': '#C06B24',
  '--eighth-complementary-color': '#D94F78',
  '--default-complementary-color': '#646B73',
  '--gradient-colors': ['#FFB596', '#FFD15C', '#E3FF93', '#65C280'],
}
