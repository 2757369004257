export const main = {
  returnToMain: 'Перейти к главной странице',
  maintenanceMode: {
    title: 'На сайте производятся технические работы.',
    subtitle: 'Приносим свои извинения!',
  },
  breadcrumb: {
    home: 'Главная',
    profile: 'Профиль',
    avaibleTracks: 'Доступные треки',
    tracksManagement: 'Управление треками',
    checkTasks: 'Проверка заданий',
    users: 'Пользователи',
    positions: 'Должности',
    items: 'Треки',
    reference: 'Справка',
    statsByUsers: 'По пользователям',
    statsByUserForItem: 'Прогресс пользователя :UserName по треку «:TrackName»',
    statsByItems: 'По трекам',
    profileSettings: 'Настройки',
    usersProfile: 'Профиль пользователя :UserName',
    notFound: 'Страница не найдена',
    stat: 'Статистика',
  },
  auth: {
    signIn: 'Вход',
    signUp: 'Регистрация',
    restore: 'Восстановление пароля',
    newPassword: 'Смена пароля',
  },
  headMenu: {
    profileSettings: 'Настройки',
    reference: 'Справка',
    exit: 'Выход',
    language: 'Язык',
    theme: 'Тема',
    bonus: 'Бонусы для партнеров',
    bonusNotify: 'Станьте партнером WinWin и получайте пассивный доход!',
  },
  search: {
    placeholder: 'Введите поисковый запрос',
    button: 'Искать',
    searchIn: 'Искать в:',
    inUnavailableNodes: 'Недоступных модулях',
    inTrackTitle: 'Названиях трека',
    inNodeTitle: 'Названиях модуля',
    inNodeTopic: 'Содержании модуля',
    inQuestionsText: 'Тексте вопросов',
    trackTitle: 'Название трека:',
    nodeTitle: 'Название модуля:',
    nodeTopic: 'Содержание модуля:',
    questionsText: 'Текст вопроса:',
    noMatch: 'Нет совпадений',
    inManagerNodes: 'Управлении треками',
    inAvailableNodes: 'Доступных треках',
    visibleOffTooltip: 'Модуль в статусе "Недоступно"',
    editTooltip: 'Переход в редактирование трека (модуля)',
  },
  menu: {
    collapse: 'Свернуть',
    expand: 'Развернуть',
  },
  PDFViewer: {
    page: 'Страница',
    of: 'из',
    loading: 'Загрузка PDF ...',
    failedLoad: 'Не удалось загрузить файл PDF',
  },
  task: 'Задание',
  postSolution: 'Отправленное решение',
  momentLanguage: 'ru',
  editorLanguage: 'ru',
  acceptLanguage: 'ru-RU',
  editorPlaceholder: {
    default: 'Напишите что-нибудь',
    editModule: 'Введите описание',
  },
  validation: {
    requiredField: 'Обязательное поле',
    fullNameLength: 'Не менее 3-х символов',
    emailValid: 'Логин должен быть корректным email адресом',
    phoneNumberValid: 'Номер телефона должен быть корректным',
    passwordValidTitle: 'Пароль должен содержать:',
    passwordLength: 'Пароль не может быть менее 8 символов',
    passwordWithoutWhitespace: 'Пробельные символы не допустимы',
    latinLettersOnly: 'Допустимы только латинские заглавные и строчные буквы',
    upperLowerCaseLetters:
      'Пароль должен содержать латинские строчные или заглавные буквы',
    includeSpecialSymbols:
      'Должен содержать специальные символы (!@#$%^&* и др.)',
    includeDigits: 'Должен содержать 1 или более цифру',
    includeDigitsOrSymbols:
      'Должен содержать хотя бы 1 цифру (0-9) или специальныЙ символ (!@#$%^&* и др.)',
    passwordMustBeDifferent: 'Новый пароль должен отличаться от текущего',
    passwordConfirmValid: 'Пароли не совпадают',
    emailConfirmValid: 'Email не совпадает',
  },
  passwordStrength: {
    mainTitle: 'Уровень защиты',
    weakPassword: 'низкий',
    middlePassword: 'средний',
    hardPassword: 'высокий',
  },
  periods: {
    currentWeek: 'на этой неделе',
    lastWeek: 'на прошлой неделе',
    currentMonth: 'в этом месяце',
    lastMonth: 'в прошлом месяце',
    currentYear: 'в этом году',
    allTime: 'за весь период',
  },
}
