import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  FC,
  useMemo,
} from 'react'
import IconButton from '@mui/material/IconButton'
import ReplayIcon from '@mui/icons-material/Replay'
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import SaveIcon from '@mui/icons-material/Save'
import Styles from './Camera.module.scss'
import * as I from './ICamera'
import GetUserMedia from '../utils/getUserMedia'
import { Tooltip } from 'antd'
import local from '../../../../localization'

const CAPTURE_OPTIONS_FRONT = {
  audio: false,
  video: { facingMode: 'user' },
}
const CAPTURE_OPTIONS_REAR = {
  audio: false,
  video: { facingMode: 'environment' },
}

const x = 0
const y = 0

const Camera: FC<I.ICamera> = ({ onCapture }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true)
  const [captureOptions, setCaptureOptins] = useState(true)
  const [isTooltipChange, setIsTooltipChange] = useState(false)

  const mediaStream = GetUserMedia(
    captureOptions ? CAPTURE_OPTIONS_FRONT : CAPTURE_OPTIONS_REAR
  )

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream
    }
  }, [mediaStream])

  const handleCanPlay = () => {
    setIsVideoPlaying(true)
    videoRef.current! && videoRef.current.play()
  }

  const handleCaptureOrClear = useCallback(
    (isClear: boolean) => () => {
      const context = canvasRef.current!.getContext('2d')
      context!.drawImage(
        videoRef.current!,
        x,
        y,
        videoRef.current!.videoWidth,
        videoRef.current!.videoHeight
      )

      setIsCanvasEmpty(isClear)
      setIsTooltipChange(!isTooltipChange)
    },
    [isTooltipChange]
  )

  const handleSwitchCamera = () => {
    setCaptureOptins(!captureOptions)
  }

  const handleSendSelfie = useCallback(() => {
    canvasRef.current?.toBlob(blob => onCapture(blob), 'image/jpeg', 1)
    handleCaptureOrClear(true)
  }, [handleCaptureOrClear, onCapture])

  const ShowTakePictureBtn = useMemo(() => {
    return (
      <Tooltip
        open={isTooltipChange}
        onOpenChange={e => setIsTooltipChange(e)}
        title={local.selfie.camera.tooltip.takePicture}
      >
        <IconButton
          onClick={handleCaptureOrClear(false)}
          className={Styles.iconButton}
        >
          <CenterFocusStrongIcon />
        </IconButton>
      </Tooltip>
    )
  }, [handleCaptureOrClear, isTooltipChange])

  const ShowTakeNewPictureBtn = useMemo(() => {
    return (
      <Tooltip
        open={isTooltipChange}
        onOpenChange={e => setIsTooltipChange(e)}
        title={local.selfie.camera.tooltip.takeNewPicture}
      >
        <IconButton
          onClick={handleCaptureOrClear(true)}
          className={Styles.iconButton}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    )
  }, [handleCaptureOrClear, isTooltipChange])

  return (
    <div className={Styles.wrapper}>
      <Tooltip title={local.selfie.camera.tooltip.cameraSwitch}>
        <IconButton
          onClick={handleSwitchCamera}
          className={Styles.switchCamera}
        >
          <CameraswitchIcon />
        </IconButton>
      </Tooltip>

      <div
        className={Styles.container}
        style={{
          maxHeight: !videoRef.current?.videoHeight
            ? '289px'
            : videoRef.current.videoHeight,
          maxWidth: !videoRef.current?.videoWidth
            ? '100%'
            : videoRef.current.videoWidth,
        }}
      >
        <video
          ref={videoRef}
          onCanPlay={handleCanPlay}
          hidden={!isVideoPlaying}
          autoPlay
          playsInline
          muted
          className={Styles.video}
        />
        <div className={Styles.overlay}></div>
        <canvas
          ref={canvasRef}
          className={Styles.canvas}
          width={640}
          hidden={isCanvasEmpty}
          height={480}
        />
      </div>
      {isVideoPlaying && (
        <div className={Styles.videoPlaying}>
          {isCanvasEmpty ? ShowTakePictureBtn : ShowTakeNewPictureBtn}
          {!isCanvasEmpty && (
            <Tooltip title={local.selfie.camera.tooltip.saveImg}>
              <IconButton
                onClick={handleSendSelfie}
                className={Styles.iconButton}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default Camera
