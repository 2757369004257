export const notification = {
  general: {
    DEFAULT: {},
    ERROR: {
      retrievingList: 'Liste alınırken bir hata oluştu!',
      corruptOrEmptyFile: 'Dosya bozuk veya boş',
      unknownKey: 'Bilinmeyen anahtar: :StateKey',
      unknownValue: 'Nesnede bilinmeyen özellikler mevcut: :Value',
      wrongArgument: 'Geçersiz argüman alındı: :Argument',
      accidentalError: 'Yanlışlıkla oluşan hata',
      unknownError: 'Bilinmeyen hata',
      unknownAthorities: 'Bilinmeyen izinler alındı',
      wrongResponse: 'Geçersiz veya uyumsuz yanıt alındı',
      token: 'Jeton ayrıştırılırken hata oluştu',
      connectionError: 'Sunucuya bağlanırken hata oluştu',
      sessionExpired: 'Oturum sona erdi!',
      notAuthorities: 'Bu işlemi gerçekleştirme izniniz yok!',
      userLocked: 'Bu hesap kilitlenmiştir',
      wrongLoginPassword: 'Geçersiz kullanıcı adı veya şifre!',
      userUndefined: 'Bu e-posta adresine sahip bir kullanıcı bulunamadı',
      corruptOrEmpty: 'Dosya bozuk veya boş',
      message: 'Kullanıcı hesabı kilitlendi',
      tokenExpired: 'Yenileme jetonunun süresi doldu.',
    },
    SUCCESS: {
      file: 'Dosya',
      uploadedSuccessfully: 'başarıyla yüklendi',
    },
    WARNING: {
      awaitThenUploaded: 'Mevcut dosyanın indirilmesini bekleyin',
    },
    INFO: {},
  },
  task: {
    DEFAULT: {},
    ERROR: {
      taskNotFoundId: 'Görev bulunamadı: ID=:Index',
      topicNotFound: '"Modül Açıklaması" alanı boş olmamalıdır',
      moduleTaskNotFound: 'Modül görevi bulunamadı',
      usersNotFound: 'Kullanıcı bulunamadı',
      testNotFound: 'Bu görevde herhangi bir test yok',
      answerNotFound:
        'Cevap bulunamadı: görev ID =:Index, test dizini = :TestIndex',
      answerKeysNotFound: 'Bu görevde cevap anahtarları eksik',
      answerKeyNotFound:
        'Cevap anahtarı bulunamadı: görev ID = :Index, cevap anahtarı dizini = :AnswerKeyIndex',
      unknownResult: 'Bilinmeyen görev teslim sonucu: :Data',
      questionNotFound: 'Soru bulunamadı: ID=:Index',
      taskNotFound: 'Görev bulunamadı',
      draftNotFound: 'Taslak bulunamadı',
      incorrectAnswer: 'Yanlış cevap türü: :Type',
    },
    SUCCESS: {},
    WARNING: {
      blockNavigation:
        'Sayfada kaydedilmemiş değişiklikler var. Sayfadan ayrılmak istediğinizden emin misiniz?',
    },
    INFO: {},
  },
  item: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Rota bilgisi bulunamadı',
    },
    SUCCESS: {
      edited: 'Rota :title başarıyla düzenlendi',
      added: 'Rota :title başarıyla eklendi',
      deleted: 'Rota silindi',
    },
    WARNING: {},
    INFO: {},
  },
  area: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Klasör verileri bulunamadı',
    },
    SUCCESS: {
      edited: 'Klasör: ":title" başarıyla düzenlendi',
      added: 'Klasör: ":title" başarıyla eklendi',
      deleted: 'Klasör silindi',
    },
    WARNING: {},
    INFO: {},
  },
  validation: {
    DEFAULT: {},
    ERROR: {
      emptyAnswers: 'Tüm sorular cevaplanmadı',
      maxFilesSize: 'Toplam dosya boyutu şu boyutu aşmamalıdır :MaxSize MB!',
      maxFileSize: 'Dosya boyutu şu değerden küçük olmalıdır :MaxSize MB!',
      format: 'Dosya formatı şu şekilde olmalıdır :ValidFormat!',
      invalidFormat: 'Geçersiz format (sadece :ValidFormat)',
      maxSizeImg: 'Resim boyutu şundan küçük olmalıdır :MaxSize MB!',
      maxSizeFile: 'Dosya boyutu şundan küçük olmalıdır: :MaxSize MB!',
      lessThan: 'Daha küçük :MaxSize MB',
      maxUploadVideo: '*Videonun maksimum boyutunu yükleyin - :MaxSize MB',
      limitTime: 'Video, süre sınırını aşmamalıdır',
      oversize: 'Dosya boyutu şunu aşıyor :MaxSize MB!',
      sendVideoAnswer: '"Video Gönder" butonuna tıklayın',
      wrongSupportEmail:
        'Destek e-postası bağlı değil veya yanlış e-posta. Yöneticinizle iletişime geçin!',
      requiredTask: 'Görev tanımı gereklidir!',
      requiredVideoTask:
        'Video görevinin indirilmesi veya kaydedilmesi gerekiyor!',
      requiredItem: 'Cevap açıklaması :Order gereklidir!',
      requiredItems: 'En az 2 cevap seçeneği içermelidir!',
      rightAnswers: 'Görev herhangi bir doğru cevap(lar) içermiyor!',
      uniqAnswers: 'Seçenek  :TestItem zaten testte mevcut!',
      requiredAnswerKey: 'Cevap anahtarlarının açıklaması zorunludur!',
      uniqAnswerKeys: 'Cevap anahtarlarının metni eşleşiyor :AnswerKey!',
      videoLoaded: 'Video hala yükleniyor. Biraz daha bekleyin.'
    },
    SUCCESS: {
      feedBackPassed: 'Message sent',
    },
    WARNING: {},
    INFO: {},
  },
  auth: {
    DEFAULT: {},
    ERROR: { invalidLink: 'Geçersi̇z referans' },
    SUCCESS: {
      authorization: 'Yetkilendirme başarılı oldu',
      accountConfirmed: 'Hesap onaylandı',
      passwordChanged: 'Şifre değişmış',
      recoveryPassword: 'Şifre başarıyla sıfırlandı',
      registration: 'Kayıt başarılı',
    },
    WARNING: {},
    INFO: {},
  },
  positions: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Konum verileri bulunamadı',
    },
    SUCCESS: {
      edited: 'Konum: :Title başarıyla düzenlendi',
      added: 'Konum: :Title başarıyla düzenlendi',
      deleted: 'Konum silindi',
    },
    WARNING: {},
    INFO: {},
  },
  settings: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      saved: 'Ayarlar başarıyla kaydedildi',
      passwordChanged: 'Şifre başarıyla değiştirildi',
      emailChanged: 'E-posta başarıyla değiştirildi',
      phoneNumberChanged: 'Сep telefonu başarıyla değiştirildi',
    },
    WARNING: {},
    INFO: {},
  },
  verification: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      videoAdded: 'Video başarıyla eklendi',
      postZeroPassed: 'Görev ilerleme durumu sıfırlama',
      avatarChange: 'Avatar değiştirildi',
      confirmed: 'Görev tamamlama kabul edildi',
      sentForReview: 'Görev sistem kontrolü için gönderildi',
      rejected: 'Görev tamamlama reddedildi',
      updateNode: 'Yapılan değişiklikler kaydedildi',
      postTopicChanged:
        'Kullanıcılar, açıklama değişikliği hakkında bilgilendirilir',
    },
    WARNING: {},
    INFO: {},
  },
  track: {
    engine: {
      DEFAULT: {},
      ERROR: {
        oneRoot: 'Yapıda bir kök modül bulunmalıdır',
        recursion: 'Yapıda hiçbir döngü olmamalıdır',
        emptyNodeTitle: 'Modül başlığı boş bırakılamaz!',
        duplicateNodeTitle: 'Modül başlığı benzersiz olmalıdır',
        diagramNodesError: 'Rota tablosu oluşturulamıyor',
        requestError: 'Sunucudan veri alınırken hata oluştu',
      },
      SUCCESS: {
        saveNodes: 'Değişiklikler başarıyla kaydedildi',
        copied: 'Kopyalandı',
        export: 'Dışa aktarma tamamlandı',
      },
      WARNING: {
        blockCopy:
          'Rotayı kaydedin. Kaydedilmemiş modül(leri) kopyalamaya çalışıyorsunuz',
        topicNotFound: '"Modül Açıklaması" alanı boş olmamalıdır',
      },
      INFO: {
        maxLengthNodeTitle:
          'Modül başlığı maksimum uzunluğu - :MaxChars karakter',
        addNode: 'Modül eklemek için çalışma alanındaki boş alana tıklayın',
        addCurve:
          'Bağlamak için mevcut modüle bağlamak istediğiniz modülü tıklayın.',
        addNodeTitle: 'Modül başlığını girin',
      },
    },
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      cardEdit: 'Rota açıklaması değişti',
      import: 'İçe aktarma tamamlandı',
    },
    WARNING: {},
    INFO: {},
  },
  users: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Kullanıcı verisi bulunamadı',
      wrongDict: 'Geçersiz argüman alındı: :DefaultItemsDict',
    },
    SUCCESS: {
      edited: 'Kullanıcı: :fullName başarıyla düzenlendi',
      added: 'Kullanıcı: :fullName başarıyla eklendi',
      deleted: 'Kullanıcı silindi',
      resetPassword: 'Şifre başarıyla sıfırlandı',
      changeEmail: 'E-posta başarıyla değiştirildi',
    },
    WARNING: {},
    INFO: {},
  },
}
