export const table = {
  emptyData: 'Нет данных',
  counters: {
    total: 'Всего',
  },
  settingButton: 'Вид таблицы',
  settingTitle: 'Настройка таблицы',
  exportButton: 'Экспорт',
  exportTitle: 'Настройки таблицы для экспорта в файл',
  exportFormatTitle: 'Выберите формат: ',
  exportColumnsTitle: 'Выберите столбцы для выгрузки: ',
}
