export const tracksManagement = {
  filter: {
    itemSearch: 'Поиск трека',
  },
  importButton: 'Импорт',
  emptyData: {
    noManage: 'Нет добавленных треков',
    noData: 'Нет данных, соответствующих выбранным фильтрам',
  },
  trackCardModal: {
    okText: 'Изменить',
    cancelText: 'Отменить',
    description: 'Введите описание трека',
  },
  notification: {
    cardEditNotification: 'Карточка трека изменена',
    importNotification: 'Импорт завершен',
  },
  tabs: {
    view: 'Вид:',
    grid: 'Плитка',
    table: 'Таблица',
  },
  rootAreaTitle: 'Общее',
  selectFolder: 'Выберите папку',
  addFolder: 'Добавить папку',
  cardView: {
    header: 'Каталог',
    actionBtn: {
      showHide: 'Показать/Скрыть',
      edit: 'Изменить',
      add: 'Добавить',
      delete: 'Удалить',
    },
  },
  deleteAreaModalTitle: 'папку',
  addAndEditAreaModal: {
    titleEdit: 'Настройка папки',
    titleAdd: 'Добавление папки',
    forma: {
      groupName: {
        label: 'Название папки:',
        placeholder: 'Введите название папки',
        message: 'Обязательное поле',
      },
      attachedTo: {
        label: 'Путь:',
        placeholder: 'Выберите папку',
      },
      tracks: {
        label: 'Треки:',
        placeholder: 'Выберите треки',
      },
    },
  },
  viewStatistics: 'Посмотреть статистику',
  cardSizeTooltip: 'Размер карточки трека',
}
