import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import _ from 'lodash'
import moment from 'moment'
import StatService from '../../../services/stat/stat.service'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import {
  ENotificationVariant,
  EPeriods,
  IKeyValuePair,
} from '../../../constants'
import { SorterResult } from 'antd/lib/table/interface'
import { TArrayColumnType } from '../../../components/ViewTable/IViewTable'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import local from './../../../localization'

const initialState: I.IStatUsersState = {
  progressPercent: {
    timestamp: '0',
    progress: 0,
  },
  dynamicProgress: {
    data: [],
    period: EPeriods.CM,
    start: moment()
      .startOf('month')
      .toDate()
      .getTime(),
    end: moment.now(),
  },
  statusProgress: {
    available: 0,
    checking: 0,
    completed: 0,
    rejected: 0,
    unavailable: 0,
  },
  byTasks: {
    total: 0,
    averageCompleted: 0,
    averageLeft: 0,
    everyoneCompleted: 0,
    noneCompleted: 0,
    checkingCount: 0,
    blockedProgressCount: 0,
  },
  statUsersTable: {
    totalElements: 0,
    statUsersData: [],
    page: 1,
    pageSize: 20,
    sortedInfo: undefined,
  },
  statUsersMapProgress: [],
  usersFilter: undefined,
  tracksFilter: undefined,
}

export const getStatUsersTable = createAsyncThunk(
  'stat-users/table',
  async (data: I.IStatUsersTableRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        StatService.getStatUsersTable({ ...data })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatUsersByTasks = createAsyncThunk(
  'stat-users/tasks',
  async (data: I.IStatUsersRequest, { rejectWithValue, dispatch }) => {
    try {
      const { track, user } = data
      const byTasks = await stackRequestWrapper(
        StatService.getStatUsersByTasks({ track, user })
      )

      return byTasks
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatUsersStatus = createAsyncThunk(
  'stat-users/status',
  async (data: I.IStatUsersRequest, { rejectWithValue, dispatch }) => {
    try {
      const { track, user } = data
      const status = await stackRequestWrapper(
        StatService.getStatUsersStatusProgress({ track, user })
      )

      return status
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatUsersDynamic = createAsyncThunk(
  'stat-users/dynamic',
  async (data: I.IStatUsersDynamicRequest, { rejectWithValue, dispatch }) => {
    try {
      const { user, track, start, end, interval } = data
      const dynamic = await stackRequestWrapper(
        StatService.getStatUsersDynamic({
          start,
          end,
          interval,
          user,
          track,
        })
      )

      return dynamic
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getStatUsersProgress = createAsyncThunk(
  'stat-users/progress',
  async (data: I.IStatUsersRequest, { rejectWithValue, dispatch }) => {
    try {
      const { user, track } = data
      const progress = await stackRequestWrapper(
        StatService.getStatUsersProgress({ user, track })
      )

      return progress
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postStatUsersTableExport = createAsyncThunk(
  'stat-users/table/export',
  async (
    data: I.IStatUsersTableExportRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const fileName = data.fileName
      delete data.fileName
      await stackRequestWrapper(
        StatService.postStatUsersTableExport(data, fileName!)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.engine.SUCCESS.export,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

// TODO: stat to state
export const statUsersSlice = createSlice({
  name: 'stat-users',
  initialState,
  reducers: {
    clearStatUsersState: (stat: I.IStatUsersState) => {
      _.assign(stat, {
        progressPercent: initialState.progressPercent,
        dynamicProgress: initialState.dynamicProgress,
        statusProgress: initialState.statusProgress,
        byTasks: initialState.byTasks,
        statUsersMapProgress: initialState.statUsersMapProgress,
      })
    },
    clearStatUsersTableState: (stat: I.IStatUsersState) => {
      _.assign(stat.statUsersTable, {
        totalElements: 0,
        statUsersData: [],
      })
    },
    setUsersFilter: (
      stat: I.IStatUsersState,
      prop: PayloadAction<IKeyValuePair[] | undefined>
    ) => {
      stat.usersFilter = prop.payload
    },
    clearUsersFilter: (stat: I.IStatUsersState) => {
      stat.usersFilter = []
    },
    setTracksFilter: (
      stat: I.IStatUsersState,
      prop: PayloadAction<IKeyValuePair[] | undefined>
    ) => {
      stat.tracksFilter = prop.payload
    },
    setPage: (stat: I.IStatUsersState, prop: PayloadAction<number>) => {
      stat.statUsersTable.page = prop.payload
    },
    setSize: (stat: I.IStatUsersState, prop: PayloadAction<number>) => {
      stat.statUsersTable.pageSize = prop.payload
    },
    setSortedInfo: (
      stat: I.IStatUsersState,
      prop: PayloadAction<SorterResult<TArrayColumnType> | undefined>
    ) => {
      stat.statUsersTable.sortedInfo = prop.payload
    },
    setUsersDynamicPeriod: (
      stat: I.IStatUsersState,
      prop: PayloadAction<{ period: EPeriods; start: number; end: number }>
    ) => {
      const { period, start, end } = prop.payload
      stat.dynamicProgress.period = period
      stat.dynamicProgress.start = start
      stat.dynamicProgress.end = end
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatUsersProgress.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.progressPercent = action.payload.data
      }
    })
    builder.addCase(getStatUsersStatus.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.statusProgress = action.payload.data
      }
    })
    builder.addCase(getStatUsersByTasks.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.byTasks = action.payload.data
      }
    })
    builder.addCase(getStatUsersDynamic.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.dynamicProgress.data = action.payload.data.dynamicProgress
      }
    })
    builder.addCase(getStatUsersTable.fulfilled, (state, action) => {
      if (action.payload.data) {
        _.assign(state.statUsersTable, {
          totalElements: action.payload.data.totalElements,
          statUsersData: action.payload.data.content,
        })
      }
    })
  },
})

export const { reducer: statUsersReducer } = statUsersSlice

export const selectStatUsersTable = (state: I.RootState) =>
  state.stat.statUsers.statUsersTable

export const selectUsersFilter = (
  state: I.RootState
): IKeyValuePair[] | undefined => state.stat.statUsers.usersFilter

export const selectTracksFilter = (
  state: I.RootState
): IKeyValuePair[] | undefined => state.stat.statUsers.tracksFilter

export const selectStatUsersMapProgress = (state: I.RootState) =>
  state.stat.statUsers.statUsersMapProgress

export const selectStatUsers = (state: I.RootState) => ({
  dynamicProgress: state.stat.statUsers.dynamicProgress,
  progressPercent: state.stat.statUsers.progressPercent,
  byTasks: state.stat.statUsers.byTasks,
  statusProgress: state.stat.statUsers.statusProgress,
})

export const selectStatDynamic = (state: I.RootState) =>
  state.stat.statUsers.dynamicProgress

export const selectStatUsersColumn = (
  state: I.RootState
): Array<I.IStatUsersColumn> =>
  state.stat.statUsers.statUsersTable.statUsersData.map((stat, i) => ({
    sequence:
      i +
      1 +
      state.stat.statUsers.statUsersTable.pageSize *
        (state.stat.statUsers.statUsersTable.page - 1),
    key: Number(`${stat.userId}${stat.trackId}`),
    id: { userId: stat.userId, trackId: stat.trackId },
    fullName: stat.fullName,
    email: stat.email,
    position: stat.position,
    lastAuthTime: stat.lastAuthTime,
    track: stat.track,
    progress: stat.progress,
  }))

export const {
  clearStatUsersState,
  setUsersFilter,
  setTracksFilter,
  clearStatUsersTableState,
  setPage,
  setSize,
  setSortedInfo,
  clearUsersFilter,
  setUsersDynamicPeriod,
} = statUsersSlice.actions
