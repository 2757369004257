export const tasksChecking = {
  filter: {
    optionsSelect: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
    },
    users: 'Select User(s)',
    item: 'Select Map(s)',
    task: 'Select Task(s)',
    tests: 'Tests',
    checked: 'Checked',
    showAll: 'Show all',
    showAllInfo: `
    If the setting is inactive, only completed Tasks for the Maps where you are a Mentor will be displayed in the table.
    If the setting is active, the table will display Tasks where other Mentors are involved as well.`,
  },
  emptyData: {
    noData: 'No tasks for checking',
    noFilterData: 'No data matching the selected filters',
  },
  columns: {
    user: 'User',
    track: 'Map',
    node: 'Task',
    timeCompleted: 'Completion Date',
    timeVerified: 'Review Date',
    status: {
      title: 'Result',
      confirmed: 'Accepted',
      rejected: 'Rejected',
      other: 'Not checked',
    },
    actions: {
      title: 'Action',
      actionButton: 'Check',
      actionButtonView: 'View answer',
    },
  },
}
