import { IAPIResult } from '../../IBaseService'
import { AuthResponse } from '../../auth/api/IAuthService'
import { EAPIResponseStatus } from '../../../constants'
import { generateFingerprint } from '../../../utils'
import * as I from './ITokenProvider'
import local from './../../../localization'

const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'

export const createTokenProvider = ({
  localStorageKey,
  onUpdateToken,
  onUpdateAuthority,
  logout,
  processError,
  storage,
}: I.TokenProviderConfig): {
  getToken: typeof getToken
  isLoggedIn: typeof isLoggedIn
  setToken: typeof setToken
  refreshToken: typeof refreshToken
  subscribe: typeof subscribe
  unsubscribe: typeof unsubscribe
} => {
  let listeners: Array<I.Listener> = []

  const getTokenInternal = (): AuthResponse | null => {
    const data = storage.getItem(localStorageKey)

    const token = (data && JSON.parse(data)) || null

    return token as AuthResponse
  }

  const subscribe = (listener: I.Listener): void => {
    listeners.push(listener)
  }

  const unsubscribe = (listener: I.Listener): void => {
    listeners = listeners.filter(l => l !== listener)
  }

  const jwtExp = (token?: string): number | null => {
    if (!(typeof token === 'string')) {
      return null
    }

    const split = token.split('.')

    if (split.length < 2) {
      return null
    }

    try {
      const jwt = JSON.parse(atob(token.split('.')[1]))

      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000
      }

      return null
    } catch (e) {
      throw new Error(local.notification.general.ERROR.token)
    }
  }

  const getExpire = (token: AuthResponse | null): number | null => {
    if (!token) {
      return null
    }

    const exp = jwtExp(token[ACCESS_TOKEN_KEY])

    return exp
  }

  const isExpired = (exp: number | null): boolean => {
    if (!exp) {
      return false
    }

    const now = Date.now()
    const delta = 60000
    const expr = now - delta

    return expr > exp
  }

  const checkExpiry = async (): Promise<void> => {
    const authEntity = getTokenInternal()

    if (authEntity && isExpired(getExpire(authEntity))) {
      await refreshToken()
    }
  }

  const getToken = async (): Promise<string | null> => {
    await checkExpiry()

    const token = getTokenInternal()

    return token && token[ACCESS_TOKEN_KEY]
  }

  const isLoggedIn = (): boolean => !!getTokenInternal()

  const setToken = (authEntity: AuthResponse | null): void => {
    if (authEntity) {
      storage.setItem(localStorageKey, JSON.stringify(authEntity))
    } else {
      storage.removeItem(localStorageKey)
    }
    notify()
  }

  const notify = (): void => {
    const isLogged = isLoggedIn()
    listeners.forEach(l => l(isLogged))
  }

  const refreshToken = async (): Promise<IAPIResult<AuthResponse> | null> => {
    const authEntity = getTokenInternal()

    if (authEntity) {
      const fingerprint = await generateFingerprint()
      let authDataResult: IAPIResult<AuthResponse>

      try {
        authDataResult = await onUpdateToken({
          refreshToken: authEntity[REFRESH_TOKEN_KEY],
          fingerprint: fingerprint,
        }).toPromise()
      } catch (e) {
        const err = e as Response
        if (!e || e.constructor.name === 'TypeError') {
          authDataResult = {
            status: EAPIResponseStatus.ERROR,
            code: 500,
            message: local.notification.general.ERROR.connectionError,
          }
        } else {
          authDataResult = {
            status: EAPIResponseStatus.ERROR,
            code: err.status || 500,
            message:
              err.statusText || local.notification.general.ERROR.unknownError,
          }
        }
      }

      if (authDataResult.status !== EAPIResponseStatus.SUCCESS) {
        const code = authDataResult.code
        const message = authDataResult.message

        if (
          message ===
          `TokenExpiredException: ${local.notification.general.ERROR.tokenExpired}`
        ) {
          logout()
        }

        if (
          code === 500 &&
          message !==
            `TokenExpiredException: ${local.notification.general.ERROR.tokenExpired}`
        ) {
          processError(authDataResult.message!!)

          return authDataResult
        }

        if (code === 401 || code === 403) {
          if (code === 401) {
            // processError(local.notification.general.ERROR.sessionExpired)
          } else if (code === 403) {
            processError(local.notification.general.ERROR.notAuthorities)
          }
          logout()

          return authDataResult
        }
      } else {
        if (!authDataResult.code) {
          authDataResult.code = 200
        }

        const authData = authDataResult.data

        if (authData) {
          setToken(authData)
          await onUpdateAuthority(authData[ACCESS_TOKEN_KEY])
        } else {
          storage.removeItem(localStorageKey)
          await onUpdateAuthority()
        }
      }

      return authDataResult
    }
    logout()

    return null
  }

  return {
    getToken,
    isLoggedIn,
    setToken,
    refreshToken,
    subscribe,
    unsubscribe,
  }
}
