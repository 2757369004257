export const users = {
  columns: {
    fullName: 'User',
    position: 'Position',
    items: 'Tracks',
    phoneNumber: 'Phone number',
    email: 'Email',
    progress: 'Progress',
    createdTime: 'Registration date',
    lastAuthTime: 'Last sign in',
    activePeriod: 'Last activity more than :Period day(s) ago',
    action: {
      title: 'Action',
      editButton: 'Edit',
      deleteButton: 'Delete',
    },
  },
  deleteUserModal: {
    title: 'Delete user',
    content:
      'Do you really want to delete a user? All of his task progress will be deleted from the system.',
    okText: 'Yes',
    cancelText: 'No',
  },
  emptyData: {
    noData: 'No users added',
    noFilterUsers: 'No data matching the selected filters',
  },
  filter: {
    user: 'Enter User Name',
    role: {
      title: 'Role',
      user: 'User',
      mentor: 'Mentor',
      manager: 'Manager',
      admin: 'Administrator',
      placeholder: 'All',
    },
    isActive: 'Active',
    status: 'Status',
  },
  addButton: 'Add user',
  notification: {
    notConfirmed: 'User has not confirmed the account',
    isBlocked: 'User is blocked',
    isAdmin: 'User is an admin',
    isManager: 'User is a track manager',
    isMentor: 'User is a mentor',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Active',
      disable: 'Blocked',
    },
    title: {
      add: 'Add user',
      edit: 'Edit user',
    },
    cancelButton: 'Cancel',
    saveButton: 'Save',
    formItem: {
      fullName: {
        label: 'Full name',
        placeholder: 'Enter the full name',
        required: 'Required',
        length: 'Use at least 3 characters',
      },
      email: {
        label: 'Email',
        placeholder: 'Enter the email',
        required: 'Required',
        valid: 'Invalid email!',
      },
      phoneNumber: {
        label: 'Phone number',
        placeholder: 'Enter the phone number',
        searchPlaceholder: 'Search',
        required: 'Required',
        valid: 'Invalid phone number!',
      },
      status: {
        label: 'Active',
        placeholder: 'Active/Inactive',
        required: 'Required',
      },
      role: {
        filterInputLabel: 'Role',
        label: 'Role',
        placeholder: 'Select a role',
        required: 'Required',
      },
      position: {
        filterInputLabel: 'Position',
        label: 'Position',
        placeholder: 'Select a position',
      },
      items: {
        filterInputLabel: 'Track',
        label: 'Tracks',
        placeholder: 'Select tracks',
      },
    },
    deletePositionModal: {
      title: 'Delete a position',
      content:
        'Are you sure you want to dissociate the position from the user? All progress and tracks that have been assigned to the user by their position will be saved.',
      okText: 'Yes',
      cancelText: 'No',
    },
    deleteItemModal: {
      title: 'Delete a track',
      content:
        'Do you really want to detach a track from a user? All his progress in completing tasks on this track will be deleted from the system.',
      okText: 'Yes',
      cancelText: 'No',
    },
    disableUserModal: {
      title: 'Blocking a user',
      content:
        'A blocked user will not be able to log in, but the progress of tracks will be saved. The user will no longer act as "Manager", "Mentor" or "Administrator" in tracks assigned to him. If the user needs to be made active again, he will need to be reassigned as a mentor, manager, or administrator for all tracks.',
      okText: 'Yes',
      cancelText: 'No',
    },
    changeEmailModal: {
      title: "Change user's email address",
      okText: 'Confirm',
      description:
        "After changing the user's email address, the user won't be able to log in with the old email address.\n After the change, notifications about changing the email will be sent to the old and the new email addresses.",
      label: 'Email',
      placeholder: 'New Email',
      required: 'Required',
      valid: 'Invalid email!',
    },
  },
  exportFileName: 'Users',
}
