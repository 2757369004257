import React, { FC, useCallback, useState } from 'react'
import styles from './ProfileActionBar.module.scss'
import { IProfileActionBar } from 'Types'
import { ESearchFilter, IKeyValuePair } from '../../../../../constants'
import local from './../../../../../localization'
import { useAppDispatch } from '../../../../../app/store'
import { useSelector } from 'react-redux'
import {
  selectStatTracks,
  setRangeDate,
  setTrackFilter,
} from '../../../profileSlice'
import { EISRangePicker } from '../../../../../components/EISComponents'
import { Moment } from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import CustomSearchSelect from '../../../../../components/CustomSearchSelect/CustomSearchSelect'

const ProfileActionBar: FC<IProfileActionBar> = ({ userId }) => {
  const dispatch = useAppDispatch()
  const { track, rangeDate } = useSelector(selectStatTracks)

  //#region Search track
  const searchElement = document.getElementById('customSelect')

  const handleChangeTrack = useCallback(
    (option: IKeyValuePair[]) => {
      const result = option
        ? option.map(o => ({ key: o.key, value: o.value }))[0]
        : undefined
      dispatch(setTrackFilter(result))
      searchElement?.blur()
    },
    [dispatch, searchElement]
  )

  const trackUpdateSearchParams = {
    requiredMentorOrManager: true,
  }
  //endregion Search track

  //#region Search completed Task (DatePicker)
  const [dates, setDates] = useState<[Moment | null, Moment | null] | null>(
    (rangeDate?.split(',').map(r => moment(r)) as [Moment, Moment]) || null
  )

  /** Не позволяет выбрать дату дальше актуальной */
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment().endOf('day')
  }

  const onDateChange = useCallback(
    (dates, dateStrings: [string, string]) => {
      dispatch(setRangeDate(!!dates ? dateStrings.join() : undefined))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  //#endregion Search completed Task (DatePicker)

  return (
    <div className={styles.actionsBarWrapper}>
      {!userId && (
        <CustomSearchSelect
          data={track ? [track] : []}
          searchFilterType={ESearchFilter.TRACKS}
          updateSearchParams={trackUpdateSearchParams}
          changeValue={handleChangeTrack}
          placeholder={local.profile.stat.selectItem}
          inputPlaceholder={local.profile.stat.itemLabel}
          allowClear
        />
      )}
      {/* <EISRangePicker  TODO: Раскомментировать, как на бэке будет готов функционал фильтрации треков
        value={dates}
        disabledDate={disabledDate}
        onChange={onDateChange}
        onCalendarChange={val => setDates(val)}
      /> */}
    </div>
  )
}

export default ProfileActionBar
