import { useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { Notification } from 'Types'
import { ENotificationVariant } from '../../../constants'

const errors = new BehaviorSubject<Notification | null>(null)

export const processError = (message: string): void => {
  errors.next({
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: ENotificationVariant.ERROR,
    },
  })
}

export const useAuthErrors = (): [typeof onError$] => {
  const [onError$] = useState(() => errors)
  return [onError$]
}
