export const confirmedPassword = {
  form: {
    password: 'New password',
    passwordConfirm: 'Password confirmation',
  },
  confirmedButton: 'Confirm',
  backToSignInLink: 'Back to Login Page',
  validation: {
    passwordConfirmedRequired: 'Required',
    passwordConfirmedValid: 'Passwords do not match',

    passwordRequired: 'Required',
    passwordLengthValid: 'Use 8 or more characters',
    passwordValid:
      'Use a mix of lowercase and uppercase latin letters, number and symbols',
  },
  notification: {
    passwordChanged: 'Password has been changed',
  },
}
