import { IThemeProps } from '..'

import logo_mini from '../../assets/svg/winwin_mini.svg'
import logo from '../../assets/svg/winwin.svg'
import page_bg_img from '../../assets/svg/layout_bg.svg'
import auth_bg_img from '../../assets/svg/auth-img.svg'
import card_img from '../../assets/png/card_default_img.jpg'
// import card_img from '../../assets/svg/winwin_img.svg'

export const winwin: IThemeProps = {
  '--font-family': `'Roboto', sans-serif`,
  '--mini-logo': `url('${logo_mini}')`,
  '--main-logo': `url('${logo}')`,
  '--background-image': `url('${page_bg_img}')`,
  '--auth-background-image': auth_bg_img,
  '--default-card-image': card_img,
  '--main-theme-color': '#33D1B5',
  '--light-main-theme-color': '#D5F7F2',
  '--main-text-color': '#000000',
  '--reverse-text-color': '#FFFFFF',
  '--secondary-text-color': '#969FA2',
  '--icon-color': '#1C212D',
  '--divider-color': '#CED5D4',
  '--main-background-color': '#FFFFFF',
  '--secondary-background-color': '#F4F4F4',
  '--first-complementary-color': '#CED5D4',
  '--second-complementary-color': '#73ACF5',
  '--third-complementary-color': '#F7BD27',
  '--fourth-complementary-color': '#33D1B5',
  '--fifth-complementary-color': '#DE6C6A',
  '--sixth-complementary-color': '#E672FF',
  '--seventh-complementary-color': '#CCA883',
  '--eighth-complementary-color': '#FFB6C1',
  '--default-complementary-color': '#969FA2',
  '--gradient-colors': ['#FFB596', '#FFD15C', '#E3FF93', '#65C280'],
}
