export const table = {
  emptyData: 'Bilgi yok',
  counters: {
    total: 'Toplaml',
    shown: 'Gösterilen',
  },
  settingButton: 'Tablo görünümü',
  settingTitle: 'Masa düzeni',
  exportButton: 'İhracat',
  exportTitle: 'Bir dosyaya aktarma ayarları',
  exportFormatTitle: 'Format seçin: ',
  exportColumnsTitle: 'Yüklenecek sütunları seçin: ', 
}
