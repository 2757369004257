export const taskCheckAndView = {
  header: {
    item: 'Трек',
    attempt: 'Попытка',
  },
  body: {
    testAnswers: 'Тестовые задания (проверено автоматически)',
    verifiedButton: 'Принять задание',
    systemCheckButton: 'Принять задание',
    rejectedButton: 'Отклонить задание',
    rejectModal: {
      title: 'Комментарии отсутствуют',
      content:
        'Вы действительно хотите отклонить выполнение задания, не добавив свои комментарии к вопросам?',
      cancelText: 'Отмена',
      okText: 'Подтвердить',
    },
    tooltip: 'Выберите результат выше',
    submitButton: 'Подтвердить',
    returnButton: 'Вернуться к Проверке заданий',
    question: {
      questionTitle: {
        test: 'Тестовый вопрос',
        text: 'Развёрнутый вопрос',
        video: 'Видео-вопрос',
        default: 'Неизвестный тип вопроса:',
        task: 'Задание № ',
      },
      videoQuestion: 'видео-вопрос',
      testQuestion: {
        text1: 'Правильные варианты отмечены ',
        right: 'зеленым',
        text2: ', неправильные - ',
        wrong: 'красным',
      },
      breakpointsTitle: 'Контрольные точки правильного ответа',
    },
    answer: {
      title: 'Ответ пользователя:',
      commentCheckTitle: 'Оставьте комментарий к ответу:',
      commentCheckTitleinfo:
        'Данный комментарий будет отправлен пользователю в письме с результатом проверки',
      commentViewTitle: 'Комментарий ментора:',
      videoAnswer: 'видео-ответ',
      notPassed: 'Ответ не засчитан',
    },
  },
  checked: {
    title: 'Проверил',
    timeChecked: 'Проверено',
    estimateChecked: 'Время проверки',
    automatically: 'Автоматически',
    hour: 'ч',
    minute: 'мин',
    second: 'сек',
    rezult: {
      title: 'Результат',
      comfirmed: 'Принят',
      rejected: 'Отклонен',
    },
    mentor: 'Проверил(а)',
    empty: 'Не проверено',
  },
  fulfilled: {
    title: 'Выполнил',
    timeFulfilled: 'Выполнено',
    estimateFulfilled: {
      title: 'Расчетное время',
      notSet: 'Не задано',
      minute: 'мин',
    },
    leadTime: {
      title: 'Время выполнения',
      limit: 'ЛИМИТ',
      hour: 'ч',
      minute: 'мин',
      second: 'сек',
    },
    user: 'Выполнил(а)',
  },
  notification: {
    editComment: 'Комментарий изменен',
    verification: {
      confirmed: 'Выполнение задания принято',
      sentForReview: 'Задание отправлено на проверку',
      rejected: 'Выполнение задания отклонено',
    },
    error: {
      solutionNotFound: 'Решение данной попытки не найдено',
    },
  },
}
