export const recoveryPassword = {
  form: {
    help:
      'Lütfen aşağıya e-posta adresinizi girin. Yeni bir şifre belirlemeniz için size bir bağlantı göndereceğiz',
    username: 'Giriş Yap(e-posta)',
  },
  title: 'Şifre kurtarma',
  titleRecovery: 'Yeni bir şifre girin',
  recoveryResult: {
    info:
      'Şifrenizi sıfırlamanız için size bir bağlantı içeren bir e-posta gönderdik',
    ps: 'Bu sekmeyi kapatabilirsiniz',
  },
  recoveryButton: 'Gönder',
  backToSignInLink: 'Ana Sayfaya Dön',
}
