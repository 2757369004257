export const statItems = {
  filters: {
    label: 'Parça',
    searchItems: 'Tüm rotalar',
    mapTooltip: 'Bir parça seçildiğinde alan aktif hale gelecektir',
  },
  statTab: {
    areaConfig: {
      yAxis: 'Tamamlandı, %',
    },
    progressTitle: 'Genel ilerleme',
    diagramTitle: 'İlerleme dinamiği',
    byUsersTitle: 'Kullanıcılar tarafından',
    byUsers: {
      haveItems: 'Erişim mevcut',
      completed: 'Hepsi tamamlandı',
      inProgress: 'Devam ediyor',
      withoutItems: {
        title: 'Hiçbir harita atanmadı',
        value: ':Count',
      },
    },
    byNodesTitle: 'Modüllere göre ',
    byNodes: {
      total: 'Toplam modül sayısı',
      withTopics: 'Açıklamalı modüller',
      withTasks: 'Görev içeren modüller',
      withoutTasks: 'Görevsiz modüller',
    },
    byTasksTitle: 'Görevlere göre',
    byTasks: {
      onChecking: 'Modüller doğrulanmadı',
      averageChecking: 'Ortalama kontrol süresi',
      days: 'gün',
      hours: 'sa',
      minutes: 'dk',
    },
  },
  tableTab: {
    tableTitle: 'Modül istatistikleri',
    columns: {
      title: 'Modül başlığı',
      questionsCount: 'Soru sayısı',
      completedPercentage: '% tamamlandı',
      averageAttempts: 'Ortalama deneme sayısı',
      secondsEstimated: 'Tahmini tamamlanma süresi',
      averageSecondsActual: 'Ortalama tamamlanma süresi',
      progress: 'Ortalama ilerleme durumu',
      hours: 'sa',
      minutes: 'dk',
    },
    exportFileName: 'Modül istatistikleri',
  },
}
