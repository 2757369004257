import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import {
  ENotificationVariant,
  EPassResult,
  IKeyValuePair,
  ETaskViewMode,
} from '../../constants'
import TasksService from '../../services/tasks/tasks.service'
import TasksCheckService from '../../services/tasks/tasks-check/tasksCheck.service'
import { enqueueSnackbar } from '../notification/notificationSlice'
import { processError } from '../../app/processError'
import _ from 'lodash'
import { stackRequestWrapper } from '../../app/requestWrapper'
import { ITaskViewerAndReviewerState } from '../../components/TaskViewerAndReviewer/ITaskViewerAndReviewer'
import local from './../../localization'

const initialState: ITaskViewerAndReviewerState = {
  mode: ETaskViewMode.CHECKING,
  attemptsSolution: [],
  activeAttempt: 0,
  solution: {
    id: 0,
    track: '',
    task: '',
    status: undefined,
    userName: '',
    mentorName: undefined,
    timeCompleted: 0,
    secondsActual: 0,
    minutesEstimated: 0,
    questions: [],
    limitTime: false,
    timeVerified: 0,
    mentorAvatarUrls: undefined,
    userAvatarUrls: undefined,
  },
  accepted: 0,
}

export const getAttemptSolution = createAsyncThunk(
  'tasksCheck/solution',
  async (attemptId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TasksCheckService.getAttemptSolution(attemptId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const verificationAttemptSolution = createAsyncThunk(
  'tasksCheck/verification',
  async (data: I.IUpdateAttemptSolution, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TasksCheckService.updateAttemptSolution(data)
      )

      dispatch(
        enqueueSnackbar({
          message:
            data.status === EPassResult.CONFIRMED
              ? data.forSystemCheck
                ? local.notification.verification.SUCCESS.sentForReview
                : local.notification.verification.SUCCESS.confirmed
              : local.notification.verification.SUCCESS.rejected,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getAttemptsSolution = createAsyncThunk(
  'tasksCheck/attempts',
  async (
    data: Record<string | number, number>,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        TasksService.getAttemptsSolution(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getAcceptedCount = createAsyncThunk(
  'tasksCheck/accepted',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TasksCheckService.getAcceptedCount()
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const tasksCheckSlice = createSlice({
  name: 'tasksCheck',
  initialState,
  reducers: {
    setActiveAttempt: (
      state: ITaskViewerAndReviewerState,
      action: PayloadAction<number>
    ) => {
      state.activeAttempt = action.payload
    },
    clearAcriveAttemptState: (state: ITaskViewerAndReviewerState) => {
      _.assign(state, initialState)
    },
    setAnswerKeyChecking: (
      state: ITaskViewerAndReviewerState,
      action: PayloadAction<I.IAnswerKeyPayload>
    ) => {
      const { questionId, answerKeyId, value } = action.payload

      const question = state.solution.questions.find(
        q => q.questionId === questionId
      )

      const answer = question?.answerKeys?.find(ak => ak.id === answerKeyId)

      if (answer) answer.isChecked = value
    },
  },
  extraReducers: builder => {
    builder.addCase(getAttemptSolution.fulfilled, (state, action) => {
      if (action.payload?.data) state.solution = action.payload.data
    })
    builder.addCase(getAttemptsSolution.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        const attempts =
          (Object.keys(action.payload.data).map(
            (k: number | string) =>
              ({
                key: k,
                value: action.payload?.data ? action.payload.data[k] : '',
              } as IKeyValuePair)
          ) as Array<IKeyValuePair>).sort((a, b) => b.key - a.key) || []
        state.attemptsSolution = attempts
        state.activeAttempt = attempts.length > 0 ? attempts[0].key : 0
      }
    })
    builder.addCase(getAcceptedCount.fulfilled, (state, action: any) => {
      if (action.payload.data) {
        state.accepted = action.payload.data.ACCEPTED!!
      }
    })
  },
})

export const selectTaskName = (state: I.RootState) =>
  state.tasks.check?.solution?.task

export const selectAttemtsSolutions = (state: I.RootState) => state.tasks.check

export const selectAcceptedCount = (state: I.RootState) =>
  state.tasks.check.accepted

export const { reducer: tasksCheckReducer } = tasksCheckSlice

export const {
  setActiveAttempt,
  clearAcriveAttemptState,
  setAnswerKeyChecking,
} = tasksCheckSlice.actions
