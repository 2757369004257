import React, { FC, useState } from 'react'
import { ClearOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { TTheme, themeList } from '../../theme'
import { useTheme } from '../../theme/Theme'
import Styles from './styles/ThemeSwitcher.module.scss'
import local from '../../localization'
import { getStyle } from './ThemeSwitcher.styles'
import { EISSelect } from '../EISComponents'

interface IThemeSwitcher {
  /** Accepts a boolean to apply the style:
   * false = for authorization pages (default);
   * true = for the header.  */
  isHead?: boolean
}

export const ThemeSwitcher: FC<IThemeSwitcher> = ({ isHead }) => {
  const { setCurrentTheme } = useTheme()
  const [theme, setTheme] = useState(
    (localStorage.getItem('theme') || window.REACT_APP_DEFAULT_THEME) as TTheme
  )

  const handleChangeTheme = React.useCallback(
    (value: TTheme) => {
      setTheme(value)
      localStorage.setItem('theme', value)
      setCurrentTheme && setCurrentTheme(value)
    },
    [setCurrentTheme, setTheme]
  )

  return (
    <div className={getStyle(isHead)}>
      <Tooltip title={local.main.headMenu.theme}>
        <ClearOutlined className={Styles.languageIcon} translate={undefined} />
      </Tooltip>
      <EISSelect
        bordered={false}
        size={'small'}
        options={themeList}
        dropdownMatchSelectWidth={false}
        value={theme}
        onChange={handleChangeTheme}
      />
    </div>
  )
}
