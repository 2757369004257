export const tasksChecking = {
  filter: {
    optionsSelect: {
      yes: 'Да',
      no: 'Нет',
      all: 'Все',
    },
    users: 'Выберите пользователя',
    item: 'Выберите трек',
    task: 'Выберите задание',
    tests: 'Тестовые',
    checked: 'Проверено',
    showAll: 'Показать все',
    showAllInfo: `
    Если настройка неактивна, то в таблице будут отображаться только выполненные задания для треков, в которых вы являетесь ментором.
    Если настройка активна, то в таблице будут доступны задания, ментором в которых являетесь не только вы.`,
  },
  emptyData: {
    noData: 'Нет заданий на проверку',
    noFilterData: 'Нет данных, соответствующих выбранным фильтрам',
  },
  columns: {
    user: 'Пользователь',
    track: 'Трек',
    node: 'Задание',
    timeCompleted: 'Дата выполнения',
    timeVerified: 'Дата проверки',
    status: {
      title: 'Результат',
      confirmed: 'Принято',
      rejected: 'Отклонено',
      other: 'Не проверено',
    },
    actions: {
      title: 'Действие',
      actionButton: 'Перейти к проверке',
      actionButtonView: 'Просмотр ответа',
    },
  },
}
