import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ENotificationVariant, EStateStatus } from '../../../constants'
import { AuthService, logout } from '../../../services/auth'
import { LoginRequest } from '../../../services/auth/api/IAuthService'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import * as I from 'Types'
import { processError } from '../../../app/processError'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import local from './../../../localization'

const initialState: I.LoginState = {
  status: EStateStatus.IDLE,
  error: null,
  usernameValue: undefined,
}

export const login = createAsyncThunk(
  'auth/login',
  async (
    { username, password }: LoginRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await stackRequestWrapper(
        AuthService.login(username, password)
      )
      dispatch(
        enqueueSnackbar({
          message: local.notification.auth.SUCCESS.authorization,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return response
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('') // (e as Error).message
    }
  }
)

export const confirmed = createAsyncThunk(
  'auth/confirmed',
  async (encoded: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(AuthService.confirmed(encoded))
      dispatch(
        enqueueSnackbar({
          message:
            result.message || local.notification.auth.SUCCESS.accountConfirmed,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e, allowRedirect: false }))
      return rejectWithValue('') //(e as Error).message
    }
  }
)

export const signOut = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await stackRequestWrapper(AuthService.logout())
      return response
    } catch (e) {
      dispatch(processError({ e }))
      logout()
      return rejectWithValue((e as Error).message)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsernameValue: (
      state: I.LoginState,
      prop: PayloadAction<string | undefined>
    ) => {
      state.usernameValue = prop.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.status = EStateStatus.PENDING
    })
    builder.addCase(login.fulfilled, state => {
      state.status = EStateStatus.FULFILLED
    })
    builder.addCase(login.rejected, state => {
      state.status = EStateStatus.REJECTED
    })
  },
})

export const { reducer: authReducer } = authSlice

export const authValue = (state: I.RootState) => state.auth.authReducer

export const { setUsernameValue } = authSlice.actions
