/**Type of module */
export enum EShapeType {
  BOX = 'box',
  CURVE = 'curve',
  CIRCLE = 'circle',
}

export enum EStorageName {
  SHAPES = 'shapes',
  CONTENT = 'content',
  PAGE = 'page',
}
/**Job (module) status */
export enum ETaskState {
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  CHECKING = 'CHECKING',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export enum ETrackEngineMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW',
  STAT_ITEMS_PROGRESS_MAP = 'STAT_ITEMS_PROGRESS_MAP',
  STAT_USERS_PROGRESS_MAP = 'STAT_USERS_PROGRESS_MAP',
  STAT_USER_PROGRESS_MAP = 'STAT_USER_PROGRESS_MAP',
}

export const minScale = 0.3
export const maxScale = 2.0
export const scaleStep = 0.1
