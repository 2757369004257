import _ from 'lodash'

import { generateTaskIndex } from '../app/utils'

import {
  ICurve,
  IDiagramContent,
  IIPointCoordsWithId,
  ILineCoords,
  IManagementNode,
  INode,
  IPointCoords,
  IShape,
} from 'Types'
import { EShapeType, ETaskState } from '../constants/tracks-editor/common'
import { MathUtils } from './MathUtils'
import { uuid } from '.'
import { minRadius, EQuestionType, EAnswerType } from '../constants'
import local from './../localization'

const isMandatory = !!Number(window.REACT_APP_TASK_EDIT_NODES_MANDATORY)

export class DiagramUtils {
  // Creating shapes -------------------------------------------------------------------------------
  static createCircle(
    x: number,
    y: number,
    status: ETaskState = ETaskState.UNAVAILABLE,
    id: string = uuid(),
    mandatory: boolean = isMandatory,
    answerTypes: Array<EAnswerType> = [],
    questionTypes: Array<EQuestionType> = [],
    hasTopic?: boolean,
    revision?: number,
    draft?: boolean,
    progress?: number,
    radius?: number
  ): IShape {
    return {
      id: id,
      type: EShapeType.CIRCLE,
      x: x || minRadius,
      y: y || minRadius,
      radius: radius || minRadius,
      status: status,
      editTitle: false,
      hasTopic: hasTopic || false,
      revision,
      draft,
      progress,
      mandatory,
      questionTypes,
      answerTypes,
    }
  }

  static createCurve(
    start: IIPointCoordsWithId,
    end: IIPointCoordsWithId,
    id: string = uuid(),
    status?: ETaskState
  ): ICurve {
    const bezier = this.calculateBezier(start, end)

    return {
      id: id,
      type: EShapeType.CURVE,
      startID: start.id,
      endID: end.id,
      status,
      ...bezier,
    }
  }

  static createShapeContent(
    id: string,
    content: Record<string, IDiagramContent>
  ): IDiagramContent {
    return {
      id,
      title: `${local.main.task} ${generateTaskIndex(content)}`,
      formattedTitle: `${local.main.task} ${generateTaskIndex(content)}`,
      url: '',
      info: '',
    }
  }

  // Updating existing shapes ----------------------------------------------------------------------
  static updateCurve(
    curve: ICurve,
    start: IPointCoords,
    end: IPointCoords
  ): ICurve {
    const bezier = this.calculateBezier(start, end)

    return {
      ...curve,
      ...bezier,
    }
  }

  // Calculations ----------------------------------------------------------------------------------
  static calculateBezier(start: IPointCoords, end: IPointCoords): ILineCoords {
    return {
      x1: start.x,
      y1: start.y,
      x2: end.x,
      y2: end.y,
    }
  }

  // Determining -----------------------------------------------------------------------------------
  static determineTextControlPosition(shape: IShape) {
    const { x, y, radius = 0 } = shape
    let v = {
      x: x - radius + 10 - x,
      y: y - radius + 10 - y,
    }
    // Scalar vector length
    const lineLength = Math.sqrt(v.x ** 2 + v.y ** 2)

    if (lineLength === 0) throw new Error('Length has to be positive')
    // Noramalization
    v = { x: v.x / lineLength, y: v.y / lineLength }
    // Scalar product of vector on the radius of circle
    const scalarMultiply = {
      x: v.x * (shape.radius - 10),
      y: v.y * (shape.radius + 10),
    }

    // Coordinates of the intersection of the vector with the circle, 15 and 20 - offset under the status
    const intersectionCoords = {
      x: x + scalarMultiply.x,
      y: y + scalarMultiply.y,
    }

    const textBlockXCoord = intersectionCoords.x
    const textBlockYCoord = intersectionCoords.y
    const textBlockH = ((radius - 10) * 2) / Math.sqrt(2) + 30
    const textBlockW = ((radius - 10) * 2) / Math.sqrt(2)

    return {
      x: textBlockXCoord,
      y: textBlockYCoord,
      width: textBlockW,
      height: textBlockH,
    }
  }

  static restoreDiagram = (nodes: Array<INode | IManagementNode>) => {
    if (!nodes) {
      return null
    }

    const shapes: Record<string, IShape | ICurve> = {}
    const content: Record<string, IDiagramContent> = {}

    nodes.forEach(node => {
      const isDraftNode = !(node.children?.length || node.parents?.length)

      const shape = DiagramUtils.createCircle(
        MathUtils.roundCoord(node.coordinateX),
        MathUtils.roundCoord(node.coordinateY),
        (node as INode).status,
        `${node.id}`,
        node.mandatory,
        node.answerTypes,
        node.questionTypes,
        node.hasTopic,
        node.revision,
        isDraftNode,
        (node as INode).progress,
        node.radius
      )

      shapes[`${node.id}`] = shape

      content[`${node.id}`] = {
        id: `${node.id}`,
        title: node.title,
        formattedTitle: node.formattedTitle,
        url: '',
        info: '',
      }

      if (node.parents?.length) {
        node.parents.forEach(parentId => {
          const parent = nodes.find(n => `${n.id}` === `${parentId}`)

          if (parent) {
            const { start, end } = MathUtils.calculateCurve(
              DiagramUtils.createCircle(
                MathUtils.roundCoord(parent.coordinateX),
                MathUtils.roundCoord(parent.coordinateY),
                (parent as INode).status,
                `${parent.id}`,
                parent.mandatory,
                parent.answerTypes,
                parent.questionTypes,
                parent.hasTopic,
                parent.revision,
                undefined,
                undefined,
                parent.radius
              ),
              shape
            )

            const curve = DiagramUtils.createCurve(
              start,
              end,
              `curve-${_.keys(shapes).length}`,
              (parent as INode).status
            )
            shapes[curve.id] = curve
          }
        })
      }
    })

    return {
      shapes,
      content,
    }
  }
}
