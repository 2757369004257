import { values } from 'lodash'
import { IDiagramContent } from 'Types'
import local from './../localization'

const defaultTitle = local.main.task
const reg = new RegExp(`^${defaultTitle} (\\d+)$`)

export function generateTaskIndex(content: Record<string, IDiagramContent>) {
  const indexes = values(content).map(c => {
    const match = c.title.match(reg)
    return match ? Number(match[1]) : 0
  })

  return Math.max(...indexes, 0) + 1
}
