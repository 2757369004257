import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Form, Button, Image } from 'antd'
import { ValidationHelper } from '../../Validation'
import { useFormField } from '../../../../hooks/useFormField'
import Styles from './confirmedPassword.module.scss'
import { confirmedEmail, confirmedPassword } from '../confirmedPasswordSlice'
import { useAppDispatch } from '../../../../app/store'
import { EPages } from '../../../../constants'
import local from '../../../../localization'
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { ThemeSwitcher } from '../../../../components/ThemeSwitcher/ThemeSwitcher'
import EISPasswordProgress from '../../../../components/EISComponents/EISPasswordProgress/EISPasswordProgress'
import EISPopoverValidation from '../../../../components/EISComponents/EISPopoverValidation'
import { ReactComponent as BackArrow } from '../../../../assets/svg/arrow_back_icon.svg'
import { useTheme } from '../../../../theme/Theme'

const themeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER) || false // 0 - false, 1 - true

export const ConfirmedPasswordPL = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const [password, setPassword] = useFormField()
  const [passwordConfirm, setPasswordConfirm] = useFormField()
  const history = useHistory()
  const { search } = useLocation()
  const { themeProps } = useTheme()
  const query = new URLSearchParams(search)
  const [encoded, setEncoded] = React.useState<string>()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (encoded) {
      dispatch(confirmedEmail(encoded!!)).then(r => {
        if (r.meta.requestStatus === 'rejected') {
          history.push(EPages.SIGN_IN)
        }
      })
    } else {
      if (query.get('encoded')) setEncoded(query.get('encoded') as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encoded])

  const formOnKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onConfirmedHandler()
    }
  }

  const onChangePasswordHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validatePassword(e.target.value)
      setPassword(result)

      const confirmResult = ValidationHelper.validatePasswordConfirm(
        e.target.value,
        passwordConfirm.value
      )
      setPasswordConfirm(confirmResult)
    },
    [passwordConfirm.value, setPassword, setPasswordConfirm]
  )

  const onChangePasswordConfirmHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validatePasswordConfirm(
        password.value,
        e.target.value
      )
      setPasswordConfirm(result)
    },
    [password, setPasswordConfirm]
  )

  const onConfirmedHandler = React.useCallback((): void => {
    if (password.valid && passwordConfirm.valid && encoded) {
      dispatch(
        confirmedPassword({
          password: password.value,
          encoded: encoded,
        })
      )
      history.push(EPages.SIGN_IN)
    } else {
      const passwordResult = ValidationHelper.validatePassword(password.value)
      setPassword(passwordResult)

      const passwordConfirmResult = ValidationHelper.validatePasswordConfirm(
        password.value,
        passwordConfirm.value
      )
      setPasswordConfirm(passwordConfirmResult)
    }
  }, [
    password,
    passwordConfirm,
    encoded,
    dispatch,
    history,
    setPassword,
    setPasswordConfirm,
  ])

  const handleClickShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <div onKeyPress={formOnKeyPressed} className={Styles.container}>
      {themeSwitcher && <ThemeSwitcher />}
      <div className={Styles.wrapper}>
        <div
          className={Styles.wrapperLogo}
          style={{
            background: 'var(--main-logo) no-repeat left top / contain',
          }}
        ></div>
        <Form name='basic'>
          <Typography
            className={Styles.MuiTypographySubtitle}
            variant='subtitle1'
            gutterBottom
            component='div'
          >
            {local.recoveryPassword.titleRecovery}
          </Typography>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
              },
            ]}
            className={Styles.formItem}
            validateStatus={password.message.length > 0 ? 'error' : ''}
          >
            <EISPopoverValidation
              ruleValidate={
                ValidationHelper.passwordStrength(password.value).ruleValidate
              }
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                name={password.value}
                autoComplete='new-password'
                className={Styles.inputForm}
                onChange={onChangePasswordHandler}
                data-testid={'password'}
                label={local.confirmedPassword.form.password}
                variant='filled'
                id='filled-password-input'
                type={showPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 30 }}
              />
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            </EISPopoverValidation>
          </Form.Item>
          <EISPasswordProgress
            passwordStrength={
              ValidationHelper.passwordStrength(password.value).count
            }
          />
          <Form.Item
            name='passwordConfirm'
            rules={[
              {
                required: true,
              },
            ]}
            className={Styles.formItem}
            help={passwordConfirm.message}
            validateStatus={passwordConfirm.message.length > 0 ? 'error' : ''}
          >
            <TextField
              InputProps={{ disableUnderline: true }}
              name={password.value}
              className={Styles.inputForm}
              onChange={onChangePasswordConfirmHandler}
              data-testid={'confirmPassword'}
              label={local.confirmedPassword.form.passwordConfirm}
              variant='filled'
              id='filled-password-input'
              type={showPassword ? 'text' : 'password'}
              inputProps={{ maxLength: 30 }}
            />
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          </Form.Item>
          <Form.Item>
            <Button
              className={Styles.btn}
              onClick={onConfirmedHandler}
              disabled={!password.value.length}
            >
              {local.confirmedPassword.confirmedButton}
            </Button>
          </Form.Item>
        </Form>
        <Link to='/signin' className={Styles.backTo}>
          <BackArrow />
          <span>{local.recoveryPassword.backToSignInLink}</span>
        </Link>
      </div>
      <div className={Styles.img_side}>
        <Image src={themeProps['--auth-background-image']} preview={false} />
      </div>
    </div>
  )
}

export default ConfirmedPasswordPL
