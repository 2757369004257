export const settings = {
  tabs: {
    genereal: 'General',
    profileSettings: 'Profile settings',
    systemSettings: 'System settings',
  },
  expiration: {
    title: 'Profile activity',
    date: 'Profile is active until :date',
    help: 'To renew your profile, you must make a payment',
  },
  profileImg: 'Change avatar',
  changePassword: {
    label: 'Change password',
    currentPassword: 'Current password:',
    newPassword: 'Enter new password:',
    confirmedPassword: 'Confirm password:',
    currentPasswordPlaceholder: 'Enter current password',
    newPasswordPlaceholder: 'Enter new password',
    confirmedPasswordPlaceholder: 'Confirm password',
    button: 'Change password',
  },
  changeEmail: {
    label: 'Change email',
    newEmail: 'Enter new email:',
    confirmedEmail: 'Confirm new email:',
    newEmailPlaceholder: 'Enter new email',
    confirmedEmailPlaceholder: 'Confirm new email',
    button: 'Change email',
  },
  changeEmailModal: {
    title: 'Change email',
    content:
      'Are you sure you want to change your email to :Email? After the change, you will need to re-authorize in the system with the new email',
    okText: 'Confirm',
    cancelText: 'Cancel',
  },
  changePhoneNumber: {
    label: 'Change phone number',
    newPhoneNumber: 'Enter new phone number:',
    newPhoneNumberPlaceholder: 'Enter new phone number',
    newPhoneNumberSearchPlaceholder: 'Search',
    button: 'Change phone number',
  },
  changePhoneNumberModal: {
    title: 'Change phone number',
    content: 'Are you sure you want to change your phone number to :Phone?',
    okText: 'Confirm',
    cancelText: 'Cancel',
  },
  notifier: {
    label: 'Notifications',
    prompt: 'Send notifications to the email',
  },
  selfRegistration: {
    label: 'Registration',
    prompt: 'User self-registration',
  },
  saveSettingsButton: 'Save',
}
