export const taskPreview = {
  backToEdit: 'Back to edit',
  question: {
    titles: {
      task: 'Task № ',
    },
  },
  answer: {
    video: 'Video answer',
    text: {
      placeholder: 'Enter the answer',
      loadFileButton: 'Upload file',
    },
  },
}
