import process_en from '../../assets/png/help_process_en.jpg'
import management_en from '../../assets/png/help_management_en.jpg'
import status_en from '../../assets/png/help_status_en.jpg'

export const help = {
  welcome: 'Hoş geldiniz :System!',
  purpose:
    ':System ana fikri, çalışanları iş süreçlerine, pozisyonlarına entegre etmek, onlara yeni şeyler öğretmek, şirkette yararlı bilgiler biriktirmek ve depolamaktır, önemli bir özellik ise bunu kendilerinin yapmasıdır.',
  canDo: {
    title: 'Kullanıcılar ne yapabilir:',
    features: [
      'parça oluşturma',
      'iki eğitim modu arasında seçim yapabilir: "mentorlu" veya "mentorsuz"',
      'tamamlanan görevler hakkında geri bildirim almak',
      'izleri, ilerlemeyi, çalışanların performansını analiz etmek için istatistikleri kullanmak',
      'meslektaşların en iyi uygulamaları ve deneyimleri hakkında bilgi almak',
      'kariyerinizin yönünü değiştirmek istiyorsanız - kendinizi başka bir role kaptırın, çeşitli yollar var',
      'bilginin desteklenmesi ve güncellenmesi (şirkette bir şey değiştiyse)',
      'çalışanın yeni rolüne %100 hazır olduğundan ve tüm görevleri tamamlayarak bilgisini teyit ettiğinden emin olmak',
      'kullanıcı erişim haklarını yönetme',
    ],
    solution:
      'Farklı fonksiyonları ve organizasyonel kuralları birleştirerek çeşitli şirket vakalarını çözmek mümkündür.',
  },
  problem: {
    title: ':System hedefe nasıl ulaşıyor?',
    solution:
      'Şirketin parkuruna bir yönetici atarız (aslında parkur bilgi alanı, işlev, iş süreci, rol vb.dir) ve şemaya göre devam ederiz:',
  },
  fastNote: 'Yönetici için parça oluşturma hakkında hızlı brifing',
  passing: 'Bana atanan parkuru nasıl geçebilirim?',
  wishes: {
    title: 'Bir hata fark ettiniz mi veya herhangi bir öneriniz var mı? ',
    btn: 'Bize ',
    titleEnd: ' bildirin!',
  },
  imgSrc: {
    process: process_en,
    management: management_en,
    status: status_en,
  },
  imgSVG: {
    problem: {
      manager: 'Yönetici',
      createTrack: 'Bir takip oluşturur',
      track: 'Takip',
      trackWasAssigned: 'Kullanıcıya geçmek için takip görevlendirilir',
      employee: 'Çalışan',
      runsTheTrack: 'Modüllere görevleri tamamlar',
      mentor: 'Mentor',
      checkCompletedTask: 'Tamamlanan görevleri kontrol eder',
      readyToWork: '%100 çalışmaya hazır',
    },
    fastNote: {
      controlPanel: 'Kontrol paneli',
      hotkeys: {
        title: 'Kısayol Tuşları:',
        altQ: 'Alt + Q :split Yeni Bir Modül Ekle',
        altW: 'Alt + W :split Yeni Bir Bağlantı Ekle',
        altE: 'Alt + E :split Başlığı Düzenle',
        altS: 'Alt + S :split Değişiklikleri Kaydet',
        altC: 'Alt + C :split Modülü Kopyala',
      },
      textList: {
        changeModule: 'Modül isimlerini değiştirin',
        addModule: 'Yeni bilgi modülleri ekleyin',
        createConnection: 'Modüller arasında bağlantılar oluşturun',
        deleteModule: 'Güncelliğini yitiren bilgi modüllerini kaldırın',
        saveChanges: 'Değişiklikleri kaydedin',
        doubleClickModule:
          'Modülün açıklamasına ve görevlerine ulaşmak için modüle çift tıklayın veya bu düğmeye tıklayın',
        copyModule: 'Modülü kopyalayarak zaman kazanın',
        indicatorsWillShow:
          'Göstergeler şunları belirtecektir: Kullanıcı, kontrol bekletmeksizin bir sonraki modüle geçebilir mi Modüle açıklama eklendi mi Hangi testler, açık cevaplar ve video cevapları yapılandırıldı',
      },
    },
    passing: {
      completeTask: {
        title: 'Görevi yerine getirmek için:',
        read: 'Modül açıklamasını okuyun ve öğrenin',
        confirm: 'Bilgi kontrolüne hazır olduğunuzu onaylayın',
        complete: 'Görevleri tamamlayın',
        send: 'Gözden geçirme için gönderin',
      },
      start: 'Başlangıç',
      task: 'Görev',
      moduleCompleted:
        'Modül tamamlanmış ve bilgi edinilmiştir. Artık modülden gelen bilgi her zaman erişilebilir ve bilgiler herhangi bir zamanda yenilenebilir.',
      moduleUnderReview:
        'Modül, mentor tarafından inceleniyor. Sonuçları bekleyin.',
      youCantStart:
        'Önceki modüldeki ödev cevapları doğrulanmamış ve mentör tarafından onaylanmamış olduğu için bu modülün çalışmasına başlayamazsınız',
      moduleAvailable:
        'Modül mevcut ve görevleri yapmaya başladınız ancak henüz cevaplarınızı değerlendirmeye göndermediniz.',
      moduleTasksHaveBeenReviewed:
        'Modül görevleri kontrol edildi ancak sonuç kabul edilmedi. Görevler tekrar yapılmalıdır',
    },
  },
  feedBackModal: {
    titleModal: 'Geri bildirim',
    subjectMessage: 'Mektubun konusu',
    textAreaPlaceholder: 'Mektup metni',
    messageType: {
      bug: 'Hata',
      question: 'Soru',
      proposal: 'Teklif',
      other: 'Diğer',
    },
    uploadTitle: 'Fotoğraf veya video ekleyin',
    footNote:
      'If you want to attach a video and/or a photo, add a link to the text',
    actionBtnSend: 'Gönder',
    actionBtnCancle: 'İptal',
    thanksMessage: 'Geri bildiriminiz için teşekkür ederiz',
  },
}
