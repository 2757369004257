import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { TMap } from '../../constants'
import { IStatNodesResponse } from '../../features/stats/stat-items/IStatItems'
import { EAPIEndpoint } from '../constants'
const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
export default class NodesService {
  static getNodes(
    trackId: number
  ): Observable<IAPIResult<I.IAvailableNodesResponse>> {
    const baseApiService = new BaseApiService<I.IAvailableNodesResponse>()
    return baseApiService.getRequest(
      EAPIEndpoint.AVAILABLE_NODES.replace(':trackId', trackId.toString())
    )
  }

  static getManagementNodes(
    trackId: number
  ): Observable<IAPIResult<I.IManagementNodesResponse>> {
    const baseApiService = new BaseApiService<I.IManagementNodesResponse>()
    return baseApiService.getRequest(
      EAPIEndpoint.MANAGEMENT_NODES.replace(':trackId', trackId.toString())
    )
  }

  // static getPreviewNodes(
  //   trackId: number
  // ): Observable<IAPIResult<I.IPreviewNodesResponse>> {
  //   const baseApiService = new BaseApiService<I.IPreviewNodesResponse>()
  //   return baseApiService.getRequest(
  //     EAPIEndpoint.PREVIEW_NODES.replace(':trackId', trackId.toString())
  //   )
  // } // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра

  static getStatItemsNodes(
    track: number
  ): Observable<IAPIResult<IStatNodesResponse>> {
    const baseApiService = new BaseApiService<IStatNodesResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_ITEMS_NODES}${getParams({ track })}`
    )
  }

  static getStatUsersNodes(
    user: string,
    track: number
  ): Observable<IAPIResult<IStatNodesResponse>> {
    const baseApiService = new BaseApiService<IStatNodesResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.STAT_USERS_NODES}${getParams({ track, user })}`
    )
  }

  static saveNodes(body: I.ISaveNodesRequest): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.SAVE_NODES, head, body)
  }

  static copyNode(body: I.ICopyNodeRequest): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.COPY_NODE, head, body)
  }

  static getNodeStatus(
    nodeId: number
  ): Observable<IAPIResult<I.INodeStatusRequest>> {
    const baseApiService = new BaseApiService<I.INodeStatusRequest>()
    return baseApiService.getRequest(
      EAPIEndpoint.NODE_STATUS.replace(':nodeId', nodeId.toString())
    )
  }

  static getNodeTitle(nodeId: string): Observable<IAPIResult<string>> {
    const baseApiService = new BaseApiService<string>()
    return baseApiService.getRequest(
      EAPIEndpoint.NODE_TITLE.replace(':nodeId', nodeId.toString())
    )
  }
}
