export const login = {
  form: {
    login: 'Login (email)',
    password: 'Password',
  },
  title: 'Welcome',
  loginButton: 'Log in',
  checkbox: 'Work only in this tab',
  restoreLink: 'Forgot your password?',
  signupLink: 'Create account',
  validation: {
    loginRequired: 'Login',
    loginValid: 'Login must be a valid email address',
    passwordRequired: 'Password',
    passwordLengthValid: 'Use 8 or more characters',
    passwordValid:
      'Use a mix of lowercase and uppercase latin letters, numbers and symbols',
  },
}
