import { IThemeProps } from '..'

import logo_mini from '../../assets/svg/uMentor_logo.svg'
import logo from '../../assets/svg/uMentor_logo.svg'
import auth_bg_img from '../../assets/svg/smart_bg.svg'
import card_img from '../../assets/png/uMentor_img.jpg'

export const umentor: IThemeProps = {
  '--font-family': `'Roboto', sans-serif`,
  '--mini-logo': `url('${logo_mini}')`,
  '--main-logo': `url('${logo}')`,
  '--background-image': ``,
  '--auth-background-image': auth_bg_img,
  '--default-card-image': card_img,
  '--main-theme-color': '#BF64D2',
  '--light-main-theme-color': '#FDF4FF',
  '--main-text-color': '#394F83',
  '--reverse-text-color': '#FFFFFF',
  '--secondary-text-color': '#969FA2',
  '--icon-color': '#1C212D',
  '--divider-color': '#CED5D4',
  '--main-background-color': '#FFFFFF',
  '--secondary-background-color': '#F4F4F4',
  '--first-complementary-color': '#D4DAD9',
  '--second-complementary-color': '#7683D7',
  '--third-complementary-color': '#FFB664',
  '--fourth-complementary-color': '#4Ab97C',
  '--fifth-complementary-color': '#DD6146',
  '--sixth-complementary-color': '#E154FF',
  '--seventh-complementary-color': '#C19569',
  '--eighth-complementary-color': '#E1A9B3',
  '--default-complementary-color': '#8D9698',
  '--gradient-colors': ['#FFB596', '#FFD15C', '#E3FF93', '#65C280'],
}