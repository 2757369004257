import React from 'react'
import { Col, Row } from 'antd'
import ProfileTracksPL from './ProfileTracks/ProfileTracksPL'
import ProfileTasksPL from './ProfileTasks/ProfileTasksPL'
import style from './ProfileStat.module.scss'
import * as I from 'Types'
import local from './../../../../localization'
import ProfileActionBar from './ProfileActionBar/ProfileActionBar'

const ProfileStatPL: React.FC<I.IProfileProps> = ({ userId }): JSX.Element => {
  //#region Track

  //#endregion

  return (
    <div className={style.statContent}>
      <Row
        align={'middle'}
        gutter={[12, 6]}
        justify={'space-between'}
        className={style.title}
      >
        <Col>
          <h3>{local.profile.stat.title}</h3>
        </Col>
      </Row>
      <ProfileActionBar userId={userId} />
      <ProfileTracksPL userId={userId} />
      <ProfileTasksPL userId={userId} />
    </div>
  )
}

export default ProfileStatPL
