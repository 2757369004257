import { ColumnsType, TableProps } from 'antd/lib/table'
import {
  IPosition,
  ITask,
  INode,
  IStatUsersColumn,
  IStatUserItemColumn,
  IUserColumn,
  ITasksStatusColumn,
  ITrack,
  IConvertFoldersDataType,
} from 'Types'
import { IStatItemColumn } from '../../features/stats/stat-items/IStatItems'

export enum ETables {
  PROFILE_TASKS = 'profile_tasks',
  POSITIONS = 'positions',
  USERS = 'users',
  TASKS = 'tasks',
  STAT_ITEMS = 'stat_items',
  STAT_USERS = 'stat_users',
  STAT_USER_ITEMS = 'stat_user_items',
  TRACKS = 'tracks',
}

export type TArrayColumnType =
  | IUserColumn
  | IPosition
  | ITrack
  | ITask
  | INode
  | IStatUsersColumn
  | IStatUserItemColumn
  | ITasksStatusColumn
  | IStatItemColumn
  | IConvertFoldersDataType

export interface IViewTable extends TableProps<TArrayColumnType> {
  data: Array<TArrayColumnType>
  columns: ColumnsType<TArrayColumnType>
  mainColumns: string[]
  type: ETables
  fileName?: string
  navigate?: JSX.Element
  height?: number | string
  emptyText?: string
  selectedNode?: Array<number>
  setSelectedNodes?: (node: Array<number>) => void | undefined
  selectedRow?: number
  isExportButtonVisible?: boolean
  filters?: {
    isActive?: boolean
    role?: number
    isManager?: boolean
    isMentor?: boolean
    fullName?: string
    sort?: string | null
    user?: string | null
    track?: string | null
  }
  exportMethod?: Function
  isExportDataExist?: boolean
  excludeFields?: string[]
}
