/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import * as I from './IRouter'
import {
  Authorities,
  ENotificationVariant,
  EPages,
  ETaskState,
  ETrackEngineMode,
} from '../../../constants'
import { isInRole, logout } from '../../../services/auth'
import { getAcceptedCount } from '../../../features/task-check/taskCheckSlice'
import { store, useAppDispatch } from '../../../app/store'
import { Spin } from 'antd'
import local from './../../../localization'

import ProfilePL from '../../../features/profile/components/ProfilePL'
import AuthorizationPL from '../../../features/auth/login/components/AuthorizationPL'
import RegistrationPL from '../../../features/auth/registration/components/RegistrationPL'
import RecoveryPasswordPL from '../../../features/auth/recovery-password/components/RecoveryPasswordPL'
import ConfirmedPasswordPL from '../../../features/auth/confirned-password/components/confirmedPasswordPL'
import TracksService from '../../../services/tracks/tracks.service'
import NodesService from '../../../services/nodes/nodes.service'
import { enqueueSnackbar } from '../../../features/notification/notificationSlice'
import { getSelfRegistrationStatus } from '../../../features/profile/settings/profileSettingsSlice'

const TracksPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/tracks/available/components/TracksPL'
  )
)
const TracksEnginePL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/tracks-engine/containers/Main/TrackEnginePL'
  )
)
const NodesAvailablePL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/nodes/nodes-available/nodesAvailablePL'
  )
)
const TaskPassing = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/task-passing/components/TaskPassingPL'
  )
)
const TasksPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/tasks/components/TasksPL'
  )
)
const TaskPassingStart = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/task-passing-start/components/TaskPassingStartPL'
  )
)
const TaskViewPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/task-veiw/components/TaskViewPL'
  )
)
const TasksCheckPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/task-check/components/TaskCheckPL'
  )
)
const TracksManagmentPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/tracks/manager/TracksManagerPL'
  )
)
const NodesManagementPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/nodes/nodes-management/nodesManagementPL'
  )
)
const TaskEditPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/task-edit/components/TaskEditPL'
  )
)
const UsersPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/users/components/UsersPL'
  )
)
const PositionsPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/positions/components/PositionsPL'
  )
)
const TaskPassResult = React.lazy(() =>
  import('../../../features/task-passing-result/components/TaskPassResult')
)
const StatUsersPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/stats/stat-users/components/statUsersPL'
  )
)
const StatUserItemPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/stats/stat-users/stat-user-item/components/statUserItemPL'
  )
)
const StatItemsPL = React.lazy(() =>
  import(
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    '../../../features/stats/stat-items/components/statItemsPL'
  )
)
const Help = React.lazy(() => import('../../../features/help/Help'))
const ProfileSettingsPL = React.lazy(() =>
  import('../../../features/profile/settings/components/profileSettingsPL')
)

export const Router: React.FC<I.OwnProps> = ({
  isLoggedIn,
}): React.ReactElement => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const lastTrackId = localStorage.getItem('lastTrackId')

  const selfRegistrationEnabled = store.getState().settings.userSelfRegistration

  const matchAll = useRouteMatch({
    path: Object.values(EPages),
    exact: true,
    sensitive: true,
  })

  const matchAuth = useRouteMatch({
    path: Object.values(EPages).filter(
      v =>
        ![
          EPages.SIGN_IN,
          EPages.SIGN_UP,
          EPages.RESTORE,
          EPages.NEW_PASSWORD,
        ].includes(v)
    ),
    exact: true,
    sensitive: true,
  })

  useEffect(() => {
    dispatch(getSelfRegistrationStatus())
  }, [])

  useEffect(() => {
    if (isLoggedIn && isInRole(Authorities.CHECK_TASKS_PAGE)) {
      dispatch(getAcceptedCount())
    }

    switch (location.pathname) {
      case '/': {
        history.push(isLoggedIn ? EPages.PROFILE : EPages.SIGN_IN)
        break
      }
      case EPages.SIGN_IN:
      case EPages.SIGN_UP:
      case EPages.RESTORE: {
        if (isLoggedIn) {
          if (location.search.includes('?encoded')) {
            history.push(EPages.PROFILE)
            dispatch(
              enqueueSnackbar({
                message: local.notification.auth.ERROR.invalidLink,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: ENotificationVariant.ERROR,
                },
              })
            )
          } else if (location.search) {
            history.push(location.search.replace('?returnUrl=', ''))
          } else {
            if (lastTrackId) {
              NodesService.getNodes(Number(lastTrackId))
                .toPromise()
                .then(result => {
                  if (
                    !!result.data?.nodes.filter(
                      n => n.status !== ETaskState.COMPLETED
                    ).length
                  ) {
                    history.push(
                      EPages.AVAIBLE_TRACKS_NODES.replace(
                        ':trackId',
                        lastTrackId
                      )
                    )
                  } else {
                    localStorage.removeItem('lastTrackId')
                    TracksService.getAvailableTracks({
                      page: 0,
                      size: 10,
                      isCompleted: false,
                    })
                      .toPromise()
                      .then(tracks => {
                        if (!!tracks.data?.content.length) {
                          history.push(EPages.AVAIBLE_TRACKS)
                        } else {
                          history.push(EPages.PROFILE)
                        }
                      })
                  }
                })
            } else {
              TracksService.getAvailableTracks({
                page: 0,
                size: 10,
                isCompleted: false,
              })
                .toPromise()
                .then(tracks => {
                  if (!!tracks.data?.content.length) {
                    history.push(EPages.AVAIBLE_TRACKS)
                  } else {
                    history.push(EPages.PROFILE)
                  }
                })
            }
          }
        } else {
          const title =
            location.pathname === EPages.SIGN_IN
              ? local.main.auth.signIn
              : location.pathname === EPages.SIGN_UP
              ? local.main.auth.signUp
              : local.main.auth.restore
          document.title = title
        }
        break
      }
      case EPages.NEW_PASSWORD: {
        const title = local.main.auth.newPassword
        document.title = title

        if (isLoggedIn) {
          logout()
          setTimeout(() => window.location.reload(), 500)
        }
        break
      }
      default: {
        if (!matchAll) {
          history.push(EPages.NOT_FOUND)
        } else if (matchAuth && !isLoggedIn) {
          history.push(`${EPages.SIGN_IN}?returnUrl=${location.pathname}`)
        }
        break
      }
    }
  }, [isLoggedIn, location.pathname])

  return (
    <Switch>
      {isLoggedIn ? (
        <>
          {isInRole(Authorities.PROFILE_PAGE) && (
            <Route exact path={EPages.PROFILE} render={() => <ProfilePL />} />
          )}
          {isInRole(Authorities.USERS_PROFILES) && (
            <Route
              exact
              path={EPages.USERS_PROFILE}
              render={() => <ProfilePL />}
            />
          )}
          {isInRole(Authorities.PROFILE_PAGE) && (
            <Route
              exact
              path={EPages.PROFILE_SETTINGS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <ProfileSettingsPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.USERS_PAGE) && (
            <Route
              exact
              path={EPages.USERS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <UsersPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.POSITIONS_PAGE) && (
            <Route
              exact
              path={EPages.POSITIONS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <PositionsPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(
            Authorities.CHECK_TASKS_PAGE ||
              Authorities.TASKS_CHECK_SHOW_ALL ||
              Authorities.TASKS_CHECK_SHOW_ONLY_ALLOWED
          ) && (
            <Route
              exact
              path={EPages.CHECK_TASKS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TasksPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.CHECK_TASK_PAGE) && (
            <Route
              path={EPages.CHECK_TASK_DETAILS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TasksCheckPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.AVAILABLE_TRACKS_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TracksPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.AVAILABLE_TRACKS_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS_PASSING}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TaskPassing />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.AVAILABLE_TRACKS_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS_PASSING_START}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TaskPassingStart />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.AVAILABLE_TRACKS_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS_PASSING_RESULT}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TaskPassResult />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.MANAGEMENT_TRACKS_PAGE) && (
            <Route
              exact
              path={EPages.TRACKS_MANAGEMENT}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TracksManagmentPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.EDIT_TASK_PAGE) && (
            <Route
              exact
              path={EPages.TRACKS_MANAGEMENT_DETAILS_EDIT_TASK_DETAILS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TaskEditPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.VIEW_PASS_TASK_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS_VIEW_PASSING_TASK}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <TaskViewPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.MANAGEMENT_NODES_PAGE) && (
            <Route
              exact
              path={EPages.TRACKS_MANAGEMENT_DETAILS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <NodesManagementPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.AVAILABLE_NODES_PAGE) && (
            <Route
              exact
              path={EPages.AVAIBLE_TRACKS_NODES}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <NodesAvailablePL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.STAT_PAGE) && (
            <Route
              exact
              path={EPages.STATS_BY_USERS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <StatUsersPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.STAT_PAGE) && (
            <Route
              exact
              path={EPages.STATS_BY_USER_FOR_ITEM}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <StatUserItemPL />
                </React.Suspense>
              )}
            />
          )}
          {isInRole(Authorities.STAT_PAGE) && (
            <Route
              exact
              path={EPages.STATS_BY_ITEMS}
              render={() => (
                <React.Suspense fallback={<Spin />}>
                  <StatItemsPL />
                </React.Suspense>
              )}
            />
          )}
          <Route
            exact
            path={EPages.REFERENCE}
            render={() => (
              <React.Suspense fallback={<Spin />}>
                <Help />
              </React.Suspense>
            )}
          />
          <Route
            exact
            path={EPages.TEST}
            render={() => (
              <React.Suspense fallback={<Spin />}>
                <TracksEnginePL mode={ETrackEngineMode.EDIT} trackId={'1'} />
              </React.Suspense>
            )}
          />
        </>
      ) : (
        <>
          <Route
            exact
            path={EPages.SIGN_IN}
            render={() => <AuthorizationPL />}
          />
          {selfRegistrationEnabled ? (
            <Route
              exact
              path={EPages.SIGN_UP}
              render={() => <RegistrationPL />}
            />
          ) : (
            <Redirect to={EPages.SIGN_IN} />
          )}
          <Route
            exact
            path={EPages.RESTORE}
            render={() => <RecoveryPasswordPL />}
          />
          <Route
            exact
            path={EPages.NEW_PASSWORD}
            render={() => <ConfirmedPasswordPL />}
          />
        </>
      )}
    </Switch>
  )
}

export default Router
