import React, { useEffect, useState } from 'react'

export const useTitle = (title?: string) => {
  const [state, setState] = useState<string>(title!!)

  useEffect(() => {
    const prevTitle = document.title
    document.title = state

    return () => {
      document.title = prevTitle
    }
  })

  const updateTitle = React.useCallback((title: string) => {
    setState(title)
  }, [])

  return updateTitle
}
