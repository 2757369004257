export enum EAPIEndpoint {
  AVAILABLE_NODES = '/nodes/:trackId',
  AVAILABLE_NODE_TASK = '/tasks/:nodeId',
  AVAILABLE_NODE_TASK_QUESTIONS = '/tasks/:nodeId/questions',
  AVAILABLE_TRACKS = '/tracks',
  TRACKS = '/tracks/area/items',
  AREA = '/tracks/area',
  AREA_DELETE = '/tracks/area/:areaId',
  ALIEN_INFO = '/users/:userId/profile/info',
  ALIEN_LAST_ACTIONS = '/users/:userId/profile/last_actions',
  ALIAN_STAT_TRACKS = '/users/:userId/profile/statistics/tracks',
  ALIAN_STAT_TASKS = '/users/:userId/profile/statistics/tasks',
  SEND_FEEDBACK = '/users/support/email',
  BREIF_INFO = '/profile/brief_info',
  CONFIRMED_EMAIL = '/email',
  CONFIRMED_PASSWORD = '/password/new',
  COPY_NODE = '/nodes/management/copy/node',
  CHANGE_AVATAR = '/profile/avatar',
  CHANGE_PASSWORD = '/profile/settings/change/password',
  CHANGE_EMAIL = '/profile/settings/change/email',
  CHANGE_PHONE_NUMBER = '/profile/settings/change/phone',
  CHANGE_SELF_REGISTRATION = '/profile/settings/change/self_registration',
  DELETE_POSITION = '/positions/:positionId',
  DELETE_ITEMS = '/items/:itemId',
  DEFAULT_ITEMS = '/positions/:positionId/items',
  ERROR = '/error',
  FILTER = '/filter',
  GET_AUTHORITIES = '/auth/authorities',
  ITEMS = '/items',
  LOGIN = '/login',
  LOGOUT = '/logout',
  MANAGEMENT_TRACKS = '/tracks/management',
  MANAGEMENT_NODES = '/nodes/management/:trackId',
  MANAGEMENT_NODE_INFO = '/nodes/management/info/:nodeId',
  SAVE_NODES = '/nodes/management',
  MANAGEMENT_NODE_TASK = '/tasks/management/:nodeId',
  MANAGEMENT_NODE_TASK_CHANGE = '/tasks/management',
  NODE_STATUS = '/nodes/:nodeId/status',
  NODE_TITLE = '/nodes/title/:nodeId',
  NODE_TITLE_CHANGE = '/nodes/title',
  NODE_TOPIC = '/topics/:nodeId',
  NODE_TOPIC_CHANGE = '/topics',
  NODE_PASSED_USERS = '/nodes/:nodeId/users/completed',
  NODE_REJECTED_USERS = '/nodes/:nodeId/users/rejected',
  NODE_RESET_PASSED_USERS = '/nodes/:nodeId/users/reset',
  NODE_TOPIC_CHANGED_NOTIFY = '/nodes/:nodeId/users/notify',
  // PREVIEW_NODES = '/nodes/preview/:trackId',
  // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра
  PROFILE_INFO = '/profile/info',
  PROFILE_LAST_ACTIONS = '/profile/last_actions',
  PROFILE_STAT_TRACKS = '/profile/statistics/tracks',
  PROFILE_STAT_TASKS = '/profile/statistics/tasks',
  PROFILE_SETTINGS = '/profile/settings/all',
  SELF_REGISTRATION = '/profile/settings/self_registration',
  REGISTER = '/signup',
  REFRESH_TOKEN = '/auth/refresh',
  RESET_PASSWORD = '/password/restore',
  ROLES = '/roles',
  POSITIONS = '/positions',
  SEARCH = '/search/findText',
  SIGNIN = '/signin',
  STAT_ITEMS_NODES = '/statistics/tracks/map',
  STAT_USERS_NODES = '/statistics/users/map',
  STAT_USERS_PROGRESS = '/statistics/users/progress',
  STAT_USERS_TASKS = '/statistics/users/tasks',
  STAT_USERS_DYNAMIC = '/statistics/users/dynamic',
  STAT_USERS_STATUSES = '/statistics/users/statuses',
  STAT_USERS_TABLE = '/statistics/users/table',
  STAT_USERS_TABLE_EXPORT = '/statistics/users/table/export',
  STAT_ITEMS_PROGRESS = '/statistics/tracks/progress',
  STAT_ITEMS_DYNAMIC = '/statistics/tracks/dynamic',
  STAT_ITEMS_BY_USERS = '/statistics/tracks/users',
  STAT_ITEMS_BY_TASKS = '/statistics/tracks/tasks',
  STAT_ITEMS_BY_NODES = '/statistics/tracks/nodes',
  STAT_ITEMS_TABLE = '/statistics/tracks/table',
  STAT_NODES_RESET_PASSED_USER = '/statistics/user/reset',
  STAT_ITEMS_TABLE_EXPORT = '/statistics/tracks/table/export',
  SOLUTION_ATTEMPTS = '/solutions/attempts',
  SOLUTION_ATTEMPT = '/solutions/attempts/:nodeId',
  SOLUTION_ATTEMPT_REJECT = '/solutions/attempts/reject',
  TASKS_VERIFICATION = '/solutions/attempts/verification',
  TASKS_VERIFICATION_COUNT = '/solutions/attempts/verification/count',
  TASK_VERIFICATION = '/solutions/attempts/verification/:attemptId',
  TASK_PASSING_START_TIME = '/tasks/time',
  TASK_PASSING_DRAFT_GET = '/solutions/attempts/drafts/:taskId',
  TASK_PASSING_DRAFT_SET = '/solutions/attempts/drafts',
  TRACK_EXPORT = '/items/export',
  // TRACK_EXPORT = '/transfer/export/track', // EIS-2663
  TRACK_IMPORT = '/items/import',
  TRACK_TITLE = '/tracks/title/:trackId',
  UPLOAD_FILE_BASE64 = '/files/upload/base64',
  UPLOAD_FILE_BINARY = '/files/upload/binary',
  UPLOAD_DELAYED_FILE_BINARY = '/files/upload/binary/delayed',
  USERS = '/users',
  USERS_DELETE = '/users/:userId',
  USERS_PASSWORD_RESET = '/users/reset/:userId',
  USERS_EMAIL_CHANGE = '/users/email/change',
  USER_NAME = '/users/title/:userId',
  USERS_TABLE_EXPORT = '/users/table/export',
  SYSTEM_MAINTENANCE = '/system/events/next',
  JOB_RESULTS = '/files/result',
}
