import { rootArea } from './../../../constants/index'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import local from './../../../localization'
import * as I from 'Types'
import _ from 'lodash'
import TracksService from '../../../services/tracks/tracks.service'
import { stackRequestWrapper } from '../../../app/requestWrapper'
import { processError } from '../../../app/processError'
import { enqueueSnackbar } from '../../notification/notificationSlice'
import { ENotificationVariant, ETabState } from '../../../constants'
import { IAPIResult } from '../../../services/IBaseService'

const initialState: I.ITrackState = {
  tracks: [],
  searchTitle: undefined,
  isSearchItem: false,
  sortedInfo: undefined,
  tab: ETabState.CARD,
  areas: [],
  treeExpanded: [],
  cardViewSize: 3,
}

export const getAreas = createAsyncThunk<
  IAPIResult<I.IArea[]>,
  undefined,
  { state: I.RootState }
>('tracks/area', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    const result = await stackRequestWrapper(TracksService.getAreas())
    if (result.data) {
      const { selectedBranch } = getState().tracks.management
      const root = result.data.find(a => !a.level)

      if (root && !selectedBranch)
        dispatch(
          setSelectedBranch({
            id: root.id,
            title: root.title,
            level: root.level,
            children: [],
            tracks: root.tracks,
          })
        )
    }

    return result
  } catch (e) {
    dispatch(processError({ e }))
    return rejectWithValue('')
  }
})

export const getTracks = createAsyncThunk(
  'tracks',
  async (data: I.IGetTracksRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(TracksService.getTracks(data))

      return { result, params: data }
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const editArea = createAsyncThunk(
  'tracks/area/edit',
  async (data: I.IEditAreaRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(TracksService.editArea(data))

      dispatch(
        enqueueSnackbar({
          message: local.notification.area.SUCCESS.edited.replace(
            ':title',
            data.title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const editManagementTrack = createAsyncThunk(
  'tracks/management/edit',
  async (
    data: I.IEditManagementTrackRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(TracksService.postTrack(data))

      dispatch(
        enqueueSnackbar({
          message: local.notification.item.SUCCESS.edited.replace(
            ':title',
            data.title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const addArea = createAsyncThunk(
  'tracks/area/add',
  async (data: I.IAddAreaRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(TracksService.addArea(data))

      dispatch(
        enqueueSnackbar({
          message: local.notification.area.SUCCESS.added.replace(
            ':title',
            data.title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const addManagementTrack = createAsyncThunk(
  'tracks/management/add',
  async (data: I.IAddManagementTrackRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(TracksService.putTrack(data))

      dispatch(
        enqueueSnackbar({
          message: local.notification.item.SUCCESS.added.replace(
            ':title',
            data.title
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const deleteArea = createAsyncThunk(
  'tracks/area/delete',
  async (areaId: string, { rejectWithValue, dispatch }) => {
    try {
      await stackRequestWrapper(TracksService.deleteArea(areaId))

      dispatch(
        enqueueSnackbar({
          message: local.notification.area.SUCCESS.deleted,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return areaId
    } catch (e) {
      dispatch(processError({ e }))
      rejectWithValue(e)
    }
  }
)

export const deleteManagementTrack = createAsyncThunk(
  'tracks/management/delete',
  async (trackId: string, { rejectWithValue, dispatch }) => {
    try {
      await stackRequestWrapper(TracksService.deleteTrack(trackId))

      dispatch(
        enqueueSnackbar({
          message: local.notification.item.SUCCESS.deleted,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return trackId
    } catch (e) {
      dispatch(processError({ e }))
      rejectWithValue(e)
    }
  }
)

export const importTrack = createAsyncThunk<
  IAPIResult<I.ITrack>,
  I.IImportManagementTrackRequest,
  { state: I.RootState }
>(
  'tracks/managment/import',
  async (body, { rejectWithValue, dispatch, getState }) => {
    try {
      const result = await stackRequestWrapper(TracksService.importTrack(body))

      const { selectedBranch } = getState().tracks.management

      if (selectedBranch?.id !== rootArea.id && result.data) {
        const {
          id,
          groups,
          isActive,
          isDefault,
          isShadowMode,
          managers,
          mentors,
          title,
          users,
          description,
          imageUrl,
        } = result.data as I.ITrack

        const trackGroup = groups.map(g => ({
          id: g.id,
          mentors: Object.keys(g.mentors).map(k => Number(k)),
          users: Object.keys(g.users).map(k => Number(k)),
        }))

        const track: I.IEditManagementTrackRequest = {
          id: id!,
          groups: trackGroup,
          isActive,
          isDefault,
          isShadowMode,
          managers: Object.keys(managers).map(k => Number(k)),
          mentors: Object.keys(mentors).map(k => Number(k)),
          title,
          users: Object.keys(users).map(k => Number(k)),
          description,
          imageUrl,
          trackAreaId: selectedBranch?.id,
          trackAreaName: selectedBranch?.title,
        }

        await dispatch(editManagementTrack(track))
        await dispatch(getTracks({ areaId: selectedBranch?.id }))
      }

      dispatch(
        enqueueSnackbar({
          message: local.notification.track.SUCCESS.import,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const tracksManagementSlice = createSlice({
  name: 'tracksManagment',
  initialState,
  reducers: {
    resetTracksManagementState: state => {
      _.assign(state, {
        tracks: initialState.tracks,
      })
    },
    setSearchTitle: (state, action: PayloadAction<string | undefined>) => {
      state.searchTitle = action.payload
    },
    setIsSearchItem: (state, action: PayloadAction<boolean>) => {
      state.isSearchItem = action.payload
    },
    setSortedInfo: (
      state,
      action: PayloadAction<I.ISortColumn | undefined>
    ) => {
      state.sortedInfo = action.payload
    },
    setTab: (state, action: PayloadAction<ETabState>) => {
      state.tab = action.payload
    },
    setSelectedBranch: (state, action: PayloadAction<I.ITreeArea>) => {
      state.selectedBranch = action.payload
    },
    setTreeExpanded: (
      state,
      action: PayloadAction<{ id: number; collapse: boolean }[]>
    ) => {
      _.assign(state.treeExpanded, action.payload)
    },
    editTreeExpanded: (state, action: PayloadAction<number>) => {
      state.treeExpanded = state.treeExpanded.map(t =>
        t.id === action.payload ? { id: t.id, collapse: !t.collapse } : t
      )
    },
    setCardViewSize: (state, action: PayloadAction<number>) => {
      state.cardViewSize = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAreas.fulfilled, (state, action) => {
      const areas = action.payload.data
      if (areas) {
        if (!state.selectedBranch) {
          state.selectedBranch = {
            id: areas[0].id,
            level: areas[0].level,
            title: areas[0].title,
            tracks: areas[0].tracks,
            children: [],
          }
        }
        state.areas = _.uniq(_.assign(state.areas, areas))
      }
    })
    builder.addCase(getTracks.fulfilled, (state, action) => {
      const { result, params } = action.payload
      const tracks = result.data
      if (tracks) {
        if (params.areaId) {
          if (params.title) {
            const oldTracks = state.tracks.filter(
              t => !tracks.map(tr => tr.id).includes(t.id)
            )
            state.tracks = [...oldTracks, ...tracks]
          } else {
            const newTracks = _.unionWith(
              state.tracks,
              tracks,
              (a, b) => a.id === b.id
            )
            state.tracks = newTracks
          }
        } else {
          state.tracks = tracks
        }
      }
    })
    builder.addCase(editArea.fulfilled, (state, action) => {
      const area = action.payload.data

      state.areas = state.areas.map(a => (a.id === area?.id ? area : a))

      state.tracks.forEach(t => {
        if (
          t.id &&
          area?.tracks.includes(t.id) &&
          (t.trackArea === null ||
            (t.trackArea !== null &&
              Number(Object.keys(t.trackArea)[0]) !== area.id))
        ) {
          t.trackArea = { [area.id]: area.title }
        } else if (
          t.id &&
          !area?.tracks.includes(t.id) &&
          t.trackArea !== null &&
          Number(Object.keys(t.trackArea)[0]) === area?.id
        ) {
          _.assign(t, { ...t, trackArea: null })
        }
      })
    })
    builder.addCase(editManagementTrack.fulfilled, (state, action) => {
      const track = state.tracks.find(t => t.id === action.payload?.data?.id)
      if (track && action.payload?.data) {
        _.assign(track, {
          ...action.payload.data,
          trackArea:
            Number(Object.keys(action.payload.data.trackArea)[0]) !== 1
              ? action.payload.data.trackArea
              : null,
        })

        const trackId = action.payload.data.id
        const trackAreaId = Number(
          Object.keys(action.payload.data.trackArea)[0]
        )

        const currentArea = state.areas.find(a => a.tracks.includes(trackId!))
        const newArea = state.areas.find(a => a.id === trackAreaId)

        if (currentArea?.id! !== newArea?.id!) {
          _.assign(
            state.areas,
            state.areas.map(a => {
              if (currentArea && a.id === currentArea.id) {
                return { ...a, tracks: a.tracks.filter(t => t !== trackId) }
              } else if (newArea && a.id === newArea.id) {
                return { ...a, tracks: [...a.tracks, trackId] }
              } else {
                return a
              }
            })
          )
        }
      }
    })
    builder.addCase(addArea.fulfilled, (state, action) => {
      if (action.payload?.data)
        _.assign(state.areas, [...state.areas, action.payload.data])

      const trackIds = action.payload.data?.tracks
      const areaId = action.payload.data?.id || rootArea.id
      const areaTitle = action.payload.data?.title || rootArea.title

      trackIds?.forEach(trackId => {
        const currentTrack = state.tracks.find(t => t.id === trackId)
        state.tracks.forEach(track => {
          if (track.id === currentTrack?.id) {
            track.trackArea = { [areaId]: areaTitle }
          }
        })
      })
    })
    builder.addCase(addManagementTrack.fulfilled, (state, action) => {
      if (action.payload?.data)
        _.assign(state.tracks, [...state.tracks, action.payload.data])

      const trackId = action.payload.data?.id
      if (trackId && action.payload.data?.trackArea) {
        const trackAreaId = Object.keys(
          state.tracks.find(t => t.id === Number(trackId))?.trackArea!
        )[0]

        if (!!trackAreaId) {
          state.areas.forEach(a => {
            if (a.id === Number(trackAreaId)) {
              a.tracks = [...a.tracks, Number(trackId)]
            }
          })
        }
      }
    })
    builder.addCase(deleteArea.fulfilled, (state, action) => {
      const areaId = action.payload
      if (areaId) {
        /* If you marked the folder to be deleted, it takes the id of the parent
           Otherwise the deleted folder will be saved as selected and there will be an error when creating a new one,
           as this id does not already exist
        */
        if (state.selectedBranch?.id === Number(areaId)) {
          const getParentId = state.areas.find(a => a.id === Number(areaId))
            ?.parentId
          const parent = state.areas.find(a => a.id === getParentId)

          if (parent) {
            state.selectedBranch = {
              id: parent.id,
              level: parent.level,
              title: parent.title,
              tracks: parent.tracks,
              children: [],
            }
          }
        }

        state.areas = state.areas.filter(a => a.id !== Number(areaId))
      }
    })
    builder.addCase(deleteManagementTrack.fulfilled, (state, action) => {
      const trackId = action.payload
      if (trackId) {
        const trackArea = state.tracks.find(t => t.id === Number(trackId))
          ?.trackArea
        if (trackArea) {
          const areaId = Object.keys(
            state.tracks.find(t => t.id === Number(trackId))?.trackArea!
          )[0]
          state.areas.forEach(a => {
            if (a.id === Number(areaId)) {
              a.tracks = a.tracks.filter(t => t !== Number(trackId))
            }
          })
        }
        state.tracks = state.tracks.filter(t => t.id !== Number(trackId))
      }
    })
    builder.addCase(importTrack.fulfilled, (state, action) => {
      if (action.payload?.data)
        _.assign(state.tracks, [...state.tracks, action.payload.data])
    })
  },
})

export const { reducer: tracksManagmentReducer } = tracksManagementSlice

export const selectManagementTrackState = (state: I.RootState) =>
  state.tracks.management

export const selectAreas = (state: I.RootState) => state.tracks.management.areas

export const {
  resetTracksManagementState,
  setSearchTitle,
  setIsSearchItem,
  setSortedInfo,
  setTab,
  setSelectedBranch,
  setTreeExpanded,
  editTreeExpanded,
  setCardViewSize,
} = tracksManagementSlice.actions
