import React, { FC, useCallback, useState } from 'react'
import { Button } from '@mui/material'
import { setColorTypeButton } from '../../../EISComponents'
import Camera from '../Camera/Camera'
import * as I from './IImageCapture'
import local from '../../../../localization'

const ImageCapture: FC<I.IImageCapture> = ({ width, height, uploadImage }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)

  const handleCapture = useCallback(
    (blob: Blob) => {
      if (blob) {
        const fileName = `Avatar_${new Date().getMilliseconds()}.jpeg`
        const type = 'image/jpeg'
        const file = new File([blob], fileName, { type })
        uploadImage(file)
      }
    },
    [uploadImage]
  )

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        width: width ? width : '100%',
        height: height ? height : '100%',
        background: '#333333',
      }}
    >
      {isCameraOpen ? (
        <Camera onCapture={handleCapture} />
      ) : (
        <Button
          className={setColorTypeButton('white')}
          variant='contained'
          onClick={() => setIsCameraOpen(!isCameraOpen)}
        >
          {local.selfie.imageCaptuer.turnOnWebcam}
        </Button>
      )}
    </div>
  )
}

export default ImageCapture
