import { main } from './main.localization'
import { deleteModal } from './deleteModal.localization'
import { image } from './image.localization'
import { searchSelect } from './searchSelect.localization'
import { video } from './video.localization'
import { table } from './table.localization'
import { trackEngine } from './trackEngine.localization'
import { taskPassing } from './taskPassing.localization'
import { taskPreview } from './taskPreview.localization'
import { topicPreview } from './topicPreview.localization'
import { taskEdit } from './taskEdit.localization'
import { taskCheckAndView } from './taskCheckAndView.localization'
import { statUsers } from './statUsers.localization'
import { statItems } from './statItems.localization'
import { nodesManagement } from './nodesManagement.localization'
import { registration } from './registration.localization'
import { recoveryPassword } from './recoveryPassword.localization'
import { confirmedPassword } from './confirmedPassword.localization'
import { login } from './login.localization'
import { items } from './items.localization'
import { positions } from './positions.localization'
import { users } from './users.localization'
import { tasksChecking } from './tasksChecking.localization'
import { tracksManagement } from './tracksManagement.localization'
import { tracksAvailable } from './tracksAvailable.localization'
import { profile } from './profile.localization'
import { settings } from './settings.localization'
import { help } from './help'
import { selfie } from './selfie.localization'
import { notification } from './notification.localization'
import {
  EValidationMessage,
  EStatusUser,
  EStatusItem,
  EStatusPosition,
  ENodeStatus,
  EStatTabs,
  ESelectList,
  ESelectOnlineStatusList,  
} from './constants.localization'

const localization = {
  profile,
  settings,
  tracksAvailable,
  tracksManagement,
  tasksChecking,
  users,
  positions,
  items,
  login,
  confirmedPassword,
  recoveryPassword,
  registration,
  nodesManagement,
  statItems,
  statUsers,
  taskCheckAndView,
  taskEdit,
  taskPassing,
  taskPreview,
  topicPreview,
  trackEngine,
  table,
  video,
  searchSelect,
  image,
  deleteModal,
  main,
  EValidationMessage,
  EStatusUser,
  EStatusItem,
  EStatusPosition,
  ENodeStatus,
  EStatTabs,
  ESelectList,
  ESelectOnlineStatusList,
  help,
  selfie,
  notification,
}

export default localization
