import React, { FC, useCallback, useRef, useState, memo } from 'react'
import { ESearchFilter, IKeyValuePair } from '../../constants'
import Styles from './CustomSearchSelect.module.scss'
import { ISearch } from '../../services/filter/filter.service'
import SearchSelectModal from './components/SearchSelectModal/SearchSelectModal'
import { SearchSelectCounter } from './components/SearchSelectCounter/SearchSelectCounter'
import CancelIcon from '@mui/icons-material/Cancel'
import { IconButton } from '@mui/material'

interface ICustomSearchSelect {
  isMultipleType?: boolean
  data: IKeyValuePair[]
  searchFilterType: ESearchFilter
  updateSearchParams?: ISearch
  allowClear?: boolean
  // confirmation before clearing the field (if there are modal)
  confirmAllowClear?: boolean
  placeholder: string
  inputPlaceholder: string
  changeValue: Function
  disabled?: boolean
}

const CustomSearchSelect: FC<ICustomSearchSelect> = ({
  isMultipleType = false,
  data = [],
  placeholder = '',
  inputPlaceholder = '',
  updateSearchParams,
  searchFilterType,
  changeValue,
  allowClear = false,
  disabled = false,
  confirmAllowClear = false,
}) => {
  const inputRef = useRef<any>(null)
  const [isSearchSelectModal, setIsSearchSelectModal] = useState(false)

  const handleSelectModal = useCallback(() => {
    setIsSearchSelectModal(!isSearchSelectModal)
  }, [isSearchSelectModal])

  const setSelectedItems = useCallback(
    (selectedItems: Array<IKeyValuePair>) => {
      if (inputRef.current) {
        inputRef.current.value = selectedItems
          .map(selectedItem => selectedItem.value)
          .join(', ')

        changeValue(selectedItems)
      }
    },
    [changeValue]
  )

  return (
    <>
      <div className={Styles.searchSelectWrapper}>
        <input
          readOnly={true}
          onFocus={() => handleSelectModal()}
          ref={inputRef}
          className={Styles.inputSelect}
          value={data.map(u => u.value).join(', ')}
          placeholder={placeholder}
          disabled={disabled}
        />
        {!disabled && allowClear && !!data.length && (
          <IconButton
            className={Styles.allowClear}
            aria-label='clear'
            size='small'
            onClick={() =>
              confirmAllowClear ? changeValue([]) : setSelectedItems([])
            }
          >
            <CancelIcon />
          </IconButton>
        )}
        {isMultipleType && !!data.length && (
          <SearchSelectCounter dataLength={data.length} inputRef={inputRef} />
        )}
      </div>
      {!!isSearchSelectModal && (
        <SearchSelectModal
          placeholder={placeholder}
          inputPlaceholder={inputPlaceholder}
          handleSelectDataModal={handleSelectModal}
          selectedData={data}
          setSelectedData={setSelectedItems}
          searchFilterType={searchFilterType}
          updateSearchParams={updateSearchParams}
          isMultipleType={isMultipleType}
        />
      )}
    </>
  )
}

export default memo(CustomSearchSelect)
