export const video = {
  record: {
    title: 'Record',
    press: 'PRESS THE BUTTON TO START',
    rec: 'REC',
    when_ready: 'VIDEO',
    another_video: 'Record another video',
    upload: 'Upload a video',
    blockedUpload:
      'Only online recording of video answer is available in this question',
    uploadValidFormats: 'Acceptable format of uploaded video - mov, mp4',
    record_video: 'Record a video',
    cameraOn: 'Turn my camera ON',
    validation: {
      text: '*Upload video max size - :MaxSize MB',
    },
    loadButton: 'Upload a video',
    cancelButton: 'Cancel',
    sendButton: 'Send the video',
    loading: 'Loading...',
    errorView:
      'Your browser failed to record your video. Please restart it and try again',
    unsupportedView: 'This browser does not support video recording',
  },
  videoUrl: {
    urlButton: 'Add link',
    urlTitle: 'Link to video',
    urlPlaceholder: 'Provide link to video',
    okBtn: 'Add',
    cancelBtn: 'Cancel',
  },
  view: {
    title: 'View',
    resetVideoButton: 'Create a new video',
    resetVideoModal: {
      title: 'Delete the current video?',
      content: 'To create a new video, you need to delete the current one',
      okText: 'Confirm',
      cancelText: 'Cancel',
    },
    videoUndefined: 'The video is not available!',
  },
}
