import React, { FC } from 'react'
import Styles from './Maintenance.module.scss'
import local from './../../localization'
import image from '../../assets/svg/maintenance.svg'

const Maintenance: FC = (): React.ReactElement => {
  return (
    <div className={Styles.box}>
      <img src={image} alt='Maintenance' />
      <p className={Styles.title}>{local.main.maintenanceMode.title}</p>
      <p className={Styles.subtitle}>{local.main.maintenanceMode.subtitle}</p>
    </div>
  )
}

export default Maintenance
