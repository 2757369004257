import { Observable, of } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import { ESearchFilter, TMap } from '../../constants'
import { catchError, map } from 'rxjs/operators'
import { EAPIEndpoint } from '../constants'

export interface ISearch {
  /** Entered value in the search string */
  value?: string
  /** Number of records returned (default is 100), if 0 - all records */
  size?: number
  /** Page number */
  page?: number
  /** Filter cymbals by track */
  trackId?: number
  /** Filter for Mentors and Managers */
  requiredMentorOrManager?: boolean
  /** Only on Available tracks */
  available?: boolean
  /** Along with Blocked */
  withBlocked?: boolean
  /** Filter for Specified Users */
  userIds?: string
}

export default class FilterService {
  static getFilteredArray(
    entityName: ESearchFilter,
    params: ISearch
  ): Observable<IAPIResult<Record<string | number, any>>> {
    const baseApiService = new BaseApiService<Record<string | number, any>>()
    return baseApiService
      .getRequest(
        `/${entityName}${EAPIEndpoint.FILTER}${getParams(params as TMap)}`
      )
      .pipe(
        map(r => r),
        catchError(err => {
          console.error(err)
          return of() as Observable<IAPIResult<Record<number, string>>>
        })
      )
  }
}
