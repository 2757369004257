export const profile = {
  info: {
    defaultFullName: 'Guest',
    position: 'Position',
    items: 'Tracks',
    availableItems: 'Available tracks',
    progress: 'Overall progress',
    tasksCompleted: 'Modules completed',
  },
  lastAction: {
    tasksCompleted: 'Module successfully completed',
    tasksConfirmed: 'Module completion confirmed',
    tasksFailed: 'Unsuccessful attempt to complete the module',
    tasksRejected: 'Module completion rejected',
    tasksSent: 'The module has been submitted for checking',
    tasksDroppedNodeStatus: 'Module progress was reset',
    unknownStatus: 'Unknown module status',
    lastActions: 'Recent actions',
  },
  stat: {
    title: 'Tracks progress statistics',
    selectItem: 'Select track',
    itemLabel: 'Track',
    tasks: {
      tasksProgress: 'Progress',
      progressTooltip:
        'The pie chart is divided into two sectors - completed modules and incomplete modules. The size of the sector representing completed modules corresponds to the percentage of completed work. The size of the sector representing incomplete modules corresponds to the percentage of unfinished work. The pie chart displays progress over the entire time period.',
      completedProgress: 'Completed',
      failedProgress: 'Failed',
      tasksTotal: 'Total modules',
      tasksCompleted: 'Completed',
      tasksLeft: 'Remaining modules',
      tasksChecked: 'Waiting for checking',
      columnTasksTable: {
        name: 'Title',
        status: 'Status',
        statusRejected: 'FOR REWORK',
        statusCompleted: 'COMPLETED',
        statusChecking: 'UNDER REVIEW',
        statusUnavailable: 'NOT COMPLETED',
        timeCompleted: 'Completed',
        timeVerified: 'Checked',
      },
      tableTitle: 'Modules by status',
    },
  },
}
