import { useState } from 'react'

export interface ModalState {
  modalStatus: boolean
  isModalExport: boolean
  title: string
}

export const useModalState = (): [typeof state, typeof updateState] => {
  const [state, setState] = useState<ModalState>({
    modalStatus: false,
    isModalExport: false,
    title: '',
  })

  const updateState = (field: ModalState) => setState(field)

  return [state, updateState]
}