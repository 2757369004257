import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as I from 'Types'
import ProfileService from '../../services/profile/profile.service'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'

const initialState: I.IProfileHead = {
  id: 0,
  fullName: '',
}

export const profileHead = createAsyncThunk(
  'profile/head',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await stackRequestWrapper(ProfileService.getProfileHead())
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const profileHeadSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateHeaderAvatar: (state, action) => {
      state.avatarUrl = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(profileHead.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.id = action.payload.data.id
        state.fullName = action.payload.data.fullName
        state.avatarUrl = action.payload.data.avatarUrl
      }
    })
  },
})

export const { reducer: profileHeadReducer } = profileHeadSlice

export const { updateHeaderAvatar } = profileHeadSlice.actions

export const selectHead = (state: I.RootState) => state.profileHead
