export const main = {
  returnToMain: 'Ana Sayfaya git',
  maintenanceMode: {
    title:
      'Web sitemiz sistem bakımı nedeniyle geçici olarak kullanılamamaktadır.',
    subtitle: 'Verdiğimiz rahatsızlıktan dolayı içtenlikle özür dileriz!',
  },
  breadcrumb: {
    home: 'Ana Sayfa',
    profile: 'Profil',
    avaibleTracks: 'Mevcut rotalar',
    tracksManagement: 'Rota Yönetimi',
    checkTasks: 'Görevlerin kontrolü',
    users: 'Kullanıcılar',
    positions: 'Pozisyonlar',
    items: 'Rotalar',
    reference: 'Yardım',
    statsByUsers: 'Kullanıcılara Göre',
    statsByUserForItem: 'Kullanıcı :UserName ilerlemesi rotada «:TrackName»',
    statsByItems: 'Rotalara göre',
    profileSettings: 'Ayarlar',
    usersProfile: ':UserName profil sayfası',
    notFound: 'Sayfa bulunamadı',
    stat: 'İstatistik',
  },
  auth: {
    signIn: 'Giriş Yap',
    signUp: 'Kayıt',
    restore: 'Şifre Kurtarma',
    newPassword: 'Şifre değiştir',
  },
  headMenu: {
    profileSettings: 'Ayarlar',
    reference: 'Yardım',
    exit: 'Çıkış Yap',
    language: 'Dil',
    theme: 'Tema',
    bonus: 'Ortak bonusları',
    bonusNotify: 'Kazan Kazan ortağı olun ve pasif gelir elde edin!',
  },
  search: {
    placeholder: 'Arama isteğini girin',
    button: 'Arama',
    searchIn: 'İçinde ara:',
    inUnavailableNodes: 'Kullanılamayan düğümler',
    inTrackTitle: 'Parça adı',
    inNodeTitle: 'Düğüm adı',
    inNodeTopic: 'Düğüm içeriği',
    inQuestionsText: 'Soru metni',
    trackTitle: 'Parça adı:',
    nodeTitle: 'Düğüm adı:',
    nodeTopic: 'Düğüm içeriği:',
    questionsText: 'Soru metni:',
    noMatch: 'Eşleşme yok',
    inManagerNodes: 'Parça yönetimi',
    inAvailableNodes: 'Mevcut parçalar',
    visibleOffTooltip: 'Modülün durumu - "Kullanılamıyor"',
    editTooltip: 'Parça (modül) düzenlemeye geçme',
  },
  menu: {
    collapse: 'Küçült',
    expand: 'Büyült',
  },
  PDFViewer: {
    page: 'Sayfa',
    of: '-nın',
    loading: 'PDF yükleniyor ...',
    failedLoad: 'PDF dosyası yüklenemedi',
  },
  task: 'Rota',
  postSolution: 'Cevap gönderildi',
  momentLanguage: 'tr', //All Language you can see on https://momentjs.com/
  editorLanguage: 'tr', //ar, bs, cs, da, de, el, en_ca, en_gb, es, et, fa, fi, fr, he, hr, hu, id, it, ja, ko, ku, me, nb, nl, pl, pt_br, pt_pt, ro, ru, sk, sl, sr, sv, th, tr, uk, vi, zh_cn, zh_tw
  acceptLanguage: 'tr-TR',
  editorPlaceholder: {
    default: 'Bir şeyler yazın',
    editModule: 'Bir açıklama yazın',
  },
  validation: {
    requiredField: 'Gerekli',
    fullNameLength: 'En az 3 karakter kullanın',
    emailValid: 'Giriş için geçerli bir e-posta adresi kullanılmalıdır',
    phoneNumberValid: 'Telefon numarası geçerli olmalıdır',
    passwordValidTitle: 'Parola şunları içermelidir:',
    passwordLength: '8 veya daha fazla karakter kullanın',
    passwordWithoutWhitespace: 'Boşluğa izin verilmez',
    latinLettersOnly: 'Sadece küçük ve büyük Latin harflerine izin verilir',
    upperLowerCaseLetters: 'Şifre Latin küçük harf veya büyük harf içermelidir',
    includeSpecialSymbols: 'Özel karakterler içermelidir (!@#$%^&*, vb.)',
    includeDigits: '1 veya daha fazla sayı içermelidir',
    includeDigitsOrSymbols:
      'En az 1 sayı (0-9) veya özel bir karakter (!@#$%^&* vb.) içermelidir.',
    passwordMustBeDifferent: 'Yeni parola farklı olmalıdır',
    passwordConfirmValid: 'Parolalar eşleşmiyor',
    emailConfirmValid: 'E-posta eşleşmiyor',
  },
  passwordStrength: {
    mainTitle: 'Şifre',
    weakPassword: 'Güvensiz',
    middlePassword: 'Ortalama',
    hardPassword: 'Güçlü',
  },
  periods: {
    currentWeek: 'bu hafta',
    lastWeek: 'geçen hafta',
    currentMonth: 'bu ay',
    lastMonth: 'geçen ay',
    currentYear: 'bu yıl',
    allTime: 'her zaman',
  },
}
