import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService } from '../base.service'
import * as I from 'Types'
import { EAPIEndpoint } from '../constants'

export default class FileService {
  static postFile(
    body: I.IFileRequest
  ): Observable<IAPIResult<I.IFileResponse>> {
    const baseApiService = new BaseApiService<I.IFileResponse>()
    return baseApiService.postFileRequest(EAPIEndpoint.UPLOAD_FILE_BASE64, body)
  }

  static postBinaryFile(
    file: File,
    signal?: AbortSignal
  ): Observable<IAPIResult<I.IFileResponse>> {
    const baseApiService = new BaseApiService<I.IFileResponse>()
    return baseApiService.postImportFileRequest(
      EAPIEndpoint.UPLOAD_FILE_BINARY,
      file,
      signal
    )
  }

  static postDelayBinaryFile(
    file: File,
    signal?: AbortSignal,
    params?: Object
  ): Observable<IAPIResult<I.IFileResponse>> {
    const baseApiService = new BaseApiService<I.IFileResponse>()
    return baseApiService.postImportFileRequest(
      EAPIEndpoint.UPLOAD_DELAYED_FILE_BINARY,
      file,
      signal,
      params
    )
  }
}
