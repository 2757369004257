export const table = {
  emptyData: 'No data',
  counters: {
    total: 'Total',
  },
  settingButton: 'Table View',
  settingTitle: 'Table Setting',
  exportButton: 'Export',
  exportTitle: 'Settings for exporting to a file',
  exportFormatTitle: 'Select format: ',
  exportColumnsTitle: 'Select the columns to upload: ',
}
