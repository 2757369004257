import React, { FC, useCallback } from 'react'
import { EISModal, setColorTypeButton } from '../EISComponents'
import Styles from './Selfie.module.scss'
import * as I from './ISelfie'
import { Button } from '@mui/material'
import spriteIcons from '../../assets/svg/sprite-icons.svg'
import ImageCapture from './components/ImageCapture/ImageCapture'
import local from '../../localization'

const Selfie: FC<I.ISelfie> = ({
  isSelfieMode,
  handleSelfieMode,
  uploadLocalImg,
  onSelfieChange,
}) => {
  const handleCancel = useCallback(() => {
    handleSelfieMode(false)
  }, [handleSelfieMode])

  return (
    <EISModal
      open={isSelfieMode}
      title={local.selfie.profilePicture}
      width={676}
      className={Styles.image_modal}
      onCancel={handleCancel}
      footer={
        <div className={Styles._btn_wrap}>
          <Button
            className={setColorTypeButton('default')}
            variant='outlined'
            onClick={uploadLocalImg}
            startIcon={
              <svg className={Styles.upload_icon}>
                <use xlinkHref={`${spriteIcons}#upload-icon`}></use>
              </svg>
            }
          >
            {local.selfie.actionBtn.uploadImage}
          </Button>
          <Button
            className={setColorTypeButton('default')}
            variant='outlined'
            onClick={handleCancel}
          >
            {local.selfie.actionBtn.cancel}
          </Button>
        </div>
      }
    >
      <ImageCapture uploadImage={onSelfieChange} height={385} />
    </EISModal>
  )
}

export default React.memo(Selfie)
