import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import { ENotificationVariant, EQuestionType } from '../../constants'
import TaskEditService from '../../services/tasks/task-edit/taskEdit.service'
import { enqueueSnackbar } from '../notification/notificationSlice'
import _ from 'lodash'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import local from './../../localization'

const isMandatory = !!Number(window.REACT_APP_TASK_EDIT_NODES_MANDATORY)

const initialState: I.ITaskEditState = {
  nodeId: 0,
  task: {
    limitTime: false,
    minutesEstimated: 0,
    passThreshold: 100,
    questions: [],
    nodeTitle: '',
    trackId: 0,
    trackTitle: '',
    mandatory: isMandatory,
    displayQuestionSize: 0,
    isInterview: false,
  },
  taskInfo: {
    nodeInUse: false,
    ifDraft: false,
  },
  passedUsers: [],
  rejectedUsers: [],
  text: undefined,
  message: '',
  confirmButtonText: undefined,
}

const originalData: {
  task: I.INodeTask
  taskInfo: I.INodeTaskInfo
  moduleChanged?: boolean
} = {
  task: { ...initialState.task },
  taskInfo: { ...initialState.taskInfo },
  moduleChanged: false,
}

export const getTopic = createAsyncThunk(
  'tasks/edit/topic',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.getNodeTopic(nodeId)
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getTask = createAsyncThunk(
  'tasks/edit/task',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.getNodeTask(nodeId)
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getTaskInfo = createAsyncThunk(
  'tasks/edit/task/info',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.getNodeTaskInfo(nodeId)
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const autosaveTopic = createAsyncThunk(
  'tasks/edit/topic/autosave',
  async (
    { nodeId, text }: I.INodeTopicRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      TaskEditService.autosaveTopic(nodeId, text)
      originalData.moduleChanged = false
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const updateTopic = createAsyncThunk(
  'tasks/edit/topic/update',
  async (
    { nodeId, text, confirmButtonText }: I.INodeTopicRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.postNodeTopic(nodeId, text, confirmButtonText)
      )
      originalData.moduleChanged = false

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getJobResults = createAsyncThunk(
  'tasks/edit/topic/job/results',
  async (uiids: string[], { rejectWithValue, dispatch }) => {
    try {
      const result = TaskEditService.getJobResults(uiids)
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const updateNodeTask = createAsyncThunk(
  'tasks/edit/task/update',
  async (task: I.INodeTask, { rejectWithValue, dispatch }) => {
    try {
      //Get rid of unnecessary fields depending on the type of question
      const cloneTask = _.cloneDeep(task)
      cloneTask.questions.forEach(q => {
        if (q.questionType === EQuestionType.TEST) {
          q.answerKeys = []
        } else {
          q.testItems = []
        }
      })

      const newTask = dispatch(updateTaskState(cloneTask)).payload

      const result = await stackRequestWrapper(
        TaskEditService.postNodeTask(newTask)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.verification.SUCCESS.updateNode,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const updateNodeTaskTitle = createAsyncThunk(
  'tasks/edit/task/updateTitle',
  async (title: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as I.RootState
      const taskId = Number(state.tasks.edit.task.nodeId)

      await stackRequestWrapper(TaskEditService.postNodeTitle(taskId, title))

      return { title }
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getPassedUsers = createAsyncThunk(
  'tasks/edit/topic/changed',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.getPassedUsers(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getRejectedUsers = createAsyncThunk(
  'tasks/edit/task/reject',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.getRejectedUsers(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postZeroPassed = createAsyncThunk(
  'tasks/edit/task/zero',
  async (body: I.IZeroPassRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.postZeroPassed(body)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.verification.SUCCESS.postZeroPassed,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const postTopicChanged = createAsyncThunk(
  'tasks/edit/task/zero',
  async (body: I.ITopicChangedRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskEditService.postTopicChanged(body)
      )

      dispatch(
        enqueueSnackbar({
          message: local.notification.verification.SUCCESS.postTopicChanged,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const tasksEditSlice = createSlice({
  name: 'tasks/edit',
  initialState,
  reducers: {
    updateTopicState: (
      state: I.ITaskEditState,
      action: PayloadAction<string>
    ) => {
      originalData.moduleChanged = !(state.text === action.payload)
      state.text = action.payload
    },
    updateConfirmTextBtn: (
      state: I.ITaskEditState,
      action: PayloadAction<string | undefined>
    ) => {
      originalData.moduleChanged = !(state.text === action.payload)
      state.confirmButtonText = action.payload
    },
    updateMessage: (state: I.ITaskEditState, action: PayloadAction<string>) => {
      originalData.moduleChanged = !(state.text === action.payload)
      state.message = action.payload
    },
    updateTaskState: (
      state: I.ITaskEditState,
      action: PayloadAction<I.INodeTask>
    ) => {
      originalData.moduleChanged = !_.isEqual(state.task, action.payload)
      _.assign(state.task, action.payload)
    },
    updateQestionDescription: (
      state: I.ITaskEditState,
      action: PayloadAction<{ orderNumber: number; text: string }>
    ) => {
      originalData.moduleChanged = !(state.text === action.payload.text)
      const question = state.task.questions.find(
        q => q.orderNumber === action.payload.orderNumber
      )
      if (question) {
        question.text = action.payload.text
      } else {
        throw new Error(
          local.notification.task.ERROR.questionNotFound.replace(
            ':Index',
            `${action.payload.orderNumber}`
          )
        )
      }
    },
    clearTaskEditState: (state: I.ITaskEditState) => {
      _.assign(state, initialState)
      _.assign(originalData.task, initialState.task)
      originalData.moduleChanged = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getTopic.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.text = action.payload.data.text || ''
        originalData.moduleChanged = false
        state.confirmButtonText = !!action.payload.data.confirmButtonText
          ? action.payload.data.confirmButtonText
          : undefined
      } else {
        console.error(local.notification.task.ERROR.topicNotFound)
      }
    })
    builder.addCase(getTask.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        _.assign(state.task, action.payload.data)
        _.assign(originalData.task, action.payload.data)
      } else {
        console.error(local.notification.task.ERROR.taskNotFound)
      }
    })
    builder.addCase(getTaskInfo.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        _.assign(state.taskInfo, action.payload.data)
        _.assign(originalData.taskInfo, action.payload.data)
      } else {
        console.error(local.notification.task.ERROR.taskNotFound)
      }
    })
    builder.addCase(updateNodeTask.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        _.assign(state.task, action.payload.data)
        _.assign(originalData.task, action.payload.data)
      } else {
        console.error(local.notification.task.ERROR.taskNotFound)
      }
    })
    builder.addCase(updateNodeTaskTitle.fulfilled, (state, action: any) => {
      if (action.payload?.title) {
        _.assign(state.task.nodeTitle, action.payload.title)
        _.assign(originalData.task.nodeTitle, action.payload.title)
      } else {
        console.error(local.notification.task.ERROR.taskNotFound)
      }
    })
    builder.addCase(getPassedUsers.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        state.passedUsers = [...action.payload?.data]
      } else {
        console.error(local.notification.task.ERROR.usersNotFound)
      }
    })
    builder.addCase(getRejectedUsers.fulfilled, (state, action: any) => {
      if (action.payload?.data) {
        state.rejectedUsers = [...action.payload?.data]
      } else {
        console.error(local.notification.task.ERROR.usersNotFound)
      }
    })
  },
})

export const {
  updateTaskState,
  updateTopicState,
  updateConfirmTextBtn,
  clearTaskEditState,
  updateMessage,
  updateQestionDescription,
} = tasksEditSlice.actions

export const selectTask = (state: I.RootState) =>
  state.tasks.edit.task.nodeId
    ? {
        taskId: state.tasks.edit.task.nodeId,
        taskTitle: state.tasks.edit.task.nodeTitle,
        estimate: state.tasks.edit.task.minutesEstimated,
        allowTimeout: state.tasks.edit.task.limitTime,
      }
    : null

export const selectFullTask = (state: I.RootState): I.INodeTask => ({
  ...state.tasks.edit.task,
})

export const selectTrack = (state: I.RootState) =>
  state.tasks.edit.task.trackId
    ? {
        trackId: state.tasks.edit.task.trackId,
        trackTitle: state.tasks.edit.task.trackTitle,
      }
    : null

export const selectTopic = (state: I.RootState) => state.tasks.edit.text

export const selectConfirmBtnText = (state: I.RootState) =>
  state.tasks.edit.confirmButtonText

export const selectPassedUsers = (state: I.RootState) =>
  state.tasks.edit.passedUsers

export const selectRejectedUsers = (state: I.RootState) =>
  state.tasks.edit.rejectedUsers

export const shouldBlockNavigation = () => {
  return originalData.moduleChanged
}

// TODO: Chech when backend will done
export const selectTaskInfo = (state: I.RootState) => state.tasks.edit.taskInfo

export const selectMessage = (state: I.RootState) => state.tasks.edit.message

export const selectEditInterviewStatus = (state: I.RootState) =>
  state.tasks.edit.task.questions.some(
    q => q.questionType === EQuestionType.VIDEO_INTERVIEW
  )

export const { reducer: tasksEditReducer } = tasksEditSlice
