import { MathUtils } from './MathUtils'
import { IPointCoords } from 'Types'
import { HTML_IDS } from '../constants'

const { downloadLink, uploadInput } = HTML_IDS

export class DOMUtils {
  static calculateLayoutClickPosition(
    clientX: number,
    clientY: number
  ): IPointCoords {
    const pageHolder = document.querySelector('#page-holder') as HTMLDivElement

    const bodyRect = document.body.getBoundingClientRect(),
      elemRect = pageHolder.getBoundingClientRect(),
      offsetY = elemRect.top - bodyRect.top,
      offsetX = elemRect.left - bodyRect.left

    const resX = clientX - offsetX
    const resY = clientY + document?.scrollingElement?.scrollTop!! - offsetY

    return {
      x: MathUtils.roundCoord(resX),
      y: MathUtils.roundCoord(resY),
    }
  }

  static clickDownloadLink() {
    const link = document.getElementById(downloadLink)
    if (link) {
      link.click()
    }
  }

  static clickUploadInput() {
    const input = document.getElementById(uploadInput)
    if (input) {
      input.click()
    }
  }
}
