import React, { useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Image } from 'antd'
import { ValidationHelper } from '../../Validation'
import { useFormField } from '../../../../hooks/useFormField'
import Styles from './Registration.module.scss'
import { selectStatusRegistration, signUp } from '../registrationSlice'
import { useAppDispatch } from '../../../../app/store'
import { EStateStatus } from '../../../../constants'
import { useSelector } from 'react-redux'
import local, { getCountry } from './../../../../localization'
import { ThemeSwitcher } from '../../../../components/ThemeSwitcher/ThemeSwitcher'
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Stack,
} from '@mui/material'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { setColorTypeButton } from '../../../../components/EISComponents'
import EISPasswordProgress from '../../../../components/EISComponents/EISPasswordProgress/EISPasswordProgress'
import EISPopoverValidation from '../../../../components/EISComponents/EISPopoverValidation'
import { ReactComponent as BackArrow } from '../../../../assets/svg/arrow_back_icon.svg'
import { useTheme } from '../../../../theme/Theme'
import PDFViewer from '../../../../components/PDFViewer/PDFViewer'
import { addMetrik } from '../../../../app/metrik'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const themeSwitcher = !!Number(window.REACT_APP_WITH_THEME_SWITCHER) || false // 0 - false, 1 - true
const language = localStorage.getItem('language')
type agreementModalType = 'person_data' | 'sublicense'

export const Registration = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const status = useSelector(selectStatusRegistration)
  const { themeProps, theme } = useTheme()
  const [fullName, setFullName] = useFormField()
  const [username, setUsername] = useFormField()
  const [phoneNumber, setPhoneNumber] = useFormField()
  const [password, setPassword] = useFormField()
  const [passwordConfirm, setPasswordConfirm] = useFormField()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [agreementModal, setAgreementModal] = React.useState<{
    title: string
    src: string
  }>()
  const registration = useRef<HTMLDivElement>(null)
  const personal_data = `https://storage.yandexcloud.net/eis-static/theme/${theme}/${language}/Consent_to_process_personal_data.pdf`
  const sublicense = `https://storage.yandexcloud.net/eis-static/theme/${theme}/${language}/Sublicense_agreement.pdf`

  let instruction
  if ((theme === 'winwin' || theme === 'umentor') && language === 'ru') {
    instruction = `https://storage.yandexcloud.net/eis-static/theme/${theme}/${language}/instruction.pdf`
  }

  !!window.REACT_APP_REGISTRATION_METRIC_KEY &&
    !!window.REACT_APP_REGISTRATION_METRIC_NAME &&
    !!registration.current &&
    addMetrik(registration.current!)

  const handleSetAgreementModal = useCallback(
    (type?: agreementModalType | boolean) => {
      switch (type) {
        case 'person_data':
          setAgreementModal({
            title: local.registration.agreementUserTitle,
            src: personal_data,
          })
          break

        case 'sublicense':
          setAgreementModal({
            title: local.registration.sublicenseTitle,
            src: sublicense,
          })
          break

        default:
          setAgreementModal(undefined)
          break
      }
    },
    [personal_data, sublicense]
  )

  const formOnKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onSignUpHandler()
    }
  }

  const onChangeFullNameHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = ValidationHelper.validateFullName(e.target.value)
      setFullName(result)
    },
    [setFullName]
  )

  const onChangeLoginHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validateEmail(e.target.value)
      setUsername(result)
    },
    [setUsername]
  )

  const onChangePhoneNumberHandler = React.useCallback(
    (country: {} | CountryData, formattedValue: string): void => {
      const result = ValidationHelper.validatePhoneNumber(
        formattedValue,
        country,
        true
      )
      setPhoneNumber(result)
    },
    [setPhoneNumber]
  )

  const onChangePasswordHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validatePassword(e.target.value)
      setPassword(result)

      const confirmResult = ValidationHelper.validatePasswordConfirm(
        e.target.value,
        passwordConfirm.value
      )
      setPasswordConfirm(confirmResult)
    },
    [passwordConfirm.value, setPassword, setPasswordConfirm]
  )

  const onChangePasswordConfirmHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const result = ValidationHelper.validatePasswordConfirm(
        password.value,
        e.target.value
      )
      setPasswordConfirm(result)
    },
    [password, setPasswordConfirm]
  )

  const onSignUpHandler = React.useCallback((): void => {
    if (
      username.valid &&
      phoneNumber.valid &&
      password.valid &&
      passwordConfirm.valid &&
      fullName.valid
    ) {
      dispatch(
        signUp({
          fullName: fullName.value,
          username: username.value,
          phoneNumber: phoneNumber.value,
          password: password.value,
        })
      )
      !!window.ym &&
        !!window.REACT_APP_REGISTRATION_METRIC_KEY &&
        !!window.REACT_APP_REGISTRATION_METRIC_NAME &&
        window.ym(
          window.REACT_APP_REGISTRATION_METRIC_KEY,
          'reachGoal',
          window.REACT_APP_REGISTRATION_METRIC_NAME
        )
    } else {
      const fullNameResult = ValidationHelper.validateFullName(fullName.value)
      setFullName(fullNameResult)

      const usernameResult = ValidationHelper.validateEmail(username.value)
      setUsername(usernameResult)

      const phoneNumberResult = ValidationHelper.validatePhoneNumber(
        phoneNumber.value
      )
      setPhoneNumber(phoneNumberResult)

      const passwordResult = ValidationHelper.validatePassword(password.value)
      setPassword(passwordResult)

      const passwordConfirmResult = ValidationHelper.validatePasswordConfirm(
        password.value,
        passwordConfirm.value
      )
      setPasswordConfirm(passwordConfirmResult)
    }
  }, [
    username,
    phoneNumber,
    password,
    passwordConfirm,
    fullName,
    dispatch,
    setFullName,
    setUsername,
    setPhoneNumber,
    setPassword,
    setPasswordConfirm,
  ])

  const handleClickShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const AgreementComponent = () => {
    const necessaryTheme = theme !== 'smart'
    const necessaryLang = language !== 'en-us'

    return (
      <>
        {necessaryTheme && necessaryLang && (
          <Form.Item>
            <p className={Styles.agreement}>
              <span>{local.registration.form.agreement}</span>
              <span
                className={Styles.handler}
                onClick={() => handleSetAgreementModal('person_data')}
              >
                {local.registration.agreementUserTitle}
              </span>
              <span>{local.registration.form.and}</span>
              <span
                className={Styles.handler}
                onClick={() => handleSetAgreementModal('sublicense')}
              >
                {local.registration.sublicenseTitle}
              </span>
            </p>
          </Form.Item>
        )}
      </>
    )
  }

  return (
    <div
      onKeyPress={formOnKeyPressed}
      className={Styles.container}
      ref={registration}
    >
      {themeSwitcher && <ThemeSwitcher />}
      <div className={Styles.wrapper}>
        <div
          className={Styles.wrapperLogo}
          style={{
            background: 'var(--main-logo) no-repeat left top / contain',
          }}
        ></div>
        {status !== EStateStatus.FULFILLED ? (
          <Form name='basic'>
            <Typography
              className={Styles.MuiTypographySubtitle}
              variant='subtitle1'
              gutterBottom
              component='div'
            >
              {local.registration.title}
            </Typography>
            <Form.Item
              name='fullName'
              rules={[
                {
                  required: true,
                },
              ]}
              className={Styles.formItem}
              help={fullName.message}
              validateStatus={fullName.message.length > 0 ? 'error' : ''}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                name={fullName.value}
                autoComplete={'fullName'}
                className={Styles.inputForm}
                onChange={onChangeFullNameHandler}
                label={local.registration.form.fullName}
                variant='filled'
                id='filled-basic'
                autoFocus={true}
                inputProps={{ maxLength: 50, data_testid: 'fullName' }}
              />
            </Form.Item>
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                },
              ]}
              className={Styles.formItem}
              help={username.message}
              validateStatus={username.message.length > 0 ? 'error' : ''}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                name={username.value}
                autoComplete={'username'}
                className={Styles.inputForm}
                onChange={onChangeLoginHandler}
                label={local.registration.form.username}
                variant='filled'
                id='filled-basic'
                inputProps={{ maxLength: 100, data_testid: 'userName' }}
              />
            </Form.Item>
            <Form.Item
              name='phoneNumber'
              rules={[
                {
                  required: true,
                },
              ]}
              className={Styles.formItem}
              help={phoneNumber.message}
              validateStatus={phoneNumber.message.length > 0 ? 'error' : ''}
            >
              <PhoneInput
                inputProps={{
                  disableUnderline: true,
                  name: phoneNumber.value,
                  autoComplete: 'phoneNumber',
                  data_testid: 'phoneNumber',
                }}
                placeholder={local.registration.form.phoneNumber}
                containerClass={Styles.phoneNumberInput}
                inputClass={Styles.phoneNumberInput}
                onChange={(_value, country, _e, formattedValue) =>
                  onChangePhoneNumberHandler(country, formattedValue)
                }
                enableSearch
                countryCodeEditable={false}
                searchPlaceholder={
                  local.registration.form.phoneNumberSearchPlaceholder
                }
                country={getCountry()}
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                },
              ]}
              className={Styles.formItem}
              help={password.message}
              validateStatus={password.message.length > 0 ? 'error' : ''}
            >
              <EISPopoverValidation
                ruleValidate={
                  ValidationHelper.passwordStrength(password.value).ruleValidate
                }
              >
                <TextField
                  InputProps={{ disableUnderline: true }}
                  name={password.value}
                  autoComplete='new-password'
                  className={Styles.inputForm}
                  onChange={onChangePasswordHandler}
                  label={local.registration.form.password}
                  variant='filled'
                  id='filled-password-input'
                  type={showPassword ? 'text' : 'password'}
                  inputProps={{ maxLength: 30, data_testid: 'password' }}
                />
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              </EISPopoverValidation>
            </Form.Item>
            <EISPasswordProgress
              passwordStrength={
                ValidationHelper.passwordStrength(password.value).count
              }
            />
            <Form.Item
              name='passwordConfirm'
              rules={[
                {
                  required: true,
                },
              ]}
              className={Styles.formItem}
              help={passwordConfirm.message}
              validateStatus={passwordConfirm.message.length > 0 ? 'error' : ''}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                name={passwordConfirm.value}
                className={Styles.inputForm}
                onChange={onChangePasswordConfirmHandler}
                label={local.registration.form.passwordConfirm}
                variant='filled'
                id='filled-password-input'
                type={showPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 30, data_testid: 'confirmPassword' }}
              />
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            </Form.Item>
            <Form.Item>
              <Stack
                direction='column'
                justifyContent='flex-end'
                spacing={2}
                className={Styles.btnsWrap}
              >
                <Button
                  id='registration-button'
                  variant='contained'
                  className={setColorTypeButton('success')}
                  onClick={onSignUpHandler}
                  disabled={
                    !fullName.value.length ||
                    !username.value.length ||
                    !phoneNumber.valid ||
                    !password.value.length ||
                    !passwordConfirm.value.length
                  }
                  data-testid='signin'
                >
                  {local.registration.registrationButton}
                </Button>
                {instruction && (
                  <a
                    href={instruction}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button
                      variant='outlined'
                      className={`${Styles.instruction}`}
                    >
                      {local.registration.instructionButton}
                    </Button>
                  </a>
                )}
              </Stack>
            </Form.Item>
            <AgreementComponent />
          </Form>
        ) : (
          <div className={Styles.resultContainer}>
            <p className={Styles.result}>
              {local.registration.registrationResult.result}
            </p>
            <p className={Styles.info}>
              {local.registration.registrationResult.info}
            </p>
            <p className={Styles.ps}>
              {local.registration.registrationResult.ps}
            </p>
          </div>
        )}
        <Link to='/signin' className={Styles.backTo}>
          <BackArrow />
          <span>{local.recoveryPassword.backToSignInLink}</span>
        </Link>
      </div>
      <div className={Styles.img_side}>
        <Image src={themeProps['--auth-background-image']} preview={false} />
      </div>
      {agreementModal && (
        <PDFViewer
          src={agreementModal.src}
          title={agreementModal.title}
          setVisible={handleSetAgreementModal}
        />
      )}
    </div>
  )
}

export default Registration
