import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { EAPIEndpoint } from '../constants'

export default class TasksService {
  static getTasks(
    data: Record<string | number, any>
  ): Observable<IAPIResult<I.ITasksResponse>> {
    const baseApiService = new BaseApiService<I.ITasksResponse>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.TASKS_VERIFICATION}${getParams(data)}`
    )
  }

  static getAttemptsSolution(
    data: Record<number | string, number>
  ): Observable<IAPIResult<Record<string | number, number>>> {
    const baseApiService = new BaseApiService<Record<string | number, number>>()
    return baseApiService.getRequest(
      `${EAPIEndpoint.SOLUTION_ATTEMPTS}${getParams(data)}`
    )
  }
}
