import { Col, Row } from 'antd'
import React from 'react'
import { Authorities } from '../../../constants'
import { isInRole } from '../../../services/auth'
import ProfileInfo from './ProfileInfo/ProfileInfoPL'
import ProfileLastActionPL from './ProfileLastActions/ProfileLastActionPL'
import ProfileStatPL from './ProfileStat/ProfileStatPL'
import style from './Profile.module.scss'
import { useParams } from 'react-router'

const ProfilePL: React.FC = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>()
  return (
    <>
      {isInRole(Authorities.PROFILE_PAGE) && (
        <div className={style.profileContainer}>
          <Row>
            <Col span={24}>
              <ProfileInfo userId={Number(userId)} />
            </Col>
          </Row>
          <div className={style.profileContent}>
            <ProfileStatPL userId={Number(userId)} />
            <ProfileLastActionPL userId={Number(userId)} />
          </div>
        </div>
      )}
    </>
  )
}

export default ProfilePL
