export const trackEngine = {
  deleteModal: {
    title: 'Удаление элемента',
    okText: 'Да',
    cancelText: 'Нет',
    deleteNode:
      'Вы действительно хотите полностью удалить модуль(-и), описание(-я), а также все выполнения задания пользователями и их прогресс?',
    deleteCurve: 'Вы действительно хотите удалить связь(-и)?',
  },
  editModal: {
    saveButton: 'Сохранить',
  },
  notification: {
    saveNodes: 'Изменения успешно сохранены!',
    export: 'Экспорт завершен',
    moveInNode:
      'Чтобы перейти к заданию модуля, используйте кнопку в Панели управления',
  },
  toolbar: {
    title: 'Инструменты',
    importButton: 'Импортировать трек',
    addNodeButton: 'Добавить модуль',
    addCurveButton: 'Создать дочернюю связь',
    editNodeTitleButton: 'Изменить заголовок',
    removeButton: 'Удалить элемент',
    saveButton: 'Сохранить изменения',
    moveInNodeButton: 'Перейти к заданию (Двойной клик)',
    notSave: 'Сначала сохраните трек',
    copyNode: 'Копировать модуль(-и)',
    searchTrack: 'Копировать в ...',
    scale: {
      resetScaleButton: 'Сбросить масштаб',
      zoomInButton: 'Увеличить масштаб',
      zoomOutButton: 'Уменьшить масштаб',
    },
    export: {
      tooltip: 'Выполнить экспорт Трека',
      validation: 'Есть несохраненные изменения, сначала сохраните трек',
      button: 'Экспорт',
    },
    // preview: 'Предварительный просмотр',
    // TODO: EIS-2674 - Раскомментировать, если потребуется прогресс прохождения в режиме предварительного просмотра
  },
  draft: 'ЧЕРНОВИК',
  contains: {
    hasTopic: 'Есть описание',
    hasTest: 'Тестовый вопрос',
    hasQuestion: 'Развернутый вопрос',
    hasVideo: 'Видео вопрос',
  },
  emptyData: 'Нет данных',
}
