import React from 'react'
import { DatePicker, Input, Modal, Select } from 'antd'
import { useTheme } from '../../theme/Theme'
import { ModalFuncProps, ModalProps } from 'antd/lib/modal'
import { RangePickerProps } from 'antd/lib/date-picker'
import './EISComponents.module.scss'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'
import {
  setResultTrack,
  setColorTypeButton,
} from './EISButton/EISButton.styles'
import { EISConfirm } from './EISConfirm/EISConfirm'
import Dropdown, { DropDownProps } from 'antd/lib/dropdown'
import { InputProps } from 'antd/lib/input'

export const EISModal: React.FC<ModalFuncProps | ModalProps> = props => {
  const { themeProps } = useTheme()
  return (
    <Modal
      {...props}
      maskClosable={props.maskClosable ? props.maskClosable : false}
      className={props.className}
      style={{ ...props.style, ...(themeProps as React.CSSProperties) }}
    />
  )
}

export const EISSelect: React.FC<SelectProps<
  any | DefaultOptionType
>> = props => {
  const { themeProps } = useTheme()
  const [dropdownVisible, setDropdownVisible] = React.useState(false)

  return (
    <Select
      {...props}
      className={`antSelect ${props.className}`}
      dropdownStyle={{
        ...props.style,
        ...(themeProps as React.CSSProperties),
      }}
      listHeight={225}
      open={dropdownVisible}
      onDropdownVisibleChange={open => {
        setDropdownVisible(open)
      }}
      getPopupContainer={() => {
        const container = document.getElementsByClassName(
          'ant-modal-wrap'
        )[0] as HTMLElement
        return container || document.body
      }}
    />
  )
}

export const EISDropdown: React.FC<DropDownProps> = props => {
  const { themeProps } = useTheme()
  return (
    <Dropdown
      {...props}
      overlayStyle={{ ...(themeProps as React.CSSProperties) }}
    />
  )
}
export const EISInput: React.FC<InputProps> = props => {
  const { themeProps } = useTheme()
  return <Input {...props} style={{ ...(themeProps as React.CSSProperties) }} />
}

export const EISRangePicker: React.FC<RangePickerProps> = props => {
  const { themeProps } = useTheme()
  const { RangePicker } = DatePicker
  return (
    <RangePicker
      {...props}
      popupStyle={{ ...(themeProps as React.CSSProperties) }}
    />
  )
}

export { setColorTypeButton, setResultTrack, EISConfirm }
