import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import Styles from './Search.module.scss'
import parse from 'html-react-parser'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import local from './../../../localization'
import { Tooltip } from 'antd'
import * as I from 'Types'
import { isInRole } from '../../../services/auth'
import { Authorities } from '../../../constants'

const SearchItem: React.FC<I.ISearchItem> = ({
  itemsTitle,
  nodesTitle,
  topicsText,
  questionsText,
  isAvailableNode,
  url,
}) => {
  const handleClick = React.useCallback(
    e => {
      if (e.button === 0 || e.button === 1) {
        e.preventDefault()
        window.open(
          window.location.protocol + '//' + window.location.host + '/' + url
        )
      }
    },
    [url]
  )

  return (
    <div className={Styles.searchItem} onMouseDown={handleClick}>
      <div className={Styles.icons}>
        {url.includes('management') && (
          <Tooltip title={local.main.search.editTooltip}>
            <EditOutlined className={Styles.icon} translate={undefined} />
          </Tooltip>
        )}
        {isAvailableNode === false && url.includes('available') && (
          <Tooltip title={local.main.search.visibleOffTooltip}>
            <VisibilityOffIcon className={Styles.icon} />
          </Tooltip>
        )}
      </div>
      <div className={Styles.content}>
        <div className={Styles.track}>
          <h3>
            <span className={Styles.hint}>{local.main.search.trackTitle}</span>
            {parse(itemsTitle)}
          </h3>
        </div>
        <h4>
          <span className={Styles.hint}>{local.main.search.nodeTitle}</span>
          {parse(
            nodesTitle === 'no matches'
              ? local.main.search.noMatch
              : nodesTitle || local.main.search.noMatch
          )}
        </h4>
        <p className={Styles.text}>
          <span className={Styles.hint}>{local.main.search.nodeTopic}</span>

          {parse(
            topicsText === 'no matches'
              ? local.main.search.noMatch
              : topicsText || local.main.search.noMatch
          )}
        </p>
        {isInRole(Authorities.MANAGEMENT_NODES_PAGE) && (
          <p className={Styles.text}>
            <span className={Styles.hint}>
              {local.main.search.questionsText}
            </span>
            {parse(
              questionsText === 'no matches'
                ? local.main.search.noMatch
                : questionsText || local.main.search.noMatch
            )}
          </p>
        )}
      </div>
    </div>
  )
}

export default SearchItem
