import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService } from '../base.service'
import * as I from 'Types'
import { EAPIEndpoint } from '../constants'

const head = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
export default class ProfileSettingsService {
  static getSelfRegistrationStatus(): Observable<IAPIResult<I.IProfileSettings>> {
    const baseApiService = new BaseApiService<I.IProfileSettings>()
    return baseApiService.getRequest(EAPIEndpoint.SELF_REGISTRATION)
  }

  static changeSelfRegistrationStatus(
    body: I.IProfileSettings
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.CHANGE_SELF_REGISTRATION, head, body)
  }

  static getSettings(): Observable<IAPIResult<I.IProfileSettings>> {
    const baseApiService = new BaseApiService<I.IProfileSettings>()
    return baseApiService.getRequest(EAPIEndpoint.PROFILE_SETTINGS)
  }

  static changeProfileSettings(
    body: I.IProfileSettings
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.PROFILE_SETTINGS, head, body)
  }

  static changeProfilePassword(
    body: I.IChangeProfileSettingsPasswordRequest
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.CHANGE_PASSWORD, head, body)
  }

  static changeProfileEmail(
    body: I.IChangeProfileSettingsEmailRequest
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.CHANGE_EMAIL, head, body)
  }

  static changeProfilePhoneNumber(
    body: I.IChangeProfileSettingsPhoneNumberRequest
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.CHANGE_PHONE_NUMBER, head, body)
  }
}
