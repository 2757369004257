import React, { useEffect, useState } from 'react'
import {
  Divider,
  Empty,
  Table,
  TablePaginationConfig,
  Button as AntdButton,
} from 'antd'

import { IViewTable } from './IViewTable'
import style from './ViewTable.module.scss'
import local from './../../localization'
import { IStatUserItemColumn } from 'Types'
import { AppstoreAddOutlined, DownloadOutlined } from '@ant-design/icons'
import ViewModal from './components/ViewModal/ViewModal'
import ExportModal from './components/ExportModal/ExportModal'
import { ModalState, useModalState } from '../../hooks/useModalState'

const ViewTable: React.FC<IViewTable> = ({
  data,
  columns,
  mainColumns,
  navigate,
  type,
  pagination,
  onChange,
  title,
  height,
  emptyText,
  rowSelection,
  setSelectedNodes,
  selectedNode,
  selectedRow,
  isExportButtonVisible = false,
  filters,
  exportMethod,
  isExportDataExist,
  fileName,
  scroll = {
    x: '100%',
    y: height,
    scrollToFirstRowOnChange: false,
  },
  expandable,
  excludeFields,
}): JSX.Element => {
  const selectedRowKeys =
    selectedNode && selectedNode.length ? selectedNode.map(u => Number(u)) : []

  /**Crutch for line selection */
  useEffect(() => {
    if (selectedRow) {
      const rows = Object.values(document.getElementsByTagName('tr')).map(
        o => o.attributes[0]
      )
      const row = rows.filter(r => !!r && r.value === selectedRow.toString())[0]
      const elem = row.ownerElement
      const firstColumn = elem?.children[0]
      if (
        firstColumn &&
        firstColumn.className.includes('ant-table-cell-fix-left')
      ) {
        firstColumn.setAttribute(
          'class',
          firstColumn.getAttribute('class') + ' selectedRow'
        )
      }
      if (elem)
        elem.setAttribute('class', elem.getAttribute('class') + ' selectedRow')
    } else {
      const elem = document.getElementsByClassName('selectedRow').item(0)
      const firstColumn = elem?.children[0]
      if (elem) {
        elem.setAttribute(
          'class',
          elem.getAttribute('class')?.replace(' selectedRow', '')!
        )
      }
      if (
        firstColumn &&
        firstColumn.className.includes('ant-table-cell-fix-left')
      ) {
        firstColumn.setAttribute(
          'class',
          firstColumn.getAttribute('class')?.replace(' selectedRow', '')!
        )
      }
    }
  }, [selectedRow])

  // Checking if rowSelection appears
  if (rowSelection) {
    rowSelection = {
      onChange(srk) {
        const arrNodeIds: Array<number> = []
        srk.forEach(e => arrNodeIds.push(Number(e)))
        // Memorizes selected fields on different pages (pagination)
        const result = selectedNode
          ? selectedNode
              .filter(
                i =>
                  !data.some(u => (u as IStatUserItemColumn).key === Number(i))
              )
              .concat(arrNodeIds)
          : []

        // I check for emptiness, write down the marked modules and pass them to the parent
        setSelectedNodes && setSelectedNodes(result)
      },
      selectedRowKeys,
    }
  }

  //#region Column settings
  const [selectedViewColumns, setSelectedViewColumns] = useState<string[]>([])
  const [modalState, setModalState] = useModalState()

  useEffect(() => {
    const defaultColumns = localStorage.getItem(type)

    if (defaultColumns) {
      setSelectedViewColumns(
        columns
          .map(c => c.key as string)
          .filter(key => defaultColumns.includes(key))
      )
    } else {
      localStorage.setItem(type, columns.map(c => c.key).join(','))
      setSelectedViewColumns(columns.map(c => c.key as string))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  const handleChangeModalState = (newModalState: ModalState) => {
    setModalState(newModalState)
  }

  const handleChangeViewColumns = (newState: string[]) => {
    setSelectedViewColumns(newState)
  }

  return (
    <div className={`${style.table} ${navigate && style.tableMain}`}>
      {navigate && (
        <>
          <div className={style.tableHead}>{navigate}</div>
          <Divider className={style.divider} />
        </>
      )}
      <div
        className={style.top}
        style={{
          justifyContent: !!title ? 'space-between' : 'end',
        }}
      >
        {!!title && <h4 className={style.title}>{title}</h4>}
        <div className={style.btnGroup}>
          <div
            className={style.settingBtn}
            onClick={() =>
              setModalState({
                modalStatus: true,
                isModalExport: false,
                title: local.table.settingTitle,
              })
            }
          >
            <u>{local.table.settingButton}</u>
            <AppstoreAddOutlined translate={undefined} />
          </div>
          {!!isExportButtonVisible && (
            <AntdButton
              className={style.exportBtn}
              disabled={!isExportDataExist}
              onClick={() =>
                setModalState({
                  modalStatus: true,
                  isModalExport: true,
                  title: local.table.exportTitle,
                })
              }
            >
              {local.table.exportButton}
              <DownloadOutlined
                className={style.exportBtnIcon}
                translate={undefined}
              />
            </AntdButton>
          )}
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={data}
        columns={columns.filter(c =>
          selectedViewColumns!.includes(c.key as string)
        )}
        pagination={
          !!pagination && {
            ...pagination,
            className: `${style.pagination} ${
              (pagination as TablePaginationConfig).className
            }`,
          }
        }
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyText || local.table.emptyData}
            />
          ),
        }}
        scroll={scroll}
        size={'small'}
        onChange={onChange}
        showSorterTooltip={true}
        expandable={expandable}
      />
      {!!pagination && (
        <div className={style.tableCounters}>
          <p>
            {local.table.counters.total}: {pagination.total}
          </p>
        </div>
      )}

      {!!modalState.modalStatus &&
        (!!modalState.isModalExport ? (
          <ExportModal
            columns={columns}
            mainColumns={mainColumns}
            filters={filters}
            fileName={fileName}
            exportMethod={exportMethod}
            modalState={modalState}
            handleChangeModalState={handleChangeModalState}
            excludeFields={excludeFields}
          />
        ) : (
          <ViewModal
            columns={columns}
            type={type}
            mainColumns={mainColumns}
            modalState={modalState}
            handleChangeModalState={handleChangeModalState}
            selectedViewColumns={selectedViewColumns}
            handleChangeViewColumns={handleChangeViewColumns}
          />
        ))}
    </div>
  )
}

export default ViewTable
