import {
  bigLettersRegExp,
  cyrillicRegExp,
  digitsRegExp,
  emailRegExp,
  smallLettersRegExp,
  specialSymbolsRegExp,
  spaceLetterRegExp,
} from '../../constants'
import { FormField } from './IAuth'
import local from './../../localization'
import { CountryData } from 'react-phone-input-2'

export const ValidationHelper = {
  validateFullName: (name: string): FormField => {
    if (!name || !name.trim()) {
      return {
        valid: false,
        value: name,
        message: local.main.validation.requiredField,
      }
    } else if (name.trim().length < 3) {
      return {
        valid: false,
        value: name,
        message: local.main.validation.fullNameLength,
      }
    }

    return {
      valid: true,
      value: name,
      message: '',
    }
  },

  validateEmail: (email: string): FormField => {
    if (!email || email === '') {
      return {
        valid: false,
        value: email,
        message: local.main.validation.requiredField,
      }
    } else if (!email.match(emailRegExp)) {
      return {
        valid: false,
        value: email,
        message: local.main.validation.emailValid,
      }
    }

    return { valid: true, value: email, message: '' }
  },

  validateEmailConfirm: (email: string, emailConfirm: string): FormField => {
    if (!emailConfirm || !emailConfirm.trim()) {
      return {
        valid: false,
        message: local.main.validation.requiredField,
        value: emailConfirm,
      }
    } else if (email !== emailConfirm) {
      return {
        valid: false,
        message: local.main.validation.emailConfirmValid,
        value: emailConfirm,
      }
    }

    return {
      valid: true,
      message: '',
      value: emailConfirm,
    }
  },

  validatePassword: (password: string): FormField => {
    if (!password.trim()) {
      return {
        valid: false,
        message: local.main.validation.requiredField,
        value: password,
      }
    } else if (password.match(spaceLetterRegExp)) {
      return {
        valid: false,
        message: local.main.validation.passwordWithoutWhitespace,
        value: password,
      }
    } else if (password.match(cyrillicRegExp)) {
      return {
        valid: false,
        message: local.main.validation.latinLettersOnly,
        value: password,
      }
    } else if (
      !password.match(digitsRegExp) &&
      !password.match(specialSymbolsRegExp)
    ) {
      return {
        valid: false,
        message: local.main.validation.includeDigitsOrSymbols,
        value: password,
      }
    } else if (
      !password.match(bigLettersRegExp) &&
      !password.match(smallLettersRegExp)
    ) {
      return {
        valid: false,
        message: local.main.validation.upperLowerCaseLetters,
        value: password,
      }
    } else if (password.length < 8) {
      return {
        valid: false,
        message: local.main.validation.passwordLength,
        value: password,
      }
    }
    return {
      valid: true,
      message: '',
      value: password,
    }
  },

  validateNewPassword: (password: string, oldPassword: string): FormField => {
    if (!password || !password.trim()) {
      return {
        valid: false,
        message: local.main.validation.requiredField,
        value: password,
      }
    } else if (password.length < 8) {
      return {
        valid: false,
        message: local.main.validation.passwordLength,
        value: password,
      }
    } else if (password.match(cyrillicRegExp)) {
      return {
        valid: false,
        message: local.main.validation.latinLettersOnly,
        value: password,
      }
    } else if (
      !password.match(smallLettersRegExp) &&
      !password.match(bigLettersRegExp)
    ) {
      return {
        valid: false,
        message: local.main.validation.upperLowerCaseLetters,
        value: password,
      }
    } else if (
      !password.match(digitsRegExp) &&
      !password.match(specialSymbolsRegExp)
    ) {
      return {
        valid: false,
        message: local.main.validation.includeDigitsOrSymbols,
        value: password,
      }
    } else if (password === oldPassword) {
      return {
        valid: false,
        message: local.main.validation.passwordMustBeDifferent,
        value: password,
      }
    }
    return {
      valid: true,
      message: '',
      value: password,
    }
  },

  passwordStrength: (password: string) => {
    let protectCount: number = 0

    let ruleValidate = {
      isLength: false,
      isSmallLetters: false,
      isBigLetters: false,
      isDigits: false,
      isSpecialSymbol: false,
    }

    if (password.length >= 8) {
      protectCount++
      ruleValidate.isLength = true
    }
    if (password.match(smallLettersRegExp)) {
      protectCount++
      ruleValidate.isSmallLetters = true
    }
    if (password.match(bigLettersRegExp)) {
      protectCount++
      ruleValidate.isBigLetters = true
    }
    if (password.match(digitsRegExp)) {
      protectCount++
      ruleValidate.isDigits = true
    }
    if (password.match(specialSymbolsRegExp)) {
      protectCount++
      ruleValidate.isSpecialSymbol = true
    }

    return { count: protectCount, ruleValidate }
  },

  validatePasswordConfirm: (
    password: string,
    passwordConfirm: string
  ): FormField => {
    if (!passwordConfirm || !passwordConfirm.trim()) {
      return {
        valid: false,
        message: local.main.validation.requiredField,
        value: passwordConfirm,
      }
    } else if (password !== passwordConfirm) {
      return {
        valid: false,
        message: local.main.validation.passwordConfirmValid,
        value: passwordConfirm,
      }
    }

    return {
      valid: true,
      message: '',
      value: passwordConfirm,
    }
  },

  validatePhoneNumber: (
    phoneNumber: string,
    country?: {} | CountryData,
    isRequieredField?: boolean
  ): FormField => {
    const numberOfDigitsInValue = (value: string, regExp: RegExp): number => {
      return !!value && !!regExp ? value.match(regExp)?.length! : 0
    }

    const numberOfDigitsInFormat =
      'format' in country! && numberOfDigitsInValue(country!.format, /\./g) < 15
        ? numberOfDigitsInValue(country!.format, /\./g)
        : 15

    const numberOfDigitsInPhoneNumber =
      !!phoneNumber && phoneNumber !== '+'
        ? numberOfDigitsInValue(phoneNumber, /\d/g)
        : 0

    if ((!phoneNumber || phoneNumber === '') && isRequieredField) {
      return {
        valid: false,
        value: phoneNumber,
        message: local.main.validation.requiredField,
      }
    } else if (
      (numberOfDigitsInFormat === 0 || numberOfDigitsInPhoneNumber === 0) &&
      phoneNumber !== '+' &&
      !isRequieredField
    ) {
      return { valid: true, value: phoneNumber, message: '' }
    } else if (numberOfDigitsInFormat !== numberOfDigitsInPhoneNumber) {
      return {
        valid: false,
        value: phoneNumber,
        message: local.main.validation.phoneNumberValid,
      }
    }

    return { valid: true, value: phoneNumber, message: '' }
  },
}
