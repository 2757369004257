import { combineReducers } from 'redux'
import { authReducer } from '../features/auth/login/authSlice'
import { registrationReducer } from '../features/auth/registration/registrationSlice'
import { recoveryPasswordReducer } from '../features/auth/recovery-password/recoveryPasswordSlice'
import { confirmedPasswordReducer } from '../features/auth/confirned-password/confirmedPasswordSlice'
import { notifierReducer } from '../features/notification/notificationSlice'
import { loaderReducer } from '../features/loader/loaderSlice'
import { usersReducer } from '../features/users/usersSlice'
import { positionsReducer } from '../features/positions/positionsSlice'
import { tasksReducer } from '../features/tasks/tasksSlice'
import { profileReducer } from '../features/profile/profileSlice'
import { availableTrackReducer } from '../features/tracks/available/tracksSlice'
import { tracksManagmentReducer } from '../features/tracks/manager/tracksManagerSlice'
import { profileHeadReducer } from '../layouts/Header/HeaderSlice'
import { tasksCheckReducer } from '../features/task-check/taskCheckSlice'
import { tasksEditReducer } from '../features/task-edit/taskEditSlice'
import { tasksPassingReducer } from '../features/task-passing-start/taskPassingStartSlice'
import { tracksEngineReducer } from '../features/tracks-engine/tracksEngineSlice'
import { taskViewReducer } from '../features/task-veiw/taskViewSlice'
import { statUsersReducer } from '../features/stats/stat-users/statUsersSlice'
import { statUserItemReducer } from '../features/stats/stat-users/stat-user-item/statUserItemSlice'
import { breadcrumbReducer } from '../layouts/Breadcrumb/BreadcrumbSlice'
import { settingsReducer } from '../features/profile/settings/profileSettingsSlice'
import { statItemsReducer } from '../features/stats/stat-items/statItemsSlice'
import { searchReducer } from '../layouts/Header/Search/SearchSlice'

export const rootReducer = combineReducers({
  auth: combineReducers({
    authReducer,
    registrationReducer,
    recoveryPasswordReducer,
    confirmedPasswordReducer,
  }),
  notifications: notifierReducer,
  loader: loaderReducer,
  users: usersReducer,
  positions: positionsReducer,
  tasks: combineReducers({
    list: tasksReducer,
    edit: tasksEditReducer,
    pass: tasksPassingReducer,
    check: tasksCheckReducer,
    view: taskViewReducer,
  }),
  profile: profileReducer,
  tracks: combineReducers({
    avaible: availableTrackReducer,
    management: tracksManagmentReducer,
    engine: tracksEngineReducer,
  }),
  profileHead: profileHeadReducer,
  stat: combineReducers({
    statUsers: statUsersReducer,
    statUserItem: statUserItemReducer,
    statItems: statItemsReducer,
  }),
  breadcrumb: breadcrumbReducer,
  settings: settingsReducer,
  search: searchReducer,
})

export default rootReducer
