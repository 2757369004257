import React from 'react'
import { Drawer, Image } from 'antd'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import styles from './BurgerMenu.module.scss'
import { useTheme } from '../../../theme/Theme'
import { Menu } from '../../Sidebar/Menu/Menu'

const isEmptyURL = window.REACT_APP_COMPANY_WEBSITE_URL.trim() === ''

const BurgerMenu = () => {
  const [visible, setVisible] = React.useState(false)

  const { themeProps } = useTheme()

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <IconButton aria-label='burger menu' onClick={showDrawer}>
        {visible ? (
          <MenuOpenIcon className={styles.animatedIcon} />
        ) : (
          <MenuIcon className={styles.animatedIcon} />
        )}
      </IconButton>
      <Drawer
        closable={false}
        onClose={onClose}
        open={visible}
        placement={'left'}
        width={266}
        className={styles.menuDrawer}
        style={{ ...(themeProps as React.CSSProperties) }}
      >
        {!isEmptyURL ? (
          <a
            href={window.REACT_APP_COMPANY_WEBSITE_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image className={styles.logo} preview={false} />
          </a>
        ) : (
          <Image className={styles.logo} preview={false} />
        )}
        <Menu onClose={onClose} />
      </Drawer>
    </>
  )
}

export default BurgerMenu
