import { BehaviorSubject, from, Observable, Subscription } from 'rxjs'
import { first } from 'rxjs/operators'
import _ from 'lodash'
import { EAPIResponseStatus } from '../constants'
import { IAPIResult } from '../services/IBaseService'

export const requestSubscriptions: Array<Subscription> = []
export const httpBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  false
)

export function stackRequestWrapper<T>(observable: Observable<IAPIResult<T>>) {
  return new Promise<IAPIResult<T>>((resolve, reject) => {
    const subscription = from(httpBusy)
      .pipe(first(isBusy => !isBusy))
      .subscribe(() => {
        httpBusy.next(true)

        observable
          .subscribe(
            (value: IAPIResult<T>) => {
              if (requestSubscriptions.length) {
                requestSubscriptions.splice(0, 1)
              }

              if (value && value.status !== EAPIResponseStatus.SUCCESS) {
                const code = value.code

                if (code) {
                  switch (code) {
                    case 401: {
                      if (requestSubscriptions.length) {
                        requestSubscriptions.forEach(sub => sub.unsubscribe())
                        _.remove(requestSubscriptions)
                      }

                      break
                    }
                    case 551: {
                      //   if (requestSubscriptions.length) {
                      //     requestSubscriptions.forEach(sub => sub.unsubscribe())
                      //     _.remove(requestSubscriptions)
                      //   }

                      break
                    }
                    default: {
                      break
                    }
                  }
                }
                reject(value)
              } else {
                resolve(value)
              }
            },
            (err: Response | IAPIResult<T>) => {
              if (requestSubscriptions.length) {
                requestSubscriptions.forEach(sub => sub.unsubscribe())
                _.remove(requestSubscriptions)
              }

              reject(err)
            }
          )
          .add(() => {
            httpBusy.next(false)
          })
      })

    requestSubscriptions.push(subscription)
  })
}
