import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EStateStatus } from '../../constants'
import * as I from 'Types'

const initialState: I.LoaderState = {
  isLoading: false,
  permanentLoading: false,
}

const isAsyncAction = (action: AnyAction): action is I.AsyncAction => {
  const { type } = action
  const matches = /(.*)\/(pending|rejected|fulfilled)/.exec(type)

  return type.indexOf('tasks/pass/draft/set/') === -1 && !!matches
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setPermanentLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.permanentLoading = payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAsyncAction,
      (state, action) => {
        const { type } = action
        const matches = /(.*)\/(pending|rejected|fulfilled)/.exec(type)

        if (!matches) {
          return
        }

        const [, , requestState] = matches
        state.isLoading =
          requestState === EStateStatus.PENDING || state.permanentLoading
      }
    )
  },
})

export const getLoading = (state: I.RootState) =>
  state.loader.isLoading || state.loader.permanentLoading
export const { setPermanentLoading } = loaderSlice.actions

export const { reducer: loaderReducer } = loaderSlice
