import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Styles from './Search.module.scss'
import { Input, Spin, Empty, Switch } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import {
  selectSearch,
  updateSearch,
  search,
  updateSearchMode,
  searchMore,
  updateResult,
  updateText,
  updateManagerSwitch,
} from './SearchSlice'
import { useAppDispatch } from '../../../app/store'
import local from './../../../localization'
import SearchItem from './SearchItem'
import { isInRole } from '../../../services/auth'
import { Authorities } from '../../../constants'
import { Checkbox, FormControlLabel, IconButton } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { isMobile } from '../../../utils'
import { useInputSearch } from '../../../hooks/useInputSearch'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

export let getGlobalSearchRef: React.MutableRefObject<any>

const Search = () => {
  const dispatch = useAppDispatch()
  const container = useRef<HTMLDivElement>(null)
  const [fetch, setFetch] = useState(false)
  const [isOpenGS, setIsOpenGS] = useState(false)

  const {
    inNodeTitle,
    inNodeTopic,
    inQuestionsText,
    inTrackTitle,
    inUnavailableNodes,
    inManagerNodes,
    hasMore,
    onSearchMode,
    isLoad,
    page,
    searchItems,
    textFind,
  } = useSelector(selectSearch)

  const handleChangeFilters = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const model: any = {
        [event.target.value]: checked,
      }
      dispatch(updateSearch(model))
    },
    [dispatch]
  )

  const handleChangeManagerSwitcher = useCallback(
    (flag: boolean) => {
      dispatch(updateManagerSwitch(flag))
    },
    [dispatch]
  )

  const [onSearch$] = useInputSearch()

  const handleChangeText = useCallback(
    (e: any) => {
      dispatch(updateText(e.target.value))
      onSearch$.next(e.target.value)
    },
    [dispatch, onSearch$]
  )

  const handleClearText = useCallback(() => {
    dispatch(updateText(''))
    onSearch$.next('')
  }, [dispatch, onSearch$])

  const getSearchItems = React.useMemo(() => {
    return searchItems.map((item, i) => {
      return (
        <SearchItem
          itemsTitle={item.itemsTitle}
          nodesTitle={item.nodesTitle}
          topicsText={item.topicsText}
          questionsText={item.questionsText}
          isAvailableNode={item.isAvailableNode}
          url={item.url}
          key={i}
        />
      )
    })
  }, [searchItems])

  const setSearchMode = useCallback(
    (inSearch: boolean) => () => {
      dispatch(updateSearchMode(inSearch))
      if (!inSearch) dispatch(updateResult())
      handleSetClassBody(inSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const handleSearch = useCallback(
    (value?: string) => {
      if (value) {
        container.current?.scrollTo(0, 0)
        dispatch(
          search({
            page: 0,
            textFind: value,
            inNodeTitle,
            inNodeTopic,
            inQuestionsText,
            inTrackTitle,
            inUnavailableNodes,
            inManagerNodes,
          })
        )
      }
    },
    [
      dispatch,
      inNodeTitle,
      inNodeTopic,
      inQuestionsText,
      inTrackTitle,
      inUnavailableNodes,
      inManagerNodes,
    ]
  )

  const fetchData = useCallback(() => {
    if (textFind) {
      setFetch(false)
      dispatch(
        searchMore({
          page: page + 1,
          textFind,
          inNodeTitle,
          inNodeTopic,
          inQuestionsText,
          inTrackTitle,
          inUnavailableNodes,
          inManagerNodes,
        })
      )
    }
  }, [
    dispatch,
    inManagerNodes,
    inNodeTitle,
    inNodeTopic,
    inQuestionsText,
    inTrackTitle,
    inUnavailableNodes,
    page,
    textFind,
  ])

  useEffect(() => {
    setFetch(hasMore)
  }, [hasMore])

  useEffect(() => {
    let subscription: Subscription
    if (onSearch$ !== undefined) {
      subscription = onSearch$
        .pipe(debounceTime(500))
        .subscribe((value?: string) => {
          if (value) {
            container.current?.scrollTo(0, 0)
            dispatch(
              search({
                page: 0,
                textFind: value,
                inNodeTitle,
                inNodeTopic,
                inQuestionsText,
                inTrackTitle,
                inUnavailableNodes,
                inManagerNodes,
              })
            )
          } else {
            dispatch(updateResult())
          }
        })
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onSearch$,
    inNodeTitle,
    inNodeTopic,
    inQuestionsText,
    inTrackTitle,
    inUnavailableNodes,
    inManagerNodes,
  ])

  // for the event when hovering over the side menu
  const globalSearchRef = React.useRef<any>(null)
  getGlobalSearchRef = globalSearchRef

  const handleOpenGS = useCallback(() => {
    setIsOpenGS(!isOpenGS)
    handleSetClassBody(!isOpenGS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenGS])

  // Removes scrolling through the document
  const handleSetClassBody = useCallback((isSwitch: boolean) => {
    isSwitch
      ? document.body.classList.add('bodyOverflowHidden')
      : document.body.classList.remove('bodyOverflowHidden')
  }, [])

  const drawGlogalSearch = useMemo(() => {
    return (
      <div
        className={Styles.searchComponent}
        ref={globalSearchRef}
        id={'globalSearch'}
      >
        <Input.Search
          onSearch={handleSearch}
          placeholder={local.main.search.placeholder}
          enterButton
          loading={isLoad}
          maxLength={80}
          value={textFind}
          onChange={handleChangeText}
          onClick={setSearchMode(true)}
        />
        {!!textFind?.length && (
          <HighlightOffIcon
            className={Styles.closeIcon}
            onClick={handleClearText}
          />
        )}
        {onSearchMode && (
          <div className={Styles.dropDown}>
            <div className={Styles.filtersGroup}>
              <span className={Styles.fiterTitle}>
                {local.main.search.searchIn}
              </span>
              {/* <FormControlLabel
              disabled={inManagerNodes}
              control={
                <Checkbox
                  size='small'
                  value={'inUnavailableNodes'}
                  checked={inUnavailableNodes}
                  onChange={handleChangeFilters}
                />
              }
              className={Styles.checkbox}
              label={local.main.search.inUnavailableNodes}
            /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    value={'inTrackTitle'}
                    checked={inTrackTitle}
                    onChange={handleChangeFilters}
                  />
                }
                className={Styles.checkbox}
                label={local.main.search.inTrackTitle}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    value={'inNodeTitle'}
                    checked={inNodeTitle}
                    onChange={handleChangeFilters}
                  />
                }
                className={Styles.checkbox}
                label={local.main.search.inNodeTitle}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    value={'inNodeTopic'}
                    checked={inNodeTopic}
                    onChange={handleChangeFilters}
                  />
                }
                className={Styles.checkbox}
                label={local.main.search.inNodeTopic}
              />
              {isInRole(Authorities.MANAGEMENT_NODES_PAGE) && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        value={'inQuestionsText'}
                        checked={inQuestionsText}
                        onChange={handleChangeFilters}
                      />
                    }
                    className={Styles.checkbox}
                    label={local.main.search.inQuestionsText}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        className={Styles.managerSwitch}
                        size='small'
                        checked={inManagerNodes}
                        onChange={handleChangeManagerSwitcher}
                      />
                    }
                    label={
                      inManagerNodes
                        ? local.main.search.inManagerNodes
                        : local.main.search.inAvailableNodes
                    }
                  />
                </>
              )}
            </div>
            <div ref={container} className={Styles.resultsContainer}>
              {!!searchItems.length ? (
                <InfiniteScroll
                  hasMore={fetch}
                  loadMore={fetchData}
                  threshold={200}
                  useWindow={false}
                  scrolling={'top'}
                  className={Styles.infinity}
                  loader={<Spin key={-1} />}
                >
                  {getSearchItems}
                </InfiniteScroll>
              ) : (
                <Empty
                  className={Styles.empty}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={local.table.emptyData}
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }, [
    fetch,
    fetchData,
    getSearchItems,
    handleChangeFilters,
    handleChangeManagerSwitcher,
    handleChangeText,
    handleClearText,
    handleSearch,
    inManagerNodes,
    inNodeTitle,
    inNodeTopic,
    inQuestionsText,
    inTrackTitle,
    isLoad,
    onSearchMode,
    searchItems,
    setSearchMode,
    textFind,
  ])

  return (
    <>
      {onSearchMode && !isMobile() ? (
        <div className={Styles.mask} onClick={setSearchMode(false)} />
      ) : (
        isOpenGS && <div className={Styles.mask} onClick={handleOpenGS} />
      )}
      {isMobile() && (
        <IconButton aria-label='search' onClick={handleOpenGS}>
          <SearchIcon />
        </IconButton>
      )}
      {isMobile() && isOpenGS
        ? drawGlogalSearch
        : !isMobile() && drawGlogalSearch}
    </>
  )
}

export default React.memo(Search)
