import { Observable } from 'rxjs'
import { IAPIResult } from '../IBaseService'
import { BaseApiService, getParams } from '../base.service'
import * as I from 'Types'
import { TMap } from '../../constants'
import { EAPIEndpoint } from '../constants'

const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
export default class UsersService {
  static getUsers(
    data: Record<string | number, any>
  ): Observable<IAPIResult<I.IUsersResponse>> {
    const baseApiService = new BaseApiService<I.IUsersResponse>()
    return baseApiService.getRequest(`${EAPIEndpoint.USERS}${getParams(data)}`)
  }

  static postUsersTableExport(
    data: I.IUserTableExport,
    fileName: string
  ): Observable<IAPIResult<Blob>> {
    const baseApiService = new BaseApiService<Blob>()
    return baseApiService.postExportFileRequest(
      EAPIEndpoint.USERS_TABLE_EXPORT,
      data,
      fileName
    )
  }

  static postUser(
    id: number,
    fullName: string,
    email: string,
    isActive: boolean,
    phoneNumber?: string,
    role?: number,
    position?: number,
    items?: Array<number>
  ): Observable<IAPIResult<I.IUser>> {
    const baseApiService = new BaseApiService<I.IUser>()
    const body: Record<string, unknown> = {
      id,
      fullName,
      email,
      isActive,
      phoneNumber,
      role: role ? role : null,
      position: position ? position : null,
      items: items ? items : [],
    }
    return baseApiService.postRequest(EAPIEndpoint.USERS, head, body)
  }

  static putUser(
    fullName: string,
    email: string,
    isActive: boolean,
    phoneNumber?: string,
    role?: number,
    position?: number,
    items?: Array<number>
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    const body: Record<string, unknown> = {
      fullName,
      email,
      isActive,
      phoneNumber,
      role: role ? role : null,
      position: position ? position : null,
      items: items ? items : [],
    }
    return baseApiService.putRequest(EAPIEndpoint.USERS, head, body)
  }

  static deleteUser(userId: number): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.deleteRequest(
      EAPIEndpoint.USERS_DELETE.replace(':userId', userId.toString())
    )
  }

  static getRoles(): Observable<IAPIResult<Array<TMap>>> {
    const baseApiService = new BaseApiService<Array<TMap>>()
    return baseApiService.getRequest(EAPIEndpoint.ROLES)
  }

  static resetPassword(userId: number): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(
      EAPIEndpoint.USERS_PASSWORD_RESET.replace(':userId', userId.toString())
    )
  }

  static changeEmail(body: I.IPropsChangeEmail): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(
      EAPIEndpoint.USERS_EMAIL_CHANGE,
      head,
      body
    )
  }

  static getUserName(
    userId: number
  ): Observable<IAPIResult<I.IUserNameResponse>> {
    const baseApiService = new BaseApiService<I.IUserNameResponse>()
    return baseApiService.getRequest(
      EAPIEndpoint.USER_NAME.replace(':userId', userId.toString())
    )
  }
}
