const script = `;(function(m, e, t, r, i, k, a) {
  m[i] =
    m[i] ||
    function() {
      ;(m[i].a = m[i].a || []).push(arguments)
    }
  m[i].l = 1 * new Date()
  for (var j = 0; j < document.scripts.length; j++) {
    if (document.scripts[j].src === r) {
      return
    }
  }
  ;(k = e.createElement(t)),
    (a = e.getElementsByTagName(t)[0]),
    (k.async = 1),
    (k.src = r),
    a.parentNode.insertBefore(k, a)
})(
  window,
  document,
  'script',
  'https://mc.yandex.ru/metrika/tag.js',
  'ym'
)
ym($metrika, 'init', {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
})`

const metrikValue = /\$metrika/gm
const metrikId = 'yandexMetrik'
const metrikKey = window.REACT_APP_METRIC_KEY
const registrationMetrikKey = window.REACT_APP_REGISTRATION_METRIC_KEY

const metrikScript = document.createElement('script')
metrikScript.id = metrikId
metrikScript.type = 'text/javascript'
metrikScript.append(
  script.replace(metrikValue, metrikKey || registrationMetrikKey)
)

const metrikCounter = document.createElement('noscript')
const div = document.createElement('div')
const img = document.createElement('img')
img.src = `https://mc.yandex.ru/watch/${metrikKey || registrationMetrikKey}`
img.alt = ''
img.setAttribute('style', 'position:absolute; left:-9999px;')
div.appendChild(img)
metrikCounter.appendChild(div)

const googleKey = window.REACT_APP_GOOGLE_KEY
const KEY = /\$KEY/gm
const googleSrc = 'https://www.googletagmanager.com/gtag/js?id=$KEY'
const googleScript = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '$KEY');`
const googleMetrikScriptHead = document.createElement('script')
googleMetrikScriptHead.id = googleKey
googleMetrikScriptHead.async = true
googleMetrikScriptHead.src = googleSrc.replace(KEY, googleKey)
const googleMetrikScriptMain = document.createElement('script')
googleMetrikScriptMain.append(googleScript.replace(KEY, googleKey))

export const addMetrik = (elementId?: HTMLDivElement): void => {
  let isMetrikUsed = false

  if (registrationMetrikKey && !!elementId) {
    elementId.childNodes.forEach((v: any) => {
      if (v?.id === metrikId) isMetrikUsed = true
    })

    if (!isMetrikUsed) {
      elementId.appendChild(metrikScript)
      elementId.appendChild(metrikCounter)
    }
  } else if (googleKey) {
    document.body.childNodes.forEach((v: any) => {
      if (v?.id === googleKey) isMetrikUsed = true
    })

    if (!isMetrikUsed) {
      document.body.appendChild(googleMetrikScriptHead)
      document.body.appendChild(googleMetrikScriptMain)
    }
  } else if (metrikKey) {
    document.body.childNodes.forEach((v: any) => {
      if (v?.id === metrikId) isMetrikUsed = true
    })

    if (!isMetrikUsed) {
      document.body.appendChild(metrikScript)
      document.body.appendChild(metrikCounter)
    }
  }
}
