import process_en from '../../assets/png/help_process_en.jpg'
import management_en from '../../assets/png/help_management_en.jpg'
import status_en from '../../assets/png/help_status_en.jpg'

export const help = {
  welcome: 'Welcome to :System!',
  purpose:
    'The main idea of :System is to integrate employees into work processes, positions, teach them new things, accumulate and store useful knowledge in the company, while an important feature is that they do it themselves.',
  canDo: {
    title: 'What users can do:',
    features: [
      'create tracks',
      'choose between two modes of training: "with" or "without" a mentor',
      'receive feedback on the completed tasks',
      'use statistics to analyze tracks, progress, performance of employees',
      'receive information about the best practices and experience of colleagues',
      'if you want to change the direction of your career  - immerse yourself in another position, there is a variety of tracks',
      'support and updating of knowledge (if something has changed in the company)',
      'make sure that the employee is 100% ready for the new position and confirmed their knowledge by completing all the tasks of the track',
      'manage user access rights',
    ],
    solution:
      'It is possible to solve a variety of company cases by combining different functions and organizational rules.',
  },
  problem: {
    title: 'How does :System reach the goal?',
    solution:
      'We assign a manager to the track of the company (in fact, the track is the area of knowledge, function, work process, position, etc.) and continue according to the scheme:',
  },
  fastNote: 'Quick briefing on creating tracks for the manager',
  passing: 'How to pass the track assigned to me?',
  wishes: {
    title: 'Have you noticed a mistake, or do you have any suggestions? ',
    btn: 'Let us',
    titleEnd: ' know!',
  },
  imgSrc: {
    process: process_en,
    management: management_en,
    status: status_en,
  },
  imgSVG: {
    problem: {
      manager: 'Manager',
      createTrack: 'Create a Track',
      track: 'Track',
      trackWasAssigned: 'The Track was assigned to Employee',
      employee: 'Employee',
      runsTheTrack: 'Runs the Track’s Task',
      mentor: 'Mentor',
      checkCompletedTask: 'Check the Completed Task',
      readyToWork: '100% Ready to Work',
    },
    fastNote: {
      controlPanel: 'Control panel',
      hotkeys: {
        title: 'Hotkeys:',
        altQ: 'Alt + Q :split Add a New Module',
        altW: 'Alt + W :split Adding a New Connection',
        altE: 'Alt + E :split Edit the Title',
        altS: 'Alt + S :split Saving the Changes',
        altC: 'Alt + C :split Copy the Module',
      },
      textList: {
        changeModule: 'Change the Module Title',
        addModule: 'Add a New Module',
        createConnection: 'Create a connection from one Module to another',
        deleteModule: 'Delete the Module',
        saveChanges: 'Save the Changes',
        doubleClickModule:
          'Double-click on the Module or this button will take you to the description of the selected Module',
        copyModule: 'Copy the Module',
        indicatorsWillShow:
          'Indicators will show you if you have added the module description  and what types of checks you have set: test, detailed answers, video answers',
      },
    },
    passing: {
      completeTask: {
        title: 'To carry out the task:',
        read: 'Read and study the module description',
        confirm: 'Confirm that you are ready for a knowledge check',
        complete: 'Complete the tasks',
        send: 'Send them for review',
      },
      start: 'Start',
      task: 'Task',
      moduleCompleted:
        'The module is completed and knowledge is gained. Now the information from the module is always available and knowledge can be refreshed at any time',
      moduleUnderReview:
        'Module under review by mentor. Please wait for the results.',
      youCantStart: `You can't start studying this module because the answers to the tasks from the previous module haven't been marked as reviewed and accepted by the mentor.`,
      moduleAvailable:
        'The module is available and you have started working on the tasks, but you have not yet submitted your answers for review.',
      moduleTasksHaveBeenReviewed:
        'The module tasks have been reviewed, but the results have not been accepted. The tasks need to be completed again.',
    },
  },
  feedBackModal: {
    titleModal: 'Feedback',
    subjectMessage: 'Subject of letter',
    textAreaPlaceholder: 'Text of the letter',
    messageType: {
      bug: 'Error',
      question: 'Question',
      proposal: 'Proposal',
      other: 'Other',
    },
    uploadTitle: 'Attach photo or video',
    footNote:
      'If you want to attach a video and/or a photo, add a link to the text',
    actionBtnSend: 'Send',
    actionBtnCancle: 'Cancel',
    thanksMessage: 'Thank you for the feedback',
  },
}
