import React from 'react'
import { Col, Progress, Row, Statistic } from 'antd'
import style from './ProfileTracks.module.scss'
import { useSelector } from 'react-redux'
import { profileStatTracks, selectStatTracks } from '../../../profileSlice'
import { useAppDispatch } from '../../../../../app/store'
import { IProfileStatProps } from 'Types'
import local from './../../../../../localization'
import {
  EColorCard,
  getStyle,
} from './../../../../../components/EISComponents/EISColorCard/EISColorCard.styles'
import EISInfo from '../../../../../components/EISComponents/EISInfo/EISInfo'

const ProfileTracksPL: React.FC<IProfileStatProps> = ({
  userId,
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const {
    total,
    left,
    completed,
    checking,
    percentage,
    rangeDate,
    track,
  } = useSelector(selectStatTracks)

  React.useEffect(() => {
    dispatch(profileStatTracks({ trackId: track?.key, userId, rangeDate }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, rangeDate])

  return (
    <Row
      align={'middle'}
      gutter={[20, 10]}
      justify={'space-between'}
      className={style.progress}
    >
      <Col span={24}>
        <h4>{local.profile.stat.tasks.tasksProgress}</h4>
      </Col>
      <Col className={style.progressContainer}>
        <Row align={'bottom'} gutter={[10, 10]}>
          <Col className={style.diagram}>
            <Progress
              percent={percentage}
              format={p => <span className={style.percent}>{p + '%'}</span>}
              type='circle'
              strokeWidth={10}
            />
          </Col>
          <Col className={style.info}>
            <EISInfo text={local.profile.stat.tasks.progressTooltip} />
          </Col>
          {percentage !== 100 && (
            <Col className={style.legend}>
              <p className={style.done}>
                {local.profile.stat.tasks.completedProgress}
              </p>
              <p className={style.undone}>
                {local.profile.stat.tasks.failedProgress}
              </p>
            </Col>
          )}
        </Row>
      </Col>
      <Col flex={'1 1 340px'}>
        <Row align={'middle'} className={style.wrapperStatBlock}>
          <Col flex={'1 1 290px'}>
            <Row align={'middle'} className={style.wrapperStatBlock}>
              <Col flex={'1 1 135px'}>
                <Statistic
                  title={local.profile.stat.tasks.tasksTotal}
                  value={total}
                  groupSeparator={' '}
                  className={`${style.statBlock} ${getStyle(
                    EColorCard.DEFAULT
                  )}`}
                />
              </Col>
              <Col flex={'1 1 135px'}>
                <Statistic
                  title={local.profile.stat.tasks.tasksCompleted}
                  value={completed}
                  groupSeparator={' '}
                  className={`${style.statBlock} ${getStyle(
                    EColorCard.COMPLETED
                  )}`}
                />
              </Col>
            </Row>
          </Col>
          <Col flex={'1 1 290px'}>
            <Row align={'middle'} className={style.wrapperStatBlock}>
              <Col flex={'1 1 135px'}>
                <Statistic
                  title={local.profile.stat.tasks.tasksLeft}
                  value={left}
                  groupSeparator={' '}
                  className={`${style.statBlock} ${getStyle(
                    EColorCard.AVAILABLE
                  )}`}
                />
              </Col>
              <Col flex={'1 1 135px'}>
                <Statistic
                  title={local.profile.stat.tasks.tasksChecked}
                  value={checking}
                  groupSeparator={' '}
                  className={`${style.statBlock} ${getStyle(
                    EColorCard.WAITING
                  )}`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ProfileTracksPL
