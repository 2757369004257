import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { EISInput, EISModal } from '../../../EISComponents'
import { ISearchSelectModal, IDataType } from './ISearchSelectModal'
import local from '../../../../localization'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface'
import { Empty, Table } from 'antd'
import Styles from './SearchSelectModal.module.scss'
import { IKeyValuePair } from '../../../../constants'
import { SearchOutlined } from '@ant-design/icons'
import { useInputSearch } from '../../../../hooks/useInputSearch'
import { debounceTime } from 'rxjs/operators'
import FilterService from '../../../../services/filter/filter.service'
import { Subscription } from 'rxjs'

const SearchSelectModal: FC<ISearchSelectModal> = ({
  handleSelectDataModal,
  selectedData,
  setSelectedData,
  placeholder = '',
  inputPlaceholder = '',
  searchFilterType,
  updateSearchParams = {},
  isMultipleType = false,
}): ReactElement => {
  const [onSearch$] = useInputSearch()
  const [valueSearch, setValueSearch] = useState<string>()

  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<IDataType[]>([])
  const [localSelectedData, setLocalSelectedData] = useState<IKeyValuePair[]>(
    selectedData
  )

  const [totalElements, setTotalElements] = useState(0)
  const [tableParams, setTableParams] = useState<{
    currentPage: number
    pageSize: number
  }>({
    currentPage: 0,
    pageSize: 10,
  })

  const selectedType = isMultipleType ? 'checkbox' : 'radio'

  const setLocalRoles = () =>
    data.some(el => el.value === 'Administrator')
      ? [
          { key: 1, value: local.users.filter.role.admin },
          { key: 2, value: local.users.filter.role.user },
        ]
      : data

  //#region SearchValue
  const setSearchValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearch$.next(encodeURIComponent(e.target.value))
    },
    [onSearch$]
  )

  useEffect(() => {
    let subscription: Subscription
    if (onSearch$ !== undefined)
      subscription = onSearch$
        .pipe(debounceTime(400))
        .subscribe((value: string | undefined) => {
          setValueSearch(value)
        })
    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch$, updateSearchParams])
  //#endregion SearchValue

  //#region Fetch data
  const fetchData = useCallback(() => {
    setLoading(true)

    FilterService.getFilteredArray(searchFilterType, {
      ...updateSearchParams,
      value: valueSearch,
      size: tableParams.pageSize,
      page: tableParams.currentPage,
    })
      .toPromise()
      .then(results => {
        if (results.data) {
          const convertData: IDataType[] = results.data.content.map(
            (data: any) => ({
              key: data.id,
              value: Object.values(data)[1],
            })
          )

          setData(convertData)
          setTotalElements(results.data.totalElements)
          setLoading(false)
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilterType, tableParams, updateSearchParams, valueSearch, loading])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams, valueSearch])
  //#endregion Fetch data

  //#region Table
  const selectedRowKeys = localSelectedData.length
    ? localSelectedData.map(u => Number(u.key))
    : []

  const rowSelection: TableRowSelection<IDataType> = {
    selectedRowKeys,
    onChange: (srk: React.Key[]) => {
      if (!data) return

      const dataArr: Array<IKeyValuePair> = []

      data.forEach(d => {
        if (srk.includes(d.key)) {
          dataArr.push({ key: d.key, value: d.value })
        }
      })

      if (isMultipleType) {
        const result = localSelectedData
          .filter(i => !data.some(u => u.key === Number(i.key)))
          .concat(dataArr)

        setLocalSelectedData(result)
      } else {
        setLocalSelectedData(dataArr)
      }
    },
  }

  const defaultColumn: ColumnsType<IDataType> = [
    {
      title: placeholder,
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => {
        const textA = a.value.toUpperCase()
        const textB = b.value.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      },
      sortDirections: ['descend'],
      filterDropdown: ({ selectedKeys }: FilterDropdownProps) => (
        <EISInput
          placeholder={inputPlaceholder}
          value={selectedKeys[0]}
          onChange={setSearchValue}
          className={Styles.filterInput}
        />
      ),
      onFilter: (value: string | number | boolean, record: any) =>
        record['value']
          ? record['value']
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          : '',
      filterIcon: (
        <div>
          <SearchOutlined translate={undefined} />
        </div>
      ),
      onCell: (record: IDataType) => ({
        onClick: () => {
          if (isMultipleType) {
            const alreadySelected = localSelectedData.some(item => Number(item.key) === Number(record.key));
            const newSelectedData = alreadySelected
              ? localSelectedData.filter(item => Number(item.key) !== Number(record.key))
              : [...localSelectedData, { key: record.key, value: record.value }];
            setLocalSelectedData(newSelectedData);
          } else {
            setLocalSelectedData([{ key: record.key, value: record.value }]);
          }
        },
      }),
    },
  ]
  //#endregion Table

  const handleCancel = () => {
    handleSelectDataModal()
  }

  const handleOk = useCallback(() => {
    setSelectedData(localSelectedData)
    handleSelectDataModal()
  }, [handleSelectDataModal, localSelectedData, setSelectedData])

  return (
    <EISModal
      open={true}
      width={550}
      title={placeholder}
      onCancel={handleCancel}
      onOk={handleOk}
      className={Styles.selectUsersModal}
      children={
        <>
          <Table
            rowSelection={{ type: selectedType, ...rowSelection }}
            columns={defaultColumn}
            dataSource={setLocalRoles()}
            loading={loading}
            className={Styles.table}
            size={'small'}
            pagination={{
              onChange(page, pageSize) {
                setTableParams({
                  pageSize,
                  currentPage: page - 1,
                })
              },
              current: tableParams.currentPage + 1,
              pageSize: tableParams.pageSize,
              position: ['bottomRight'],
              total: totalElements,
              showSizeChanger: true,
            }}
            scroll={{
              y: 200,
              x: 450,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={local.items.emptyData.noFilterItems}
                />
              ),
            }}
          />

          <p className={Styles.tableCounters}>
            {local.table.counters.total}: {totalElements}
          </p>
        </>
      }
    />
  )
}
export default React.memo(SearchSelectModal)
