export const profile = {
  info: {
    defaultFullName: 'Гость',
    position: 'Должность',
    items: 'Треки',
    availableItems: 'Доступные треки',
    progress: 'Общий прогресс',
    tasksCompleted: 'Модулей выполнено',
  },
  lastAction: {
    tasksCompleted: 'Успешно выполнен модуль',
    tasksConfirmed: 'Подтверждено выполнение модуля',
    tasksFailed: 'Неуспешная попытка выполнения модуля',
    tasksRejected: 'Отклонено выполнение модуля',
    tasksSent: 'Отправлен на проверку модуль',
    tasksDroppedNodeStatus: 'Обнулено прохождение модуля',
    unknownStatus: 'Неизвестный статус модуля',
    lastActions: 'Последние действия',
  },
  stat: {
    title: 'Статистика по прохождению треков',
    selectItem: 'Выберите трек',
    itemLabel: 'Трек',
    tasks: {
      tasksProgress: 'Прогресс',
      progressTooltip:
        'Круговая диаграмма разделена на два сектора - выполненные модули и невыполненные. Размер сектора, соответствующего выполненным модулям, соответствует проценту выполненных работ. Размер сектора, соответствующего невыполненным модулям, соответствует проценту незавершенных работ. Кольцевая диаграмма отображает прогресс за весь период времени',
      completedProgress: 'Выполненные',
      failedProgress: 'Невыполненные',
      tasksTotal: 'Всего модулей',
      tasksCompleted: 'Выполнено',
      tasksLeft: 'Осталось выполнить',
      tasksChecked: 'Ожидает проверки',
      columnTasksTable: {
        name: 'Название',
        status: 'Статус',
        statusRejected: 'НА ДОРАБОТКУ',
        statusCompleted: 'ВЫПОЛНЕНО',
        statusChecking: 'НА ПРОВЕРКЕ',
        statusUnavailable: 'НЕ ПРОЙДЕНО',
        timeCompleted: 'Выполнено',
        timeVerified: 'Проверено',
      },
      tableTitle: 'Модули по статусам',
    },
  },
}
