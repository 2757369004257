import React from 'react'
import style from './ProfileLastAction.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../app/store'
import { lastActions, selectLastActions } from '../../profileSlice'
import InfiniteScroll from 'react-infinite-scroller'
import * as I from 'Types'
import { BackTop, List, Spin, Tooltip } from 'antd'
import { EActionType, EPages } from '../../../../constants'
import moment from 'moment'
import { useHistory } from 'react-router'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  UpCircleOutlined,
} from '@ant-design/icons'
import local from './../../../../localization'

const ProfileLastActionPL: React.FC<I.IProfileProps> = ({
  userId,
}): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { actionsData, isLast, page } = useSelector(selectLastActions)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(!isLast)
  const [pageNumber, setPageNumber] = React.useState(page)

  React.useEffect(() => {
    dispatch(lastActions({ page: pageNumber, size: 10, userId }))
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  React.useEffect(() => {
    setHasMore(!isLast)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsData])

  const loadMore = React.useCallback(() => {
    setHasMore(false)
    setPageNumber(page + 1)
    setIsLoading(true)
  }, [page])

  const getActionStatus = (status: EActionType): string => {
    switch (status) {
      case EActionType.COMPLETED:
        return `${local.profile.lastAction.tasksCompleted}: `
      case EActionType.CONFIRMED:
        return `${local.profile.lastAction.tasksConfirmed}: `
      case EActionType.FAILED:
        return `${local.profile.lastAction.tasksFailed}: `
      case EActionType.REJECTED:
        return `${local.profile.lastAction.tasksRejected}: `
      case EActionType.SENT:
        return `${local.profile.lastAction.tasksSent}: `
      case EActionType.DROPPED_NODE_STATUS:
        return `${local.profile.lastAction.tasksDroppedNodeStatus}: `
      default:
        return `${local.profile.lastAction.unknownStatus}: `
    }
  }

  const getActionAvatar = (status: EActionType): JSX.Element => {
    switch (status) {
      case EActionType.COMPLETED:
      case EActionType.CONFIRMED:
        return (
          <CheckCircleOutlined className={style.done} translate={undefined} />
        )
      case EActionType.FAILED:
      case EActionType.REJECTED:
        return (
          <CloseCircleOutlined className={style.undone} translate={undefined} />
        )
      case EActionType.SENT:
        return (
          <ClockCircleOutlined className={style.sent} translate={undefined} />
        )
      case EActionType.DROPPED_NODE_STATUS:
        return <RedoOutlined className={style.undone} translate={undefined} />
      default:
        return (
          <QuestionCircleOutlined
            className={style.unknown}
            translate={undefined}
          />
        )
    }
  }

  const onActionTask = (node: I.INodeAction | undefined) => () => {
    if (node)
      history.push(
        EPages.AVAIBLE_TRACKS_PASSING.replace(
          ':trackId',
          node.trackId.toString()
        ).replace(':taskId', node.id.toString())
      )
  }

  const getScrollElement = (): HTMLElement =>
    document.getElementById('infinityScroll')!!

  return (
    <div className={style.actionContent}>
      <h3 className={style.actionHead}>
        {local.profile.lastAction.lastActions}
      </h3>
      <div className={style.last_actions} id={'infinityScroll'}>
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          threshold={150}
          useWindow={false}
          scrolling={'top'}
        >
          <List
            dataSource={actionsData}
            renderItem={(item: I.IProfileLastAction) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={getActionAvatar(item.actionType)}
                  title={
                    <div className={style.actionContainer}>
                      <span className={style.actionTitle}>
                        {getActionStatus(item.actionType)}
                      </span>
                      {userId ? (
                        <Tooltip
                          placement='bottom'
                          title={item.node?.title}
                          mouseLeaveDelay={0}
                        >
                          <span>{item.node?.title}</span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          placement='bottom'
                          title={item.node?.title}
                          mouseLeaveDelay={0}
                        >
                          <span
                            onClick={onActionTask(item.node)}
                            className={style.actionNode}
                          >
                            {item.node?.title}
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  }
                  description={moment(item.actionTime).calendar()}
                />
              </List.Item>
            )}
          >
            {isLoading && <Spin />}
          </List>
          <BackTop target={getScrollElement} visibilityHeight={200}>
            <UpCircleOutlined className={style.up} translate={undefined} />
          </BackTop>
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default ProfileLastActionPL
