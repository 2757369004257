import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import enUS from 'antd/es/locale/en_US'
import trTR from 'antd/es/locale/tr_TR'
import moment from 'moment'
import 'moment/locale/ru'
import { store } from './app/store'
import App from './App'
import { Notifier } from './features/notification/components/Notifier'
import './index.css'
import local from './localization'
import { ThemeProvider } from './theme/Theme'
import styles from './features/notification/components/Notifier.module.scss'

import * as serviceWorker from './serviceWorker'

moment.locale(local.main.momentLanguage)

const getAntdLocale = () => {
  const lang = localStorage.getItem('language')
  switch (lang) {
    case 'en':
    case 'en-us':
      return enUS
    case 'tr':
      return trTR
    default:
      return ruRU
  }
}

ReactDOM.render(
  <ThemeProvider>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      disableWindowBlurListener={true}
      preventDuplicate={true}
      classes={{
        variantError: styles.eisSnackBarError,
        variantInfo: styles.eisSnackBarInfo,
        variantSuccess: styles.eisSnackBarSuccess,
        variantWarning: styles.eisSnackBarWarning,
      }}
    >
      <ConfigProvider locale={getAntdLocale()}>
        <Provider store={store}>
          <BrowserRouter>
            <Notifier />
            <App />
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
