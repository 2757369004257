import { from, Observable, of } from 'rxjs'
import { switchMap, retryWhen, delay, take, map } from 'rxjs/operators'
import { IAPIResult } from './IBaseService'
import { EAPIResponseStatus, TMap } from '../constants'
import { authFetch, refreshToken } from './auth'
import _ from 'lodash'
import { IExportTrackResponse } from 'Types'
import local from './../localization'

const defaultHeaders: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': local.main.acceptLanguage,
}
const env = window.REACT_APP_API_URL

const API_ENDPOINT = env

const method: Record<string, () => Promise<Response>> = {}
let resp: Response | null

const mapResponse = (e: Response): Response => {
  if (!resp) {
    if (e.status !== 200) {
      throw e
    }
    return e
  } else {
    const copy = resp.clone()
    resp = null

    if (copy.status !== 200) {
      throw copy
    }

    return copy
  }
}

const errorResponseHandler = (
  errors: Observable<Response>
): Observable<Response> =>
  errors.pipe(
    switchMap(
      (e, count) =>
        new Promise<Response>((resolve, reject) => {
          resp = null

          /**Cancel file download */
          if (
            ((e as unknown) as DOMException).message ===
            'The user aborted a request.'
          ) {
            return
          }

          if (!e || !e.clone) {
            // eslint-disable-next-line no-throw-literal
            throw {
              status: EAPIResponseStatus.ERROR,
              code: 500,
              message: local.notification.general.ERROR.unknownError,
            }
          }

          if (e.status === 200) {
            resolve(e)
            return
          }

          if (e.status !== 551 || count === 2) {
            reject(e)
            return
          } else if (e.status === 551) {
            refreshToken().then(r => {
              if (r && r.code !== 200) {
                reject(r)
              } else {
                const request = method[e.url]
                request().then((response: Response) => {
                  resp = response.clone()
                  resolve(resp)
                })
              }
            })
          }
        })
    ),
    delay(1000),
    take(3)
  )

export class BaseApiService<T> {
  getRequest(url: string, headers?: TMap): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null
        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'GET',
            headers: { ...defaultHeaders, ...headers },
          })
        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }

  async postBackgroundRequest(url: string, headers?: TMap, body?: object) {
    const response = await authFetch(`${API_ENDPOINT}${url}`, {
      method: 'POST',
      headers: { ...defaultHeaders, ...headers },
      body: JSON.stringify(body),
    })
    return response.json()
  }

  postRequest(
    url: string,
    headers?: TMap,
    body?: object
  ): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null
        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'POST',
            headers: { ...defaultHeaders, ...headers },
            body: JSON.stringify(body),
          })
        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }

  postImportFileRequest(
    url: string,
    file: File,
    signal?: AbortSignal,
    params?: Object,
    username?: string
  ): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null
        const formData = new FormData()
        formData.append('file', file)
        params && formData.append('params', JSON.stringify(params))
        username && formData.append('username', username)

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'POST',
            headers: {
              'Accept-Language': local.main.acceptLanguage,
            },
            body: formData,
            signal,
          })
        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }

  postFileRequest(url: string, body: object): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'POST',
            headers: { ...defaultHeaders },
            body: JSON.stringify(body),
          })

        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }

  postExportFileRequest(
    url: string,
    body: object,
    fileName: string
  ): Observable<IAPIResult<Blob>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(body),
          })

        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),
      switchMap<Response, Promise<Blob>>(e => {
        if (e.status === 200) {
          return e.blob()
        } else {
          throw e
        }
      }),
      switchMap((result: Blob) => {
        if (!!result.size) {
          const blob = new Blob([result], {
            type: result.type,
          })

          const uri = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = uri
          a.setAttribute('download', fileName)
          a.click()
          a.href = ''
          return of({
            status: EAPIResponseStatus.SUCCESS,
            data: blob as Blob,
          })
        } else {
          throw result
        }
      })
    )
  }

  getExportFileRequest(url: string): Observable<IAPIResult<Blob>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'GET',
            headers: defaultHeaders,
          })

        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),
      switchMap<Response, Promise<IAPIResult<IExportTrackResponse>>>(e => {
        return e.json()
      }),
      switchMap((result: IAPIResult<IExportTrackResponse>) => {
        if (result.status === EAPIResponseStatus.SUCCESS && result.data) {
          const byteCharacters = atob(result.data.fileBase64String)

          const byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }

          const byteArray = new Uint8Array(byteNumbers)

          const blob = new Blob([byteArray], {
            type: 'application/xml',
          })

          const uri = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = uri
          a.setAttribute('download', result.data.fileName)
          a.click()
          a.href = ''
          return of({
            status: EAPIResponseStatus.SUCCESS,
            data: blob as Blob,
          })
        } else {
          throw result
        }
      })
    )
  }

  // getExportFileRequest(url: string): any // EIS-2663
  // Observable<IAPIResult<Blob>>
  // {
  // return from([0]).pipe(
  //   switchMap(() => {
  //     resp = null

  //     method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
  //       authFetch(`${API_ENDPOINT}${url}`, {
  //         method: 'POST',
  //         headers: defaultHeaders,
  //       })

  //     return from(method[`${API_ENDPOINT}${url}`]())
  //       .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
  //       .toPromise()
  //   }),
  // switchMap<Response, Promise<IAPIResult<IExportTrackResponse>>>(
  //   async e => {
  //     function streamAsyncIterator(stream: any) {
  //       const reader = stream.getReader()
  //       return {
  //         next() {
  //           return reader.read()
  //         },
  //         return() {
  //           reader.releaseLock()
  //           return {}
  //         },
  //         [Symbol.asyncIterator]() {
  //           return this
  //         },
  //       }
  //     }
  //     for await (const chunk of streamAsyncIterator(e.body)) {
  //       const status = e.status === 200 ? EAPIResponseStatus.SUCCESS : null
  //       return { status, data: chunk }
  //     }
  //   }
  // ),
  // switchMap((result: IAPIResult<IExportTrackResponse>) => {
  //   if (result.status === EAPIResponseStatus.SUCCESS && result.data) {
  //     const byteCharacters = atob(result.data) //atob - из base64 в строку

  //     const byteNumbers = new Array(byteCharacters.length) //эта часть для того, чтобы у архива не менялся размер между скачиваниями
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i)
  //     }

  //     const byteArray = new Uint8Array(byteNumbers) //Данные с бэка сразу приходят в Uint8Array

  //     const blob = new Blob([result.data], {
  //       type: 'application/zip',
  //     })

  //     const uri = window.URL.createObjectURL(blob)
  //     const a = document.createElement('a')
  //     a.href = uri
  //     a.setAttribute('download', result.data.fileName)
  //     a.click()
  //     a.href = ''
  //     return of({
  //       status: EAPIResponseStatus.SUCCESS,
  //       data: blob as Blob,
  //     })
  //   } else {
  //     throw result
  //   }
  // })
  // )
  // }

  putRequest(
    url: string,
    headers: TMap,
    body: object
  ): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'PUT',
            headers: { ...defaultHeaders, ...headers },
            body: JSON.stringify(body),
            keepalive: true, // EIS-2459: Чтобы запрос уходил после onbeforeunload
          })

        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }

  deleteRequest(
    url: string,
    headers?: TMap,
    body?: object | string
  ): Observable<IAPIResult<T>> {
    return from([0]).pipe(
      switchMap(() => {
        resp = null

        method[`${API_ENDPOINT}${url}`] = (): Promise<Response> =>
          authFetch(`${API_ENDPOINT}${url}`, {
            method: 'DELETE',
            headers: { ...defaultHeaders, ...headers },
            body: JSON.stringify(body),
          })

        return from(method[`${API_ENDPOINT}${url}`]())
          .pipe(map(mapResponse), retryWhen<Response>(errorResponseHandler))
          .toPromise()
      }),

      switchMap<Response, Promise<IAPIResult<T>>>(e => {
        return e.json()
      })
    )
  }
}

export const getParams = (x: Record<string, unknown>) => {
  const arr = Object.keys(x).filter(k => x[k] !== undefined)
  return arr.length > 0
    ? `?${_.join(
        arr.map(k => `${k}=${x[k]}`),
        '&'
      )}`
    : ''
}
