import React, { useCallback } from 'react'
import { EISModal } from '../../../EISComponents'
import style from './ViewModal.module.scss'
import EISCheckbox from '../../../EISComponents/EISCheckbox/EISCheckbox'
import { IViewModal } from './IViewModal'

const ViewModal: React.FC<IViewModal> = ({
  columns,
  type,
  mainColumns,
  modalState,
  handleChangeModalState,
  selectedViewColumns,
  handleChangeViewColumns,
}): JSX.Element => {
  const changeViewTableColumns = useCallback(
    (key: string, value: boolean) => {
      if (value) {
        const newColumns = [
          ...selectedViewColumns!,
          columns.find(c => (c.key as string) === key)?.key! as string,
        ]
        handleChangeViewColumns(newColumns)
        localStorage.setItem(type, newColumns.join(','))
      } else {
        const newColumns = selectedViewColumns!.filter(c => c !== key)
        handleChangeViewColumns(newColumns)
        localStorage.setItem(type, newColumns.join(','))
      }
    },
    [selectedViewColumns, columns, type, handleChangeViewColumns]
  )

  return (
    <EISModal
      open={modalState.modalStatus}
      width={400}
      onCancel={() =>
        handleChangeModalState({
          modalStatus: false,
          isModalExport: false,
          title: '',
        })
      }
      maskClosable
      footer={<></>}
    >
      <div className={style.modalContainer}>
        <p className={style.modalTitle}>{modalState.title}</p>

        <div className={style.checkboxGroup}>
          {columns.map(
            c =>
              !!c.title && (
                <EISCheckbox
                  title={c.title as string}
                  key={c.key}
                  callback={(e: any) => {
                    changeViewTableColumns(c.key as string, e.target.checked)
                  }}
                  checked={selectedViewColumns!.includes(c.key as string)}
                  includeCheckedMark={false}
                  disabled={mainColumns.includes(c.key as string)}
                />
              )
          )}
        </div>
      </div>
    </EISModal>
  )
}

export default ViewModal
