import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as I from 'Types'
import ProfileService from '../../services/profile/profile.service'
import { processError } from '../../app/processError'
import { enqueueSnackbar } from '../notification/notificationSlice'
import { ENotificationVariant, IKeyValuePair } from '../../constants'
import _ from 'lodash'
import { stackRequestWrapper } from '../../app/requestWrapper'
import { updateHeaderAvatar } from '../../layouts/Header/HeaderSlice'
import local from './../../localization'
import { SorterResult } from 'antd/lib/table/interface'
import { TArrayColumnType } from '../../components/ViewTable/IViewTable'

const initialState: I.IProfileState = {
  profile: {
    fullName: local.profile.info.defaultFullName,
    position: '',
    items: [],
    progress: 0,
    tasksCompleted: 0,
  },
  stat: {
    total: 0,
    left: 0,
    completed: 0,
    checking: 0,
    percentage: 0,
    tasks: [],
    tasksPage: 0,
    tasksTotal: 0,
    tasksSortedInfo: {},
    track: undefined,
    rangeDate: undefined,
  },
  lastActions: {
    actionsData: [],
    isLast: true,
    page: 0,
  },
}

export const changeAvatar = createAsyncThunk(
  'profile/avatar',
  async (body: I.IChangeAvatarRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileService.changeAvatar(body)
      )

      const profile = (await dispatch(profiles({}))) as any

      dispatch(updateHeaderAvatar(profile?.payload?.data.avatarUrl))

      dispatch(
        enqueueSnackbar({
          message: local.notification.verification.SUCCESS.avatarChange,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: ENotificationVariant.SUCCESS,
          },
        })
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const profiles = createAsyncThunk(
  'profile/info',
  async (data: I.IProfileProps, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileService.getProfileInfo(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const profileStatTracks = createAsyncThunk(
  'profile/stat/tracks',
  async (data: I.IGetProfileStatRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileService.getProfileStatTracks(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const profileStatTasks = createAsyncThunk(
  'profile/stat/tasks',
  async (data: I.IGetProfileStatRequest, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        ProfileService.getProfileStatTasks(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const lastActions = createAsyncThunk(
  'profile/actions',
  async (
    data: I.IGetProfileLastActionsRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        ProfileService.getProfileLastActions(data)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfileState: state => {
      _.assign(state, initialState)
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.stat.tasksPage = action.payload
    },
    changeSorted: (
      state,
      action: PayloadAction<SorterResult<TArrayColumnType>>
    ) => {
      state.stat.tasksSortedInfo = action.payload
    },
    setTrackFilter: (
      state,
      action: PayloadAction<IKeyValuePair | undefined>
    ) => {
      state.stat.track = action.payload
    },
    setRangeDate: (state, prop: PayloadAction<string | undefined>) => {
      state.stat.rangeDate = prop.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(profiles.fulfilled, (state, action) => {
      if (action.payload?.data) {
        _.assign(state.profile, action.payload.data)
      }
    })
    builder.addCase(lastActions.fulfilled, (state, action) => {
      if (action.payload?.data) {
        _.assign(state.lastActions, {
          page: action.payload.data.pageable.pageNumber,
          isLast: action.payload.data.last,
          actionsData: _.uniqBy(
            [...state.lastActions.actionsData, ...action.payload.data.content],
            a => a.id
          ),
        })
      }
    })
    builder.addCase(profileStatTracks.fulfilled, (state, action) => {
      if (action.payload.data) {
        _.assign(state.stat, action.payload.data)
      }
    })
    builder.addCase(profileStatTasks.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.stat.tasks = action.payload.data.content
        state.stat.tasksTotal = action.payload.data.totalElements
      }
    })
  },
})

export const { reducer: profileReducer } = profileSlice

export const selectProfile = (state: I.RootState) => state.profile.profile

export const selectLastActions = (state: I.RootState) =>
  state.profile.lastActions

export const selectStatTracks = (state: I.RootState) => state.profile.stat

export const selectTasksStatus = (
  state: I.RootState
): Array<I.ITasksStatusColumn> =>
  state.profile.stat.tasks.map(t => ({
    id: Number(`${t.node.trackId}${t.node.id}`),
    title: t.node.title,
    status: t.status,
    timeCompleted: t.timeCompleted,
    timeVerified: t.timeVerified,
  }))

export const {
  clearProfileState,
  changePage,
  changeSorted,
  setTrackFilter,
  setRangeDate,
} = profileSlice.actions
