export const users = {
  columns: {
    fullName: 'Пользователь',
    position: 'Должность',
    items: 'Треки',
    phoneNumber: 'Номер телефона',
    email: 'Email',
    progress: 'Прогресс',
    createdTime: 'Дата регистрации',
    lastAuthTime: 'Последний вход',
    activePeriod: 'Последняя активность более :Period дня(-ей) назад',
    action: {
      title: 'Действие',
      editButton: 'Изменить',
      deleteButton: 'Удалить',
    },
  },
  deleteUserModal: {
    title: 'Удаление пользователя',
    content:
      'Вы действительно хотите удалить пользователя? Весь его прогресс выполнения заданий будет удален из системы.',
    okText: 'Да',
    cancelText: 'Нет',
  },
  emptyData: {
    noData: 'Нет добавленных пользователей',
    noFilterUsers: 'Нет данных, соответствующих выбранным фильтрам',
  },
  filter: {
    user: 'Введите ФИО пользователя',
    role: {
      title: 'Роль',
      user: 'Пользователь',
      mentor: 'Ментор',
      manager: 'Управляющий',
      admin: 'Администратор',
      placeholder: 'Все',
    },
    isActive: 'Активные',
    status: 'Статус',
  },
  addButton: 'Добавить пользователя',
  notification: {
    notConfirmed: 'Пользователь не подтвердил учетную запись',
    isBlocked: 'Пользователь заблокирован',
    isAdmin: 'Пользователь является админом',
    isManager: 'Пользователь является управляющим трека',
    isMentor: 'Пользователь является ментором',
  },
  addAndEditModal: {
    optionsStatus: {
      active: 'Активный',
      disable: 'Заблокированный',
    },
    title: {
      add: 'Добавить пользователя',
      edit: 'Редактировать пользователя',
    },
    cancelButton: 'Отменить',
    saveButton: 'Сохранить',
    formItem: {
      fullName: {
        label: 'ФИО',
        placeholder: 'Введите ФИО',
        required: 'Обязательное поле!',
        length: 'Не менее 3-х символов',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Введите e-mail',
        required: 'Обязательное поле!',
        valid: 'Некорректный email!',
      },
      phoneNumber: {
        label: 'Номер телефона',
        placeholder: 'Введите номер телефона',
        searchPlaceholder: 'Поиск',
        required: 'Обязательное поле!',
        valid: 'Некорректный номер телефона!',
      },
      status: {
        label: 'Статус',
        placeholder: 'Активный / Неактивный',
        required: 'Обязательное поле!',
      },
      role: {
        filterInputLabel: 'Роль',
        label: 'Роль',
        placeholder: 'Выберите роль',
        required: 'Обязательное поле!',
      },
      position: {
        filterInputLabel: 'Должность',
        label: 'Должность',
        placeholder: 'Выберите должность',
      },
      items: {
        filterInputLabel: 'Трек',
        label: 'Треки',
        placeholder: 'Выберите треки',
      },
    },
    deletePositionModal: {
      title: 'Удаление должности',
      content:
        'Вы действительно хотите открепить должность от пользователя? Все треки, которые были назначены пользователю по его должности, будут сохранены за пользователем и прогресс прохождения будет сохранен.',
      okText: 'Да',
      cancelText: 'Нет',
    },
    deleteItemModal: {
      title: 'Удаление трека',
      content:
        'Вы действительно хотите открепить трек от пользователя? Весь его прогресс выполнения заданий по данному треку будет удален из системы.',
      okText: 'Да',
      cancelText: 'Нет',
    },
    disableUserModal: {
      title: 'Блокировка пользователя',
      content:
        'Заблокированный пользователь не сможет войти в систему, но прогресс прохождения треков будет сохранен. С пользователя будут сняты права "Управляющего", "Ментора" или "Администратора" в назначенных ранее на него треках. Если пользователя понадобится снова сделать Активным, его нужно будет заново назначать ментором, управляющим или администратором во всех необходимых треках.',
      okText: 'Да',
      cancelText: 'Нет',
    },
    changeEmailModal: {
      title: 'Изменение адреса электронной почты пользователя',
      okText: 'Подтвердить изменение',
      description:
        'Изменив адрес электронной почты пользователя, он больше не сможет авторизоваться в системе по старому адресу.\n После изменения пользователю будут отправлены два письма об изменении на его старый и новый адреса электронной почты.',
      label: 'Email',
      placeholder: 'Введите новый Email',
      required: 'Обязательное поле!',
      valid: 'Некорректный email!',
    },
  },
  exportFileName: 'Пользователи',
}
