export const tracksManagement = {
  filter: {
    itemSearch: 'Find track',
  },
  importButton: 'Import',
  emptyData: {
    noManage: 'No added tracks',
    noData: 'No data matching the selected filters',
  },
  trackCardModal: {
    okText: 'Save',
    cancelText: 'Cancel',
    description: 'Enter track description',
  },
  notification: {
    cardEditNotification: 'Track description changed',
    importNotification: 'Import completed',
  },
  tabs: {
    view: 'View:',
    grid: 'Tile',
    table: 'Table',
  },
  rootAreaTitle: 'General',
  selectFolder: 'Select a folder',
  addFolder: 'Add Folder',
  cardView: {
    header: 'Directory',
    actionBtn: {
      showHide: 'Show/Hide',
      edit: 'Edit',
      add: 'Add',
      delete: 'Delete',
    },
  },
  deleteAreaModalTitle: 'folder',
  addAndEditAreaModal: {
    titleEdit: 'Configuring a folder',
    titleAdd: 'Adding a folder',
    forma: {
      groupName: {
        label: 'Folder name:',
        placeholder: 'Enter a folder name',
        message: 'Required field',
      },
      attachedTo: {
        label: 'Path:',
        placeholder: 'Select a folder',
      },
      tracks: {
        label: 'Tracks:',
        placeholder: 'Select tracks',
      },
    },
  },
  viewStatistics: 'View statistics',
  cardSizeTooltip: 'The size of the track card',
}
