export const selfie = {
  profilePicture: 'Фото профиля',
  actionBtn: {
    uploadImage: 'Загрузить изображение',
    cancel: 'Отмена',
  },
  imageCaptuer: {
    turnOnWebcam: 'Включить вебкамеру',
  },
  camera: {
    tooltip: {
      cameraSwitch: 'Переключить камеру',
      takePicture: 'Сделать снимок',
      takeNewPicture: 'Сделать новый снимок',
      saveImg: 'Сохранить изображение',
    },
  },
}
