export const tracksManagement = {
  filter: {
    itemSearch: 'Find map',
  },
  importButton: 'Import',
  emptyData: {
    noManage: 'No added maps',
    noData: 'No data matching the selected filters',
  },
  trackCardModal: {
    okText: 'Save',
    cancelText: 'Cancel',
    description: 'Enter map description',
  },
  notification: {
    cardEditNotification: 'Map description changed',
    importNotification: 'Import completed',
  },
  tabs: {
    view: 'View:',
    grid: 'Tile',
    table: 'Table',
  },
  rootAreaTitle: 'General',
  selectFolder: 'Select a folder',
  addFolder: 'Add Folder',
  cardView: {
    header: 'Directory',
    actionBtn: {
      showHide: 'Show/Hide',
      edit: 'Edit',
      add: 'Add',
      delete: 'Delete',
    },
  },
  deleteAreaModalTitle: 'folder',
  addAndEditAreaModal: {
    titleEdit: 'Configuring a folder',
    titleAdd: 'Adding a folder',
    forma: {
      groupName: {
        label: 'Folder name:',
        placeholder: 'Enter a folder name',
        message: 'Required field',
      },
      attachedTo: {
        label: 'Path:',
        placeholder: 'Select a folder',
      },
      tracks: {
        label: 'Maps:',
        placeholder: 'Select maps',
      },
    },
  },
  viewStatistics: 'View statistics',
  cardSizeTooltip: 'The size of the Map Card',
}
