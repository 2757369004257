export enum EValidationMessage {
  REQUIRED_FIELD = 'Обязательное поле',
  OPTION_NOT_SELECTED = 'Значение не выбрано',
  OPTIONS_EMPTY = 'Отсутствует список допустимых значений',
  UNKNOW_OPTION = 'Выбранное значение отсутствует в списке доступных значений',
  ONLY_POSITIVE = 'Только целые числа',
  INCORRECT_PHONE = 'Номер введён не полностью',
  ONLY_BOOLEAN = 'Только логическое значение',
  INCORRECT_DATE = 'Неверная дата',
  ONLY_DECIMAL_OR_INTEGER = 'Только целые или дробные числа',
  UNKNOW_COLUMN = 'Неизвестная колонка',
  NONE = '',
}
export enum EStatusUser {
  ACTIVE = 'Активный',
  DISABLE = 'Заблокированный',
}
export enum EStatusItem {
  ACTIVE = 'Активный',
  DISABLE = 'Неактивный',
}
export enum EStatusPosition {
  ACTIVE = 'Активный',
  DISABLE = 'Неактивный',
}
export enum ENodeStatus {
  COMPLETED = 'Выполненные',
  REJECTED = 'Отклоненные',
  CHECKING = 'На проверке',
  NOT_COMPLETED = 'Не пройденные',
  ALL = 'Все',
}
export enum EStatTabs {
  STAT = 'Статистика',
  TABLE = 'Таблица',
  MAP = 'Карта прогресса',
}
export enum ESelectList {
  YES = 'Да',
  NO = 'Нет',
  ALL = 'Все',
}
export enum ESelectOnlineStatusList {
  ONLINE = 'Онлайн',
  OFFLINE = 'Оффлайн',
  NO_STATUS = 'Без статуса',
  ALL = 'Все',
}