import process_ru from '../../assets/png/help_process_ru.jpg'
import management_ru from '../../assets/png/help_management_ru.jpg'
import status_ru from '../../assets/png/help_status_ru.jpg'

export const help = {
  welcome: 'Добро пожаловать в :System!',
  purpose:
    'Основная идея :System - интегрировать сотрудников в рабочие процессы, должности, обучать новому, накапливать и хранить полезные знания в компании, при этом важная особенность - они это делают сами.',
  canDo: {
    title: 'Что могут пользователи:',
    features: [
      'строить треки',
      'проходить обучение в режимах «с» и «без» ментора',
      'получать обратную связь по прохождению',
      'использовать статистику для анализа треков, прохождения, успеваемости сотрудников',
      'получать информацию о лучших практиках и опыте коллег',
      'при желании смены деятельности - погрузиться в другую должность, треков много',
      'поддержка и обновление знаний (если что-то изменилось в компании)',
      'убедиться, что сотрудник на 100% готов к новой должности и подтвердил свои знания, пройдя все задания трека',
      'управлять правами доступов пользователей',
    ],
    solution:
      'Комбинируя различные функции и организационные правила, можно решать разнообразные кейсы компании.',
  },
  problem: {
    title: 'Как :System решает основную задачу?',
    solution:
      'Назначаем управляющего на трек компании (по сути, трек и является областью знания, функцией, рабочим процессом, должностью и т.д.) и дальше по схеме:',
  },
  fastNote: 'Быстрая справка по созданию треков управляющему',
  passing: 'Как проходить назначенный на меня трек?',
  wishes: {
    title: 'Заметили ошибку, есть пожелания? ',
    btn: 'Напишите',
    titleEnd: ' нам!',
  },
  imgSrc: {
    process: process_ru,
    management: management_ru,
    status: status_ru,
  },
  imgSVG: {
    problem: {
      manager: 'Управляющий',
      createTrack: 'Создает трек',
      track: 'Трек',
      trackWasAssigned: 'Трек назначен на пользователя для прохождения',
      employee: 'Сотрудник',
      runsTheTrack: 'Выполняет задания к модулям ',
      mentor: 'Ментор',
      checkCompletedTask: 'Проверяет выполненные задания',
      readyToWork: '100% готов к работе ',
    },
    fastNote: {
      controlPanel: 'Инструменты',
      hotkeys: {
        title: 'Горячие клавиши:',
        altQ: 'Alt + Q :split Добавить новый модуль',
        altW: 'Alt + W :split Создать связь от модуля к модулю',
        altE: 'Alt + E :split Редактировать название модуля',
        altS: 'Alt + S :split Сохранить изменения',
        altC: 'Alt + C :split Копировать модуль',
      },
      textList: {
        changeModule: 'Меняйте названия модулей',
        addModule: 'Добавляйте новые модули знаний',
        createConnection: 'Создавайте связи между модулями',
        deleteModule: 'Удаляйте неактуальные модули знаний',
        saveChanges: 'Сохраняйте изменения',
        doubleClickModule:
          'Двойной клик по модулю или клик по этой кнопке направит к описанию модуля и заданиям',
        copyModule: 'Копируйте модуль, чтоб сэкономить время',
        indicatorsWillShow:
          'Индикаторы подскажут: может ли ли пользователь перейти к следующему модулю без ожидания проверки добавлено ли описание к модулю какие виды проверок установлены: тест, развернутый ответ, видео-ответ',
      },
    },
    passing: {
      completeTask: {
        title: 'Для выполнения задания:',
        read: 'Прочитайте и изучите описание модуля',
        confirm: 'Подтвердите, что вы готовы к проверке знаний',
        complete: 'Выполните задания',
        send: 'Отправьте их на проверку',
      },
      start: 'Старт',
      task: 'Задание',
      moduleCompleted: `Модуль выполнен, знания получены. Теперь информация из модуля всегда доступна, и в любое время можно “освежить” знания.`,
      moduleUnderReview: 'Модуль на проверке у ментора. Ожидайте результатов',
      youCantStart:
        'Вы не можете приступить к изучению данного модуля, потому что  ответы к заданиям из предыдущего модуля не отмечены как уже проверенные и одобренные ментором',
      moduleAvailable:
        'Модуль доступен, вы начали выполнять задания, но еще не отправили ответы на проверку',
      moduleTasksHaveBeenReviewed:
        'Задания к модулю были проверены, но результат не засчитан. Требуется выполнить задания повторно.',
    },
  },
  feedBackModal: {
    titleModal: 'Обратная связь',
    subjectMessage: 'Тема письма',
    textAreaPlaceholder: 'Текст письма',
    messageType: {
      bug: 'Ошибка',
      question: 'Вопрос',
      proposal: 'Предложение',
      other: 'Другое',
    },
    uploadTitle: 'Прикрепить фото или видео',
    footNote:
      'Eсли хотите прикрепить видео и/или фото, добавьте в текст ссылкой',
    actionBtnSend: 'Отправить',
    actionBtnCancle: 'Отмена',
    thanksMessage: 'Спасибо за обратную связь',
  },
}
