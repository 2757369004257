export const profile = {
  info: {
    defaultFullName: 'Misafir',
    position: 'Konum',
    items: 'Rotalar',
    availableItems: 'Uygun rotalar',
    progress: 'Genel ilerleme',
    tasksCompleted: 'Tamamlanan modüller',
  },
  lastAction: {
    tasksCompleted: 'Modül başarıyla tamamlandı',
    tasksConfirmed: 'Modülün tamamlandığı onaylandı',
    tasksFailed: 'Modülü tamamlamak için başarısız girişim',
    tasksRejected: 'Modül tamamlama reddedildi',
    tasksSent: 'Modül kontrol için gönderilmiştir',
    tasksDroppedNodeStatus: 'Modül ilerlemesi sıfırlandı',
    unknownStatus: 'Bilinmeyen modül durumu',
    lastActions: 'Son eylemler',
  },
  stat: {
    title: 'İlerleme istatistiklerini gör',
    selectItem: 'Rota seçin',
    itemLabel: 'Rota',
    tasks: {
      tasksProgress: 'İlerleme',
      progressTooltip:
        'Pasta grafik, tamamlanan modüller ve tamamlanmamış modüller olmak üzere iki sektöre ayrılmıştır. Tamamlanan modülleri temsil eden sektörün büyüklüğü, tamamlanan işin yüzdesine karşılık gelir. Tamamlanmamış modülleri temsil eden sektörün büyüklüğü ise tamamlanmamış işin yüzdesine karşılık gelir. Pasta grafik, tüm zaman dilimi boyunca ilerlemeyi gösterir.',
      completedProgress: 'Tamamlandı',
      failedProgress: 'Başarısız oldu',
      tasksTotal: 'Toplam modüller',
      tasksCompleted: 'Tamamlandı',
      tasksLeft: 'Kalan modüller',
      tasksChecked: 'Kontrol için bekleniyor',
      columnTasksTable: {
        name: 'Başlık',
        status: 'Durum',
        statusRejected: 'REVİZYON',
        statusCompleted: 'TAMAMLANDI',
        statusChecking: 'GÖZDEN GEÇİRİLİYOR',
        statusUnavailable: 'TAMAMLANMADI',
        timeCompleted: 'Tamamlandı',
        timeVerified: 'İncelendi',
      },
      tableTitle: 'Duruma göre modüller',
    },
  },
}
