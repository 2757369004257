import { Observable, from } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { IAPIResult } from '../../IBaseService'
import { EAPIResponseStatus, TMap } from '../../../constants'
import { generateFingerprint } from '../../../utils'
import * as I from './IAuthService'
import { getParams } from '../../base.service'
import { EAPIEndpoint } from '../../constants'
import local from './../../../localization'

const headers: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': local.main.acceptLanguage,
}

const API_ENDPOINT = window.REACT_APP_API_URL

export class TokenService {
  refreshToken(
    refreshTokenRequest: I.RefreshTokenRequest
  ): Observable<IAPIResult<I.AuthResponse>> {
    return from(
      fetch(`${API_ENDPOINT}${EAPIEndpoint.REFRESH_TOKEN}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(refreshTokenRequest),
      })
    ).pipe(switchMap(response => response.json()))
  }

  getAuthorities(token: string): Observable<IAPIResult<I.AuthoritiesResponse>> {
    return from(
      fetch(`${API_ENDPOINT}${EAPIEndpoint.GET_AUTHORITIES}`, {
        method: 'GET',
        headers: { ...headers, Authorization: `Bearer ${token}` },
      })
    ).pipe<IAPIResult<I.AuthoritiesResponse>>(
      switchMap(response => response.json())
    )
  }
}

export default class AuthService {
  private authFetch: (
    input: RequestInfo,
    init?: RequestInit
  ) => Promise<Response>
  private setLogin: (newTokens: I.AuthResponse) => Promise<IAPIResult<void>>
  private setLogOut: () => void

  constructor(
    authFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
    setLogin: (newTokens: I.AuthResponse) => Promise<IAPIResult<void>>,
    setLogOut: () => void
  ) {
    this.authFetch = authFetch
    this.setLogin = setLogin
    this.setLogOut = setLogOut
  }

  login(
    username: string,
    password: string
  ): Observable<IAPIResult<I.AuthResponse>> {
    return from(generateFingerprint()).pipe(
      switchMap(fingerprint =>
        from(
          fetch(
            `${API_ENDPOINT}${EAPIEndpoint.LOGIN}${getParams({ fingerprint })}`,
            {
              method: 'POST',
              headers,
              body: JSON.stringify({ username, password }),
            }
          )
        ).pipe<IAPIResult<I.AuthResponse>, IAPIResult<I.AuthResponse>>(
          switchMap(response => response.json()),
          switchMap(
            (result: IAPIResult<I.AuthResponse>) =>
              new Promise<IAPIResult<I.AuthResponse>>((resolve, reject) => {
                if (result.status === EAPIResponseStatus.SUCCESS) {
                  if (result && result.data) {
                    from(this.setLogin(result.data)).subscribe(() =>
                      resolve(result)
                    )
                  } else {
                    reject({
                      status: EAPIResponseStatus.FAIL,
                      message: result.message,
                    })
                  }
                } else {
                  reject(result)
                }
              })
          )
        )
      )
    )
  }

  confirmed(encoded: string): Observable<IAPIResult<void>> {
    return from(
      fetch(
        `${API_ENDPOINT}${EAPIEndpoint.CONFIRMED_EMAIL}${getParams({
          encoded,
        })}`,
        {
          method: 'POST',
          headers,
        }
      )
    ).pipe<IAPIResult<void>, IAPIResult<void>>(
      switchMap(response => response.json()),
      switchMap(
        result =>
          new Promise<IAPIResult<void>>((resolve, reject) => {
            if (result.status === EAPIResponseStatus.SUCCESS) {
              resolve(result)
            } else {
              reject(result)
            }
          })
      )
    )
  }

  logout(): Observable<IAPIResult<void>> {
    return from(generateFingerprint()).pipe(
      switchMap(fingerprint =>
        from(
          this.authFetch(
            `${API_ENDPOINT}${EAPIEndpoint.LOGOUT}${getParams({
              fingerprint: fingerprint,
            })}`,
            {
              method: 'POST',
              headers,
            }
          )
        ).pipe(
          tap(() => this.setLogOut()),
          switchMap(response => response.json()),
          switchMap(
            result =>
              new Promise<IAPIResult<void>>((resolve, reject) => {
                if (result.status === EAPIResponseStatus.SUCCESS) {
                  resolve(result)
                } else {
                  reject(result)
                }
              })
          )
        )
      )
    )
  }

  register(
    fullName: string,
    username: string,
    phoneNumber: string,
    password: string
  ): Observable<IAPIResult<void>> {
    return from(
      fetch(`${API_ENDPOINT}${EAPIEndpoint.REGISTER}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ fullName, username, phoneNumber, password }),
      })
    ).pipe<IAPIResult<void>, IAPIResult<void>>(
      switchMap(response => response.json()),
      switchMap(
        result =>
          new Promise<IAPIResult<void>>((resolve, reject) => {
            if (result.status === EAPIResponseStatus.SUCCESS) {
              resolve(result)
            } else {
              reject(result)
            }
          })
      )
    )
  }

  resetPassword(username: string): Observable<IAPIResult<void>> {
    return from(
      fetch(`${API_ENDPOINT}${EAPIEndpoint.RESET_PASSWORD}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username }),
      })
    ).pipe<IAPIResult<void>, IAPIResult<void>>(
      switchMap(response => response.json()),
      switchMap(
        result =>
          new Promise<IAPIResult<void>>((resolve, reject) => {
            if (result.status === EAPIResponseStatus.SUCCESS) {
              resolve(result)
            } else {
              reject(result)
            }
          })
      )
    )
  }

  confirmedPassword(
    body: I.ConfirmedPasswordRequest
  ): Observable<IAPIResult<void>> {
    return from(
      fetch(`${API_ENDPOINT}${EAPIEndpoint.CONFIRMED_PASSWORD}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      })
    ).pipe<IAPIResult<void>, IAPIResult<void>>(
      switchMap(response => response.json()),
      switchMap(
        result =>
          new Promise<IAPIResult<void>>((resolve, reject) => {
            if (result.status === EAPIResponseStatus.SUCCESS) {
              resolve(result)
            } else {
              reject(result)
            }
          })
      )
    )
  }

  getError(
    code: number,
    message: string = local.notification.general.ERROR.accidentalError
  ): Observable<IAPIResult<void>> {
    return from(
      fetch(
        `${API_ENDPOINT}${EAPIEndpoint.ERROR}${getParams({ code, message })}`,
        {
          method: 'GET',
          headers,
        }
      )
    ).pipe<IAPIResult<void>, IAPIResult<void>>(
      switchMap(response => response.json()),
      switchMap(
        result =>
          new Promise<IAPIResult<void>>((resolve, reject) => {
            if (result.status === EAPIResponseStatus.SUCCESS) {
              resolve(result)
            } else {
              reject(result)
            }
          })
      )
    )
  }
}
