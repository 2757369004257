export const tasksChecking = {
  filter: {
    optionsSelect: {
      yes: 'Yes',
      no: 'No',
      all: 'All',
    },
    users: 'Select user(s)',
    item: 'Select track(s)',
    task: 'Select task(s)',
    tests: 'Tests',
    checked: 'Checked',
    showAll: 'Show all',
    showAllInfo: `
    If the setting is inactive, only completed tasks for the tracks where you are a mentor will be displayed in the table.
    If the setting is active, the table will display tasks where other mentors are involved as well`,
  },
  emptyData: {
    noData: 'No tasks for checking',
    noFilterData: 'No data matching the selected filters',
  },
  columns: {
    user: 'User',
    track: 'Track',
    node: 'Task',
    timeCompleted: 'Completion date',
    timeVerified: 'Review date',
    status: {
      title: 'Result',
      confirmed: 'Accepted',
      rejected: 'Rejected',
      other: 'Not checked',
    },
    actions: {
      title: 'Action',
      actionButton: 'Check',
      actionButtonView: 'View answer',
    },
  },
}
