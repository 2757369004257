import { Observable } from 'rxjs'
import { IAPIResult } from '../../IBaseService'
import { BaseApiService } from '../../base.service'
import { IAttemptSolution } from '../../../components/TaskViewerAndReviewer/ITaskViewerAndReviewer'
import { EAPIEndpoint } from '../../constants'

export default class TaskViewService {
  static getSolution(nodeId: number): Observable<IAPIResult<IAttemptSolution>> {
    const baseApiService = new BaseApiService<IAttemptSolution>()
    return baseApiService.getRequest(
      EAPIEndpoint.SOLUTION_ATTEMPT.replace(':nodeId', nodeId.toString())
    )
  }
}
