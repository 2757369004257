export const positions = {
  columns: {
    title: 'Title',
    items: 'Default Tracks',
    isActive: {
      title: 'Active',
      true: 'Yes',
      false: 'No',
    },
    createdTime: 'Сreation date',
    action: {
      title: 'Action',
      editButton: 'Edit',
      deleteButton: 'Delete',
    },
  },
  positionDeleteModal: {
    title: 'Delete a position',
    content:
      'Do you really want to delete the post? All users will have this assigned position detached. The tracks attached to the position will be saved by the users who have been assigned this position.',

    okText: 'Yes',
    cancelText: 'No',
  },
  emptyData: {
    noData: 'No positions added',
    noFilterPositions: 'No data matching the selected filters',
  },
  filter: {
    position: 'Enter the Position',
    isActive: 'Active',
  },
  addPositionButton: 'Add Position',
  addAndEditModal: {
    optionsStatus: {
      active: 'Active',
      disable: 'Inactive',
    },
    title: {
      add: 'Add position',
      edit: 'Edit position',
    },
    cancelText: 'Cancel',
    okText: 'Save',
    formItem: {
      title: {
        label: 'Position title',
        placeholder: 'Enter position title',
      },
      status: {
        label: 'Position status',
        placeholder: 'Active / Inactive',
      },
      items: {
        filterInputLabel: 'Track',
        label: 'Attached tracks',
        placeholder: 'Select track',
      },
      users: {
        filterInputLabel: 'User',
        label: 'Users',
        placeholder: 'Select user',
      },
    },
    deleteItemModal: {
      title: 'Delete tracks',
      content:
        'Are you sure you want to disconnect the track from the position? The track will remain with the users who have been appointed to this position.',

      okText: 'Yes',
      cancelText: 'No',
    },
    disablePositionModal: {
      title: 'Position locking',
      content:
        'Are you sure you want to make the position inactive? It will be dissociated from all the users who have this position now. The tracks assigned to the position will remain with the users who had this position.',

      okText: 'Yes',
      cancelText: 'No',
    },
  },
  notification: {
    isNotActive: 'Inactive position',
  },
}
