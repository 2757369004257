export const registration = {
  agreementPersonDataTitle: 'Consent to personal data processing',
  agreementUserTitle: 'User agreement',
  sublicenseTitle: 'Sublicense agreement',
  form: {
    fullName: 'Full name',
    username: 'Login (email)',
    phoneNumber: 'Phone number',
    phoneNumberSearchPlaceholder: 'Search',
    password: 'Password',
    passwordConfirm: 'Password confirmation',
    agreement: 'By clicking the "Sign up" button, you accept the terms ',
    and: ' and ',
  },
  title: 'Registration',
  registrationResult: {
    result: 'Registration completed',
    info: 'To confirm your account, click the link sent to the email',
    ps: 'You can close this tab',
  },
  registrationButton: 'Sign up',
  backToSignInLink: 'Back to Login Page',
  validation: {
    fullNameRequired: 'Required',
    fullNameLength: 'Use at least 3 characters',
    emailRequired: 'Required',
    emailValid: 'Login must be a valid email address',
    passwordLength: 'Use 8 or more characters',
    passwordRequired: 'Required',
    passwordValid:
      'Use a mix of lowercase and uppercase latin letters, numbers and symbols',
    passwordConfirmRequired: 'Required',
    passwordConfirmValid: 'Passwords do not match',
  },
}
