import { Observable } from 'rxjs'
import { IAPIResult } from '../../IBaseService'
import { BaseApiService } from '../../base.service'
import * as I from 'Types'
import { TMap } from '../../../constants'
import { IAttemptSolution } from '../../../components/TaskViewerAndReviewer/ITaskViewerAndReviewer'
import { EAPIEndpoint } from '../../constants'

const head: TMap = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export default class TasksCheckService {
  static getAttemptSolution(
    attemptId: number
  ): Observable<IAPIResult<IAttemptSolution>> {
    const baseApiService = new BaseApiService<IAttemptSolution>()
    return baseApiService.getRequest(
      EAPIEndpoint.TASK_VERIFICATION.replace(':attemptId', attemptId.toString())
    )
  }

  static updateAttemptSolution(
    data: I.IUpdateAttemptSolution
  ): Observable<IAPIResult<void>> {
    const baseApiService = new BaseApiService<void>()
    return baseApiService.postRequest(EAPIEndpoint.TASKS_VERIFICATION, head, {
      id: data.id,
      status: data.status,
      answerKeys: data.answerKeys,
      comments: data.comments,
    })
  }

  static getAcceptedCount(): Observable<IAPIResult<I.ITasksForReviewCont>> {
    const baseApiService = new BaseApiService<I.ITasksForReviewCont>()
    return baseApiService.getRequest(EAPIEndpoint.TASKS_VERIFICATION_COUNT)
  }
}
