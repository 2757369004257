export const tracksManagement = {
  filter: {
    itemSearch: 'Rota bul',
  },
  importButton: 'İçe aktar',
  emptyData: {
    noManage: 'Eklenmiş parça yok',
    noData: 'Seçilen filtrelerle eşleşen veri yok',
  },
  trackCardModal: {
    okText: 'Kaydet',
    cancelText: 'Kaldır',
    description: 'Rota açıklamasını girin',
  },
  notification: {
    cardEditNotification: 'Rota açıklaması değişti',
    importNotification: 'İçe aktarma tamamlandı',
  },
  tabs: {
    view: 'Bak:',
    grid: 'Çini',
    table: 'Tablo',
  },
  rootAreaTitle: 'Genel',
  selectFolder: 'Bir klasör seçin',
  addFolder: 'Klasör Ekle',
  cardView: {
    header: 'Katalog',
    actionBtn: {
      showHide: 'Göster/Gizle',
      edit: 'Düzenle',
      add: 'Ekle',
      delete: 'Silme',
    },
  },
  deleteAreaModalTitle: 'klasör',
  addAndEditAreaModal: {
    titleEdit: 'Klasör yapılandırılıyor',
    titleAdd: 'Klasör ekleme',
    forma: {
      groupName: {
        label: 'Klasör adı:',
        placeholder: 'Bir klasör adı girin',
        message: 'Gerekli alan',
      },
      attachedTo: {
        label: 'Yol:',
        placeholder: 'Bir klasör seçin',
      },
      tracks: {
        label: 'Parçalar:',
        placeholder: 'Parçaları seç',
      },
    },
  },
  viewStatistics: 'İstatistikleri görüntüle',
  cardSizeTooltip: 'İz kartının boyutu',
}
