import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { Breadcrumb, Typography } from 'antd'
import _ from 'lodash'
import { EPages } from '../../constants'
import Styles from './Breadcrumb.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../app/store'
import {
  selectTaskName,
  selectTrackName,
  selectUserName,
  getTaskTitle,
  getTrackTitle,
  getUserName,
  resetBreadcrumbStore,
  setTaskName,
} from './BreadcrumbSlice'
import { updateNodeTaskTitle } from '../../features/task-edit/taskEditSlice'
import { useTitle } from './useTitle'
import local from './../../localization'
import spriteIcons from '../../assets/svg/sprite-icons.svg'

/** Get the height of the breadcrumb and title component (function) */
export let getTotalHeight: any

const breadcrumbNameMap: { [key in EPages]?: string } = {
  [EPages.PROFILE]: local.main.breadcrumb.profile,
  [EPages.AVAIBLE_TRACKS]: local.main.breadcrumb.avaibleTracks,
  [EPages.TRACKS_MANAGEMENT]: local.main.breadcrumb.tracksManagement,
  [EPages.CHECK_TASKS]: local.main.breadcrumb.checkTasks,
  [EPages.USERS]: local.main.breadcrumb.users,
  [EPages.POSITIONS]: local.main.breadcrumb.positions,
  [EPages.REFERENCE]: local.main.breadcrumb.reference,
}

export const BreadcrumbList: React.FC = (): React.ReactElement => {
  const updateTitle = useTitle()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const taskName = useSelector(selectTaskName)
  const trackName = useSelector(selectTrackName)
  const userName = useSelector(selectUserName)

  const [title, setTitle] = useState<string>('')
  const [breadcrumbs, setBreadcrumbs] = useState<Array<JSX.Element>>([])

  /** The breadcrumb and title wrapper element */
  const bcContainer = React.useRef<any>(null)

  const setTitleIcon = React.useCallback((title: string) => {
    switch (title) {
      case local.main.breadcrumb.profileSettings:
        return (
          <svg width={22} height={22}>
            <use xlinkHref={`${spriteIcons}#header-settings-icon`}></use>
          </svg>
        )
      case local.main.breadcrumb.reference:
        return (
          <svg width={22} height={22}>
            <use xlinkHref={`${spriteIcons}#header-info-icon`}></use>
          </svg>
        )
      default:
        break
    }
  }, [])

  const getCustomBreadcrumbs = React.useCallback(() => {
    const breadcrumbItems = [
      <Breadcrumb.Item key={EPages.PROFILE}>
        <Link to={EPages.PROFILE}>{local.main.breadcrumb.profile}</Link>
      </Breadcrumb.Item>,
    ]

    let defaultTitle = local.main.breadcrumb.profile

    const pathList = _.map(EPages, k => k)
    let match

    let i = 0

    while (!match && i < pathList.length) {
      const path = pathList[i]
      match = matchPath(location.pathname, {
        path,
        strict: true,
        exact: true,
      })

      if (match) {
        switch (match.path) {
          case EPages.PROFILE: {
            defaultTitle = local.main.breadcrumb.profile
            updateTitle(defaultTitle)
            break
          }

          case EPages.STATS_BY_USERS: {
            defaultTitle = local.main.breadcrumb.statsByUsers
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.STATS_BY_USERS}>
                <Link to={EPages.STATS_BY_USERS}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.STATS_BY_USER_FOR_ITEM: {
            const { trackId, userId } = match.params as any

            if (trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              return
            }

            if (userName === undefined) {
              dispatch(getUserName(Number(userId)))
              return
            }

            defaultTitle = local.main.breadcrumb.statsByUserForItem
              .replace(':UserName', `${userName}`)
              .replace(':TrackName', `${trackName}`)
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.STATS_BY_USERS}>
                <Link to={EPages.STATS_BY_USERS}>
                  {local.main.breadcrumb.statsByUsers}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.STATS_BY_USER_FOR_ITEM}>
                {defaultTitle}
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.STATS_BY_ITEMS: {
            defaultTitle = local.main.breadcrumb.statsByItems
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.STATS_BY_ITEMS}>
                <Link to={EPages.STATS_BY_ITEMS}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.TRACKS_MANAGEMENT: {
            defaultTitle = local.main.breadcrumb.tracksManagement
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.TRACKS_MANAGEMENT}>
                <Link to={EPages.TRACKS_MANAGEMENT}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.TRACKS_MANAGEMENT_DETAILS: {
            const { trackId } = match.params as any

            if (trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              return
            }

            defaultTitle = trackName
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.TRACKS_MANAGEMENT}>
                <Link to={EPages.TRACKS_MANAGEMENT}>
                  {breadcrumbNameMap[EPages.TRACKS_MANAGEMENT]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.TRACKS_MANAGEMENT_DETAILS}>
                <Link
                  to={EPages.TRACKS_MANAGEMENT_DETAILS.replace(
                    ':trackId',
                    trackId
                  )}
                >
                  {defaultTitle}
                </Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.TRACKS_MANAGEMENT_DETAILS_EDIT_TASK_DETAILS: {
            const { taskId, trackId } = match.params as any

            if (taskName === undefined || trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              dispatch(getTaskTitle(taskId))
              return
            }

            defaultTitle = taskName
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.TRACKS_MANAGEMENT}>
                <Link to={EPages.TRACKS_MANAGEMENT}>
                  {breadcrumbNameMap[EPages.TRACKS_MANAGEMENT]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.TRACKS_MANAGEMENT_DETAILS}>
                <Link
                  to={EPages.TRACKS_MANAGEMENT_DETAILS.replace(
                    ':trackId',
                    trackId
                  )}
                >
                  {trackName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item
                key={EPages.TRACKS_MANAGEMENT_DETAILS_EDIT_TASK_DETAILS}
              >
                <Link to={match.url}>{taskName}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.AVAIBLE_TRACKS: {
            defaultTitle = local.main.breadcrumb.avaibleTracks
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS}>
                <Link to={EPages.AVAIBLE_TRACKS}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.PROFILE_SETTINGS: {
            defaultTitle = local.main.breadcrumb.profileSettings
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.PROFILE_SETTINGS}>
                <Link to={EPages.PROFILE_SETTINGS}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.USERS_PROFILE: {
            const { userId } = match.params as any
            if (userName === undefined) {
              dispatch(getUserName(Number(userId)))
              return
            }
            defaultTitle = local.main.breadcrumb.usersProfile.replace(
              ':UserName',
              `${userName}`
            )
            updateTitle(userName!)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.USERS_PROFILE}>
                <Link to={EPages.USERS_PROFILE}>
                  {local.main.breadcrumb.usersProfile.replace(
                    ':UserName',
                    `${userName}`
                  )}
                </Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.AVAIBLE_TRACKS_NODES: {
            const { trackId } = match.params as any

            if (trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              return
            }

            defaultTitle = trackName
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS}>
                <Link to={EPages.AVAIBLE_TRACKS}>
                  {breadcrumbNameMap[EPages.AVAIBLE_TRACKS]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_NODES}>
                <Link to={match.url}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.AVAIBLE_TRACKS_PASSING: {
            const { taskId, trackId } = match.params as any

            if (taskName === undefined || trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              dispatch(getTaskTitle(taskId))
              return
            }

            defaultTitle = taskName
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS}>
                <Link to={EPages.AVAIBLE_TRACKS}>
                  {breadcrumbNameMap[EPages.AVAIBLE_TRACKS]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_NODES}>
                <Link
                  to={EPages.AVAIBLE_TRACKS_NODES.replace(':trackId', trackId)}
                >
                  {trackName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_PASSING}>
                <Link to={match.url}>{defaultTitle}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.AVAIBLE_TRACKS_PASSING_START:
          case EPages.AVAIBLE_TRACKS_PASSING_RESULT: {
            const { taskId, trackId } = match.params as any

            if (taskName === undefined || trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              dispatch(getTaskTitle(taskId))
              return
            }

            defaultTitle = `${local.main.task}: «${taskName}»`
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS}>
                <Link to={EPages.AVAIBLE_TRACKS}>
                  {breadcrumbNameMap[EPages.AVAIBLE_TRACKS]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_NODES}>
                <Link
                  to={EPages.AVAIBLE_TRACKS_NODES.replace(':trackId', trackId)}
                >
                  {trackName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_PASSING}>
                <Link
                  to={EPages.AVAIBLE_TRACKS_PASSING.replace(
                    ':trackId',
                    trackId
                  ).replace(':taskId', taskId)}
                >
                  {taskName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_PASSING_START}>
                {local.main.task}
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.AVAIBLE_TRACKS_VIEW_PASSING_TASK: {
            const { taskId, trackId } = match.params as any

            if (taskName === undefined || trackName === undefined) {
              dispatch(getTrackTitle(trackId))
              dispatch(getTaskTitle(taskId))
              return
            }

            defaultTitle = `${local.main.postSolution}: «${taskName}»`
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS}>
                <Link to={EPages.AVAIBLE_TRACKS}>
                  {breadcrumbNameMap[EPages.AVAIBLE_TRACKS]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_NODES}>
                <Link
                  to={EPages.AVAIBLE_TRACKS_NODES.replace(':trackId', trackId)}
                >
                  {trackName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_PASSING}>
                <Link
                  to={EPages.AVAIBLE_TRACKS_PASSING.replace(
                    ':trackId',
                    trackId
                  ).replace(':taskId', taskId)}
                >
                  {taskName}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.AVAIBLE_TRACKS_PASSING_START}>
                {local.main.postSolution}
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.CHECK_TASK_DETAILS: {
            if (!taskName) {
              const { taskId } = match.params as any
              dispatch(getTaskTitle(taskId))
              return
            }

            defaultTitle = `${local.main.task}: «${taskName}»`
            updateTitle(defaultTitle)

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.CHECK_TASKS}>
                <Link to={EPages.CHECK_TASKS}>
                  {breadcrumbNameMap[EPages.CHECK_TASKS]}
                </Link>
              </Breadcrumb.Item>
            )

            breadcrumbItems.push(
              <Breadcrumb.Item key={EPages.CHECK_TASK_DETAILS}>
                <Link to={match.url}>{taskName}</Link>
              </Breadcrumb.Item>
            )

            break
          }
          case EPages.NOT_FOUND: {
            defaultTitle = local.main.breadcrumb.notFound
            updateTitle(defaultTitle)

            break
          }
          default: {
            defaultTitle = breadcrumbNameMap[match.path as EPages]!!
            updateTitle(defaultTitle)
            const pathSnippets = match.path.split('/').filter(i => i)

            const extraBreadcrumbItems = pathSnippets.map((_, index) => {
              const url = `/${pathSnippets
                .slice(0, index + 1)
                .join('/')}` as EPages
              return (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{breadcrumbNameMap[url]}</Link>
                </Breadcrumb.Item>
              )
            })

            breadcrumbItems.push(...extraBreadcrumbItems)
          }
        }
      }
      i++
    }

    setTitle(defaultTitle)
    setBreadcrumbs(breadcrumbItems)
  }, [dispatch, location.pathname, taskName, trackName, updateTitle, userName])

  getTotalHeight = React.useCallback(() => {
    if (!bcContainer.current) return
    const getBcContainerHeight = bcContainer.current?.clientHeight
    return getBcContainerHeight + 44
  }, [])

  useEffect(() => {
    dispatch(resetBreadcrumbStore())
    getTotalHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const [isEditPage, setIsEditPage] = useState(false);

  useEffect(() => {
    setIsEditPage(!!matchPath(location.pathname, {
      path: EPages.TRACKS_MANAGEMENT_DETAILS_EDIT_TASK_DETAILS,
      exact: true
    }));
  }, [location.pathname]);

  useEffect(() => getCustomBreadcrumbs(), [getCustomBreadcrumbs])

  const handleTitleChange = useCallback((newTitle: string) => {
    if (newTitle !== '') {
      dispatch(setTaskName(newTitle));
      updateTitle(newTitle);
      dispatch(updateNodeTaskTitle(newTitle));
    }
  }, [dispatch, updateTitle]);

  return (
    <div ref={bcContainer} className={Styles.container}>
      <div className={Styles.breadcrumb_wrapper}>
        <Breadcrumb separator='•' className={Styles.breadcrumb}>
          {breadcrumbs}
        </Breadcrumb>
      </div>
      <div className={Styles.title_wrapper}>
        {setTitleIcon(title)}
        <Typography.Title
          className={Styles.title}
          editable={isEditPage && {
            onChange: handleTitleChange
          }}
          onClick={() => {
            const editButton = document.querySelector('.ant-typography-edit') as HTMLElement;
            editButton?.click();
          }}
        >
          {title}
        </Typography.Title>
      </div>
    </div>
  )
}
