import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  enqueueSnackbar as pushErrorToStore,
  removeSnackbar,
  getAllNotifications,
} from '../notificationSlice'
import { useAuthErrors } from '../../../services/auth/provider/processError'
import { Subscription } from 'rxjs'
import { useTheme } from '../../../theme/Theme'

export const Notifier: React.FC = (): null => {
  const [onError$] = useAuthErrors()
  const [displayed, setDisplayed] = useState<Array<number>>([])
  const dispatch = useDispatch()
  const notifications = useSelector(getAllNotifications)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { themeProps } = useTheme()

  const storeDisplayed = React.useCallback(
    (key: number): void => {
      setDisplayed([...displayed, key])
    },
    [displayed]
  )

  const removeDisplayed = React.useCallback(
    (key: number): void => {
      setDisplayed([...displayed.filter(k => k !== key)])
    },
    [displayed]
  )

  // Listen for errors that occurred outside of components, for example in the authorization service and add them to the stor
  React.useEffect(() => {
    const subscriptions: Array<Subscription> = new Array<Subscription>()

    subscriptions.push(
      onError$.subscribe((error: any) => {
        if (error) {
          dispatch(pushErrorToStore(error))
        }
      })
    )

    return (): void => {
      if (subscriptions.length) {
        subscriptions.forEach(s => s.unsubscribe())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onError$])

  // We run through the queue of notifications and show them, otherwise we remove them from the queue
  React.useEffect(() => {
    notifications.forEach(({ message, options, dismissed = false }) => {
      const key = options.key

      if (dismissed) {
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (key && displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
        preventDuplicate: true,
        style: { ...(themeProps as React.CSSProperties) },
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (_event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(Number(myKey)))
          removeDisplayed(Number(myKey))
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, closeSnackbar, enqueueSnackbar])

  return null
}
