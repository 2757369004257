import { createAuthProvider } from '../auth/provider/authProvider'

export const [
  useAuth,
  authFetch,
  isInRole,
  refreshToken,
  AuthService,
  logout,
] = createAuthProvider({})
