export const taskCheckAndView = {
  header: {
    item: 'Map',
    attempt: 'Attempt',
  },
  body: {
    testAnswers: 'Tests (checked automatically)',
    verifiedButton: 'Accept the task',
    systemCheckButton: 'Accept the task',
    rejectedButton: 'Reject the task',
    rejectModal: {
      title: 'No comments',
      content:
        'Are you sure you want to reject the task without adding your comments?',

      cancelText: 'Cancel',
      okText: 'Confirm',
    },
    tooltip: 'Select result above',
    submitButton: 'Confirm',
    returnButton: 'Back to Review Tasks',
    question: {
      questionTitle: {
        test: 'Test question',
        text: 'Detailed question',
        video: 'Video question',
        default: 'Unknown question type:',
        task: 'Task № ',
      },
      videoQuestion: 'Video question',
      testQuestion: {
        text1: 'Correct options are marked ',
        right: 'green',
        text2: ', incorrect ',
        wrong: 'red',
      },
      breakpointsTitle: 'Correct answer keys',
    },
    answer: {
      title: "User's answer:",
      commentCheckTitle: 'Comment on the answer:',
      commentCheckTitleinfo:
        'This comment will be emailed to the User along with the result of the task check.',
      commentViewTitle: "Mentor's сomment:",
      videoAnswer: 'Video answer:',
      notPassed: 'Answer not accepted',
    },
  },
  checked: {
    title: 'Checked by',
    timeChecked: 'Checked',
    estimateChecked: 'Checking time',
    automatically: 'Automatically',
    hour: 'h',
    minute: 'm',
    second: 's',
    rezult: {
      title: 'Result',
      comfirmed: 'Accepted',
      rejected: 'Rejected',
    },
    mentor: 'Checked by',
    empty: 'Not checked',
  },
  fulfilled: {
    title: 'Completed by',
    timeFulfilled: 'Completed',
    estimateFulfilled: {
      title: 'Estimated time',
      notSet: 'Not set',
      minute: 'm',
    },
    leadTime: {
      title: 'Actual time',
      limit: 'LIMIT',
      hour: 'h',
      minute: 'm',
      second: 's',
    },
    user: 'Completed by',
  },
  notification: {
    editComment: 'Comment changed',
    verification: {
      confirmed: 'Task completion accepted',
      sentForReview: 'The task has been submitted for checking',
      rejected: 'Task completion rejected',
    },
    error: {
      solutionNotFound: 'No solution found for this attempt',
    },
  },
}
