import React, { FC, useEffect, useMemo, useState } from 'react'
import { Popover } from 'antd'
import { useTheme } from '../../../theme/Theme'
import Styles from './EISPopoverValidation.module.scss'
import local from './../../../localization'
import { TooltipPlacement } from 'antd/lib/tooltip'

interface IRuleValidate {
  isLength: boolean
  isSmallLetters: boolean
  isBigLetters: boolean
  isDigits: boolean
  isSpecialSymbol: boolean
}

interface IPopoverValidation {
  ruleValidate: IRuleValidate
  children: React.ReactNode
}

const EISPopoverValidation: FC<IPopoverValidation> = ({
  children,
  ruleValidate,
}) => {
  const { themeProps } = useTheme()
  const [placement, setPlacement] = useState<TooltipPlacement>('topLeft')
  const screenWidth = window.innerWidth

  const validationContent = useMemo(() => {
    return (
      <ul style={{ ...(themeProps as React.CSSProperties) }}>
        <li className={ruleValidate?.isLength ? Styles.done : ''}>
          {local.main.validation.passwordLength}
        </li>
        <li
          className={
            ruleValidate?.isBigLetters || ruleValidate?.isSmallLetters
              ? Styles.done
              : ''
          }
        >
          {local.main.validation.upperLowerCaseLetters}
        </li>
        <li
          className={
            ruleValidate?.isDigits || ruleValidate?.isSpecialSymbol
              ? Styles.done
              : ''
          }
        >
          {local.main.validation.includeDigitsOrSymbols}
        </li>
      </ul>
    )
  }, [ruleValidate, themeProps])

  useEffect(() => {
    setPlacement(screenWidth <= 1200 ? 'top' : 'topLeft')
  }, [screenWidth])

  return (
    <Popover
      placement={placement}
      content={validationContent}
      title={local.main.validation.passwordValidTitle}
      trigger={'focus'}
    >
      {children}
    </Popover>
  )
}

export default EISPopoverValidation
