import local from '../../localization'
import { IKeyValuePair } from '../../constants'
import { enqueueSnackbar } from '../../features/notification/notificationSlice'
import { ENotificationVariant } from '../../constants'
import { store } from '../../app/store'

export const isZeroFileHelper = (file: File) => {
  if (!file || file.size / 1024 / 1024 <= 0) {
    store.dispatch(
      enqueueSnackbar({
        message: local.notification.general.ERROR.corruptOrEmptyFile,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: ENotificationVariant.ERROR,
        },
      })
    )
    return true
  } else {
    return false
  }
}

// TODO: this is a temporary crutch (needs to be refactored)
/** Takes IKeyValuePair[] and returns an adapted format `{label: string, value: string}` for OptionSelect */
export const adaptedIKeyValuePairToOptionSelect = (items: IKeyValuePair[]) => {
  return items.map(m => ({ label: m.value, value: m.value }))
}
