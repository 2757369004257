export const registration = {
  agreementPersonDataTitle: 'Consent to personal data processing',
  agreementUserTitle: 'User agreement',
  sublicenseTitle: 'Sublicense agreement',
  form: {
    fullName: 'Full name',
    username: 'Login (email)',
    phoneNumber: 'Phone number',
    phoneNumberSearchPlaceholder: 'Search',
    password: 'Password',
    passwordConfirm: 'Password confirmation',
    agreement: 'By clicking the "Sign up" button, you accept the terms ',
    and: ' and ',
  },
  title: 'Registration',
  registrationResult: {
    result: 'Registration completed',
    info: 'To confirm your account, click the link sent to the email',
    ps: 'You can close this tab',
  },
  registrationButton: 'Sign up',
  backToSignInLink: 'Back to Login Page',
}
